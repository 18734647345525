import { localizer } from "../../../../utils/Localizer";
import ModalWrapper, { EModalSize } from "../../../common/modal/ModalWrapper";
import { StyledAddButton } from "../../../styled";
import CaregiverAddForm from "./CaregiverAddForm";

const CaregiverAddButton = () => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" data-qa="add_caregiver_user_button"/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<CaregiverAddForm />}
            modalTitle={`${localizer("users.addButton.Caregiver")}`}
            size={EModalSize.S}
        />
    );
};

export default CaregiverAddButton;
