import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Colors from "../../utils/Colors";

const StyledFormGroup = styled(Form.Group)`
    margin-bottom: 16px;
    width: 100%;
    ${(props) => props.hasErrors && StyledFormHasError}
`;

const StyledFormHasError = `
    label {
        color: ${Colors.alert.red};
    }

    button {
        border-color: ${Colors.alert.red} !important;
        background: ${Colors.alert.redFill};
    }

    input, .react-select__control {
        border-color: ${Colors.alert.red} !important;
        background: ${Colors.alert.redFill};
        box-shadow: none !important;
    }
`;

const StyledLabel = styled.label`
    margin-bottom: 8px;
`;

const StyledDiv = styled.div<any>`
    position: ${(props) => props.multiErrorStack ? 'inherit' : 'absolute'};
    max-width: ${(props) => props.fullWidthMessage ? '100%' : '360px'};
    font-size: 12px;
    color: ${Colors.alert.red};
    display: block;
`;

export interface IErrors {
    dependentErrorCode: string;
    values?: any;
}

interface IValidatedFormGroupWrapperProps {
    children: JSX.Element | JSX.Element[];
    validationErrors: string[] | IErrors[];
    dependentErrorCodes?: string[];
    label?: string | undefined;
    hideMessage?: boolean;
    multiErrorStack?: boolean;
    fullWidthMessage?: boolean;
}

const ValidatedFormGroupWrapper = (props: IValidatedFormGroupWrapperProps) => {
    const getErrorMessage = (errorCode: string | IErrors) => {
        if (errorCode) {
            if (typeof errorCode === "string") {
                return <FormattedMessage id={errorCode} />;
            } else {
                return (
                    <FormattedMessage
                        id={errorCode.dependentErrorCode}
                        values={errorCode.values}
                    />
                );
            }
        } else {
            return "";
        }
    };

    const activeErrorsCodes = (
        props.validationErrors as Array<string | IErrors>
    ).filter((validationError: string | IErrors) =>
        props.dependentErrorCodes
            ? props.dependentErrorCodes.some((errorCode: string) =>
                typeof validationError === "string"
                    ? validationError === errorCode
                    : validationError.dependentErrorCode === errorCode
            )
            : []
    );

    return (
        <StyledFormGroup hasErrors={activeErrorsCodes.length > 0}>
            {props.label && (
                <StyledLabel>
                    <FormattedMessage id={props.label} />
                </StyledLabel>
            )}
            {props.children}
            {activeErrorsCodes.map((error: string | IErrors) => {
                return (
                    <StyledDiv
                        data-qa={(typeof error === "string" ? error : error.dependentErrorCode) + "-error_message"}
                        multiErrorStack={props.multiErrorStack}
                        fullWidthMessage={props.fullWidthMessage}
                    >
                        {!props.hideMessage && getErrorMessage(error)}
                    </StyledDiv>
                )
            })}
        </StyledFormGroup>
    );
};

export default ValidatedFormGroupWrapper;
