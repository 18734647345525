import { FormattedMessage } from "react-intl";
import ResidentAddButton from '../../routes/Residents/ResidentAddButton';
import { StyledAmount, StyledDescription, StyledTitle } from "../../components/common/informationBlock/styled";
import { StyledInlineFlexStart } from '../../components/styled';
import { Ward } from "../../models/Ward";

export interface IProps {
    limitedAccess: boolean;
    amountOfActiveResidents: number;
    amountOfAllResidents: number;
    selectedWards: Ward[];
}

const ResidentsInformationBlock = (props: IProps) => (
    <>
        <div>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={"residents.informationBlocks.title.residents"} /></StyledTitle>
                {!props.limitedAccess && <ResidentAddButton selectedWards={props.selectedWards} />}
            </StyledInlineFlexStart>
            <StyledDescription>
                <FormattedMessage id={'constants.ResidentStatus.active'} /> / <FormattedMessage id={'constants.ResidentStatus.all'} />
            </StyledDescription>
        </div>
        <StyledAmount>
            {`${props.amountOfActiveResidents}/${props.amountOfAllResidents}`}
        </StyledAmount>
    </>
);

export default ResidentsInformationBlock;
