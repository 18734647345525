import React, { useState } from 'react';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NursingHome } from '../../../models/NursingHome';
import NursingHomeAddEditForm from '../../../routes_admin/NursingHomes/NursingHomeAddEditForm';
import ModalWrapper, { EModalSize } from '../../common/modal/ModalWrapper';
import { localizer } from '../../../utils/Localizer';
import { Icon } from '../../common/table/Icon';

interface INursingHomeEditIconProps {
    nursingHome: NursingHome;
}

const NursingHomeEditIcon = (props: INursingHomeEditIconProps) => {
    const [modalSize, setModalSize] = useState<EModalSize>(EModalSize.M);

    const triggerComponent: React.ReactNode = (
        <Icon
            icon={faPencilAlt}
            text={"nursingHomes.editButton"}
            qaId={props.nursingHome.name.toLowerCase() + "-edit_button"}
        />
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={(
                <NursingHomeAddEditForm
                    entity={props.nursingHome}
                    onModalSizeChange={setModalSize}
                />
            )}
            fixedFooter={true}
            modalTitle={localizer("nursingHomes.editForm.title", { name: props.nursingHome?.name })}
            size={modalSize}
        />
    );
};

export default NursingHomeEditIcon;
