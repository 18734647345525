import { Configuration } from '@azure/msal-browser';
import { cleanupRedux } from '../reducers/dataHelper';
import { toastLogoutInfo } from '../utils/Toaster';
import { cleanupLocalStorage } from "./Persistence";
import { AuthRestClient } from './RestClient';

export const msalAuthParams = {
    scopes: ["openid", "profile", "User.Read"],
    prompt: 'login',
};

export const silentLoginRequest = {
    account: {},
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: false,
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: false,
};

export const msalConfig: Configuration = {
    auth: {
        authority: process.env.REACT_APP_AAD_AUTHORITY_URL!,
        clientId: process.env.REACT_APP_AAD_CLIENT_ID!
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const handleLogout = async (message?: string| null) => {
    cleanupLocalStorage();
    cleanupRedux();
    message && toastLogoutInfo(message);
    sessionStorage.removeItem("blockFetchUserResources");
    await AuthRestClient.logout();
};

export const browserLoginWithAdToken = async (idToken: string): Promise<number> => {
    if (idToken) {
        const response = await AuthRestClient.loginWithAdToken(idToken);
        const sessionTimeoutMinutes = response.sessionTimeoutMinutes!;

        return Promise.resolve(sessionTimeoutMinutes);
    }
    return Promise.reject();
};

