import { useEffect, useState } from "react";
import UsersTable from "../users/tables/UsersTable";
import UserFilterInformationBlock from "../UserCountryFilterInformationBlock";
import { useDispatch, useSelector } from "react-redux";
import { IContextStateType } from "../../../../reducers/contextSlice";
import {
    fetchUsers,
    IUserStateType,
    NursingHomeRole,
    ResourceTypes,
    setChildResourceId,
    setChildResourceType,
    UserRole
} from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from "../../../common/View";
import { localizer } from "../../../../utils/Localizer";
import UserAddInformationBlock from "../UserAddInformationBlock";
import UserAddButton from "../users/UserAddButton";
import CaregiverAddButton from "../caregivers/CaregiverAddButton";
import CareGiversTable from "../caregivers/tables/CareGiversTable";
import SupportUserAddButton from "../support/SupportUserAddButton";
import SupportsTable from "../support/tables/SupportsTable";

const NursingHomeManagerUserView = () => {
    const dispatch = useDispatch();
    const context = useSelector<RootState, IContextStateType>(
        (state) => state.contextSlice
    );

    const usersTypes = {
        Nurses: "Nurses",
        CareGivers: "CareGivers",
        Support: "Support",
    };

    const usersTypesOptions = [
        {
            label: localizer("users.filters.nursesOption"),
            value: usersTypes.Nurses,
        },
        {
            label: localizer("users.filters.careGiversOption"),
            value: usersTypes.CareGivers,
        },
        {
            label: localizer("users.filters.supportUsersOption"),
            value: usersTypes.Support,
        },
    ];

    const [userType, setUserType] = useState(usersTypes.Nurses);
    const { childResourceId, users } = useSelector<RootState, IUserStateType>(
        (state) => state.userSlice
    );

    useEffect(() => {
        dispatch(setChildResourceType(ResourceTypes.NursingHome));
        dispatch(setChildResourceId(context.id));
    }, [userType, context]);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [childResourceId]);

    const nurses = users.filter((user) => user.role === "Nurse");
    const caregivers = users.filter((user) => user.role === "Caregiver");
    const supportUsers = users.filter((user) => user.role === "Support");

    const getAddTile = () => {
        switch (userType) {
            case usersTypes.Support:
                return (
                    <StyledInformationBlockWrapper data-qa="user_support_card">
                        <UserAddInformationBlock
                            addButton={<SupportUserAddButton />}
                            amountOfUsers={supportUsers.length}
                        />
                    </StyledInformationBlockWrapper>
                );
            case usersTypes.CareGivers:
                return (
                    <StyledInformationBlockWrapper data-qa="user_caregiver_card">
                        <UserAddInformationBlock
                            addButton={<CaregiverAddButton />}
                            amountOfUsers={caregivers.length}
                        />
                    </StyledInformationBlockWrapper>
                );
            default:
                return (
                    <StyledInformationBlockWrapper data-qa="user_nurse_card">
                        <UserAddInformationBlock
                            addButton={
                                <UserAddButton
                                    assignedRole={NursingHomeRole.Nurse}
                                    titleBasedOnRole={UserRole.Nurse}
                                />
                            }
                            amountOfUsers={nurses.length}
                        />
                    </StyledInformationBlockWrapper>
                );
        }
    };
    const getTable = () => {
        switch (userType) {
            case usersTypes.Support:
                return <SupportsTable data={supportUsers} />;
            case usersTypes.CareGivers:
                return <CareGiversTable data={caregivers} />;
            default:
                return <UsersTable data={nurses} />;
        }
    };

    return (
        <StyledViewContainer>
            <StyledInformationBlocks alignLeft>
                <StyledInformationBlockWrapper data-qa="user_type_card">
                    <UserFilterInformationBlock
                        qaId="user_type_dropdown"
                        title={localizer(
                            "users.informationBlocks.title.selectUserType"
                        )}
                        name="userTypeFilter"
                        selectOption={setUserType}
                        selectedValue={userType}
                        options={usersTypesOptions}
                    />
                </StyledInformationBlockWrapper>
                {getAddTile()}
            </StyledInformationBlocks>
            {getTable()}
        </StyledViewContainer>
    );
};

export default NursingHomeManagerUserView;
