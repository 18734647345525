import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Colors from "../utils/Colors";

const StyledConnectedIndicator = styled.div`
    margin-right: 25px;
    font-size: 12px;
    color: ${Colors.green.dark};
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: bold;
    display: flex;
    align-items: center;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    margin-right: 6px;
    font-size: 8px;
`;

const ConnectedIndicator = () => (
    <StyledConnectedIndicator>
        <StyledFontAwesome icon={faCircle}/>
        <span><FormattedMessage id={'titleBar.connected'} /></span>
    </StyledConnectedIndicator>
);

export default ConnectedIndicator;
