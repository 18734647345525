import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import CheckBox from "../../components/common/controls/CheckBox";
import { EModalSize } from '../../components/common/modal/ModalWrapper';
import { Country } from '../../models/Country';
import ErrorCodes from '../../models/ErrorCodes';
import { nursingHomeToReqBody } from '../../models/helpers/RequestHelpers';
import { NursingHome } from "../../models/NursingHome";
import NursingHomeGroup from '../../models/NursingHomeGroup';
import { Roles } from '../../models/Role';
import { IContextStateType } from '../../reducers/contextSlice';
import { validateNursingHome } from "../../reducers/nursingHomeHelpers";
import {
    createNursingHome, deleteNursingHome, updateNursingHome
} from '../../reducers/nursingHomeSlice';
import DayNightTimes from '../../routes/Setup/DayNightTimes';
import Notifications from '../../routes/Setup/Notifications';
import { RootState } from '../../store';
import '../../styles/main.scss';
import { localizer } from "../../utils/Localizer";
import AddEditButtonGroup from '../../views/Buttons/AddEditButtonGroup';
import ConfirmDeleteForm from '../../views/ConfirmDeleteForm';
import AdminNursingHomePreferences from './AdminNursingHomePreferences';
import './NursingHomeAddEditForm.scss';


export enum NursingHomeAddEditFields {
    Name = 'Name',
    MorningShiftStartTime = 'MorningShiftStartTime',
    EveningShiftStartTime = 'EveningShiftStartTime',
    ApplyByTime = 'ApplyByTime',
    SupervisorNotificationInterval = 'SupervisorNotificationInterval',
    SupervisorNotificationIntervalDay = 'SupervisorNotificationIntervalDay',
    SupervisorTargetResponseTimeDay = 'SupervisorTargetResponseTimeDay',
    SupervisorTargetResponseTime = 'SupervisorTargetResponseTime',
    CaregiverNotificationIntervalDay = 'CaregiverNotificationIntervalDay',
    CaregiverTargetResponseTimeDay = 'CaregiverTargetResponseTimeDay',
    CaregiverNotificationInterval = 'CaregiverNotificationInterval',
    CaregiverResponseTarget = 'CaregiverResponseTarget',
    MaxLoggedInInactivityTime = 'MaxLoggedInInactivityTime',
    ReapplyHours = 'ReapplyHours',
    IsWeakAuthenticationAllowed = 'IsWeakAuthenticationAllowed',
    Is2FAActive = 'is2FAActive',
    CustomerId = 'CustomerId',
    IsActive = 'IsActive',
    MarketCode = 'MarketCode',
    MorningShiftNotificationTime = 'MorningShiftNotificationTime',
    NightShiftNotificationTime = 'NightShiftNotificationTime',
    ShiftNotificationTimeDisabled = 'ShiftNotificationTimeDisabled',
    EightHourNotification = "EightHourNotification"
}

enum EditStates {
    Edit,
    ConfirmDelete
}

interface INursingHomeAddEditFormProps {
    entity: NursingHome | undefined;
    hideModal?: () => void;
    onModalSizeChange?: (modalSize: EModalSize) => void;
}

const StyledMarginTop = styled.div`
    margin-top: 40px;
`;

const NursingHomeAddEditForm = (props: INursingHomeAddEditFormProps) => {
    const context = useSelector<RootState, IContextStateType>(state => state.contextSlice);
    const countries = useSelector<RootState, Country[]>(state => state.countrySlice.countries);
    const nursingHomeGroups = useSelector<RootState, NursingHomeGroup[]>(state => state.nursingHomeGroupSlice.nursingHomeGroups);
    const nursingHomes = useSelector<RootState, NursingHome[]>(state => state.nursingHomeSlice.nursingHomes);

    const getMarketCode = () => {
        switch (context.role) {
            case Roles.CountryAdmin:
                const activeCountry = countries.find(country => String(country.id) === String(context.id));
                return activeCountry?.marketCode;
            case Roles.NHGManager:
                const activeNursingHomeGroup = nursingHomeGroups.find(nursingHomeGroup => String(nursingHomeGroup.id) === String(context.id));
                return activeNursingHomeGroup?.marketCode;
            default:
                break;
        }
        return null;
    };

    const dispatch = useDispatch();


    const getNewNursingHome = () => new NursingHome({ marketCode: getMarketCode() });

    const [entity, setEntity] = useState<NursingHome>(cloneDeep(props.entity) || getNewNursingHome());
    const [editState, setEditState] = useState<EditStates>(EditStates.Edit);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const isEdit = !!props.entity;

    useEffect(() => {
        const modalSize = editState === EditStates.Edit ? EModalSize.M : EModalSize.S;
        if (props?.onModalSizeChange) {
            props.onModalSizeChange(modalSize);
        }
    }, [editState]);

    const onSave = async (event: any) => {
        await save(event, false);
    };

    const onSaveAndAddAnother = async (event: any) => {
        await save(event, true);
    };

    const save = async (event: any, addMore: boolean) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }

        sendNursingHome(addMore);
    };

    const onDiscard = (event: any) => {
        event.preventDefault();
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setEditState(EditStates.Edit);
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (entity.id) {
            setEditState(EditStates.ConfirmDelete);
        }
    };

    const onDeleteConfirm = async (event: any) => {
        event.preventDefault();
        dispatch(deleteNursingHome(String(entity.id)));
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const validate = async (): Promise<boolean> => {
        const errors: ErrorCodes[] = validateNursingHome(entity);
        const nursingHomeAlreadyExists = nursingHomes?.find(nursingHome =>
            nursingHome.id !== entity.id
            && nursingHome.name.toLowerCase() === entity.name.toLowerCase());
        if (nursingHomeAlreadyExists) {
            errors.push(ErrorCodes.DuplicateNameError);
        }
        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendNursingHome = (addMore: boolean) => {
        const requestBody = nursingHomeToReqBody(entity);
        if (isEdit) {
            const successCallback = () => {
                if (props?.hideModal) {
                    props.hideModal();
                }
            };

            dispatch(updateNursingHome(requestBody, true, successCallback));
        } else {
            const successCallback = () => {
                if (addMore) {
                    setEntity(getNewNursingHome());
                } else if (props?.hideModal) {
                    props.hideModal();
                }
            };

            dispatch(createNursingHome(requestBody, successCallback));
        }
    };

    const shouldClearNotify = (timeA: string, timeB: string) => {
        const [shiftHours, shiftMinutes] = timeA.split(":");
        const [notifyHours, notifyMinutes] = timeB.split(":");

        const shiftToMinutes = Number(shiftHours) * 60 + Number(shiftMinutes);
        const notifyToMinutes = Number(notifyHours) * 60 + Number(notifyMinutes);

        return shiftToMinutes > notifyToMinutes;
    };

    const handleChange = (name: string, value: string) => {
        const nursingHome = cloneDeep(entity);

        switch (name) {
            case NursingHomeAddEditFields.Name:
                nursingHome.name = value;
                break;
            case NursingHomeAddEditFields.MorningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.morningShiftNotificationTime)) {
                    nursingHome.morningShiftNotificationTime = "";
                }
                nursingHome.morningShiftStartTime = value;
                break;
            case NursingHomeAddEditFields.EveningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.nightShiftNotificationTime)) {
                    nursingHome.nightShiftNotificationTime = "";
                }

                nursingHome.nightShiftStartTime = value;
                break;
            case NursingHomeAddEditFields.CaregiverNotificationInterval:
                nursingHome.caregiverNotificationIntervalMinutes = Number(value);
                break;
            case NursingHomeAddEditFields.CaregiverNotificationIntervalDay:
                nursingHome.caregiverNotificationIntervalMinutesDay = Number(value);
                break;
            case NursingHomeAddEditFields.CaregiverResponseTarget:
                nursingHome.caregiverResponseTargetMinutes = Number(value);
                break;
            case NursingHomeAddEditFields.CaregiverTargetResponseTimeDay:
                nursingHome.caregiverResponseTargetMinutesDay = Number(value);
                break;
            case NursingHomeAddEditFields.IsWeakAuthenticationAllowed:
                nursingHome.isWeakAuthenticationAllowed = Boolean(value);
                break;
            case NursingHomeAddEditFields.CustomerId:
                nursingHome.customerId = value;
                break;
            case NursingHomeAddEditFields.IsActive:
                nursingHome.isActive = Boolean(value);
                break;
            case NursingHomeAddEditFields.MarketCode:
                nursingHome.marketCode = value;
                break;
            case NursingHomeAddEditFields.MorningShiftNotificationTime:
                nursingHome.morningShiftNotificationTime = value;
                break;
            case NursingHomeAddEditFields.NightShiftNotificationTime:
                nursingHome.nightShiftNotificationTime = value;
                break;
            case NursingHomeAddEditFields.ShiftNotificationTimeDisabled:
                nursingHome.shiftNotificationTimeDisabled = Boolean(value);
                break;
            case NursingHomeAddEditFields.Is2FAActive:
                nursingHome.is2FactorAuthenticationEnabled = Boolean(value);
                break;
            case NursingHomeAddEditFields.EightHourNotification:
                nursingHome.notifyOfSomeUrineStatusOverdue = !!Number(value);
                break;
        }

        setEntity(nursingHome);
    };

    const addEditForm =
        <React.Fragment>
            <div id="setup-view">
                <Row>
                    <Col sm={12} md={6}>
                        <AdminNursingHomePreferences
                            nursingHome={entity}
                            nursingHomeGroups={nursingHomeGroups}
                            handlePreferenceChanges={handleChange}
                            validationErrors={validationErrors}
                        />
                    </Col>
                    <Col sm={12} md={6}>
                        <h3 className="fields-box-title"><FormattedMessage id="setup.shiftStartTime" /></h3>
                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            dayValue={entity.morningShiftStartTime}
                            nightValue={entity.nightShiftStartTime}
                            dayTimeFieldName={NursingHomeAddEditFields.MorningShiftStartTime}
                            nightTimeFiledName={NursingHomeAddEditFields.EveningShiftStartTime}
                            dayError={ErrorCodes.EmptyMorningShiftStartTime}
                            nightError={ErrorCodes.EmptyNightShiftStartTime}
                            minuteStep={10}
                        />
                        <br />
                        <p><FormattedMessage id="setup.notifyCaregiver.title" /></p>
                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            dayValue={entity.morningShiftNotificationTime}
                            nightValue={entity.nightShiftNotificationTime}
                            dayTimeFieldName={NursingHomeAddEditFields.MorningShiftNotificationTime}
                            nightTimeFiledName={NursingHomeAddEditFields.NightShiftNotificationTime}
                            dayError={ErrorCodes.EmptyMorningShiftStartNotificationTime}
                            nightError={ErrorCodes.EmptyNightShiftStartNotificationTime}
                            minuteStep={10}
                            dayMinTime={entity.morningShiftStartTime}
                            nightMinTime={entity.nightShiftStartTime}
                            disabled={entity.shiftNotificationTimeDisabled}
                        />
                        <CheckBox
                            qaId="nh_notifyCaregiver_checkbox"
                            label={localizer("setup.notifyCaregiver.disable")}
                            value={entity.shiftNotificationTimeDisabled}
                            fieldName={NursingHomeAddEditFields.ShiftNotificationTimeDisabled}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>

                <StyledMarginTop>
                    <Notifications
                        nursingHome={entity}
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                        horizontal={true}
                    />
                </StyledMarginTop>
            </div>
        </React.Fragment>;
    const confirmDeleteForm = (
        <Modal.Body>
            <ConfirmDeleteForm
                title={entity.name}
                onConfirmCancel={onConfirmCancel}
                onDeleteConfirm={onDeleteConfirm}
            />
        </Modal.Body>
    );

    switch (editState) {
        case EditStates.Edit:
            return (
                <React.Fragment>
                    <Modal.Body>
                        {addEditForm}
                    </Modal.Body>
                    < Modal.Footer >
                        <AddEditButtonGroup
                            isEdit={isEdit}
                            onSave={onSave}
                            onSaveAndAddAnother={onSaveAndAddAnother}
                            onDiscard={onDiscard}
                            extraActions={(isEdit ? [
                                {
                                    label: "nursingHome.editForm.deleteLabel",
                                    action: onDelete
                                }
                            ] : [])} />

                    </Modal.Footer>
                </React.Fragment>
            );
        case EditStates.ConfirmDelete:
            return confirmDeleteForm;
    }
};

export default NursingHomeAddEditForm;
