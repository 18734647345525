import React, { ReactNode } from 'react';
import './InformationBlock.scss';
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import { isProdEnv } from "../../utils/EnvCheck";

const StyledWrapper = styled.div`
    :not(:last-of-type) {
        margin-right: 16px;
    }
`;

const StyledChildContainer = styled.div<any>`
    width: ${(props) => props.width ? props.width : ""}px;
`;
interface IInformationBlockProps {
    title: string;
    tooltip?: string;
    children: ReactNode;
    childrenClassName?: string;
    parentClassName?: string;
    width?: number;
}

export default class InformationBlock extends React.Component<IInformationBlockProps> {
    private wrapperClassName = () => {
        return `${this.props.parentClassName || ""}`;
    };

    render(): React.ReactElement {
        const tooltip = (!isProdEnv() && this.props.tooltip) ? String(this.props.tooltip) : "";

        return (
            <StyledWrapper className={this.wrapperClassName()}>
                <span><FormattedMessage id={this.props.title} /></span>
                <StyledChildContainer width={this.props.width} className={this.props.childrenClassName} title={tooltip}>{this.props.children}</StyledChildContainer>
            </StyledWrapper>
        );
    }
}
