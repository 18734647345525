import { createSlice } from "@reduxjs/toolkit";
import { RestClient } from "../data/RestClient";
import { Ward } from "../models/Ward";
import { fetchResidents } from "./residentSlice";

export interface IWardStateType {
    wards: Ward[];
}

const initialState: IWardStateType = {
    wards: [],
};

export const wardSlice = createSlice({
    name: "ward",
    initialState,
    reducers: {
        setWards: (state, action) => {
            state.wards = [...action.payload];
        },

        addUpdateWardInStore: (state, action) => {
          const updatedWard = action.payload;

          const index = state.wards.findIndex(
              (ward) => ward.id === updatedWard.id?.toString()
          );

          if (index > -1) {
              state.wards[index] = new Ward(updatedWard);
          } else {
              state.wards.push(new Ward(updatedWard));
          }
      },

        clearWardSlice: () => initialState,
    },
});

export const { setWards, addUpdateWardInStore, clearWardSlice } = wardSlice.actions;

export const updateWard = (requestBody: Ward) => (
    dispatch: any,
    getState: any
): Promise<Ward> => {
    return new Promise(async (resolve, reject) => {
        try {
            const newWard = await RestClient.updateWard(requestBody);
            const updatedWard = new Ward(newWard);

            const state = getState().wardSlice as IWardStateType;
            const updatedWards =
                state.wards.map((ward) => {
                    return ward.id === updatedWard.id ? updatedWard : ward;
                }) || [];

            dispatch(setWards(updatedWards));

            resolve(updatedWard);
        } catch (exception) {
            reject(exception);
        }
    });
};

export const deleteWard = (id: string) => (
    dispatch: any,
    getState: any
): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteWard(id);

            const state = getState().wardSlice as IWardStateType;
            const updatedWards = state.wards.filter((ward) => ward.id !== id);

            dispatch(setWards(updatedWards));
            dispatch(fetchResidents());

            resolve(true);
        } catch (exception) {
            reject(exception);
        }
    });
};

export const createWard = (requestBody: any) => (
    dispatch: any
): Promise<Ward> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await RestClient.postWard(requestBody);
            const newWard = new Ward(response);
            dispatch(fetchWards());
            resolve(newWard);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchWards = () => async (dispatch: any) => {
    try {
        dispatch(clearWardSlice());
        const wardsResponse = await RestClient.getWards();
        const wards = wardsResponse.map(
            (wardResponse: any) => new Ward(wardResponse)
        );
        dispatch(setWards(wards));
    } catch (exception) {
        console.log(exception);
    }
};

export default wardSlice.reducer;
