import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';

import { StyledAddButton } from "../../components/styled";
import ModalWrapper, { EModalSize } from "../../components/common/modal/ModalWrapper";
import AddEditWardForm from "./AddEditWardForm";
interface IAddWardButtonState {
    isModalDisplayed: boolean;
}

export default class AddWardButton extends Component<{}, IAddWardButtonState> {
    render(): React.ReactElement {
        const triggerComponent: React.ReactNode = (
            <StyledAddButton variant="primary" />
        );

        return (
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={<AddEditWardForm
                    entity={undefined}
                />}
                modalTitle={<FormattedMessage id="setup.addWardPopup.modalTitle"></FormattedMessage>}
                size={EModalSize.S}
            />
        );
    }
}
