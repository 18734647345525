import { useDispatch } from "react-redux";
import User from "../../../../models/User";
import { revokeAccess } from "../../../../reducers/userSlice";
import ConfirmDeleteForm from "../../../common/forms/ConfirmDeleteForm";

interface Props {
    user: User,
    hideModal?: () => void,
}

const CommonUserDeleteForm = (props: Props) => {
    const dispatch = useDispatch();
    const onDeleteConfirm = () => {
        dispatch(revokeAccess(props.user.id))
        props.hideModal!();
    }

    return (
        <ConfirmDeleteForm
            onConfirmCancel={props.hideModal!}
            onDeleteConfirm={onDeleteConfirm}
            title={props.user.name}
        />
    );
}

export default CommonUserDeleteForm;