import styled from 'styled-components';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';
import { StyledCenter } from '../../../common/table/formatters/PositionContainers';
import WarningIcon from '../../../../images/WarningIcon';
import Colors from '../../../../utils/Colors';

const StyledImg = styled(WarningIcon)`
    margin-right: 10px;
`;

const StyledCount = styled.span`
    vertical-align: middle;
`;

const CellFormatter = (props: any) => {
    const loggedInCareGiversCount = (props.data?.value?.filter((careGiver: any) => careGiver.isLoggedIn) || []).length;
    const { hideTooltipOnProd } = props.data.column;
    return (
        <StyledCenter>
            <TextOverflowWrapper tooltip={loggedInCareGiversCount} hideTooltipOnProd={hideTooltipOnProd}>
                {loggedInCareGiversCount > 0
                    ? <div> {loggedInCareGiversCount} </div>
                    : <>
                        <StyledImg color={Colors.alert.red} />
                        <StyledCount>{loggedInCareGiversCount}</StyledCount>
                    </>
                }
            </TextOverflowWrapper>
        </StyledCenter>
    );
};

const CareGiversFormatter = (data: any) => <CellFormatter data={data} />;
export default CareGiversFormatter;
