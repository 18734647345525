import { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RestClient } from '../../../data/RestClient';
import Market from '../../../models/Market';
import ToastMessages from '../../../models/ToastMessages';
import { RootState } from '../../../store';
import { localizer } from '../../../utils/Localizer';
import { toastError, toastSuccess } from '../../../utils/Toaster';
import AddEditButtonGroup from '../../../views/Buttons/AddEditButtonGroup';
import DropdownButtonNew from '../../common/controls/DropdownButtonNew';
import TextFieldNew from '../../common/controls/TextFieldNew';
import ValidatedFormGroupWrapper from '../../wrappers/ValidatedFormGroupWrapper';
import { nursingHomeGroupToReqBody } from "../../../models/helpers/RequestHelpers";
import ConfirmDeleteForm from '../../../views/ConfirmDeleteForm';
import ErrorCodes from '../../../models/ErrorCodes';
import NursingHomeGroup from '../../../models/NursingHomeGroup';
import { fetchNursingHomeGroups, deleteNursingHomeGroup } from '../../../reducers/nursingHomeGroupSlice';
import { Country } from '../../../models/Country';

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    Name = "Name",
    CustomerId = "CustomerId",
    Market = "Market",
}

const NursingHomeGroupEditForm = (props: any) => {
    const activeCountryId = useSelector<RootState, string>(state => state.contextSlice.id);
    const activeCountry = useSelector<RootState, Country | undefined>(state => state.countrySlice.countries.find(country => String(country.id) === String(activeCountryId)));
    const onDiscard = (event: any) => {
        event.preventDefault();
        props?.hideModal();
    };

    const getNewNursingHomeGroup = () => new NursingHomeGroup({ marketCode: activeCountry?.marketCode });

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [nursingHomeGroupEntity, setNursingHomeGroupEntity] = useState<NursingHomeGroup>(props.entity || getNewNursingHomeGroup());
    const [validationErrors, setErrors] = useState<string[]>([]);

    const isEdit = !!props.entity;
    const nursingHomeGroups = useSelector<RootState, NursingHomeGroup[]>(state => state.nursingHomeGroupSlice.nursingHomeGroups);

    const dispatch = useDispatch();

    const clearFormData = () => {
        setNursingHomeGroupEntity(getNewNursingHomeGroup());
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!nursingHomeGroupEntity.marketCode) {
            errors.push(ErrorCodes.EmptyNursingHomeGroupMarket);
        }

        if (!nursingHomeGroupEntity.name) {
            errors.push(ErrorCodes.EmptyNameError);
        }

        if (!nursingHomeGroupEntity.customerId) {
            errors.push(ErrorCodes.EmptyNursingHomeGroupCustomerId);
        }

        const duplicateNameFound = nursingHomeGroups.some(nursingHomeGroup => nursingHomeGroup.name?.toLowerCase() === nursingHomeGroupEntity.name?.toLowerCase() && nursingHomeGroup.id !== nursingHomeGroupEntity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.NursingHomeGroupDuplicateNameError);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendNursingHomeGroup = async (addMore: boolean) => {
        if (isEdit) {
            try {
                const requestBody = nursingHomeGroupToReqBody(nursingHomeGroupEntity);
                await RestClient.updateNursingHomeGroup(requestBody);

                dispatch(fetchNursingHomeGroups());
                toastSuccess(localizer(ToastMessages.UpdateNursingHomeGroupSuccessful));
                props?.hideModal();
            }
            catch (error) {
                toastError(localizer(ToastMessages.UpdateNursingHomeGroupError));
                // tslint:disable-next-line:no-console
                console.error(error);
            }

        } else {
            try {
                const requestBody = nursingHomeGroupToReqBody(nursingHomeGroupEntity);
                await RestClient.createNursingHomeGroup(requestBody);
                toastSuccess(localizer(ToastMessages.CreateNursingHomeGroupSuccessful));
                if (addMore) {
                    clearFormData();
                } else {
                    props?.hideModal();
                }
                dispatch(fetchNursingHomeGroups());
            }
            catch (error) {
                toastError(localizer(ToastMessages.CreateNursingHomeGroupError));
                // tslint:disable-next-line:no-console
                console.error(error);
            }
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendNursingHomeGroup(false);
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setShowDeleteConfirmation(false);
    };

    const onDeleteConfirm = async (event: any) => {
        event.preventDefault();
        dispatch(deleteNursingHomeGroup(props.entity.id));
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendNursingHomeGroup(true);
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (props.entity.id) {
            setShowDeleteConfirmation(true);
        }
    };

    const handleChange = (name: string, value: any) => {
        const updatedNursingHomeGroup = { ...nursingHomeGroupEntity };

        switch (name) {
            case Fields.Name:
                updatedNursingHomeGroup.name = value;
                break;
            case Fields.CustomerId:
                updatedNursingHomeGroup.customerId = value;
                break;
            case Fields.Market:
                updatedNursingHomeGroup.marketCode = value;
                break;
        }

        setNursingHomeGroupEntity(updatedNursingHomeGroup);
    };

    const markets = useSelector<RootState, Market[]>(state => state.marketSlice.markets);
    const dropdownOptions = markets.map(market => ({
        label: market.name,
        value: market.code
    })) || [];

    const deleteConfirmation = (
        <ConfirmDeleteForm
            title={props.entity?.name}
            onConfirmCancel={onConfirmCancel}
            onDeleteConfirm={onDeleteConfirm}
        />
    );

    const form = (
        <StyledFormContainer>
            <Form>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNameError, ErrorCodes.NursingHomeGroupDuplicateNameError]}
                        label={'form.nursingHomeGroup.name'}
                    >
                        <TextFieldNew
                            data-qa="nhg_name_input_text"
                            fieldName={Fields.Name}
                            value={nursingHomeGroupEntity.name}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNursingHomeGroupCustomerId]}
                        label={'form.nursingHomeGroup.customerId'}
                    >
                        <TextFieldNew
                            data-qa="nhg_customer_id_input_text"
                            fieldName={Fields.CustomerId}
                            value={nursingHomeGroupEntity.customerId}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNursingHomeGroupMarket]}
                        label={'form.nursingHomeGroup.assortment'}
                    >
                        <DropdownButtonNew
                            qaId="nhg_market_dropdown"
                            id={Fields.Market}
                            name={Fields.Market}
                            handleChange={(name, value) => handleChange(name, value)}
                            options={dropdownOptions}
                            selectedValue={nursingHomeGroupEntity.marketCode}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="g-0 mt-3">
                    <AddEditButtonGroup
                        isEdit={isEdit}
                        onSave={onSave}
                        onSaveAndAddAnother={onSaveAndAddAnother}
                        onDiscard={onDiscard}
                        extraActions={isEdit ? [{
                            label: "constants.delete",
                            action: onDelete
                        }] : []} />
                </Row>
            </Form>
        </StyledFormContainer >
    );

    return showDeleteConfirmation ? deleteConfirmation : form;
};

export default NursingHomeGroupEditForm;
