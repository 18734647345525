import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IRoutesWrapperProps {
    children: React.ReactElement | React.ReactElement[] | null;
}

const RoutesWrapper = ({ children }: IRoutesWrapperProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const lastPath = localStorage.getItem("lastPath");

    useEffect(() => {
        localStorage.setItem("lastPath", location.pathname);
    }, [location]);

    useEffect(() => {
        if (lastPath) {
            navigate(lastPath);
        }
    }, []);

    return (
        <>{children}</>
    );
};

export default RoutesWrapper;
