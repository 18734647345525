import { useContext } from "react";
import IdleTimer from 'react-idle-timer';
import { useEffect } from "react";
import { AppContext } from "./AppRepository";
import { AuthRestClient } from "./RestClient";

interface Props {
    inactivityLimitMinutes: number;
}

let cookieTime = Date.now();

const SessionHandler = (props: Props) => {
    const minutesBeforeRefreshCookie = 5;
    const contextValue = useContext(AppContext);

    useEffect(() => {
        cookieTime = Date.now();
    }, [])

    const onIdle = () => {
        contextValue.logout('logout.inactivity');
    };
    const onAction = () => {
        const milisecondsDiff = Date.now() - cookieTime;
        if (milisecondsDiff > minutesBeforeRefreshCookie * 60 * 1000) {
            cookieTime = Date.now();
            AuthRestClient.keepSessionAlive()
        }
    };

    return (
        <div>
            <IdleTimer
                element={document}
                onAction={onAction}
                throttle={60 * 1000}
            />
            <IdleTimer
                element={document}
                onIdle={onIdle}
                debounce={250}
                timeout={props.inactivityLimitMinutes * 60 * 1000}
            />
        </div>
    );
}

export default SessionHandler;