import ChangeEvent from "../../models/ChangeEvent";
import { Ward } from "../../models/Ward";
import GraphHeader from "./GraphHeader";
import { Chart } from "./Chart";
import moment, { Moment } from "moment";
import { KpiType } from "../../models/KpiType";
import { IChartData } from "./Statistics";
import { DateTimeUtils } from "../../models/DateTimeUtils";

interface IProductStatisticsGraphProps {
    averageChangeEventsLogs: ChangeEvent[];
    compareChangeEventsLogs: ChangeEvent[];
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    kpiValue: { count: number | null | undefined, percentage: number | null | undefined } | number | null | undefined;
    compareKpiValue: { count: number | null | undefined, percentage: number | null | undefined } | number | null | undefined;
    startDate: string;
    endDate: string;
    oldestWardMoment: Moment;
    startCompareDate: Date | null;
    selectedWardCreation?: Moment;
    setSelectedCompareStartDate: (value: Date | null) => void;
    setSelectedCompareEndDate: (value: Date | null) => void;
    customPeriod: boolean;
}

const getKpiValue = (selectedKpi: KpiType, kpiValue: { count: number | null | undefined, percentage: number | null | undefined } | number | null | undefined) => {
    if (kpiValue !== undefined) {
        if (selectedKpi === KpiType.PromptedProductChanges || selectedKpi === KpiType.UnpromptedProductChanges) {
            if (kpiValue && typeof kpiValue !== "number") {
                return kpiValue.count ? kpiValue.count : null;
            }
            return null;
        }
        if (typeof kpiValue === "number") {
            return kpiValue;
        }
    }
    return null;
};

const getPercentageValue = (selectedKpi: KpiType, kpiValue: { count: number | null | undefined, percentage: number | null | undefined } | number | null | undefined) => {
    if (kpiValue !== undefined) {
        if (selectedKpi === KpiType.PromptedProductChanges || selectedKpi === KpiType.UnpromptedProductChanges) {
            if (kpiValue && typeof kpiValue !== "number") {
                return kpiValue.percentage ? kpiValue.percentage : null;
            }
        }
    }
    return null;
};

const ProductStatisticsGraph = (props: IProductStatisticsGraphProps) => {

    const start = new Date(props.startDate);
    const end = new Date(props.endDate);
    const interval = DateTimeUtils.getCompareInterval(start, end);
    const generateChartData = (): IChartData[] => {
        const dayProductChangesMap: Map<string, {
            all: number[],
            ward: number,
            wardCompare?: number,
            compareDate?: string,
            allPercentage: number[],
        }> = new Map();

        const dayProductChangesCompareMap: Map<string, {
            all: number[],
            ward: number,
            allPercentage: number[],
            percentage?: number
        }> = new Map();

        const chartData: IChartData[] = [];

        const fromDate = moment(props.startDate);
        const currentDate = moment(props.endDate).subtract(1, "d");
        const fromCompareDate = props.startCompareDate ? moment(props.startCompareDate) : undefined;
        const currentCompareDate = fromCompareDate ? moment(fromCompareDate.clone().add(interval - 1, 'days')) : undefined;

        while (currentDate >= fromDate) {
            const compareDate = currentCompareDate ? currentCompareDate.format('YYYY-MM-DD') : undefined;
            dayProductChangesMap.set(currentDate.format('YYYY-MM-DD'), { all: [], ward: 0, compareDate, allPercentage: [] });
            currentDate.subtract(1, 'days');
            if (compareDate && currentCompareDate) {
                dayProductChangesCompareMap.set(compareDate, { all: [], ward: 0, allPercentage: [] });
                currentCompareDate.subtract(1, 'days');
            }
        }

        for (const event of props.averageChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesMap.has(date)) {
                dayProductChangesMap?.get(date)?.all.push(props.selectedKpi === KpiType.AllProductChanges ? event.avgProductChanges : event.productChanges);
                if (event.productChangesPercentage) {
                    dayProductChangesMap?.get(date)?.allPercentage?.push(event.productChangesPercentage)
                }
            }
        }

        for (const event of props.compareChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesCompareMap.has(date)) {
                dayProductChangesCompareMap?.get(date)?.all.push(props.selectedKpi === KpiType.AllProductChanges ? event.avgProductChanges : event.productChanges);
                if (event.productChangesPercentage) {
                    dayProductChangesCompareMap?.get(date)?.allPercentage?.push(event.productChangesPercentage);
                }
            }
        }


        for (const [key, value] of dayProductChangesMap.entries()) {
            const allChanges = value.all.length > 0 ? value.all.reduce((sum, current) => sum + current, 0): 0;
            const allAvgPercentage = value.allPercentage ? (value.allPercentage.length > 0 ? Math.round((value.allPercentage.reduce((sum, current) => sum + current, 0) / value.allPercentage.length)) : 0) : 0;
            let allCompareChanges;
            let allComparePercentage;
            if (value.compareDate && dayProductChangesCompareMap.has(value.compareDate)) {
                const entry = dayProductChangesCompareMap.get(value.compareDate);
                if (entry) {
                    allCompareChanges = entry.all.length > 0 ? entry.all.reduce((sum, current) => sum + current, 0) : 0;
                    allComparePercentage = entry.allPercentage.length > 0 ? Math.round((entry.allPercentage.reduce((sum, current) => sum + current, 0) / entry.allPercentage.length)) : 0;
                }
            }
            chartData.push({
                date: key,
                selectedWard: allChanges,
                compareDate: value.compareDate,
                selectedCompareWard: allCompareChanges,
                selectedWardPercentage: allAvgPercentage,
                selectedCompareWardPercentage: allComparePercentage

            });
        }
        return chartData;
    };

    const maxYAxisValue = (): number => {
        const mappedValues: Map<Date, number> = new Map();
        props.averageChangeEventsLogs.forEach((changeEvent: ChangeEvent) => {
            const createdDate = changeEvent.timeCreated;
            if (createdDate !== undefined) {
                if (mappedValues.has(createdDate)) {
                    const mappedValue = mappedValues.get(createdDate);
                    mappedValues.set(createdDate, (mappedValue ?? 0) + (props.selectedKpi === KpiType.AllProductChanges ? changeEvent.avgProductChanges : changeEvent.productChanges));
                } else {
                    mappedValues.set(createdDate, props.selectedKpi === KpiType.AllProductChanges ? changeEvent.avgProductChanges : changeEvent.productChanges);
                }
            }
        });
        const compareMappedValues: Map<Date, number> = new Map();
        props.compareChangeEventsLogs.forEach((changeEvent: ChangeEvent) => {
            const createdDate = changeEvent.timeCreated;
            if (createdDate !== undefined) {
                if (compareMappedValues.has(createdDate)) {
                    const mappedValue = compareMappedValues.get(createdDate);
                    compareMappedValues.set(createdDate, (mappedValue ?? 0) + (props.selectedKpi === KpiType.AllProductChanges ? changeEvent.avgProductChanges : changeEvent.productChanges));
                } else {
                    compareMappedValues.set(createdDate, props.selectedKpi === KpiType.AllProductChanges ? changeEvent.avgProductChanges : changeEvent.productChanges);
                }
            }
        });
        const allProductChanges = [...mappedValues.values(), ...compareMappedValues.values()];
        const maxAggregatedProductChanges = Math.max(...allProductChanges);
        return Math.max(Math.ceil(maxAggregatedProductChanges * 1.2), 2);
    };


    return (
        <div>
            <GraphHeader
                disabledCompare={false}
                selectedKpi={props.selectedKpi}
                isDataPresent={props.averageChangeEventsLogs.length > 0}
                daysInterval={interval}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate)}
                startRangeDate={props.selectedWard ? props.selectedWardCreation! : props.oldestWardMoment}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate).format("DD MMM YYYY") : null}
            />
            <Chart
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate).subtract(1)}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate) : null}
                compareEndDate={moment(props.startCompareDate).add(interval - 1, 'days')}
                chartData={generateChartData()}
                maxYAxisValue={maxYAxisValue()}
                selectedKpiValue={getKpiValue(props.selectedKpi, props.kpiValue)}
                compareKpiValue={getKpiValue(props.selectedKpi, props.compareKpiValue)}
                percentageKpiValue={getPercentageValue(props.selectedKpi, props.kpiValue)}
                comparePercentageKpiValue={getPercentageValue(props.selectedKpi, props.compareKpiValue)}
                selectedKpi={props.selectedKpi}
                interval={interval}
            />
        </div>
    );
};

export default ProductStatisticsGraph;
