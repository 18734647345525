import React, { useEffect, useRef } from "react";
import * as signalR from '@microsoft/signalr';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { IContextStateType } from "../reducers/contextSlice";
import { Role, Roles } from "../models/Role";

const socketUrl = `${process.env.REACT_APP_API_URL}/nursingHomeHub`;

export enum SocketStatus {
    INACTIVE = '-',
    CONNECTING = 'Connecting',
    OK = 'OK',
    ERROR = 'ERROR'
}

export enum Method {
    New,
    Update,
    Delete
}

export enum DataType {
    SocketStatus,
    NursingHome,
    Ward,
    WardState,
    Resident,
    ResidentCurrentState,
    CareGiver,
    User
}

enum SocketActionTypes {
    UPDATE_ALL_WARDS = 'update_all_wards',
    NEW_RESIDENT = 'new_resident',
    UPDATE_RESIDENT = 'update_resident',
    DELETED_RESIDENT = 'delete_resident',
    NEW_NURSING_HOME = 'new_nursing_home',
    UPDATE_WARD_RESPONSE_TIMES = 'update_ward_response_times',
    UPDATE_RESIDENT_CURRENT_STATE = 'update_resident_current_state',
    UPDATE_CAREGIVER_DEVICE = 'update_caregiverdevice',
}

interface ISocketClientProps {
    onSocketPayload: (method: Method, dataType: DataType, payload: any) => void;
}

const SocketClient = (props: ISocketClientProps) => {
    const context = useSelector<RootState, IContextStateType>(state => state.contextSlice);

    const rolesUsingSocket: Role[] = [
        Roles.NHManager,
        Roles.Nurse,
        Roles.Support
    ];

    const socket: React.MutableRefObject<signalR.HubConnection | undefined> = useRef();
    const buildConnection = () => {
        return new signalR.HubConnectionBuilder()
            .withUrl(socketUrl + '?NursingHomeId=' + context.id)
            .withAutomaticReconnect()
            .build();
    };

    const socketStop = async (socket: signalR.HubConnection) => {
        await socket.stop();
        // tslint:disable-next-line:no-console
        console.info('Disconnected web socket.');
    };

    const registerHandlers = (socket: signalR.HubConnection, props: ISocketClientProps) => {
        props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.CONNECTING);

        socket.onreconnecting(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.CONNECTING);
        });

        socket.onreconnected(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.OK);
        });

        socket.onclose(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
        });

        socket.start()
            .then(() => {
                // tslint:disable-next-line:no-console
                console.info('Connected to the server socket!');
                props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.OK);
            })
            .catch(err => {
                // tslint:disable-next-line:no-console
                console.warn(`Failed to connect the socket. ${JSON.stringify(err)}`);
                props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
            });

        const printPayload = (action: SocketActionTypes, payload: any) => {
            // tslint:disable-next-line:no-console
            console.debug('Socket ' + action.replace('_', ' ') + ':', JSON.stringify(payload, null, 2));
        };

        socket.on('error', error => {
            // tslint:disable-next-line:no-console
            console.error(`Socket Error: \n ${error.type} \n ${error.message} \n ${error.code}`);
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
        });

        socket.on(SocketActionTypes.NEW_RESIDENT, payload => {
            printPayload(SocketActionTypes.NEW_RESIDENT, payload);
            if (payload) {
                props.onSocketPayload(Method.New, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.DELETED_RESIDENT, payload => {
            printPayload(SocketActionTypes.DELETED_RESIDENT, payload);
            if (payload) {
                props.onSocketPayload(Method.Delete, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_RESIDENT, payload => {
            printPayload(SocketActionTypes.UPDATE_RESIDENT, payload);
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_WARD_RESPONSE_TIMES, payload => {
            printPayload(SocketActionTypes.UPDATE_WARD_RESPONSE_TIMES, payload);
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.WardState, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_RESIDENT_CURRENT_STATE, payload => {
            printPayload(SocketActionTypes.UPDATE_RESIDENT_CURRENT_STATE, payload);
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.ResidentCurrentState, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_CAREGIVER_DEVICE, payload => {
            printPayload(SocketActionTypes.UPDATE_CAREGIVER_DEVICE, payload);
            props.onSocketPayload(Method.Update, DataType.CareGiver, payload);
        });
    };

    useEffect(() => {
        if (socket.current) {
            socketStop(socket.current);
        }
        if (context.isAuthenticated && rolesUsingSocket.includes(context.role)) {
            socket.current = buildConnection();

            registerHandlers(socket.current, props);
        }
    }, [context]);

    useEffect(() => {
        return () => {
            if (socket.current) {
                socketStop(socket.current);
            }
        };
    }, []);

    return (
        <div />
    );
};

export default SocketClient;
