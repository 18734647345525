import { useSelector } from 'react-redux';
import { Ward } from '../../../../../../models/Ward';
import { RootState } from '../../../../../../store';
import { StyledLeft } from '../../../../../common/table/formatters/PositionContainers';

const CellFormatter = (props: any) => {
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);
    const assignedWardsIds: string[] = props.data.value;
    const assignedWards = wards.filter(ward => assignedWardsIds.some(assignedWardId => assignedWardId === ward.id)).map(ward => ward.name);

    return (
        <StyledLeft>
            {assignedWards.join(',')}
        </StyledLeft>
    );
};

const WardsFormatter = (data: any) => <CellFormatter data={data} />;
export default WardsFormatter;