import NursingHomeGroupEditForm from "./NursingHomeGroupEditForm";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import { localizer } from "../../../utils/Localizer";
import { StyledAddButton } from "../../styled";

const NursingHomeGroupAddButton = () => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" data-qa="nhg_add_button"/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<NursingHomeGroupEditForm entity={undefined} />}
            modalTitle={localizer("nursingHomeGroups.addButton.title")}
            size={EModalSize.S}
        />
    );
};

export default NursingHomeGroupAddButton;
