import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ModalWrapper, { EModalSize } from "../../components/common/modal/ModalWrapper";
import ResidentHistoryView from "../../components/themed/resident/history/ResidentHistoryView";
import clockHistory from "../../images/clock-history.svg";
import Colors from "../../utils/Colors";

const Disabled = `
    filter: brightness(2.25) grayscale(1);
`;

const StyledIcon = styled.div<any>`
    font-size: 12px;
    color: ${Colors.blue.brandingDark};
    display: flex;
    flex-direction: column;
    cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
    ${(props) => props.disabled && Disabled};
    justify-content: center;
    align-items: center;
`;

const StyledImage = styled.img`
    width: 24px;
`;

interface IResidentHistoryIconState {
    isModalDisplayed: boolean;
}

interface IResidentHistoryIconProps {
    residentId: string;
    residentName: string;
    disabled: boolean;
}

export default class ResidentHistoryIcon extends Component<
    IResidentHistoryIconProps,
    IResidentHistoryIconState
> {
    constructor(props: IResidentHistoryIconProps) {
        super(props);
        this.state = {
            isModalDisplayed: false,
        };
    }

    private setModalDisplay = (shouldDisplay: boolean): void => {
        this.setState({
            isModalDisplayed: shouldDisplay,
        });
    };

    private openResidentHistoryPopupModal = (): void => {
        if (!this.props.disabled) {
            this.setModalDisplay(true);
        }
    };

    render(): React.ReactElement {
        const triggerComponent: React.ReactNode = (
            <StyledIcon
                data-qa={this.props.residentName.toLowerCase() + "-history_button"}
                onClick={() => {
                    this.openResidentHistoryPopupModal();
                }}
                disabled={this.props.disabled}
            >
                <StyledImage src={clockHistory} alt={clockHistory} />
                <span>
                    <FormattedMessage id={"residents.historyButton"} />
                </span>
            </StyledIcon>
        );
        return (
            <div
                className="history-resident-button-wrapper"
                id="history-resident-button-wrapper"
            >
                <ModalWrapper
                    triggerComponent={triggerComponent}
                    modalContent={
                        <ResidentHistoryView
                            residentId={this.props.residentId}
                            residentName={this.props.residentName}
                        />
                    }
                    modalTitle={
                        <FormattedMessage
                            id="residents.history.title"
                            values={{ resident: this.props.residentName }}
                        />
                    }
                    size={EModalSize.L}
                />
            </div>
        );
    }
}
