import { Role, Roles } from "../models/Role";
import { createSlice } from "@reduxjs/toolkit";
import { populateRedux } from "./dataHelper";
import { RestClient } from "../data/RestClient";
import { LocalStorageKeys } from "../data/Persistence";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";

export interface IUserResourcesHierarchy {
    children: IUserResourcesHierarchy[];
    id: number;
    name: string;
    role: Role;
}

export interface IContextStateType {
    id: string;
    role: Role;
    inactiveTimeInMinutesBeforeLogout: number;
    userResourcesHierarchy: IUserResourcesHierarchy[];
    isAuthenticated: boolean;
    isPending: boolean;
    userName: string;
    cookieScriptExecution: boolean;
}

const initialState: IContextStateType = {
    id: "0",
    role: Roles.Invalid,
    inactiveTimeInMinutesBeforeLogout: 60,
    userResourcesHierarchy: [],
    isAuthenticated: false,
    isPending: false,
    userName: "",
    cookieScriptExecution: false
};

export const contextSlice = createSlice({
    name: "context",
    initialState,
    reducers: {
        setContext: (state, action) => {
            state.id = action.payload.id;
            state.role = action.payload.role;
        },
        setInactiveTimeInMinutesBeforeLogout: (state, action) => {
            state.inactiveTimeInMinutesBeforeLogout = action.payload;
        },
        setUserResourcesHierarchy: (state, action) => {
            state.userResourcesHierarchy = action.payload;
        },

        setUsername: (state, action) => {
            state.userName = action.payload;
        },

        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },

        setIsPending: (state, action) => {
            state.isPending = action.payload;
        },
        setCookieScripExecution: (state, action) => {
            state.cookieScriptExecution = action.payload;
        },
        clearContextSlice: (state) => ({ ...initialState, cookieScriptExecution: state.cookieScriptExecution })
    },
});

export const {
    setContext,
    setInactiveTimeInMinutesBeforeLogout,
    setUserResourcesHierarchy,
    setIsAuthenticated,
    setIsPending,
    clearContextSlice,
    setUsername,
    setCookieScripExecution
} = contextSlice.actions;

export const contextChange = (newId: string, newRole: Role) => async (
    dispatch: any
) => {
    dispatch(setContext({ id: newId, role: newRole }));
    await populateRedux(newRole);
};

export const fetchUserResources = (
    avoidLogoutMessage: boolean = false
) => async (dispatch: any) => {
    try {
        const { userResources, userName } = await RestClient.getUserResources(
            avoidLogoutMessage
        );
        const { enableCookieScriptExecution } = await RestClient.getConfiguration();
        dispatch(setCookieScripExecution(enableCookieScriptExecution));
        dispatch(setUserResourcesHierarchy(userResources));
        dispatch(setUsername(userName));
        dispatch(setIsAuthenticated(true));
        toast.dismiss("toastLogoutInfo");
    } catch {        
        const { enableCookieScriptExecution } = await RestClient.getConfiguration();
        dispatch(setCookieScripExecution(enableCookieScriptExecution));
        dispatch(setIsAuthenticated(false));
    }
    const { value, role } = reactLocalStorage.getObject(
        LocalStorageKeys.ResourceContext,
        {}
    );
    dispatch(contextChange(value, role as Roles));
};

export default contextSlice.reducer;
