import { StyledLeft } from './PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                {value}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const StringFormatter = (data: any) => <CellFormatter data={data} />;
export default StringFormatter;
