import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import Picker from "rc-picker";
import "rc-picker/assets/index.css";
import generateConfig from "rc-picker/lib/generate/moment";
import enUS from "rc-picker/lib/locale/en_US";
import { useState } from "react";
import { useRef } from "react";
import Colors from "../../../../utils/Colors";
import "./TimePickerDropdown.scss";

interface IProps {
    id: string;
    disabled?: boolean;
    value: string | Moment | undefined;
    minTime?: string;
    onChange: ((value: Moment) => void) | undefined;
    minuteStep?: 1 | 10 | 15 | 30;
    popupWidth: number | undefined;
    calculateDisabledHours: (() => number[]) | undefined;
    calculateDisabledMinutes: (() => number[]) | undefined;
    readOnly?: boolean;
    inputReadOnly?: boolean;
    // container on which the dropdown container will be child of, used for scrolling behaviour in popups and overlapping popups
    defaultPopupContainer?: HTMLElement;
    qaId?: string;
}

const TimePickerDropdown = (props: IProps) => {
    const [isOpen, setOpen] = useState(false);
    const inputRef = useRef<HTMLElement>(null);
    const selectedValue = !props.disabled                   // check if not disabled
        ? props.value                                       // then if we have a value
            ? props.minTime && props.minTime > props.value  // then if we have a minimum available time and the value is earlier than it
                ? moment(props.minTime, "HH:mm")            // we select the minimum available
                : moment(props.value, "HH:mm")              // else the selected value
            : undefined
        : props.readOnly && props.value ? moment(props.value, "HH:mm") : undefined;

    const defaultValue = !props.disabled
        ? props.minTime
            ? moment(props.minTime, "HH:mm")
            : undefined
        : undefined;

    return (
        <Picker<Moment>
            id={props.id}
            data-qa={props.qaId}
            format={"HH:mm"}
            onOpenChange={(openState) => {
                if (!openState) {
                    document.getElementById(props.id)?.blur();
                    if (inputRef !== null && inputRef.current !== null && inputRef.current !== undefined) {
                        const innerInputValue: string = (inputRef.current as HTMLInputElement).value;
                        if (moment(innerInputValue, "HH:mm").isValid() && ((selectedValue && selectedValue.format("HH:mm") !== innerInputValue) || selectedValue === undefined) && props.onChange) {
                            props.onChange(moment(innerInputValue, "HH:mm"));
                        }
                    }
                }
                setOpen(openState);
            }}
            open={isOpen}
            suffixIcon={
                <FontAwesomeIcon
                    icon={faCaretDown}
                    color={props.disabled || props.readOnly ? Colors.grey.medium : "#212529"} />
            }
            onClick={() => {
                setOpen(true);
                document.getElementById(props.id)?.focus();
                inputRef.current?.focus();
            }}
            showNow={false}
            showSecond={false}
            generateConfig={generateConfig}
            value={selectedValue}
            defaultValue={defaultValue}
            defaultPickerValue={
                props.minTime ? moment(props.minTime, "HH:mm") : undefined
            }
            inputRender={(props: any) => {
                return <input {...props} id={props.id + "-input"} ref={inputRef} />;
            }}
            onSelect={props.onChange}
            onChange={(value: any) => props.onChange && props.onChange(value)}
            picker="time"
            locale={enUS}
            minuteStep={props.minuteStep || 10}
            mode="time"
            disabled={!!props.disabled}
            disabledHours={props.calculateDisabledHours}
            disabledMinutes={props.calculateDisabledMinutes}
            popupStyle={{
                width: props.popupWidth,
                borderRadius: "4px",
            }}
            inputReadOnly={props.inputReadOnly ? props.inputReadOnly : false}
            getPopupContainer={(node: HTMLElement) => (props.defaultPopupContainer ? props.defaultPopupContainer : node)}
        />
    );
};

export default TimePickerDropdown;
