import { useSelector } from 'react-redux';
import styled from "styled-components";
import MultiSelectionFieldNew from "../../components/common/controls/MultiSelectionFieldNew";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from '../../components/common/View';
import { Resident } from "../../models/Resident";
import { ResidentEventType } from "../../models/ResidentEventType";
import { Ward } from '../../models/Ward';
import { getActiveResidentCount } from '../../reducers/residentHelper';
import { RootState } from '../../store';
import '../../styles/main.scss';
import { localizer } from "../../utils/Localizer";
import InformationBlock from "../../views/InformationBlock/InformationBlock";
import AverageResponseTimeInformationBlock from "./AverageResponseTimeInformationBlock";
import ResidentsInformationBlock from "./ResidentsInformationBlock";
import './ResidentsView.scss';
import StatusSummaryInformationBlock from "./StatusSummaryInformationBlock";

const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
    width: 240px;
`;

const CustomStyledViewContainer = styled(StyledViewContainer)`
    padding: 0px;
`;

export interface IResidentsInformationBlocksProps {
    handleWardSelection: (name: string, value: any) => void;
    selectedWards: Ward[];
    wards: Ward[];
    limitedAccess: boolean;
}

function ResidentsInformationBlocks(props: IResidentsInformationBlocksProps): React.ReactElement {
    const residents = useSelector<RootState, Resident[]>(state => state.residentSlice.residents.filter(resident => props.selectedWards.map(ward => ward.id).includes(resident.wardId)));

    const getAmountOfResidentsByBedStatus = (residentEventType: ResidentEventType[]): number => {
        return residents.filter(resident => residentEventType.some(type => type === resident.bedStatus)).length;
    };

    const getAverageResponseTime = (fieldName: keyof Ward) => {
        const totalSum = props.wards.filter(ward => props.selectedWards.map(ward => ward.id).includes(ward.id))
            .reduce((acc, ward) => {
                return (acc + (ward[fieldName] as number || 0));
            }, 0);
        const numberOfSelectedWardsWithResidents = props.wards.filter(ward =>
            props.selectedWards.some(selectedWard =>
                selectedWard.id === ward.id &&
                selectedWard[fieldName]))
            .length;
        return numberOfSelectedWardsWithResidents === 0 ? null : totalSum / numberOfSelectedWardsWithResidents;
    };

    return (
        <CustomStyledViewContainer>
            <StyledDropdownsContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.ward"
                    width={240}
                >
                    <MultiSelectionFieldNew
                        name={''}
                        handleChange={props.handleWardSelection}
                        options={props.wards.map(ward => ({
                            label: ward.name,
                            value: ward.id
                        }))}
                        selectedValue={props.selectedWards.map(ward => ({
                            label: ward.name,
                            value: ward.id
                        }))}
                        translations={{
                            allOptionsSelected: localizer("statistics.chart.allWards"),
                            multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                            noOptions: localizer("statistics.chart.noWards")
                        }}
                        maxHeight={500}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>
            <StyledInformationBlocks>
                <StyledInformationBlockWrapper minWidth={300}>
                    <ResidentsInformationBlock
                        selectedWards={props.selectedWards}
                        limitedAccess={props.limitedAccess}
                        amountOfActiveResidents={getActiveResidentCount(residents)}
                        amountOfAllResidents={residents.length}
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper
                    data-tip={localizer("residents.averageOver7Days")}
                    minWidth={300}>
                    <AverageResponseTimeInformationBlock
                        averageResponseTimeLast7DaysMinutes={getAverageResponseTime("avgResponseTimeLast7DaysMinutes")}
                        averageProductChangesLast7Days={getAverageResponseTime("avgProductChangesLast7Days")}
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper minWidth={300}>
                    <StatusSummaryInformationBlock
                        amountOfSaturated={getAmountOfResidentsByBedStatus([ResidentEventType.Saturated])}
                        amountOfErrors={getAmountOfResidentsByBedStatus([ResidentEventType.Error])}
                        amountOfWarnings={getAmountOfResidentsByBedStatus([ResidentEventType.SomeUrine])}
                        amountOfSuccesses={getAmountOfResidentsByBedStatus([ResidentEventType.OK])}
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >
        </CustomStyledViewContainer>

    );
}

export default ResidentsInformationBlocks;
