import { useSelector } from "react-redux";
import NursingHomeGroup from "../../models/NursingHomeGroup";
import { RootState } from "../../store";
import NursingHomeGroupsTable from "../themed/nursingHomeGroups/tables/NursingHomeGroupsTable";
import { StyledViewContainer, StyledInformationBlocks, StyledInformationBlockWrapper } from "../common/View";
import NursingHomeGroupInformationBlock from "../themed/nursingHomeGroups/NursingHomeGroupInformationBlock";

const NursingHomeGroupsView = () => {
    const nursingHomeGroups = useSelector<RootState, NursingHomeGroup[]>(state => state.nursingHomeGroupSlice.nursingHomeGroups);
    
    return (
        <StyledViewContainer>
            <StyledInformationBlocks
                alignLeft
            >
                <StyledInformationBlockWrapper data-qa="nursing_home_groups_card">
                    <NursingHomeGroupInformationBlock />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >

            <NursingHomeGroupsTable
                data={nursingHomeGroups}
            />
        </StyledViewContainer>
    );
}

export default NursingHomeGroupsView;
