// import debounce from 'lodash/debounce';
import moment, { Moment } from "moment";
import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import styled from "styled-components";
import ModalWrapper, { EModalSize } from "../../components/common/modal/ModalWrapper";
import { RestClient } from '../../data/RestClient';
import { ReactComponent as ExportIcon } from "../../images/exportIcon.svg";
import ChangeEvent from '../../models/ChangeEvent';
import ChangeEventPager from '../../models/ChangeEventPager';
import { DateFilter } from "../../models/DateFilter";
import { DateTimeUtils } from "../../models/DateTimeUtils";
import { getPromptedFilter, KpiType } from "../../models/KpiType";
import { NursingHome } from '../../models/NursingHome';
import Sorting, { Direction } from '../../models/Sorting';
import { IStatisticValues } from '../../models/StatisticValues';
import { TimeFilter } from "../../models/TimeFilter";
import { Ward } from "../../models/Ward";
import { RootState } from '../../store';
import '../../styles/main.scss';
import Colors from "../../utils/Colors";
import { localizer } from "../../utils/Localizer";
import ChangeInformationBlocks from "./ChangeInformationBlock";
import ExcelExportForm from "./ExcelExportForm";
import ProductChangesGraph from "./ProductChangesGraph";
import './Statistics.scss';
import StatisticsGraph from "./StatisticsGraph";

interface IStatisticsState {
    dateFilterSelected: DateFilter;
    timeFilterSelected: TimeFilter;
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    selectedStartDate: Date;
    selectedEndDate: Date;
    selectedCompareStartDate: Date | null;
    selectedCompareEndDate: Date | null;
    selectedStartTime: Date | null;
    selectedEndTime: Date | null;
    totalItemsCount: number;
    compareChangeEventsLogs: ChangeEvent[];
    averageChangeEventsLogs: ChangeEvent[];
    hasMorePages: boolean;
    sorting: Sorting;
    isLoadingNextPage: boolean;
    productChanges: IStatisticValues;
    responseTime: IStatisticValues;
    promptedProductChanges: IStatisticValues;
    unpromptedProductChanges: IStatisticValues;
    nightTimeMinutesBetweenChanges: IStatisticValues;
    selectedWardDaysOld: number;
    oldestWardCreationDateDays: number;
    oldestWardMoment: Moment;
    compareKpis?: {
        compareProductChanges: IStatisticValues | undefined;
        compareResponseTime: IStatisticValues | undefined;
        comparePromptedProductChanges: IStatisticValues | undefined;
        compareUnpromptedProductChanges: IStatisticValues | undefined;
        compareNightTimeMinutesBetweenChanges: IStatisticValues | undefined;
    };
}

export interface IChartData {
    date: string;
    selectedWard: number | null;
    selectedCompareWard?: number | null;
    compareDate?: string;
    selectedWardPercentage?: number | null;
    selectedCompareWardPercentage?: number | null;
}

const StyledButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 90px;
    float: right;
    &:hover{
        fill: #092477 !important;
    }
`;

const StyledExportIcon = styled(ExportIcon)`
    margin-left: 16px;
    margin-top: -3px;
    fill: ${Colors.blue.brandingDark};
    &:hover{
        fill: inherit;
    }
    & path {
        transition: all 0.15s ease-in-out;
    }
`;


class Statistics extends Component<IReduxProps, IStatisticsState> {
    readonly state: IStatisticsState = {
        dateFilterSelected: DateFilter.Week,
        timeFilterSelected: DateTimeUtils.getTimeFilterOptions()[0].value,
        selectedKpi: this.getKpiOptions()[0].value,
        selectedWard: undefined,
        selectedStartDate: moment().subtract(7, 'd').toDate(),
        selectedEndDate: moment().subtract(1, 'd').toDate(),
        selectedStartTime: null,
        selectedEndTime: null,
        totalItemsCount: 0,
        averageChangeEventsLogs: [],
        compareChangeEventsLogs: [],
        hasMorePages: true,
        sorting: { column: 'timeCreated', direction: Direction.Down },
        isLoadingNextPage: false,
        productChanges: { value: null, trend: null },
        responseTime: { value: null, trend: null },
        promptedProductChanges: { value: null, trend: null },
        nightTimeMinutesBetweenChanges: { value: null, trend: null },
        unpromptedProductChanges: { value: null, trend: null },
        selectedWardDaysOld: 0,
        oldestWardCreationDateDays: 0,
        oldestWardMoment: moment(),
        selectedCompareStartDate: null,
        selectedCompareEndDate: null,

    };

    averageChangeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);
    changeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);

    componentDidMount(): void {
        this.loadChangeLogs();
        const oldestWardDate = this.props.wards.map(ward => new Date(ward.createdDate)).sort((a, b) => a.getTime() - b.getTime())[0];
        if (this.props.wards && this.props.wards.length > 0) {
            // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
            oldestWardDate.setUTCHours(0,0,0,0);
            const days = Math.floor((Date.now() - oldestWardDate.getTime()) / (24 * 3600 * 1000));
            this.setState({
                oldestWardCreationDateDays: days,
                oldestWardMoment: moment(oldestWardDate)
            });
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IReduxProps>): boolean {
        // If user is logged out we DO NOT need to update component
        // fix for b/35379
        return Boolean(nextProps.userName);
    }

    componentDidUpdate(prevProps: IReduxProps, prevState: IStatisticsState): void {
        if (
            ((prevProps.nursingHome?.id !== this.props.nursingHome?.id) && !!this.props.nursingHome?.id)
            || prevState.dateFilterSelected !== this.state.dateFilterSelected
            || prevState.selectedStartDate !== this.state.selectedStartDate
            || prevState.selectedEndDate !== this.state.selectedEndDate
            || prevState.selectedStartTime !== this.state.selectedStartTime
            || prevState.selectedEndTime !== this.state.selectedEndTime
            || prevState.selectedWard?.id !== this.state.selectedWard?.id
            || prevState.selectedKpi !== this.state.selectedKpi
            || prevState.timeFilterSelected !== this.state.timeFilterSelected
            || prevState.sorting.column !== this.state.sorting.column
            || prevState.sorting.direction !== this.state.sorting.direction
            || prevState.selectedCompareStartDate !== this.state.selectedCompareStartDate
            || prevState.selectedCompareEndDate !== this.state.selectedCompareEndDate
        ) {
            this.loadChangeLogs();
        }

        if (prevProps.wards !== this.props.wards && this.props.wards.length > 0) {
            const oldestWardDate = this.props.wards.map(ward => new Date(ward.createdDate)).sort((a, b) => a.getTime() - b.getTime())[0];
            // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
            oldestWardDate.setUTCHours(0,0,0,0);
            const days = Math.floor((Date.now() - oldestWardDate.getTime()) / (24 * 3600 * 1000));
            this.setState({
                oldestWardCreationDateDays: days,
                oldestWardMoment: moment(oldestWardDate)
            });
        }
    }

    loadChangeLogs = () => {
        this.getAverageChangeLogs();
    }

    private allWardOption = { name: localizer('statistics.chart.allWards'), id: 'all' };

    private getKpiOptions(): { value: KpiType, label: string }[] {
        return [
            { value: KpiType.ResponseTime, label: localizer('constants.changeEventsFilter.kpi.responseTime') },
            { value: KpiType.AllProductChanges, label: localizer('constants.changeEventsFilter.kpi.allProductChanges') },
            { value: KpiType.PromptedProductChanges, label: localizer('constants.changeEventsFilter.kpi.promptedProductChanges') },
            { value: KpiType.UnpromptedProductChanges, label: localizer('constants.changeEventsFilter.kpi.unpromptedProductChanges') }
        ];
    }

    handleWardSelection = (wardId: string | null) => {
        if (wardId !== null) {
            const ward = this.props.wards.find(ward => ward.id === wardId);
            let amountOfDays = 1;
            if (ward) {
                const wardDate = new Date(ward.createdDate);
                // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
                wardDate.setUTCHours(0,0,0,0);
                amountOfDays = Math.floor((Date.now() - wardDate.getTime()) / (24 * 3600 * 1000));
            }
            this.setState({ selectedWard: ward, selectedWardDaysOld: amountOfDays });
        } else {
            this.setState({ selectedWard: undefined });
        }
        this.clearCompareDates();
    };

    dateFilterChanged = (value: any) => {
        this.setState({ dateFilterSelected: Number(value) });
        this.changeEventsPager.timeFrameDays = Number(value);
        this.averageChangeEventsPager.timeFrameDays = Number(value);
        this.clearCompareDates();
    };

    timeFilterChanged = (value: any) => {
        if (value === TimeFilter.AllDay || value === TimeFilter.DayShift || value === TimeFilter.NightShift) {
            this.setState({ timeFilterSelected: value, selectedStartTime: null, selectedEndTime: null });
        } else {
            this.setState({ timeFilterSelected: value });
        }
        this.clearCompareDates();
    };

    startDateChanged = (value: any) => {
        if (this.state.dateFilterSelected !== DateFilter.CustomRange) {
            this.setState({ selectedStartDate: value, dateFilterSelected: DateFilter.CustomRange });
        } else {
            this.setState({ selectedStartDate: value });
        }
        this.clearCompareDates();
    }

    endDateChanged = (value: any) => {
        if (this.state.dateFilterSelected !== DateFilter.CustomRange) {
            this.setState({ selectedEndDate: value, dateFilterSelected: DateFilter.CustomRange });
        } else {
            this.setState({ selectedEndDate: value });
        }
        this.clearCompareDates();
    }

    startTimeChanged = (value: any) => {
        if (this.state.timeFilterSelected !== TimeFilter.CustomRange) {
            this.setState({ selectedStartTime: value, timeFilterSelected: TimeFilter.CustomRange });
        } else {
            this.setState({ selectedStartTime: value });
        }
    }

    endTimeChanged = (value: any) => {
        if (this.state.timeFilterSelected !== TimeFilter.CustomRange) {
            this.setState({ selectedEndTime: value, timeFilterSelected: TimeFilter.CustomRange });
        } else {
            this.setState({ selectedEndTime: value });
        }
    }

    kpiChanged = (value: any) => {
        this.setState({ selectedKpi: value });
    }

    getAllWardsOptions(): { name: string, id: string }[] {
        const options = this.props.wards.map((ward: Ward) => ({ name: ward.name, id: ward.id }));
        // sort alphabetically
        options.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        if (options.length !== 0) {
            options.unshift(this.allWardOption);
        }
        return options;
    }

    validateStartTimeField(): string {
        if (this.state.selectedStartTime === null && this.state.selectedEndTime !== null) {
            return "error-field";
        }
        return "";
    }

    clearAverageChangeLogs = () => {
        this.averageChangeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);
        this.setState({
            averageChangeEventsLogs: [],
        });
    }

    getAverageChangeLogs = async () => {
        this.clearAverageChangeLogs();

        const { startDate, endDate } = DateTimeUtils.getDateRange(this.state.dateFilterSelected, this.state.selectedStartDate, this.state.selectedEndDate, this.state.selectedWard ? this.state.selectedWardDaysOld - 1 : this.state.oldestWardCreationDateDays - 1, true);

        // Day shift = true
        // Night shift = false
        // Day and night = null
        const isDayShift = this.state.timeFilterSelected === TimeFilter.DayShift || this.state.timeFilterSelected === TimeFilter.NightShift ? this.state.timeFilterSelected === TimeFilter.DayShift : null;

        try {
            const response: any = await RestClient.getAverageChangeLogs(
                this.props.nursingHome.id,
                startDate,
                endDate,
                isDayShift,
                getPromptedFilter(this.state.selectedKpi),
                this.state.selectedWard ? this.state.selectedWard.id : this.props.wards.map(w => w.id)
            );

            const rawChangeLogs: ChangeEvent[] = response.groupedNurseActions;

            const logsPage = rawChangeLogs
                .map(value => new ChangeEvent(value));

            this.averageChangeEventsPager.pages.push(logsPage);

            const logs = this.averageChangeEventsPager.getLogs();

            let compareLogs: ChangeEvent[] = [];

            let compareProductChanges: IStatisticValues | undefined;
            let compareResponseTime: IStatisticValues | undefined;
            let comparePromptedProductChanges: IStatisticValues | undefined;
            let compareUnpromptedProductChanges: IStatisticValues | undefined;
            let compareNightTimeMinutesBetweenChanges: IStatisticValues | undefined;
            if (this.state.selectedCompareStartDate !== null && this.state.selectedCompareEndDate !== null) {
                const compareResponse: any = await RestClient.getAverageChangeLogs(
                    this.props.nursingHome.id,
                    moment(this.state.selectedCompareStartDate).subtract(1, "d").utc().toISOString(),
                    moment(this.state.selectedCompareEndDate).utc().toISOString(),
                    isDayShift,
                    getPromptedFilter(this.state.selectedKpi),
                    this.state.selectedWard ? this.state.selectedWard.id : this.props.wards.map(w => w.id)
                );
                const rawCompareChangeLogs: ChangeEvent[] = compareResponse.groupedNurseActions;
                const compareLogsPage = rawCompareChangeLogs
                    .map(value => new ChangeEvent(value));

                compareLogs = compareLogsPage;
                compareProductChanges = compareResponse.productChanges;
                compareResponseTime = compareResponse.responseTime;
                comparePromptedProductChanges = compareResponse.promptedProductChanges;
                compareUnpromptedProductChanges = compareResponse.unpromptedProductChanges;
                compareNightTimeMinutesBetweenChanges = compareResponse.nightTimeMinutesBetweenChanges;

            }

            const responseTime = response.responseTime.value ? { ...response.responseTime, value: Math.round(response.responseTime.value) } : response.responseTime;

            this.setState({
                averageChangeEventsLogs: logs,
                compareChangeEventsLogs: compareLogs,
                productChanges: response.productChanges,
                responseTime,
                promptedProductChanges: response.promptedProductChanges,
                unpromptedProductChanges: response.unpromptedProductChanges,
                nightTimeMinutesBetweenChanges: response.nightTimeMinutesBetweenChanges,
                compareKpis: {
                    compareProductChanges,
                    compareResponseTime,
                    comparePromptedProductChanges,
                    compareUnpromptedProductChanges,
                    compareNightTimeMinutesBetweenChanges
                }
            });
        } catch (error: any) {
            this.clearAverageChangeLogs();
            // tslint:disable-next-line:no-console
            console.error(error);
        }
    }

    setLoadingNextPage = (value: boolean) => {
        this.setState({
            isLoadingNextPage: value
        });
    }

    setCompareStartDate = (value: Date | null) => {
        this.setState({
            selectedCompareStartDate: value
        });
    }

    setCompareEndDate = (value: Date | null) => {
        this.setState({
            selectedCompareEndDate: value
        });
    }

    clearCompareDates = () => {
        this.setState({
            selectedCompareStartDate: null,
            selectedCompareEndDate: null
        });

    }

    getSelectedKpiValueProduct = (selectedKpi: KpiType, isCompare: boolean) => {
        let value;
        switch (selectedKpi) {
            case KpiType.AllProductChanges:
                value = isCompare ? this.state.compareKpis?.compareProductChanges?.value : this.state.productChanges.value;
                break;
            case KpiType.PromptedProductChanges:
                value = isCompare ?
                    { percentage: this.state.compareKpis?.comparePromptedProductChanges?.value, count: this.state.compareKpis?.comparePromptedProductChanges?.count }
                    :
                    { percentage: this.state.promptedProductChanges.value, count: this.state.promptedProductChanges.count };
                break;
            case KpiType.UnpromptedProductChanges:
                value = isCompare ?
                    { percentage: this.state.compareKpis?.compareUnpromptedProductChanges?.value, count: this.state.compareKpis?.compareUnpromptedProductChanges?.count }
                    :
                    { percentage: this.state.unpromptedProductChanges.value, count: this.state.unpromptedProductChanges.count };
                break;
            default:
                break;
        }
        return value;
    }

    getSelectedKpiValueStatistics = (selectedKpi: KpiType, isCompare: boolean) => {
        let value;
        switch (selectedKpi) {
            case KpiType.ResponseTime:
                value = isCompare ? this.state.compareKpis?.compareResponseTime?.value : this.state.responseTime.value;
                break;
            case KpiType.AvgNightTimeMinutesBetweenChanges:
                value = isCompare ? this.state.compareKpis?.compareNightTimeMinutesBetweenChanges?.value : this.state.nightTimeMinutesBetweenChanges.value;
                break;
            default:
                value = isCompare ? this.state.compareKpis?.compareResponseTime?.value : this.state.responseTime.value;
                break;
        }
        return value;
    }

    render(): React.ReactElement {
        const { startDate, endDate } = DateTimeUtils.getDateRange(this.state.dateFilterSelected, this.state.selectedStartDate, this.state.selectedEndDate, this.state.selectedWard ? this.state.selectedWardDaysOld - 1 : this.state.oldestWardCreationDateDays - 1, true);
        const dateFilterRanges = [
            DateFilter.Yesterday,
            DateFilter.Week,
            DateFilter.HalfMonth,
            DateFilter.Month,
            DateFilter.TwoMonths,
            DateFilter.ThreeMonths,
            DateFilter.SixMonths,
            DateFilter.TwelveMonths,
            DateFilter.SinceStart,
            DateFilter.CustomRange,
        ];


        const triggerComponent = (
            <StyledButtonsContainer>
                <Button variant="outline-secondary">
                    {localizer("statistics.excelExport.exportData")}
                    <StyledExportIcon />
                </Button>
            </StyledButtonsContainer>);

        return (
            <div id="statistics-view">
                {this.props.nursingHome.id && <div>
                    <ChangeInformationBlocks
                        allWards={this.getAllWardsOptions()}
                        selectedDateFilter={Number(this.state.dateFilterSelected)}
                        ward={this.state.selectedWard ? this.state.selectedWard : this.allWardOption}
                        dateFilterOptions={DateTimeUtils.getDateFilterOptions(dateFilterRanges)}
                        timeFilterOptions={DateTimeUtils.getTimeFilterOptions([TimeFilter.AllDay, TimeFilter.DayShift, TimeFilter.NightShift])}
                        dateFilterChanged={this.dateFilterChanged}
                        startDateChanged={this.startDateChanged}
                        endDateChanged={this.endDateChanged}
                        startTimeChanged={this.startTimeChanged}
                        endTimeChanged={this.endTimeChanged}
                        timeChanged={this.timeFilterChanged}
                        selectWard={this.handleWardSelection}
                        selectedTimeFilter={this.state.timeFilterSelected}
                        selectedStartDate={this.state.selectedStartDate}
                        selectedEndDate={this.state.selectedEndDate}
                        selectedStartTime={this.state.selectedStartTime}
                        selectedEndTime={this.state.selectedEndTime}
                        kpiChanged={this.kpiChanged}
                        selectedKpi={this.state.selectedKpi}
                        productChanges={this.state.productChanges}
                        responseTime={this.state.responseTime}
                        promptedProductChanges={this.state.promptedProductChanges}
                        unpromptedProductChanges={this.state.unpromptedProductChanges}
                        nightTimeMinutesBetweenChanges={this.state.nightTimeMinutesBetweenChanges}
                        oldestWardCreationDateDays={this.state.oldestWardCreationDateDays}
                        selectedWardDaysOld={this.state.selectedWardDaysOld}
                    />
                    <div className="table-wrapper">
                        {this.state.selectedKpi === KpiType.ResponseTime || this.state.selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges ? (
                            <StatisticsGraph
                                averageChangeEventsLogs={this.state.averageChangeEventsLogs}
                                compareChangeEventsLogs={this.state.compareChangeEventsLogs}
                                selectedWard={this.state.selectedWard}
                                selectedKpi={this.state.selectedKpi}
                                kpiValue={this.getSelectedKpiValueStatistics(this.state.selectedKpi, false)}
                                compareKpiValue={this.getSelectedKpiValueStatistics(this.state.selectedKpi, true)}
                                startDate={startDate}
                                endDate={endDate}
                                startCompareDate={this.state.selectedCompareStartDate}
                                nursingHome={this.props.nursingHome}
                                oldestWardMoment={this.state.oldestWardMoment}
                                selectedWardCreation={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : undefined}
                                setSelectedCompareStartDate={this.setCompareStartDate}
                                setSelectedCompareEndDate={this.setCompareEndDate}
                                customPeriod={this.state.dateFilterSelected === DateFilter.CustomRange}

                            />
                        ) : (
                            <ProductChangesGraph
                                averageChangeEventsLogs={this.state.averageChangeEventsLogs}
                                compareChangeEventsLogs={this.state.compareChangeEventsLogs}
                                selectedWard={this.state.selectedWard}
                                selectedKpi={this.state.selectedKpi}
                                kpiValue={this.getSelectedKpiValueProduct(this.state.selectedKpi, false)}
                                compareKpiValue={this.getSelectedKpiValueProduct(this.state.selectedKpi, true)}
                                startDate={startDate}
                                endDate={endDate}
                                startCompareDate={this.state.selectedCompareStartDate}
                                oldestWardMoment={this.state.oldestWardMoment}
                                selectedWardCreation={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : undefined}
                                setSelectedCompareStartDate={this.setCompareStartDate}
                                setSelectedCompareEndDate={this.setCompareEndDate}
                                customPeriod={this.state.dateFilterSelected === DateFilter.CustomRange}
                            />
                        )}


                        <ModalWrapper
                            triggerComponent={triggerComponent}
                            modalContent={
                                <ExcelExportForm
                                    startDate={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : this.state.oldestWardMoment}
                                    wardIds={this.state.selectedWard ? [this.state.selectedWard.id] : this.props.wards.map((ward: Ward) => ward.id)}
                                    nursingHomeId={this.props.nursingHome.id}
                                />
                            }
                            modalTitle={localizer("statistics.excelExport.exportData")}
                            size={EModalSize.S}
                        />

                    </div>
                </div>
                }
            </div>
        );
    }
}

interface IReduxProps {
    nursingHome: NursingHome;
    wards: Ward[];
    userName: string;
}

const mapStateToProps = (state: RootState): IReduxProps => {
    return {
        nursingHome: state.nursingHomeSlice.nursingHome || new NursingHome(),
        wards: state.wardSlice.wards,
        userName: state.contextSlice.userName,
    };
};

export default connect(
    mapStateToProps
)(Statistics);
