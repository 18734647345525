import { DateFilter } from "./DateFilter";
import { TimeFilter } from "./TimeFilter";
import moment from "moment";
import { EMPTY_VALUE, getBrowserLocale, localizer } from "../utils/Localizer";
import { NursingHome } from "./NursingHome";
import format from "date-fns/format";

export class DateTimeUtils {
    private static timeFrameOptions = [
        DateFilter.Today,
        DateFilter.Yesterday,
        DateFilter.Week,
        DateFilter.HalfMonth,
        DateFilter.Month,
        DateFilter.TwoMonths,
        DateFilter.ThreeMonths,
        DateFilter.CustomRange,
    ];

    static getDateFilterOptions(
        timeFrameOptions?: DateFilter[]
    ): Array<{ value: DateFilter; label: string }> {
        return (timeFrameOptions || this.timeFrameOptions).map((option) => ({
            value: option,
            label:
                option === DateFilter.Today
                    ? localizer("constants.today")
                    : option === DateFilter.Yesterday
                        ? localizer("constants.lastDay")
                        : option === DateFilter.CustomRange
                            ? localizer("constants.customRange")
                            : option === DateFilter.SinceStart
                                ? localizer("constants.sinceStart")
                                : localizer("constants.lastDays", { amount: option }),
        }));
    }

    static getTimeFilterOptions(timeFrameOptions?: TimeFilter[]) {
        const optionList: { value: TimeFilter, label: string }[] = [];
        const options = timeFrameOptions ? timeFrameOptions : [TimeFilter.AllDay, TimeFilter.DayShift, TimeFilter.NightShift, TimeFilter.CustomRange];
        options.forEach((option: TimeFilter) => {
            switch (option) {
                case TimeFilter.AllDay:
                    optionList.push(
                        {
                            value: TimeFilter.AllDay,
                            label: localizer("constants.changeEventsFilter.time.allDay"),
                        });
                    break;
                case TimeFilter.DayShift:
                    optionList.push(
                        {
                            value: TimeFilter.DayShift,
                            label: localizer("constants.changeEventsFilter.time.dayShift"),
                        });
                    break;
                case TimeFilter.NightShift:
                    optionList.push(
                        {
                            value: TimeFilter.NightShift,
                            label: localizer("constants.changeEventsFilter.time.nightShift"),
                        });
                    break;
                case TimeFilter.CustomRange:
                    optionList.push(
                        {
                            value: TimeFilter.CustomRange,
                            label: localizer("constants.customRange"),
                        });
                    break;

                default:
                    break;
            }
        });
        return optionList;
    }

    static getDateRange(
        dateFilter: DateFilter,
        selectedStartDate: Date,
        selectedEndDate: Date,
        sinceStart?: number,
        excludeToday?: boolean
    ): { startDate: string; endDate: string } {
        let startDate = excludeToday ? moment().startOf("day").subtract(1, "d") : moment().startOf("day");
        let endDate = excludeToday ? moment().startOf("day") : moment().startOf("day").add(1, "d");

        // since we include today, we substract 1 day less in filters
        switch (dateFilter) {
            case DateFilter.Today:
                break;
            case DateFilter.Yesterday:
                startDate = moment().startOf("day").subtract(1, "d");
                endDate = moment().startOf("day");
                break;
            case DateFilter.Week:
                startDate.subtract(DateFilter.Week - 1, "d");
                break;
            case DateFilter.HalfMonth:
                startDate.subtract(DateFilter.HalfMonth - 1, "d");
                break;
            case DateFilter.Month:
                startDate.subtract(DateFilter.Month - 1, "d");
                break;
            case DateFilter.TwoMonths:
                startDate.subtract(DateFilter.TwoMonths - 1, "d");
                break;
            case DateFilter.ThreeMonths:
                startDate.subtract(DateFilter.ThreeMonths - 1, "d");
                break;
            case DateFilter.SixMonths:
                startDate.subtract(DateFilter.SixMonths - 1, "d");
                break;
            case DateFilter.TwelveMonths:
                startDate.subtract(DateFilter.TwelveMonths - 1, "d");
                break;
            case DateFilter.SinceStart:
                startDate.subtract(sinceStart, "d");
                break;
            case DateFilter.CustomRange:
                startDate = moment(selectedStartDate).startOf("day");
                endDate = moment(selectedEndDate).startOf("day").add(1, "d");
                break;
            default:
                startDate.subtract(DateFilter.Week - 1, "d");
        }

        return {
            startDate: startDate.utc().toISOString(),
            endDate: endDate.utc().toISOString(),
        };
    }

    static getTimeRange(
        nursingHome: NursingHome,
        timeFilter: TimeFilter,
        selectedStartTime: Date | null,
        selectedEndTime: Date | null
    ): { startTime: string | null; endTime: string | null } {
        let startTime = null;
        let endTime = null;

        switch (timeFilter) {
            case TimeFilter.AllDay:
                break;
            case TimeFilter.DayShift:
                startTime = this.generateTimeFromString(
                    nursingHome.morningShiftStartTime
                );
                endTime = this.generateTimeFromString(
                    nursingHome.nightShiftStartTime
                );
                break;
            case TimeFilter.NightShift:
                startTime = this.generateTimeFromString(
                    nursingHome.nightShiftStartTime
                );
                endTime = this.generateTimeFromString(
                    nursingHome.morningShiftStartTime
                );
                break;
            case TimeFilter.CustomRange:
                startTime = moment(selectedStartTime).utc().toISOString();
                endTime = moment(selectedEndTime).utc().toISOString();
                break;
        }

        return { startTime, endTime };
    }

    static generateTimeFromString = (hoursMinutes: string) => {
        const [hours, minutes] = hoursMinutes.split(":");
        return moment()
            .set("hour", Number(hours))
            .set("minute", Number(minutes))
            .utc()
            .toISOString();
    };

    static generateTime = (hours: number, minutes: number) => {
        return moment().set("hour", hours).set("minute", minutes).toDate();
    };

    static formatUsingBrowserLocale = (
        value: string,
        pattern: string = "Pp",
        locale = getBrowserLocale()
    ) => {
        if (value) {
            const formattedDate = format(moment.utc(value).toDate(), pattern, {
                locale,
            });

            return formattedDate;
        }

        return EMPTY_VALUE;
    };

    static formatWithMiliseconds = (value: string) => {
        if (value) {
            const formattedDate = format(
                moment.utc(value).toDate(),
                "yyyy-MM-dd HH:mm:ss.SSS"
            );

            return formattedDate;
        }

        return EMPTY_VALUE;
    };

    static getCompareInterval = (start: Date, end: Date) => {
        return Math.round((end.getTime() - start.getTime()) / (24 * 3600 * 1000));
    };
}
