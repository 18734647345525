import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StyledAmount, StyledDescription, StyledTitle } from "../../common/informationBlock/styled";
import { StyledInlineFlexStart } from "../../styled";

const StyledDescriptionUsers = styled(StyledDescription)`
    margin-top: 3px;
`;
interface IUserAddInformationBlockProps {
    amountOfUsers: number;
    addButton: React.ReactNode | null;
}

const UserAddInformationBlock = (props: IUserAddInformationBlockProps) => {
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={"users.informationBlocks.title.add"} /></StyledTitle>
                {props.addButton}
            </StyledInlineFlexStart>
            <StyledDescriptionUsers>
                <FormattedMessage id={'users.informationBlocks.totalUsersProvisioned'} />
            </StyledDescriptionUsers>
            <StyledAmount data-qa="users_amount">
                {props.amountOfUsers}
            </StyledAmount>
        </>

    );
};

export default UserAddInformationBlock;
