import React from 'react';
import ResidentHistoryIcon from '../ResidentHistoryIcon';
import ResidentLimitedEditIcon from '../ResidentViewIcon';
import { StyledRight } from '../../../components/common/table/formatters/PositionContainers';
import styled from 'styled-components';

const MarginedStyledRight = styled(StyledRight)`
    > :not(:last-child) {
        margin-right: 12px;
    }

    word-break: normal;
`;

class LimitedActions extends React.Component<any> {
    render(): React.ReactElement {
        return (
            <MarginedStyledRight>
                <ResidentHistoryIcon
                    residentId={this.props.row.original.id}
                    residentName={this.props.row.original.name}
                    disabled={false}
                />
                <ResidentLimitedEditIcon
                    resident={this.props.row.original}
                />
            </MarginedStyledRight>
        );
    }
}

export default LimitedActions;
