import { createIntl, createIntlCache, IntlShape } from "react-intl";
import { getCookie } from "./Cookies";
import { be, da, de, enUS, fr, it, nb, nl, nlBE, pl, sv } from "date-fns/locale";
import czJSON from "../lang/SmartCare_Professional_Web_Czech.json";
import daJSON from "../lang/SmartCare_Professional_Web_Danish.json";
import nlJSON from "../lang/SmartCare_Professional_Web_Dutch.json";
import enJSON from "../lang/SmartCare_Professional_Web_English.json";
import enUSJSON from "../lang/SmartCare_Professional_Web_English_US.json";
import nlBEJSON from "../lang/SmartCare_Professional_Web_Flemish.json";
import frJSON from "../lang/SmartCare_Professional_Web_French.json";
import frBEJSON from "../lang/SmartCare_Professional_Web_French_BE.json";
import deJSON from "../lang/SmartCare_Professional_Web_German.json";
import itJSON from "../lang/SmartCare_Professional_Web_Italian.json";
import nbJSON from "../lang/SmartCare_Professional_Web_Norwegian.json";
import skJSON from "../lang/SmartCare_Professional_Web_Slovak.json";
import svJSON from "../lang/SmartCare_Professional_Web_Swedish.json";

export const localizations: any = {
    cs: czJSON,
    da: daJSON,
    nl: nlJSON,
    en: enJSON,
    enUS: enUSJSON,
    nlBE: nlBEJSON,
    fr: frJSON,
    frBE: frBEJSON,
    de: deJSON,
    it: itJSON,
    nb: nbJSON,
    sk: skJSON,
    sv: svJSON,
};

export const languageCookieName = 'language';
export const EMPTY_VALUE = '--';
let intl: IntlShape | null = null;
const resetPasswordAvailableCodes = ['pl', 'en', 'sv', 'da', 'nl', 'de', 'fr', 'it', 'nl-BE', 'be', 'nb', 'cs', 'sk'];
const locales: any = { pl, enUS, sv, da, nl, de, fr, it, nlBE, be, nb };

export const convertLangCode = (langCode: string): string => langCode.replace("-", "");

export const getLocale = (languageCookieValue: string | null = null): string => {
    const languageCookie = languageCookieValue || getCookie(languageCookieName);
    const browserLocaleKey = ((navigator.languages && navigator.languages[0]) || navigator.language).substring(0, 2);

    if (languageCookie && localizations[convertLangCode(languageCookie)]) {
        return languageCookie;
    } else if (localizations[browserLocaleKey]) {
        return browserLocaleKey;
    }
    return 'en';
};

export const getBrowserLocale = () => {
    const currentLocale = convertLangCode(navigator.language);
    return locales[currentLocale] || locales[currentLocale.substring(0, 2)];
};

export const getLocaleWithKey = (localeKey: string) => {
    if (Object.keys(locales).includes(localeKey)) {
        return locales[localeKey];
    }

    return enUS;
};


export const localizer = (key: string, values?: Record<string, string | number | Date>): string => {
    const locale = getLocale();

    if (!intl || intl.locale !== locale) {
        intl = createIntl({
            locale,
            messages: (localizations as any)[convertLangCode(locale)]
        }, createIntlCache());
    }

    return intl ? intl.formatMessage({ id: key }, values) : '';
};

export const getLocalizedCountries = (): Array<{ code: string, label: string }> => {
    const locale = getLocale().replace('-', '_');
    let localizedCountries;
    try {
        localizedCountries = require("localized-countries")(require(`localized-countries/data/${locale}`));
    } catch {
        localizedCountries = require("localized-countries")(require("localized-countries/data/en"));
    }

    return localizedCountries.array();
};

export const getLocalizedCountry = (code: string): string => {
    const localizedCountries = getLocalizedCountries();

    return localizedCountries.find(country => country.code.toLowerCase() === code.toLowerCase())?.label || code;
};

export const getResetPasswordLocalizedUrl = (): string => {
    const doubleLetterCountryCode = getLocale().substring(0, 2);
    const resetPasswordLocaleQueryParameter = resetPasswordAvailableCodes.some(resetPasswordAvailableCode => resetPasswordAvailableCode.toLowerCase() === doubleLetterCountryCode.toLowerCase())
        ? `&ui_locales=${doubleLetterCountryCode}`
        : '';

    return process.env.REACT_APP_RESET_PASSWORD_URL + resetPasswordLocaleQueryParameter;
};

// This method converts the locales we are using to a different version of the same country 
export const convertLocale = (locale: string) => {
    switch (locale) {
        case "nb":
            return "no";
        default:
              return locale;
    }
};
