import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import RoutesGenerator from "./components/common/RoutesGenerator";
import Tooltip from "./components/common/tooltip/Tooltip";
import AppRepository, { AppContext } from "./data/AppRepository";
import { RestClient } from "./data/RestClient";
import SessionHandler from "./data/SessionHandler";
import { fetchUserResources, IContextStateType, setCookieScripExecution } from "./reducers/contextSlice";
import { spinnerKeys } from "./reducers/spinnerSlice";
import { getConsentNoticeScripts } from "./scripts/CookieScripts";
import { RootState } from "./store";
import { convertLangCode, convertLocale, getLocale, languageCookieName, localizations } from "./utils/Localizer";
import { toastError } from "./utils/Toaster";
import { BlockingSpinner } from "./views/BlockingSpinner/BlockingSpinner";
import Footer from "./views/Footer";
import TitleBar from "./views/TitleBar";

const StyledApp = styled.div`
    min-height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyledConentWrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
`;

const AuthenticationProvider = () => {
    const dispatch = useDispatch();
    const blockFetchUserResources = sessionStorage.getItem("blockFetchUserResources") === "true";
    useEffect(() => {
        if (!blockFetchUserResources) {
            dispatch(fetchUserResources(true));
        }
    }, [dispatch, blockFetchUserResources]);
    return <RoutesGenerator />;
};


const App = () => {
    const [cookies] = useCookies();
    const dispatch = useDispatch();

    const { inactiveTimeInMinutesBeforeLogout, isAuthenticated } = useSelector<
        RootState,
        IContextStateType
    >((state) => state.contextSlice);
    const isSpinnerVisible = useSelector<RootState, boolean>(
        (state) => state.spinnerSlice.activeSpinners.some((activeSpinner) => activeSpinner === spinnerKeys.global)
    );

    const locale = getLocale(cookies[languageCookieName]);

    useEffect(() => {
        const fetchData = async () => {
            const { enableCookieScriptExecution } = await RestClient.getConfiguration();
            dispatch(setCookieScripExecution(enableCookieScriptExecution));
            if (enableCookieScriptExecution) {
                const cookiesScripts = getConsentNoticeScripts();
                cookiesScripts.forEach(script => document.head.appendChild(script));
                document.documentElement.lang = convertLocale(locale);
            }
        };
        fetchData().catch((error: any) =>
            toastError(error.message || error)
        );
    }, []);

    return (
        <IntlProvider
            key={locale}
            locale={locale}
            messages={localizations[convertLangCode(locale)]}
        >
            <Tooltip />
            <ToastContainer enableMultiContainer={false} />
            <BlockingSpinner isVisible={isSpinnerVisible} />
            <AppRepository>
                <AppContext.Consumer>
                    {() => (
                        <>
                            {isAuthenticated && (
                                <SessionHandler
                                    inactivityLimitMinutes={
                                        inactiveTimeInMinutesBeforeLogout
                                    }
                                />
                            )}
                            <StyledApp>
                                <TitleBar />
                                <StyledConentWrapper>
                                    <AuthenticationProvider />
                                </StyledConentWrapper>
                            </StyledApp>
                        </>
                    )}
                </AppContext.Consumer>
            </AppRepository>
            <Footer />
        </IntlProvider>
    );
};

export default App;
