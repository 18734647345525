import moment from "moment";
import styled from "styled-components";
import DropdownDateTimeDaysInThePastFromNowRangeButton from "../../components/common/controls/dateTime/DropdownDateTimeDaysInThePastFromNowRangeButton";
import { PickerType } from "../../components/common/controls/dateTime/helpers/PickerType";
import TimeRangePickerDropdown from "../../components/common/controls/dateTime/TimeRangePickerDropdown";
import DropdownButtonNew from "../../components/common/controls/DropdownButtonNew";
import NumberInformationBlock from "../../components/common/informationBlock/NumberInformationBlock";
import {
    StyledBoldSpan,
    StyledInformationBlocks,
    StyledInformationBlockWrapper,
    StyledSpan, StyledViewContainer
} from "../../components/common/View";
import { ReactComponent as TrendDown } from "../../images/trendDown.svg";
import { ReactComponent as TrendStagnant } from "../../images/trendStagnant.svg";
import { ReactComponent as TrendUp } from "../../images/trendUp.svg";
import { KpiType } from "../../models/KpiType";
import { IStatisticValues } from "../../models/StatisticValues";
import "../../styles/main.scss";
import Colors from "../../utils/Colors";
import {
    convertMinutesToHours,
    convertTimeToHtml
} from "../../utils/ConvertMinutesToHours";
import { localizer } from "../../utils/Localizer";
import InformationBlock from "../../views/InformationBlock/InformationBlock";
import "./ChangeInformationBlock.scss";

const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
`;

const CustomStyledInformationBlockWrapper = styled(StyledInformationBlockWrapper)`
    width: 270px;
`;

const StyledValueContainer = styled.div`
    float: left;
    font-size:24px;
`;

const StyledTrendContainer = styled.div`
    float: right;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    max-height: 30px;
    line-height: 10px;
`;

const IconContainer = styled.div`
    align-self: center;
    min-height: 17px;
    max-height: 17px;
`;

const parentClassName = "no-border-block";
const childClassName = "general-info-child";

interface IChangeInformationBlocksProps {
    selectedDateFilter: number;
    selectedStartDate: Date;
    selectedEndDate: Date;
    selectedStartTime: Date | null;
    selectedEndTime: Date | null;
    ward: any;
    allWards: any[];
    dateFilterOptions: any[];
    timeFilterOptions: any[];
    dateFilterChanged: (value: any) => void;
    startDateChanged: (value: Date | null) => void;
    endDateChanged: (value: Date | null) => void;
    startTimeChanged: (value: Date | null) => void;
    endTimeChanged: (value: Date | null) => void;
    timeChanged: (value: any) => void;
    selectWard: (wardId: string | null) => void;
    selectedTimeFilter: string;
    kpiChanged: (value: any) => void;
    selectedKpi: KpiType;
    productChanges: IStatisticValues;
    responseTime: IStatisticValues;
    promptedProductChanges: IStatisticValues;
    unpromptedProductChanges: IStatisticValues;
    nightTimeMinutesBetweenChanges: IStatisticValues;
    oldestWardCreationDateDays: number;
    selectedWardDaysOld: number;
}

const ChangeInformationBlocks = (props: IChangeInformationBlocksProps) => {

    const handleDropdownSelection = (name: string, value: any) => {
        if (name === "wardSelected" && value !== props.ward.id) {
            props.selectWard(value);
        }
        if (name === "dateSelected" && value !== String(props.selectedDateFilter)) {
            props.dateFilterChanged(value);
        }
        if (name === "timeSelected" && value !== props.selectedTimeFilter) {
            props.timeChanged(value);
        }
        if (name === "kpiSelected" && value !== props.selectedKpi) {
            props.kpiChanged(value);
        }
    };

    const getTrendIcon = (value: number | null, kpi: KpiType) => {
        if (value === null) {
            return <></>;
        } else {
            if (value > 0) {
                return <TrendUp stroke={getTrendIconColor(kpi, true)} />;
            } else if (value < 0) {
                return <TrendDown stroke={getTrendIconColor(kpi, false)} />;
            } else {
                return <TrendStagnant />;
            }
        }
    };

    const getTrendIconColor = (kpi: KpiType, isPositiveValue: boolean) => {
        if (kpi === KpiType.PromptedProductChanges || kpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            return isPositiveValue ? Colors.green.trendGreen : Colors.red.maryRed;
        } else {
            return isPositiveValue ? Colors.red.maryRed : Colors.green.trendGreen;
        }
    };

    const getTrendValue = (trendValue: number | null) => {
        if (trendValue !== null) {
            const roundedValue = Math.round(trendValue);
            if (roundedValue > 0) {
                return "+" + roundedValue.toString();
            }
            return roundedValue;
        }
        return "--";
    };

    const getUmpromptedValue = (unpromptedValue: number | null, promptedValue: number | null) => {
        if (unpromptedValue !== null && promptedValue !== null) {
            const unpromptedProductChangesRounded = Math.round(unpromptedValue);
            if (unpromptedProductChangesRounded + Math.round(promptedValue) === 101) {
                return unpromptedProductChangesRounded - 1;
            }
            return unpromptedProductChangesRounded;
        }
        return "--";
    };

    const {
        allWards,
        dateFilterOptions,
        endDateChanged,
        endTimeChanged,
        nightTimeMinutesBetweenChanges,
        productChanges,
        promptedProductChanges,
        responseTime,
        selectedDateFilter,
        selectedEndDate,
        selectedEndTime,
        selectedKpi,
        selectedStartTime,
        selectedStartDate,
        selectedTimeFilter,
        startDateChanged,
        startTimeChanged,
        timeFilterOptions,
        unpromptedProductChanges,
        ward
    } = props;

    const specificXAxisOffsetValue = 167;

    return (
        <StyledViewContainer className="statistics-view-info">
            <StyledDropdownsContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.ward"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                >
                    <DropdownButtonNew
                        id="wardDropdown"
                        handleChange={handleDropdownSelection}
                        options={allWards.map((ward) => ({
                            label: ward.name,
                            value: ward.id,
                        }))}
                        selectedValue={ward.id}
                        name="wardSelected"
                        noOptionsMessageId="statistics.chart.noWards"
                        maxHeight={500}
                    />
                </InformationBlock>
                <InformationBlock
                    title="statistics.changeInformationBlock.date"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                >
                    <DropdownDateTimeDaysInThePastFromNowRangeButton
                        amountOfDays={ward.createdDate ? props.selectedWardDaysOld : props.oldestWardCreationDateDays}
                        id="dateDropdown"
                        options={dateFilterOptions}
                        handleChange={handleDropdownSelection}
                        selectedValue={selectedDateFilter}
                        name="dateSelected"
                        start={selectedStartDate}
                        end={selectedEndDate}
                        handleStartDateTimeChange={
                            startDateChanged
                        }
                        handleEndDateTimeChange={endDateChanged}
                        pickerType={PickerType.Date}
                        offsetValue={specificXAxisOffsetValue}
                        endPickerPropertiesInitialMaxDate={moment().subtract(1, "d")}
                    />
                </InformationBlock>
                <InformationBlock
                    title="statistics.changeInformationBlock.time"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                >
                    <TimeRangePickerDropdown
                        name="timeSelected"
                        value={selectedTimeFilter}
                        onChange={handleDropdownSelection}
                        options={timeFilterOptions}
                        handleStartDateTimeChange={
                            startTimeChanged
                        }
                        handleEndDateTimeChange={endTimeChanged}
                        start={selectedStartTime}
                        end={selectedEndTime}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>

            <StyledInformationBlocks>
                <CustomStyledInformationBlockWrapper
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.ResponseTime
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.ResponseTime
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.responseTime"
                        )}
                        description={localizer(
                            "statistics.graphHeader.responseTimeAverage"
                        )}
                        value={
                            <>
                                <StyledValueContainer>
                                    {convertTimeToHtml(
                                        convertMinutesToHours(
                                            responseTime.value
                                        ),
                                        true,
                                        true
                                    )}
                                </StyledValueContainer>
                                {responseTime.value !== null && (
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(responseTime.trend, KpiType.ResponseTime)}</IconContainer>
                                        <div>{getTrendValue(responseTime.trend)}%</div>
                                    </StyledTrendContainer>)}
                            </>
                        }
                    />
                </CustomStyledInformationBlockWrapper>
                <CustomStyledInformationBlockWrapper
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.AllProductChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.AllProductChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.allProductChanges"
                        )}
                        description={localizer("constants.changeEventsFilter.kpi.allProductChanges.description")}
                        value={productChanges.value !== null && productChanges.value > 0 && productChanges.value !== null ?
                            (
                                <>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{Math.round(productChanges.value * 10) / 10}</StyledBoldSpan>
                                        <StyledSpan>
                                            /{localizer("constants.changeEventsFilter.kpi.day")}
                                        </StyledSpan>
                                    </StyledValueContainer>
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(productChanges.trend, KpiType.AllProductChanges)}</IconContainer>
                                        <div>{getTrendValue(productChanges.trend)}%</div>
                                    </StyledTrendContainer>
                                </>
                            ) : (
                                <StyledValueContainer>
                                    --
                                </StyledValueContainer>
                            )
                        }
                    />
                </CustomStyledInformationBlockWrapper>
                <CustomStyledInformationBlockWrapper
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.PromptedProductChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.PromptedProductChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.promptedProductChanges"
                        )}
                        description={localizer("constants.changeEventsFilter.kpi.promptedProductChanges.description")}
                        value={productChanges.value !== null && productChanges.value > 0 && promptedProductChanges.value !== null ?
                            (
                                <>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{promptedProductChanges.count}</StyledBoldSpan>
                                        <StyledSpan>({Math.round(promptedProductChanges.value)}</StyledSpan>
                                        <StyledSpan>%</StyledSpan>)
                                    </StyledValueContainer>
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(promptedProductChanges.trend, KpiType.PromptedProductChanges)}</IconContainer>
                                        <div>{getTrendValue(promptedProductChanges.trend)}% p.p.</div>
                                    </StyledTrendContainer>
                                </>
                            ) : (
                                <StyledValueContainer>
                                    --
                                </StyledValueContainer>
                            )
                        }
                    />
                </CustomStyledInformationBlockWrapper>
                <CustomStyledInformationBlockWrapper
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.UnpromptedProductChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.UnpromptedProductChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.unpromptedProductChanges"
                        )}
                        description={localizer("constants.changeEventsFilter.kpi.unpromptedProductChanges.description")}
                        value={productChanges.value !== null && productChanges.value > 0 ?
                            (
                                <>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{unpromptedProductChanges.count}</StyledBoldSpan>
                                        <StyledSpan>({getUmpromptedValue(unpromptedProductChanges.value, promptedProductChanges.value)}</StyledSpan>
                                        <StyledSpan>%</StyledSpan>)
                                    </StyledValueContainer>
                                    {unpromptedProductChanges.value !== null && (
                                        <StyledTrendContainer>
                                            <IconContainer>{getTrendIcon(unpromptedProductChanges.trend, KpiType.UnpromptedProductChanges)}</IconContainer>
                                            <div>{getTrendValue(unpromptedProductChanges.trend)}% p.p.</div>
                                        </StyledTrendContainer>)}
                                </>
                            ) : (
                                <StyledValueContainer>
                                    --
                                </StyledValueContainer>
                            )
                        }
                    />
                </CustomStyledInformationBlockWrapper>
                <CustomStyledInformationBlockWrapper
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.AvgNightTimeMinutesBetweenChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer("constants.changeEventsFilter.kpi.nightTime")}
                        description={localizer("constants.changeEventsFilter.kpi.nightTime.description")}
                        value={
                            <>
                                <StyledValueContainer>
                                    {convertTimeToHtml(
                                        convertMinutesToHours(
                                            nightTimeMinutesBetweenChanges.value
                                        ),
                                        true,
                                        true
                                    )}
                                </StyledValueContainer>
                                {nightTimeMinutesBetweenChanges.value !== null && (
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(nightTimeMinutesBetweenChanges.trend, KpiType.AvgNightTimeMinutesBetweenChanges)}</IconContainer>
                                        <div>{getTrendValue(nightTimeMinutesBetweenChanges.trend)}%</div>
                                    </StyledTrendContainer>)}
                            </>}
                    />
                </CustomStyledInformationBlockWrapper>


            </StyledInformationBlocks>
        </StyledViewContainer>
    );
};


export default ChangeInformationBlocks;