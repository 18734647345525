import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Colors from "../utils/Colors";

const backgroundColor = Colors.white.clearWhite;

const StyledNavbar = styled.div`
    background-color: ${backgroundColor};
    height: 56px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding-left: 10px;
    border-bottom: 1px inset #E0E0E0;
`;

export const StyledNavbarItem = `
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledLink = styled.div<any>`
    ${StyledNavbarItem}
    ${(props: any) => props.id === 'mainNav.audit' ?
        css`
            padding-left: 30px;
            margin-left: auto;
            margin-right: 40px;
        `:
        'margin-left: 30px;'
    }
    border-bottom: ${(props: any) => props.active ? '4px solid #05BAF1' : '4px solid ' + backgroundColor};
    color: ${(props: any) => props.active ? '#05BAF1' : '#003B73'};
    &:hover {
        text-decoration: none;
        color: ${(props: any) => props.active ? '#05BAF1' : '#003B73'};
    }
`;

export type LinkData = {
    id: string,
    qaId: string,
    to: string,
};

interface Props {
    linksData: LinkData[];
    hideButtons?: boolean;
}

const Navbar = (props: Props) => {
    const [windowLocationPathname, setWindowLocationPathname] = useState(window.location.pathname);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        setWindowLocationPathname(location.pathname);
    }, [location]);

    const onNavigationButtonClick = (path: string) => {
        setWindowLocationPathname(path);
        navigate(path);
    };

    return (
        <StyledNavbar>
            {
                !props.hideButtons && props.linksData.map(linkData => {
                    return (
                        <StyledLink
                            id={linkData.id}
                            data-qa={"navbar-"+linkData.qaId}
                            key={linkData.id}
                            onClick={() => onNavigationButtonClick(linkData.to)}
                            active={(linkData.to === windowLocationPathname)}
                        >
                            <FormattedMessage id={linkData.id} />
                        </StyledLink>
                    );
                })
            }
        </StyledNavbar>
    );
};

export default Navbar;