import { Transmitter } from "./Transmitter";
import { MonitoringTime } from "./MonitoringTime";

export class Resident {
    id: string;
    name: string;
    wardId: string;
    isActive: boolean;
    description: string;
    bedStatus: string;
    statusUpdatedTime: Date | undefined;
    transmitterError: string;
    isBatteryFlat: boolean;
    monitoringTransmitter: string;
    nightTransmitter: Transmitter = new Transmitter();
    dayTransmitter: Transmitter = new Transmitter();
    nightTimeSetup: boolean = false;
    dayTimeSetup: boolean = false;
    separateTransmitter: boolean = false;
    responseTargetOverdue: boolean = false;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.name = json.name || '';
        this.wardId = json.wardId ? String(json.wardId) : '';
        this.isActive = json.isActive ?? true;
        this.description = json.description || '';
        this.bedStatus = json.bedStatus || 'Inactive';
        this.statusUpdatedTime = json.statusUpdatedTime;
        this.transmitterError = json.transmitterError;
        this.isBatteryFlat = json.isBatteryFlat;
        this.monitoringTransmitter = json.monitoringTransmitter;
        this.separateTransmitter = json.isSeparateTransmitter;
        this.responseTargetOverdue = json.responseTargetOverdue;
        this.setExistingTransmitterProperties(json);
    }

    private setExistingTransmitterProperties(json?: any): void {
        if (json.id) {
            if (json.transmitters && Array.isArray(json.transmitters) && json.transmitters.length > 0) {
                const transmitters: Transmitter[] = json.transmitters.map((obj: any) => new Transmitter(obj));
                if (transmitters.length === 1) {
                    this.nightTransmitter = transmitters[0];
                    if (this.nightTransmitter.monitoringTime === MonitoringTime.Both) {
                        this.nightTimeSetup = true;
                        this.dayTimeSetup = true;
                        this.dayTransmitter.copyFrom(this.nightTransmitter);
                    }
                    if (this.nightTransmitter.monitoringTime === MonitoringTime.Night) {
                        this.nightTimeSetup = true;
                    }
                }
                else if (transmitters.length === 2) {
                    const nightTransmitter = transmitters.find(x => x.monitoringTime === MonitoringTime.Night);
                    const dayTransmitter = transmitters.find(x => x.monitoringTime === MonitoringTime.Day);
                    if (nightTransmitter && dayTransmitter) {
                        this.nightTimeSetup = true;
                        this.dayTimeSetup = true;
                        this.nightTransmitter = nightTransmitter;
                        this.dayTransmitter = dayTransmitter;
                    }
                }
            }
        } else {
            this.nightTimeSetup = true;
        }
    }

}
