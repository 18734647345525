import React, { useState } from "react";
import BasicModal from "./BasicModal";

interface IProps {
    triggerComponent?: React.ReactNode;
    isOpen?: boolean;
    modalContent: React.ReactNode;
    modalTitle: string | JSX.Element;
    size: EModalSize;
    closeCallback?: () => void;
    disabled?: boolean;
    fixedFooter?: boolean;
}

export enum EModalSize {
    L = "1440px",
    M = "1074px",
    S = "640px",
}

const ModalWrapper = (props: IProps) => {
    const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(
        props.isOpen !== undefined ? props.isOpen : false
    );

    const modalContent = React.cloneElement(props.modalContent as any, {
        hideModal: () => {
            setIsModalDisplayed(false);
            if (props.closeCallback) {
                props.closeCallback();
            }
        },
    });
    const modal = (
        <BasicModal
            width={props.size}
            handleOnHide={() => {
                setIsModalDisplayed(false);
                if (props.closeCallback) {
                    props.closeCallback();
                }
            }}
            show={isModalDisplayed}
            title={props.modalTitle}
            fixedFooter={props.fixedFooter}
        >
            {modalContent}
        </BasicModal>
    );
    let content;
    if (props.triggerComponent) {
        content = (
            <div>
                <div onClick={() => !props.disabled && setIsModalDisplayed(true)}>
                    {props.triggerComponent}
                </div>
                {modal}
            </div>
        );
    } else if (props.isOpen !== undefined) {
        content = <>{modal}</>;
    }
    return <>{content}</>;
};

export default ModalWrapper;
