import { useSelector } from "react-redux";
import { Country } from "../../models/Country";
import { RootState } from "../../store";
import CountriesTable from "../themed/country/CountriesTable";
import { StyledViewContainer, StyledInformationBlocks, StyledInformationBlockWrapper } from "../common/View";
import CountryInformationBlock from "../themed/country/CountryInformationBlock";

const CountriesView = () => {
    const countries = useSelector<RootState, Country[]>(state => state.countrySlice.countries);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks
                alignLeft
            >
                <StyledInformationBlockWrapper  data-qa = "countries_card">
                    <CountryInformationBlock />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >

            <CountriesTable
                data={countries}
            />
        </StyledViewContainer>
    );
};

export default CountriesView;
