import { useEffect } from "react";
import UsersTable from "../users/tables/UsersTable";
import UserFilterInformationBlock from "../UserCountryFilterInformationBlock";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchUsers,
    IUserStateType,
    NursingHomeRole,
    ResourceTypes,
    setChildResourceId,
    setChildResourceType,
    UserRole
} from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from "../../../common/View";
import NursingHomeGroup from "../../../../models/NursingHomeGroup";
import { NursingHome } from "../../../../models/NursingHome";
import { localizer } from "../../../../utils/Localizer";
import UserAddInformationBlock from "../UserAddInformationBlock";
import UserAddButton from "../users/UserAddButton";

const CountryAdminUserView = () => {
    const dispatch = useDispatch();
    const {
        childResourceType,
        childResourceId,
        users
    } = useSelector<RootState, IUserStateType>((state) => state.userSlice);

    const nursingHomeGroups = useSelector<RootState, NursingHomeGroup[]>((state) => state.nursingHomeGroupSlice.nursingHomeGroups);
    const nursingHomes = useSelector<RootState, NursingHome[]>((state) => state.nursingHomeSlice.nursingHomes);

    const resourceTypes = {
        NursingHomeGroup: ResourceTypes.NursingHomeGroup,
        NursingHome: ResourceTypes.NursingHome,
    };

    const resourceTypesOptions = [
        {
            label: localizer("users.filters.nursingHomeGroupsOption"),
            value: resourceTypes.NursingHomeGroup,
        },
        {
            label: localizer("users.filters.nursingHomesOption"),
            value: resourceTypes.NursingHome,
        },
    ];

    const selectResourceType = (selectedChildResourceType: any) => {
        if (childResourceType !== selectedChildResourceType) {
            dispatch(setChildResourceType(selectedChildResourceType));
            dispatch(setChildResourceId(""));
        }
    };

    useEffect(() => {
        dispatch(
            setChildResourceType(
                childResourceType === ResourceTypes.NursingHome
                    ? ResourceTypes.NursingHome
                    : ResourceTypes.NursingHomeGroup
            )
        );
    }, [childResourceType]);

    useEffect(() => {
        if (childResourceType === ResourceTypes.NursingHome) {
            dispatch(setChildResourceId(childResourceId ? childResourceId : nursingHomes[0]?.id));
        }
    }, [nursingHomes, childResourceType]);

    useEffect(() => {
        if (childResourceType === ResourceTypes.NursingHomeGroup) {
            dispatch(setChildResourceId(childResourceId ? childResourceId : nursingHomeGroups[0]?.id));
        }
    }, [nursingHomeGroups, childResourceType]);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [childResourceId]);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks alignLeft>
                <StyledInformationBlockWrapper data-qa="select_resource_type_card">
                    <UserFilterInformationBlock
                        qaId="resource_type_dropdown"
                        title={localizer(
                            "users.informationBlocks.title.selectResourceType"
                        )}
                        name="resourceTypeFilter"
                        selectOption={selectResourceType}
                        selectedValue={childResourceType}
                        options={resourceTypesOptions}
                    />
                </StyledInformationBlockWrapper>
                {childResourceType === resourceTypes.NursingHomeGroup ? (
                    <StyledInformationBlockWrapper data-qa="select_nursing_home_group_card">
                        <UserFilterInformationBlock
                            qaId="nursing_home_group_dropdown"
                            title={localizer(
                                "users.informationBlocks.title.nursingHomesGroup"
                            )}
                            name="nursingHomesGroupsFilter"
                            selectOption={(childResourceId) =>
                                dispatch(setChildResourceId(childResourceId))
                            }
                            selectedValue={childResourceId}
                            options={nursingHomeGroups.map(
                                (nursingHomeGroup) => {
                                    return {
                                        label: nursingHomeGroup.name,
                                        value: nursingHomeGroup.id,
                                    };
                                }
                            )}
                        />
                    </StyledInformationBlockWrapper>
                ) : (
                    <StyledInformationBlockWrapper data-qa="select_nursing_home_card">
                        <UserFilterInformationBlock
                            qaId="nursing_home_dropdown"
                            title={localizer(
                                "users.informationBlocks.title.nursingHome"
                            )}
                            name="nursingHomesFilter"
                            selectOption={(childResourceId) =>
                                dispatch(setChildResourceId(childResourceId))
                            }
                            selectedValue={childResourceId}
                            options={nursingHomes.map((nursingHome) => {
                                return {
                                    label: nursingHome.name,
                                    value: nursingHome.id,
                                };
                            })}
                        />
                    </StyledInformationBlockWrapper>
                )}

                {childResourceId && (
                    <StyledInformationBlockWrapper data-qa="users_card">
                        <UserAddInformationBlock
                            addButton={
                                <UserAddButton
                                    titleBasedOnRole={
                                        childResourceType === ResourceTypes.NursingHomeGroup
                                            ? UserRole.NHGManager
                                            : UserRole.NHManager
                                    }
                                    assignedRole={NursingHomeRole.Manager}
                                />
                            }
                            amountOfUsers={users.length}
                        />
                    </StyledInformationBlockWrapper>
                )}
            </StyledInformationBlocks>

            <UsersTable data={users} />
        </StyledViewContainer>
    );
};

export default CountryAdminUserView;
