import moment, { Moment } from "moment";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import DropdownButtonNew from "../../components/common/controls/DropdownButtonNew";
import { IDropDownOption } from "../../components/common/IDropDownOption";
import ValidatedFormGroupWrapper from "../../components/wrappers/ValidatedFormGroupWrapper";
import { RestClient } from "../../data/RestClient";
import ErrorCodes from "../../models/ErrorCodes";
import { getLocale, localizer } from "../../utils/Localizer";
import AddEditButtonGroup from "../../views/Buttons/AddEditButtonGroup";

const StyledButtonContainerRow = styled(Row)`
    margin-top: 85px;
`;

const StyledLabel = styled.label`
    margin-bottom: 8px;
`;

const getYearOptions = (creationYear: number) => {
    const options: IDropDownOption[] = [];
    let startYear = creationYear;
    const endYear = moment().year();
    while (endYear - startYear >= 0) {
        options.push({
            label: startYear.toString(),
            value: startYear,
        });
        startYear++;
    }
    return options;
};

const getMonthOptions = (selectedYear: number, startDate: Moment) => {
    const options: IDropDownOption[] = [];
    if (selectedYear === startDate.year()) {
        for (let monthIndex = startDate.month(); monthIndex < 12; monthIndex++) {
            options.push({
                label: localizer("month." + (monthIndex + 1)),
                value: monthIndex + 1
            });
        }
    } else if (selectedYear === moment().year()) {
        for (let monthIndex = 0; monthIndex <= moment().month(); monthIndex++) {
            options.push({
                label: localizer("month." + (monthIndex + 1)),
                value: monthIndex + 1
            });
        }

    } else {
        for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
            options.push({
                label: localizer("month." + (monthIndex + 1)),
                value: monthIndex + 1
            });
        }
    }
    return options;
};

interface IExcelExportFormProps {
    startDate: Moment;
    wardIds: string[];
    nursingHomeId: string;
    hideModal?: () => void;
}

const ExcelExportForm = (props: IExcelExportFormProps) => {
    const [selectedYear, setSelectedYear] = useState<number>(moment().year());
    const [selectedMonth, setSelectedMonth] = useState<number | undefined>(moment().month() + 1);
    const [validationErrors, setErrors] = useState<string[]>([]);

    const getStatisticsFile = async (wardIds: string[], selectedYear: number, selectedMonth: number | undefined, nursingHomeId: string) => {
        try {
            if (selectedMonth) {
                const date = moment({ year: selectedYear, month: selectedMonth - 1 }).locale(getLocale());
                const startDate = date.clone().utc().toISOString();
                const endDate = date.clone().endOf('month').utc().toISOString();
                const todaysLocalizedMoment = moment().locale(getLocale());
                const localizedFormat = todaysLocalizedMoment.localeData().longDateFormat('L');
                const response: any = await RestClient.getStatisticsFile(
                    "SmartCare-Statistics-" + selectedYear + "-" + date.clone().locale(getLocale()).format("MMMM") + "-" + todaysLocalizedMoment.format(localizedFormat).replaceAll("/", "-"),
                    wardIds,
                    startDate,
                    endDate,
                    nursingHomeId
                );
                if (response.status === 204) {
                    setErrors([ErrorCodes.NoDataAvailable]);
                } else {
                    const filename = response.get('content-disposition')?.split(';')?.find((n: any) => n.includes('filename='))?.replace('filename=', '')?.trim();
                    const url = window.URL.createObjectURL(response.body);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    if (props.hideModal) {
                        props.hideModal();
                    }
                }
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }

    };

    const onDiscard = (event: any) => {
        event.preventDefault();
        if (props.hideModal) {
            props.hideModal();
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <StyledLabel>{localizer("statistics.excelExport.year")}</StyledLabel>
                    <DropdownButtonNew
                        id="yearDropdown"
                        handleChange={(_name: string, value: string) => {
                            const availableMonths = getMonthOptions(parseInt(value), props.startDate).map(monthOption => monthOption.value);
                            if (!availableMonths.includes(selectedMonth)) {
                                setSelectedMonth(undefined);
                            }
                            setSelectedYear(parseInt(value));
                            setErrors([]);
                        }}
                        options={getYearOptions(props.startDate.year())}
                        selectedValue={selectedYear}
                        name="yearSelected"
                        portaling={true}
                        maxHeight={350} />
                </Col>
                <Col>
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.NoDataAvailable]}
                        label={'statistics.excelExport.month'}
                    >
                        <DropdownButtonNew
                            id="monthDropdown"
                            handleChange={(_name: string, value: string) => {
                                setSelectedMonth(parseInt(value));
                                setErrors([]);
                            }}
                            options={getMonthOptions(selectedYear, props.startDate)}
                            selectedValue={selectedMonth}
                            portaling={true}
                            name="monthSelected"
                            maxHeight={350}
                        />
                    </ValidatedFormGroupWrapper>

                </Col>
                <Col md={"auto"} />
            </Row>
            <StyledButtonContainerRow>
                <AddEditButtonGroup
                    customConfirmButtonMessageId={"statistics.excelExport.download"}
                    isEdit={false}
                    onSave={() => getStatisticsFile(props.wardIds, selectedYear, selectedMonth, props.nursingHomeId)}
                    onDiscard={onDiscard}
                    disabledConfirm={validationErrors.length > 0 || !selectedMonth}
                />
            </StyledButtonContainerRow>
        </div>);
};

export default ExcelExportForm;