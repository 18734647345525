import { StyledLeft } from "../../../../common/table/formatters/PositionContainers";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import TextOverflowWrapper from "../../../../wrappers/TextOverflowWrapper";
import { localizer } from "../../../../../utils/Localizer";
import { CRC8 } from "../../../../../utils/CRC8";

const StyledContainer = styled(StyledLeft)<any>`
    align-items: ${(props) => props.alignItems ? props.alignItems : ''};
`;

const StyledImage = styled.img`
    width: 25px;
    margin-right: 5px;
`;

const StyledOff = styled.span`
    text-transform: capitalize;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { transmitterProductCode } = props.data.row.original;
    const { hideTooltipOnProd } = props.data.column;

    const transmitterProductCodeWithChecksum = transmitterProductCode ? transmitterProductCode + CRC8.computeChecksum(transmitterProductCode) : "";
    const tooltip = transmitterProductCodeWithChecksum ? `${localizer("resident.history.tableRow.transmitterMonitoringTime.tooltipPrefix")}: ${transmitterProductCodeWithChecksum}` : "";

    return (
        <StyledContainer alignItems={props.data.column.cellAlignItems}>
            <TextOverflowWrapper ignoreOverflowing tooltip={tooltip} hideTooltipOnProd={hideTooltipOnProd}>
                {value === 0 ? (
                    <StyledOff>
                        <FormattedMessage id={"monitoring.off"} />
                    </StyledOff>
                ) : (
                    <>
                        <StyledImage
                            alt={`${value} event`}
                            src={`images/${value?.trim()?.toLowerCase()}.png`}
                        />
                        <FormattedMessage id={"resident.history.tableRow.transmitterMonitoringTime." + value} />
                    </>
                )}
            </TextOverflowWrapper>
        </StyledContainer>
    );
};

const TransmitterMonitoringTimeFormatter = (data: any) => <CellFormatter data={data} />;
export default TransmitterMonitoringTimeFormatter;
