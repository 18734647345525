import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Table, {
    headerJustifyOptions
} from "../../components/common/table/Table";
import { Resident } from "../../models/Resident";
import { ResidentEventType } from "../../models/ResidentEventType";
import { TransmitterError } from "../../models/TransmitterError";
import { Ward } from "../../models/Ward";
import { RootState } from "../../store";
import { noBatteryIconList } from "../../utils/Constants";
import Actions from "./CellFormatters/Actions";
import BatteryIcon from "./CellFormatters/BatteryIcon";
import DateFormatter from "./CellFormatters/DateDifferenceInHours";
import LimitedActions from "./CellFormatters/LimitedActions";
import MonitoringEditor from "./CellFormatters/MonitoringEditor";
import ResidentIcon from "./CellFormatters/ResidentIcon";
import ResidentStatus from "./CellFormatters/ResidentStatus";
import WardName from "./CellFormatters/WardName";

const getStatusSortValue = (resident: Resident) => {
    if (
        resident.bedStatus === ResidentEventType.Inactive &&
        resident.transmitterError === TransmitterError.NoTransmitter
    ) {
        return 7;
    }

    switch (resident.bedStatus) {
        case ResidentEventType.Saturated:
            return 1;
        case ResidentEventType.Error:
            return 2;
        case ResidentEventType.Connecting:
            return 3;
        case ResidentEventType.SomeUrine:
            return 4;
        case ResidentEventType.OK:
            return 5;
        case ResidentEventType.NotBeingMonitored:
            return 6;
        case ResidentEventType.Inactive:
            return 9; // Inactive should be always at the bottom
        default:
            return 8;
    }
};

const BedStatusSort = (a: any, b: any) => {
    return getStatusSortValue(b.original) - getStatusSortValue(a.original);
};

interface IProps {
    selectedWards: Ward[];
    limitedAccess: boolean;
}

const ResidentsTable = (props: IProps) => {
    const residents = useSelector<RootState, Resident[]>(
        (state) => state.residentSlice.residents
    ).filter((resident) =>
        props.selectedWards.map((ward) => ward.id).includes(resident.wardId)
    );
    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.residents" />
                ),
                accessor: "name",
                Cell: ResidentIcon,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="residents.tableHeader.status" />,
                accessor: "bedStatus",
                Cell: ResidentStatus,
                sortType: BedStatusSort,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 7,
                filterDisabled: true,
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.statusTime" />
                ),
                accessor: "statusUpdatedTime",
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                filterDisabled: true,
                leftAlign: true,
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.monitoring" />
                ),
                accessor: "nightTimeSetup",
                disableSortBy: true,
                Cell: MonitoringEditor,
                flexGrow: 2,
                headerAlignment: headerJustifyOptions.left,
                minWidth: 190,
            },
            {
                Header: (
                    <FormattedMessage id="residents.informationBlocks.title.ward" />
                ),
                accessor: "wardId",
                disableSortBy: true,
                Cell: WardName,
                flexGrow: 7,
                headerAlignment: headerJustifyOptions.left,
                maxWidth: 270
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.batteryLevel" />
                ),
                accessor: "isBatteryFlat",
                Cell: (props: any) => {
                    const bedStatus = props.cell.row.original.bedStatus;
                    const showBatteryIcon = !noBatteryIconList.some(eventType => bedStatus === eventType);
                    if (showBatteryIcon) {
                        return <BatteryIcon isBatteryFlat={props.cell.row.values.isBatteryFlat} />;
                    }
                    return <></>;
                },
                disableSortBy: true,
                maxWidth: 70
            },
            {
                Header: <FormattedMessage id="general.tableHeader.actions" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: props.limitedAccess ? LimitedActions : Actions,
                disableSortBy: true,
                flexShrink: 3,
                filterDisabled: true,
                maxWidth: 160
            },
        ],
        [props.limitedAccess]
    );
    const data = React.useMemo(() => residents || [], [residents]);

    return (
        <Table
            qaId={"residents_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "bedStatus",
                        desc: true,
                    },
                ],
            }}
        />
    );
};

export default React.memo(ResidentsTable);
