import moment, { Moment } from "moment";
// update logic after https://github.com/react-component/picker/pull/289 is merged and released
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/nl-be';
import 'moment/locale/sk';
import 'moment/locale/sv';
import Picker from "rc-picker";
import "rc-picker/assets/index.css";
import generateConfig from "rc-picker/lib/generate/moment";
import locale from "rc-picker/lib/locale/en_US";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Colors from "../../../../utils/Colors";
import { getLocale, localizer } from "../../../../utils/Localizer";
import { StyledNextIcon, StyledPrevIcon } from "./CommonDatePickerStyle";
import { StyledCloseIcon, StyledDay, StyledDropdownArrow, StyledLabel, StyledMonth, StyledPicker, StyledPickerPanel } from "./CompareDatePickerStyle";

interface ICompareDatePickers {
    daysInterval: number;
    startDate: Moment;
    endDate: Moment;
    startRangeDate: Moment;
    setSelectedCompareStartDate: (value: Date | null) => void;
    setSelectedCompareEndDate: (value: Date | null) => void;
    disabledCompare: boolean;
    compareStartDate: string | null;
}

const CompareDatePicker = (props: ICompareDatePickers) => {

    const [selectedDate, setSelectedDate] = useState<Moment | null>(props.compareStartDate ? moment(props.compareStartDate) : null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const compareRef = useRef(null);
    const pickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: Event): void => {
            if (isOpen && (!event.composedPath().some(s => s === compareRef.current) && !event.composedPath().some(s => s === pickerRef.current))) {
                setIsOpen(!isOpen);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [compareRef, isOpen]);

    useEffect(() => {
        if (props.compareStartDate === null) {
            setSelectedDate(null);
        }
    }, [props.compareStartDate]);

    const start = props.startDate.format("YYYY-MM-DD");
    const end = props.endDate.format("YYYY-MM-DD");

    const isBaselinePeriod = (date: Moment) => {
        const currentDate = date.format("YYYY-MM-DD");
        return currentDate >= start && currentDate < end;
    };

    const isSelectedPeriod = (date: Moment) => {
        const currentDate = date.format("YYYY-MM-DD");
        if (selectedDate !== null) {
            const endSelectedPeriod = selectedDate.clone().add(props.daysInterval, "days").format("YYYY-MM-DD");
            return currentDate >= selectedDate.format("YYYY-MM-DD") && currentDate < endSelectedPeriod;
        }
        return false;
    };

    const isDisabledPeriod = (date: Moment) => {
        return date.format("YYYY-MM-DD") < props.startRangeDate.format("YYYY-MM-DD") || date.format("YYYY-MM-DD") > moment().subtract(1, "d").format("YYYY-MM-DD");
    };

    const onChange = (newValue: Moment | null) => {
        if (newValue !== null) {
            let startCompareDay = newValue;
            let endCompareDay = newValue.clone().add(props.daysInterval - 1, "days").endOf("day");
            const lastAvailableEndDay = moment().endOf("day");
            if (endCompareDay > lastAvailableEndDay) {
                startCompareDay = lastAvailableEndDay.clone().subtract(props.daysInterval - 1, "days");
                endCompareDay = lastAvailableEndDay;
            }
            setSelectedDate(startCompareDay);
            props.setSelectedCompareStartDate(startCompareDay.toDate());
            props.setSelectedCompareEndDate(endCompareDay.toDate());
        } else {
            setSelectedDate(null);
            props.setSelectedCompareStartDate(null);
            props.setSelectedCompareEndDate(null);

        }
        setIsOpen(!isOpen);
    };

    if (!isOpen) {
        document.getElementById('compare-picker')?.blur();
    }

    return (
        <StyledPicker selectedValue={selectedDate}>
            <StyledLabel><FormattedMessage id={"statistics.compareTo"} /></StyledLabel>
            <div ref={pickerRef}>
                <Picker<Moment>
                    id={"compare-picker"}
                    disabled={props.disabledCompare}
                    generateConfig={generateConfig}
                    disabledDate={(value: Moment) => isDisabledPeriod(value)}
                    value={selectedDate}
                    // update logic after https://github.com/react-component/picker/pull/289 is merged and released
                    locale={{ ...locale, locale: getLocale(), monthFormat: "MMMM" }}
                    nextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                    prevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                    superNextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                    superPrevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                    suffixIcon={selectedDate ?
                        undefined
                        :
                        (<div
                            onClick={(_props: any) => {
                                setIsOpen(!isOpen);
                            }}>
                            <StyledDropdownArrow />
                        </div>)}
                    clearIcon={<StyledCloseIcon />}
                    open={isOpen}
                    picker={"date"}
                    inputRender={(value: any) => {
                        // empty title so it won't trigger tooltip on hover
                        const customAttrs = { ...value, title: "" };
                        return (
                            <input
                                onClick={(_props: any) => {
                                    setIsOpen(!isOpen);
                                }}
                                {...customAttrs}
                            />);
                    }}
                    format={(value: Moment) => {
                        const endCompareDay = value.clone().add(props.daysInterval - 1, "days").format("YYYY-MM-DD");
                        const lastAvailableEndDay = moment().format("YYYY-MM-DD");
                        if (endCompareDay > lastAvailableEndDay) {
                            return moment().subtract(props.daysInterval - 1, "days").format("YYYY-MM-DD") + " - " + lastAvailableEndDay;
                        }
                        return value.format("YYYY-MM-DD") + " - " + endCompareDay;
                    }}
                    onSelect={onChange}
                    allowClear
                    panelRender={(originPanel: React.ReactNode) => {
                        return <StyledPickerPanel
                            ref={compareRef} >{originPanel}</StyledPickerPanel>;
                    }}
                    placeholder={localizer("statistics.compareTo.placeholder")}
                    onOpenChange={(isOpen) => {
                        // when panel is closing de-focus input
                        if (!isOpen) {
                            document.getElementById('compare-picker')?.blur();
                        }
                    }}
                    onChange={onChange}
                    monthCellRender={(current: Moment) => {
                        const style: any = {};
                        const baseline = current.isSameOrAfter(props.startDate, "month") && current.isSameOrBefore(props.endDate, "month");
                        const monthDisabledPeriod = current.isAfter(props.startRangeDate, "month") && current.isSameOrBefore(moment(), "month");
                        const selectedPeriod = selectedDate !== null && current.isSameOrAfter(selectedDate, "month") && current.isBefore(selectedDate.clone().add(props.daysInterval - 1, "days"), "month");

                        if (baseline && !selectedPeriod) {
                            style.color = Colors.black;
                        } else if (selectedPeriod && !baseline) {
                            if (current !== selectedDate) {
                                style.backgroundColor = Colors.teal.tail;
                            }
                            style.backgroundColor = Colors.teal.tail;
                        }
                        style.borderRadius = "5px";

                        // update logic after https://github.com/react-component/picker/pull/289 is merged and released
                        current.locale(getLocale());
                        return (
                            // empty title to disable built in tooltip on hover
                            <div className="rc-picker-cell-inner" style={style} title="">
                                <StyledMonth disabled={!monthDisabledPeriod} baseline={baseline} overlap={baseline && selectedPeriod}>{current.format("MMM")}</StyledMonth>
                            </div>);
                    }}
                    dateRender={(current: Moment) => {
                        const style: React.CSSProperties = {};
                        const baselinePeriod = isBaselinePeriod(current);
                        const selectedPeriod = isSelectedPeriod(current);

                        if (baselinePeriod && !selectedPeriod) {
                            style.width = "28px";
                            style.height = "28px";
                            style.color = Colors.black;
                            style.borderRadius = "5px";
                            style.border = "1px solid " + Colors.blue.brandingDark;
                        } else if (selectedPeriod && !baselinePeriod) {
                            if (current !== selectedDate) {
                                style.backgroundColor = Colors.teal.tail;
                            }
                            style.borderRadius = "5px";
                        } else if (baselinePeriod && selectedPeriod) {
                            style.width = "28px";
                            style.height = "28px";
                            style.border = "1px solid " + Colors.blue.brandingDark;
                            style.borderRadius = "5px";
                        }

                        return (
                            // empty title to disable built in tooltip on hover
                            <div className="rc-picker-cell-inner" style={style} title="">
                                <StyledDay disabled={isDisabledPeriod(current)} baseline={baselinePeriod} overlap={baselinePeriod && selectedPeriod}>{current.date()}</StyledDay>
                            </div>
                        );
                    }}
                />
            </div>
        </StyledPicker>
    );
};

export default CompareDatePicker;