import moment from "moment";
import { DayNight } from "../models/DayNight";
import ErrorCodes from "../models/ErrorCodes";
import { NursingHome } from "../models/NursingHome";

export const getSystemDate = (timeSpan: string, minutesToAdd: number = 0) => {
    const time = moment(timeSpan, 'HH:mm:ss');
    time.add(minutesToAdd, "m");
    return time.format('HH:mm');
};

export const getCaregiverResponseTargetMinutes = (nursingHome: NursingHome | null): number => {
    if (nursingHome) {
        const dayNight = isDayShift(nursingHome.morningShiftStartTime, nursingHome.nightShiftStartTime) ? DayNight.Day : DayNight.Night;

        if (dayNight === DayNight.Day) {
            return nursingHome.caregiverResponseTargetMinutesDay;
        }
        return nursingHome.caregiverResponseTargetMinutes;
    }
    return 0;
};

export const isDayShift = (morningShiftStartTime: string, nightShiftStartTime: string): boolean => {
    const now = moment();
    const morningStart = moment(morningShiftStartTime, 'hh:mm');
    const nightStart = moment(nightShiftStartTime, 'hh:mm');

    return now > morningStart && now < nightStart;
};

export const sortNursingHomes = (nursingHomes: NursingHome[]): NursingHome[] => {
    nursingHomes.sort(((a, b) => {
        if (a.name.toLowerCase() === b.name.toLowerCase()) { return 0; }
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }));
    return nursingHomes;
};

export const validateNursingHome = (nursingHome: NursingHome): ErrorCodes[] => {
    let errors: any[] = [];

    const nameErrors = !nursingHome.name ? ErrorCodes.EmptyNameError : [];
    const preferencesFieldErrors = validatePreferencesFields(nursingHome);
    const shiftStartTimesFieldErrors = validateShiftStartTimesFields(nursingHome);
    const shiftNotificationTimesFieldErrors = validateShiftNotificationTimesFields(nursingHome);
    const dayShiftNotificationFieldErrors = validateDayShiftNotificationFields(nursingHome);
    const nightShiftNotificationFieldErrors = validateNightShiftNotificationFields(nursingHome);

    errors = errors.concat(
        nameErrors,
        preferencesFieldErrors,
        shiftStartTimesFieldErrors,
        shiftNotificationTimesFieldErrors,
        dayShiftNotificationFieldErrors,
        nightShiftNotificationFieldErrors);

    if (dayShiftNotificationFieldErrors.length > 0 && nightShiftNotificationFieldErrors.length > 0) {
        errors.push(ErrorCodes.BothDayAndNightNotificationError);
    }

    else if (dayShiftNotificationFieldErrors.length > 0) {
        errors.push(ErrorCodes.DayNotificationError);
    }

    else if (nightShiftNotificationFieldErrors.length > 0) {
        errors.push(ErrorCodes.NightNotificationError);
    }

    return errors;
};

const validatePreferencesFields = (nursingHome: NursingHome) => {
    const preferencesErrors = [];

    if (!nursingHome.customerId || nursingHome.customerId === '') {
        preferencesErrors.push(ErrorCodes.EmptyCustomer);
    }

    return preferencesErrors;
};

const validateShiftStartTimesFields = (nursingHome: NursingHome) => {
    const shiftStartTimesErrors = [];

    if (!nursingHome.morningShiftStartTime || nursingHome.morningShiftStartTime.length < 1) {
        shiftStartTimesErrors.push(ErrorCodes.EmptyMorningShiftStartTime);
    }

    if (!nursingHome.nightShiftStartTime || nursingHome.nightShiftStartTime.length < 1) {
        shiftStartTimesErrors.push(ErrorCodes.EmptyNightShiftStartTime);
    }

    return shiftStartTimesErrors;
};

const validateShiftNotificationTimesFields = (nursingHome: NursingHome) => {
    const shiftNotificationTimesErrors = [];

    if (!nursingHome.shiftNotificationTimeDisabled) {
        if (!nursingHome.morningShiftNotificationTime || nursingHome.morningShiftNotificationTime.length < 1) {
            shiftNotificationTimesErrors.push(ErrorCodes.EmptyMorningShiftStartNotificationTime);
        }

        if (!nursingHome.nightShiftNotificationTime || nursingHome.nightShiftNotificationTime.length < 1) {
            shiftNotificationTimesErrors.push(ErrorCodes.EmptyNightShiftStartNotificationTime);
        }
    }

    return shiftNotificationTimesErrors;
};

const validateDayShiftNotificationFields = (nursingHome: NursingHome) => {
    const dayErrors = [];

    if (!nursingHome.caregiverResponseTargetMinutesDay
        || !NursingHome.caregiverResponseTargetTimes.includes(nursingHome.caregiverResponseTargetMinutesDay)) {
        dayErrors.push(ErrorCodes.EmptyCaregiverResponseTargetMinutesDay);
    }

    if (nursingHome.caregiverNotificationIntervalMinutesDay === undefined
        || nursingHome.caregiverNotificationIntervalMinutesDay < 0
        || !NursingHome.caregiverNotificationIntervals.includes(nursingHome.caregiverNotificationIntervalMinutesDay)) {
        dayErrors.push(ErrorCodes.EmptyCaregiverNotificationIntervalMinutesDay);
    }

    return dayErrors;
};
const validateNightShiftNotificationFields = (nursingHome: NursingHome) => {
    const nightErrors = [];

    if (!nursingHome.caregiverResponseTargetMinutes
        || !NursingHome.caregiverResponseTargetTimes.includes(nursingHome.caregiverResponseTargetMinutes)) {
        nightErrors.push(ErrorCodes.EmptyCaregiverResponseTargetMinutes);
    }

    if (nursingHome.caregiverNotificationIntervalMinutes === undefined
        || nursingHome.caregiverNotificationIntervalMinutes < 0
        || !NursingHome.caregiverNotificationIntervals.includes(nursingHome.caregiverNotificationIntervalMinutes)) {
        nightErrors.push(ErrorCodes.EmptyCaregiverNotificationIntervalMinutes);
    }

    return nightErrors;
};
