import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NursingHome } from "../../../models/NursingHome";
import { RootState } from "../../../store";
import { StyledAmount, StyledDescription, StyledTitle } from "../../common/informationBlock/styled";
import { StyledInlineFlexStart } from "../../styled";
import NursingHomeAddButton from "./NursingHomeAddButton";
const NursingHomeInformationBlock = () => {
    const nursingHomes = useSelector<RootState, NursingHome[]>(state => state.nursingHomeSlice.nursingHomes);
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={'nursingHomes.informationBlocks.title'} /></StyledTitle>
                <NursingHomeAddButton />
            </StyledInlineFlexStart>
            <StyledDescription>
                <FormattedMessage id={'nursingHomes.informationBlocks.totalNursingHomesProvisioned'} />
            </StyledDescription>
            <StyledAmount data-qa="nursing_homes_amount">
                {nursingHomes.length}
            </StyledAmount>
        </>
    );
};

export default NursingHomeInformationBlock;
