import React from 'react';
import TenaLogo from "../images/TenaLogo.svg";
import SmartcareLogo from "../images/SmartCareLogo.svg";
import styled from 'styled-components';

const StyledLogoTitleContainer = styled.div`
    align-self: flex-start;
    width: 600px;
    display: flex;
    margin-bottom: 14px;
`;

const StyledTenaLogo = styled.img`
    margin-left: -80px;
    margin-bottom: 6px;
    float: left;
`;

const StyledSmartcareLogo = styled.img`
    margin-left: -70px;
    height: 60px;
    margin-top: 20px;
    width: 168px;
`;

class LogoAndTitle extends React.Component {
    render() {
        return (
            <StyledLogoTitleContainer>
                <StyledTenaLogo src={TenaLogo} alt="TENA Logo" />
                <StyledSmartcareLogo src={SmartcareLogo} alt="Smartcare Logo"/>
            </StyledLogoTitleContainer>
        )
    }
}

export default LogoAndTitle;
