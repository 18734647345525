import React, { useState } from "react";
import {
    Dropdown, DropdownButton as ReactBootstrapDropdownButton,
    FormControl
} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import Colors from "../../../utils/Colors";
import { localizer } from "../../../utils/Localizer";

export const StyledDropdownItem = styled(DropdownItem)`
    &.dropdown-item {
        min-height: 40px;
        line-height: 22px;
        padding: 9px 16px;
        word-break: break-all;
        white-space: break-spaces;
    }
`;

const commonDropdownStyle = css`
    button:not(.react-datepicker__navigation) {
        width: 100%;
        background-color: ${Colors.white.clearWhite};
        display: flex;
        align-items: center;
        border: 1px solid ${Colors.grey.mediumLight};
        justify-content: space-between;
        height: 42px;
    }
    .dropdown-toggle[aria-expanded="false"] {
        &:focus {
            box-shadow: none !important;
            border: 1px solid ${Colors.grey.mediumLight};
            background-color: ${Colors.white.clearWhite} !important;
        }
    }
    .dropdown-toggle[aria-expanded="true"] {
        border: 1px solid ${Colors.grey.mediumLight};
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        &:focus {
            box-shadow: none !important;
            border: 1px solid ${Colors.grey.mediumLight};
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: ${Colors.white.hoverWhite};
        }
    }
    .dropdown-menu.show {
        transform: translate(0px, 41px) !important;
        border: 1px solid ${Colors.grey.mediumLight};
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
`;

export const StyledReactBootstrapDropdownButton = styled(ReactBootstrapDropdownButton)`
    ${commonDropdownStyle}
    .dropdown-menu.show {
        ${props => props.portaling ? `
            transform: translate(0px, -1px) !important;
            position: fixed !important;
            inset: inherit !important;
            max-width: 255px;
            background: white;
        ` : ""}
        max-height: ${props => props.maxHeight}px;
        overflow: ${props => props.noOverflow ? '' : 'auto'};
    }
`;

const StyledFormControl = styled(FormControl)`
    height: auto;
    border-radius: unset;
`;

const StyledTextDropdownSelectedOption = styled.div<any>`
    ${props => (props.hasSelectedOption ? `color: ${Colors.black};` : `color: ${Colors.grey.medium};`)}
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledTextDropdownOptions = styled.div`
    word-break: break-word;
    white-space: break-spaces;
    overflow: hidden;
`;

const StyledReactBootstrapDropdown = styled(Dropdown) <any>`
    ${commonDropdownStyle}
    .dropdown-menu.show {       
         ${props => props.portaling ? `
            transform: translate(0px, -1px) !important;
            position: fixed !important;
            inset: inherit !important;
            max-width: 558px;
            background: white;` : ""}
        z-index: 1200;
        max-height: ${props => props.maxHeight}px;
        overflow: auto;
    }
    input {
        border-top: 1px solid ${Colors.grey.mediumLight};
        border-bottom: 1px solid ${Colors.grey.mediumLight};
        border-left: 0px;
        border-right: 0px;
        &:focus {
            border: 1px solid ${Colors.blue.selectionBlue};
            box-shadow: 0px 0px 2px 2px ${Colors.blue.glowingBlue};
        }
    }
`;

const StyledListForFilterableDropdown = styled.ul`
    margin-bottom: 0px;
`;

export interface IDropDownOption {
    label: string;
    value: any;
}

interface IDropdownButtonNewProps {
    id: string;
    name: string;
    handleChange: (name: string, value: string) => void;
    selectedValue: any;
    options: IDropDownOption[];
    maxHeight?: number;
    placeholder?: string;
    isFilterable?: boolean;
    disabled?: boolean;
    noOptionsMessageId?: string;
    qaId?: string;
    portaling?: boolean;
}

const DropdownButtonNew = (props: IDropdownButtonNewProps) => {
    const getSelectedOptionLabel = (): JSX.Element => {
        const selectedOption = props.options.find(
            (option) => option.value === props.selectedValue
        );
        return (
            <StyledTextDropdownSelectedOption hasSelectedOption={selectedOption}>
                {selectedOption
                    ? selectedOption.label
                    : localizer(
                        props.placeholder ||
                        "dropDownFieldFormGroup.placeholder"
                    )}
            </StyledTextDropdownSelectedOption>
        );
    };

    const generateDropDownItems = (): JSX.Element[] => {
        if (props.options.length > 0) {
            return props.options.map((option) => {
                return (
                    <StyledDropdownItem
                        data-qa={((option.label ? option.label.replace(/\u00A0/g, ' ').toLowerCase() : undefined) + "-dropdown_item")}
                        key={option.value}
                        eventKey={option.value}
                        active={option.value === props.selectedValue}
                    >
                        {option.label}
                    </StyledDropdownItem>
                );
            });
        }
        return [
            <DropdownItem key={-1} eventKey={-1} disabled={true}>
                <StyledTextDropdownOptions>
                    <FormattedMessage
                        id={
                            props.noOptionsMessageId
                                ? props.noOptionsMessageId
                                : "dropDown.noOptions"
                        }
                    />
                </StyledTextDropdownOptions>
            </DropdownItem>,
        ];
    };

    const handleOptionSelect = (value: string) => {
        props.handleChange(props.name, value);
    };

    const [dropDownOptionsFilterValue, setDropDownOptionsFilterValue] =
        useState("");

    const FilterableDropdown = (
        <StyledReactBootstrapDropdown
            maxHeight={props.maxHeight ? props.maxHeight : 350}
            onSelect={(value: string) => handleOptionSelect(value)}
            portaling={props.portaling ? props.portaling.toString() : undefined}
        >
            <Dropdown.Toggle
                bsPrefix="custom-toggle"
                as={React.forwardRef((props: any, ref: any) => (
                    <Dropdown.Toggle
                        {...props}
                        ref={ref}
                        onSelect={(e: any) => {
                            e.preventDefault();
                            props.onSelect(e);
                        }}
                    >
                        {getSelectedOptionLabel()}
                    </Dropdown.Toggle>
                ))}
                id={props.id}
                variant={"light"}
                value={props.selectedValue}
            ></Dropdown.Toggle>
            <Dropdown.Menu
                as={React.forwardRef((props: any, ref: any) => {
                    return (
                        <div
                            ref={ref}
                            style={props.style}
                            className={props.className}
                            aria-labelledby={props["aria-labelledby"]}
                        >
                            <StyledFormControl
                                autoFocus
                                type="search"
                                placeholder={localizer(
                                    "dropDown.filterOptions"
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setDropDownOptionsFilterValue(
                                        e.target?.value
                                    );
                                }}
                                value={dropDownOptionsFilterValue}
                            />
                            <StyledListForFilterableDropdown className="list-unstyled">
                                {React.Children.toArray(props.children).filter(
                                    (child: any) =>
                                        !dropDownOptionsFilterValue ||
                                        child?.props?.children
                                            ?.toLowerCase()
                                            ?.includes(
                                                dropDownOptionsFilterValue?.toLowerCase()
                                            )
                                )}
                            </StyledListForFilterableDropdown>
                        </div>
                    );
                })}
            >
                {generateDropDownItems()}
            </Dropdown.Menu>
        </StyledReactBootstrapDropdown>
    );

    const SimpleDropdown = (
        <StyledReactBootstrapDropdownButton
            data-qa={props.qaId}
            maxHeight={props.maxHeight ? props.maxHeight : 350}
            id={props.id}
            variant={"light"}
            title={getSelectedOptionLabel()}
            disabled={props.disabled}
            value={props.selectedValue}
            onSelect={(value: string) => handleOptionSelect(value)}
            portaling={props.portaling ? props.portaling.toString() : undefined}
        >
            {generateDropDownItems()}
        </StyledReactBootstrapDropdownButton>
    );

    return props.isFilterable ? FilterableDropdown : SimpleDropdown;
};

export default DropdownButtonNew;
