import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NursingHomesTable from "../themed/nursingHomes/NursingHomesTable";
import { StyledViewContainer, StyledInformationBlocks, StyledInformationBlockWrapper } from "../common/View";
import NursingHomeInformationBlock from "../themed/nursingHomes/NursingHomeInformationBlock";
import { NursingHome } from "../../models/NursingHome";

const NursingHomesView = () => {
    const nursingHomeGroups = useSelector<RootState, NursingHome[]>(state => state.nursingHomeSlice.nursingHomes);
    
    return (
        <StyledViewContainer>
            <StyledInformationBlocks
                alignLeft
            >
                <StyledInformationBlockWrapper data-qa="nursing_homes_card">
                    <NursingHomeInformationBlock />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >

            <NursingHomesTable
                data={nursingHomeGroups}
            />
        </StyledViewContainer>
    );
}

export default NursingHomesView;
