import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Country } from "../../../models/Country";
import { RootState } from "../../../store";
import { StyledAmount, StyledDescription, StyledTitle } from "../../common/informationBlock/styled";
import { StyledInlineFlexStart } from "../../styled";
import CountryAddButton from "./CountryAddButton";
const CountryInformationBlock = () => {
    const countries = useSelector<RootState, Country[]>(state => state.countrySlice.countries);
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={'countries.informationBlocks.title'} /></StyledTitle>
                <CountryAddButton />
            </StyledInlineFlexStart>
            <StyledDescription>
                <FormattedMessage id={'countries.informationBlocks.totalCountriesProvisioned'} />
            </StyledDescription>
            <StyledAmount data-qa="countries_amount">
                {countries.length}
            </StyledAmount>
        </>
    );
};

export default CountryInformationBlock;
