import * as _ from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
import {RestClient} from '../data/RestClient';
import NursingHomeGroup from '../models/NursingHomeGroup';
import { setUserResourcesHierarchy } from './contextSlice';
import { toastSuccess, toastError } from "../utils/Toaster";
import { localizer } from "../utils/Localizer";
import ToastMessages from "../models/ToastMessages";
import User from '../models/User';

export interface nursingHomeGroupStateType {
    nursingHomeGroups: NursingHomeGroup[];
}

const initialState: nursingHomeGroupStateType = {
    nursingHomeGroups: [],
};

export const nursingHomeGroupSlice = createSlice({
    name: 'nursingHomeGroup',
    initialState: initialState,
    reducers: {
        setNursingHomeGroups: (state, action) => {
            state.nursingHomeGroups = action.payload;
        },

        clearNursingHomeGroupSlice: () => initialState,
    },
});

export const {
    setNursingHomeGroups, clearNursingHomeGroupSlice
} = nursingHomeGroupSlice.actions;

export const addUpdateNursingHomeGroupsInStore = (requestBodies: NursingHomeGroup[]) => (dispatch: any, getState: any) => {
    const requestBodyNursingHomeGroups = requestBodies.map(requestBody => new NursingHomeGroup(requestBody));

    const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;

    let updatedNursingHomeGroups: NursingHomeGroup[] = state.nursingHomeGroups;
    requestBodyNursingHomeGroups.forEach(requestBodyNursingHomeGroup => {
        if (state.nursingHomeGroups && state.nursingHomeGroups.find(nursingHomeGroup => requestBodyNursingHomeGroup.id?.toString() === nursingHomeGroup.id)) {
            updatedNursingHomeGroups = updatedNursingHomeGroups.map((nursingHomeGroup) => {
                return requestBodyNursingHomeGroup.id === nursingHomeGroup.id ? requestBodyNursingHomeGroup : nursingHomeGroup;
            });
        } else {
            updatedNursingHomeGroups.push(requestBodyNursingHomeGroup);
        }
    });

    dispatch(setNursingHomeGroups(updatedNursingHomeGroups));
};

export const updateNursingHomeGroup = (requestBody: NursingHomeGroup) => async (dispatch: any, getState: any) => {
    try {
        const newNursingHomeGroup = await RestClient.updateNursingHomeGroup(requestBody);
        const updatedNursingHomeGroup = new NursingHomeGroup(newNursingHomeGroup);

        const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;
        const updatedNursingHomeGroups = state.nursingHomeGroups!.map((nursingHomeGroup) => {
            return nursingHomeGroup.id === updatedNursingHomeGroup.id ? updatedNursingHomeGroup : nursingHomeGroup;
        }) || [];

        dispatch(setNursingHomeGroups(updatedNursingHomeGroups));
    } catch (exception) {
        console.error(exception);
    }
};

export const deleteNursingHomeGroup = (id: string) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteNursingHomeGroup(id);

            const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;
            const updatedNursingHomeGroups = state.nursingHomeGroups.filter(nursingHomeGroup => nursingHomeGroup.id !== id);
           
            toastSuccess(localizer(ToastMessages.DeleteNursingHomeGroupSuccessful));
            dispatch(setNursingHomeGroups(updatedNursingHomeGroups));
            resolve(true);
        } catch (error: any) {
            if (error.status === 409) {
                toastError(
                    localizer(
                        ToastMessages.DeleteNursingHomeGroupRestrictionError
                    )
                );
                resolve(false);
                return;
            }
            toastError(localizer(ToastMessages.DeleteNursingHomeGroupError));
            reject(error);
        } finally {
            const { userResources } = await RestClient.getUserResources(true);
            dispatch(setUserResourcesHierarchy(userResources));
        }
    });
};

export const createNursingHomeGroup = (requestBody: any) => (dispatch: any, getState: any): Promise<NursingHomeGroup> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await RestClient.createNursingHomeGroup(requestBody);
            const newNursingHomeGroup = new NursingHomeGroup(response);
            const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;

            const updatedNursingHomeGroups = [...state.nursingHomeGroups, newNursingHomeGroup];

            dispatch(setNursingHomeGroups(updatedNursingHomeGroups));
            resolve(newNursingHomeGroup);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchNursingHomeGroups = () => async (dispatch: any) => {
    try {
        const nursingHomeGroupsResponse = await RestClient.getAllNursingHomeGroups();
        const nursingHomeGroups = nursingHomeGroupsResponse.map((requestBody: any) => new NursingHomeGroup(requestBody));
        dispatch(setNursingHomeGroups(nursingHomeGroups));
    } catch (exception) {
        console.error(exception);
    }
};

export const removeUserFromNursingHomeGroup = (nursingHomeGroupId: string, userId: string, getState: any) => (dispatch: any) => {
    const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;
    if (state.nursingHomeGroups) {
        const nursingHomeGroups = [...state.nursingHomeGroups];
        const nursingHomeGroup: NursingHomeGroup | undefined = nursingHomeGroups.find(
            (nursingHomeGroup) => nursingHomeGroup.id === nursingHomeGroupId
        );
        if (nursingHomeGroup) {
            const updatedUserList = nursingHomeGroup.assignedUsersIds.filter(assignedUserId => assignedUserId !== parseInt(userId, 10));
            const updatedNursingHomeGroup = {
                ...nursingHomeGroup,
                assignedUsersIds: updatedUserList,
                amountOfAssignedUsers: updatedUserList?.length,
            };
            const updatedNursingHomes = [...nursingHomeGroups.filter(nhg => nhg.id !== nursingHomeGroupId), updatedNursingHomeGroup];
            dispatch(setNursingHomeGroups(updatedNursingHomes));
        }
    }
};

export const addUserToNursingHomeGroup = (nursingHomeGroupId: string, users: User[], getState: any) => (dispatch: any) => {
    const state = getState().nursingHomeGroupSlice as nursingHomeGroupStateType;
    if (state.nursingHomeGroups) {
        const nursingHomeGroups = [...state.nursingHomeGroups];
        const nursingHomeGroup: NursingHomeGroup | undefined = nursingHomeGroups.find(
            (nursingHomeGroup) => nursingHomeGroup.id === nursingHomeGroupId
        );
        if (nursingHomeGroup) {
            const updatedAssignedUsersId = _.union(nursingHomeGroup.assignedUsersIds, users.map(u => parseInt(u.id)));
            const updatedNursingHomeGroup = {
                ...nursingHomeGroup,
                assignedUsersIds: updatedAssignedUsersId,
                amountOfAssignedUsers: updatedAssignedUsersId.length
            };
            if(updatedNursingHomeGroup.amountOfAssignedUsers !== nursingHomeGroup.amountOfAssignedUsers && updatedNursingHomeGroup.assignedUsersIds !== nursingHomeGroup.assignedUsersIds) {
                const updatedNursingHomeGroupss = [...nursingHomeGroups.filter(nhg => nhg.id !== nursingHomeGroupId), updatedNursingHomeGroup];
                dispatch(setNursingHomeGroups(updatedNursingHomeGroupss));
            }
        }
    }
};

export default nursingHomeGroupSlice.reducer;

