import {
    faUserLock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import essityOnlyE from "../../../../../../images/essity-only-e.svg";
import Colors from '../../../../../../utils/Colors';
import { localizer } from "../../../../../../utils/Localizer";
import { StyledLeft } from '../../../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../../../wrappers/TextOverflowWrapper';


const StyledLogo = styled.img`
    width: 18px;
    margin-left: -17px;
    margin-right: 7px;
`;

export const StyledIconWithTooltipWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledFontAwesome = styled(FontAwesomeIcon) <any>`
    color: ${Colors.grey.mediumLight};
    font-size: 13px;
    margin-right: 10px;
    margin-left: 5px;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { activeDirectoryAccount, isLocked } = props.data.row.original;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledLeft data-qa={value.toLowerCase() +"-table_item"}>
            <StyledLogo src={activeDirectoryAccount && essityOnlyE} />
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                {value}
            </TextOverflowWrapper>
            <StyledIconWithTooltipWrapper data-tip={localizer("users.tableRow.isLockedTooltip")}>
                {isLocked ? <StyledFontAwesome icon={faUserLock} data-qa={value.toLowerCase() + "-locked_icon"}/> : ""}
            </StyledIconWithTooltipWrapper>
        </StyledLeft>
    );
};

const UserNameFormatter = (data: any) => <CellFormatter data={data} />;
export default UserNameFormatter;




