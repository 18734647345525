import React from "react";
import styled from "styled-components";
import { isProdEnv } from "../../utils/EnvCheck";
import { useIsOverflow } from "../../hooks/useIsOverflow";

export const StyledTextOverflowWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    tooltip: string | number;
    children: React.ReactChild;
    hideTooltipOnProd?: boolean;
    ignoreOverflowing?: boolean;
}

const TextOverflowWrapper = (props: IProps) => {
    const ref = React.useRef(null);
    const isOverflow = useIsOverflow(ref);

    const tooltipValue = isProdEnv() && props?.hideTooltipOnProd
        ? ""
        : (props.ignoreOverflowing || isOverflow) ? props?.tooltip : "";

    return (
        <StyledTextOverflowWrapper data-tip={tooltipValue}  ref={ref}>
            {props.children}
        </StyledTextOverflowWrapper>
    );
};

export default TextOverflowWrapper;
