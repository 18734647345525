import { Component } from 'react';
import './ErrorMessage.scss';
import { FormattedMessage } from 'react-intl';


interface Props {
    errorMessageStyles: string;
    errorMessage: string | undefined;
}

export default class ErrorMessage extends Component<Props> { 
    render() {
        if (!this.props.errorMessage) {
            return null;
        }
    
        return (
            <span className={this.props.errorMessageStyles}>
                {<FormattedMessage id={this.props.errorMessage}></FormattedMessage>}
            </span>
        );
    }
}