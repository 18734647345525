
import { Button, ButtonGroup } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import strip from "../../../../src/images/strip.svg";
import Colors from '../../../utils/Colors';

const StyledButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const StyledStripesContainer = styled.div`
    display: flex;
    flex: 25%;
    padding: 0 12px;
`;

const StyledImage = styled.img`
    margin: 0 2px;
`;

const StyledButtonGroup = styled<any>(ButtonGroup)`
    min-width: 120px;
    display: inline-flex;
`;

const StyledButton = styled<any>(Button)`
    margin: 0 !important;
    border-color: ${Colors.grey.disabledGrey} !important;
    background: #F1F5F8 !important;
    color: ${Colors.black} !important;
    box-shadow: none !important;

    ${(props) =>
        props.stroke
            ? css`
                  stroke: ${Colors.black} !important;
                  fill: none;
              `
            : css`
                  fill: ${Colors.black} !important;
              `}

    &:not(:last-child) {
        border-right-width: 0;
    }

    &.active {
        background: ${Colors.blue.brandingDark} !important;
        color: ${Colors.white.clearWhite} !important;
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
        z-index: 0 !important;

        ${(props) =>
        props.stroke
            ? css`
                      stroke: ${Colors.white.clearWhite} !important;
                  `
            : css`
                      fill: ${Colors.white.clearWhite} !important;
                  `}
    }
    &:hover&:not(.disabled) {
        background: #092477 !important;
        color: ${Colors.white.clearWhite} !important;

        ${(props) =>
        props.stroke
            ? css`
                      stroke: ${Colors.white.clearWhite} !important;
                  `
            : css`
                      fill: ${Colors.white.clearWhite} !important;
                  `}
    }


    &:disabled {
        fill:  ${(props) => (props.value === "off" ? "none" : Colors.grey.mediumLight)} !important;
        color: ${Colors.grey.mediumLight} !important;
        stroke:  ${(props) => (props.value === "off" ? Colors.grey.mediumLight : "")} !important;
    }

    white-space: nowrap;
    font-size: 14px;
    flex: 1 !important;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 40px;
    width: 40px;
    height: 40px;
`;

export const MonitoringSwitch = (props: any): React.ReactElement => {
    return (
        <StyledButtonsContainer>
            <div>
                <StyledButtonGroup>
                    {props.buttons.map((button: any) => (
                        <StyledButton
                            stroke={button.stroke}
                            disabled={props.disabled || button.disabled}
                            id={props.id + button.value}
                            key={button.value}
                            value={button.value}
                            active={props.buttonValue === button.value}
                            checked={props.buttonValue === button.value}
                            onClick={(e: any) => {
                                if (props.buttonValue !== button.value) {
                                    props.toggle(button.value, e.currentTarget.value);
                                }
                            }}
                        >
                            {button.name}
                        </StyledButton>
                    ))}
                </StyledButtonGroup>
            </div>
            <StyledStripesContainer>
                {new Array(props.strips).fill(true).map((_: any, i: number) =>
                    <StyledImage src={strip} key={i} />
                )}
            </StyledStripesContainer>
        </StyledButtonsContainer>
    );
};
