import moment, { Moment } from "moment";
import ChangeEvent from "../../models/ChangeEvent";
import { DateTimeUtils } from "../../models/DateTimeUtils";
import { KpiType } from "../../models/KpiType";
import { NursingHome } from "../../models/NursingHome";
import { Ward } from "../../models/Ward";
import { getCaregiverResponseTargetMinutes } from "../../reducers/nursingHomeHelpers";
import { Chart } from "./Chart";
import GraphHeader from "./GraphHeader";
import { IChartData } from "./Statistics";

interface IStatisticsGraphProps {
    averageChangeEventsLogs: ChangeEvent[];
    compareChangeEventsLogs: ChangeEvent[];
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    kpiValue: number | null | undefined;
    compareKpiValue: number | null | undefined;
    startDate: string;
    endDate: string;
    nursingHome: NursingHome;
    startCompareDate: Date | null;
    oldestWardMoment: Moment;
    selectedWardCreation?: Moment;
    setSelectedCompareStartDate: (value: Date | null) => void;
    setSelectedCompareEndDate: (value: Date | null) => void;
    customPeriod: boolean;
}


const StatisticsGraph = (props: IStatisticsGraphProps) => {

    const valueSelector = props.selectedKpi === KpiType.ResponseTime ? "responseTimeMinutes" : "nightTimeMinutesBetweenChanges";
    const start = new Date(props.startDate);
    const end = new Date(props.endDate);
    const interval = DateTimeUtils.getCompareInterval(start, end);
    
    const generateChartData = (): IChartData[] => {
        const dayAveragesMap: Map<string, {
            all: number[],
            ward: number,
            allCompare?: number[],
            wardCompare?: number,
            compareDate?: string
        }> = new Map();

        const dayProductChangesCompareMap: Map<string, {
            all: number[],
            ward: number
        }> = new Map();

        const chartData: IChartData[] = [];

        const fromDate = moment(props.startDate);
        const currentDate = moment(props.endDate).subtract(1, "d");
        const fromCompareDate = props.startCompareDate ? moment(props.startCompareDate) : undefined;
        const currentCompareDate = fromCompareDate ? moment(fromCompareDate.clone().add(interval - 1, 'days')) : undefined;
        while (currentDate >= fromDate) {
            const compareDate = currentCompareDate ? currentCompareDate.format('YYYY-MM-DD') : undefined;
            dayAveragesMap.set(currentDate.format('YYYY-MM-DD'), { all: [], ward: 0, compareDate });
            currentDate.subtract(1, 'days');
            if (compareDate && currentCompareDate) {
                dayProductChangesCompareMap.set(compareDate, { all: [], ward: 0 });
                currentCompareDate.subtract(1, 'days');
            }
        }

        for (const event of props.averageChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayAveragesMap.has(date)) {
                const value = event[valueSelector];
                if (value) {
                    dayAveragesMap.get(date)?.all.push(value);
                    if (props.selectedWard && event.wardId === props.selectedWard.id) {
                        // @ts-ignore
                        dayAveragesMap.get(date).ward = value || null;
                    }
                }
            }
        }

        for (const event of props.compareChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesCompareMap.has(date)) {
                const value = event[valueSelector];
                if (value) {
                    dayProductChangesCompareMap.get(date)?.all.push(value);
                    if (props.selectedWard && event.wardId === props.selectedWard.id) {
                        // @ts-ignore
                        dayProductChangesCompareMap.get(date).ward = value || null;
                    }
                }
            }
        }

        for (const [key, value] of dayAveragesMap.entries()) {
            const valuesAboveZero = value.all.filter(v => v > 0);
            const allAvg = valuesAboveZero.length > 0 ? valuesAboveZero.reduce((a, b) => a + b) / valuesAboveZero.length : 0;
            let allCompareChanges;
            if (value.compareDate && dayProductChangesCompareMap.has(value.compareDate)) {
                const entry = dayProductChangesCompareMap.get(value.compareDate);
                if (entry) {
                    const valuesAboveZeroCompare = entry.all.filter(v => v > 0);
                    allCompareChanges = valuesAboveZeroCompare.length > 0 && entry.all.length > 0 ? entry.all.reduce((sum, current) => sum + current) / valuesAboveZeroCompare.length : 0;
                }
            }
            chartData.push({
                date: key,
                selectedWard: allAvg,
                compareDate: value.compareDate,
                selectedCompareWard: allCompareChanges
            });
        }
        return chartData;
    };

    const getMaxYAxisValue = (): number => {
        const allPresentResponseTimes: ChangeEvent[] = props.averageChangeEventsLogs.filter((changeEvent: ChangeEvent) =>
            props.selectedKpi === KpiType.ResponseTime ?
                !!changeEvent.responseTimeMinutes :
                !!changeEvent.nightTimeMinutesBetweenChanges);
        const allResponseTimes: number[] = allPresentResponseTimes.map((changeEvent: ChangeEvent) => (
            props.selectedKpi === KpiType.ResponseTime ?
                changeEvent.responseTimeMinutes :
                changeEvent.nightTimeMinutesBetweenChanges) as number);
        const allComparePresentResponseTimes: ChangeEvent[] = props.compareChangeEventsLogs.filter((changeEvent: ChangeEvent) =>
            props.selectedKpi === KpiType.ResponseTime ?
                !!changeEvent.responseTimeMinutes :
                !!changeEvent.nightTimeMinutesBetweenChanges);
        const allCompareResponseTimes: number[] = allComparePresentResponseTimes.map((changeEvent: ChangeEvent) => (
            props.selectedKpi === KpiType.ResponseTime ?
                changeEvent.responseTimeMinutes :
                changeEvent.nightTimeMinutesBetweenChanges) as number);
        const maxAggregatedResponseTime = Math.max(...allResponseTimes, ...allCompareResponseTimes);
        return Math.max(Math.ceil(maxAggregatedResponseTime * 1.2), 5);
    };

    const caregiverResponseTargetMinutes = getCaregiverResponseTargetMinutes(props.nursingHome);

    return (
        <div>
            <GraphHeader
                disabledCompare={false}
                selectedKpi={props.selectedKpi}
                isDataPresent={props.averageChangeEventsLogs.length > 0}
                daysInterval={interval}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate)}
                startRangeDate={props.selectedWard ? props.selectedWardCreation! : props.oldestWardMoment}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate).format("DD MMM YYYY") : null}
            />
            <Chart
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate).subtract(1)}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate) : null}
                compareEndDate={moment(props.startCompareDate).add(interval - 1, 'days')}
                chartData={generateChartData()}
                maxYAxisValue={getMaxYAxisValue()}
                targetKpiValue={caregiverResponseTargetMinutes}
                selectedKpiValue={props.kpiValue !== undefined ? props.kpiValue : null}
                compareKpiValue={props.compareKpiValue !== undefined ? props.compareKpiValue : null}
                selectedKpi={props.selectedKpi}
                interval={interval}
            />
        </div>
    );
};

export default StatisticsGraph;
