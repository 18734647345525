import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import moment, { Moment } from "moment";
import Picker from "rc-picker";
import "rc-picker/assets/index.css";
import generateConfig from "rc-picker/lib/generate/moment";
import enUS from "rc-picker/lib/locale/en_US";
import { useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { TimeFilter } from "../../../../models/TimeFilter";
import Colors from "../../../../utils/Colors";
import { localizer } from "../../../../utils/Localizer";
import { IDropDownOption, StyledDropdownItem, StyledReactBootstrapDropdownButton } from "../DropdownButtonNew";
import "./TimeRangePickerDropdown.scss";

interface IProps {
    disabled?: boolean;
    value: string | Moment | undefined;
    name: string;
    minTime?: string;
    options: IDropDownOption[];
    onChange: ((name: string, value: any) => void);
    minuteStep?: 1 | 10 | 15 | 30;
    handleStartDateTimeChange: (value: Date | null) => void;
    handleEndDateTimeChange: (value: Date | null) => void;
    start: Date | null;
    end: Date | null;
}

const StyledTextDiv = styled.div`
    font-size: 16px;
`;

const StyledDropdownContainer = styled(Container) <any>`
    padding: 0.25rem 16px;
    max-width: 300px;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: ${Colors.blue.dark} !important;
            color: ${Colors.white.clearWhite} !important;

            svg {
                color: ${Colors.white.clearWhite};
            }
        `};
`;

const StyledDateTimeRow = styled(Row)`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px 6px 0px;
`;

const StyledSmallerTextDiv = styled.div`
    font-size: 14px;
    font-weight: normal;
    width: 40px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    color: ${Colors.blue.brandingDark};
    font-size: 22px;
    width: inherit !important;
`;

const StyledPanelHeader = styled.div`
    text-align: center;
    background-color: ${Colors.grey.almostWhite};
    color: ${Colors.black};
    border-top-left-radius: 0px;
    border-top-right-radius: 4px;
    border: 1px solid ${Colors.grey.mediumLight};
    border-bottom: 0px;
    height: 42px;
    size: 16px;
    line-height: 42px;
`;

const TimeRangePickerDropdown = (props: IProps) => {

    const [selectedStartValue, setSelectedStartValue] = useState(moment("12:00", "HH:mm"));
    const [selectedEndValue, setSelectedEndValue] = useState(moment("13:00", "HH:mm"));
    const [startOpen, setStartOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    const startPickerRef = useRef<Picker<Moment>>(null);
    const endPickerRef = useRef<Picker<Moment>>(null);

    const alignConfig: any = {
        points: ['tl', 'tr'],        // align top left point of sourceNode with top right point of targetNode
        offset: [52, 80],            // the offset sourceNode by 52px in x and 80px in y,
        targetOffset: ['0%', '250%'], // the offset targetNode by 0% of targetNode width in x and 250% of targetNode height in y,
        overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
    };

    const handleStartChange = (value: Moment) => {
        setSelectedStartValue(value);
        props.handleStartDateTimeChange(value.toDate());
        if (!props.end) {
            props.handleEndDateTimeChange(
                selectedEndValue.toDate()
            );
        }
    };

    const handleEndChange = (value: Moment) => {
        setSelectedEndValue(value);
        props.handleEndDateTimeChange(value.toDate());
        if (!props.start) {
            props.handleStartDateTimeChange(
                selectedStartValue.toDate()
            );
        }
    };

    const handleOptionSelect = (value: any) => {
        if (value === "customRange") {
            if (!props.start) {
                props.handleStartDateTimeChange(
                    selectedStartValue.toDate()
                );
            }

            if (!props.end) {
                props.handleEndDateTimeChange(
                    selectedEndValue.toDate()
                );
            }
        }
        props.onChange(props.name, value);
    };

    const getSelectedOptionLabel = () => {
        if (props.value === "customRange") {
            return `${format(moment(props.start).toDate(), "HH:mm")} - ${format(moment(props.end).toDate(), "HH:mm")}`;
        }

        const selectedOption = props.options.find(
            (option: IDropDownOption) => option.value === props.value
        );

        return selectedOption
            ? selectedOption.label
            : localizer("dropDownFieldFormGroup.placeholder");
    };
    return (
        <StyledReactBootstrapDropdownButton
            id={"this.props.id"}
            variant={"light"}
            title={getSelectedOptionLabel()}
            onSelect={handleOptionSelect}
            noOverflow={true}
        >
            {props.options.map((option) => (
                <StyledDropdownItem
                    key={option.value}
                    active={option.value === props.value}
                    eventKey={option.value}
                    hidden={option.label === localizer("constants.customRange")}
                >
                    {option.label}
                </StyledDropdownItem>
            ))}
            {props.options.some(option => option.value === TimeFilter.CustomRange) && (
                <>
                    <StyledDropdownContainer
                        onClick={() => handleOptionSelect("customRange")}
                        key={"timeRangeSeparator"}
                        active={"customRange" === props.value}>
                        <StyledTextDiv >
                            <FormattedMessage id="constants.changeEventsFilter.date.custom" />:
                        </StyledTextDiv>
                        <StyledDateTimeRow className={"g-0"}>
                            <StyledSmallerTextDiv>
                                <FormattedMessage id="constants.changeEventsFilter.date.from" />
                            </StyledSmallerTextDiv>
                            <Picker<Moment>
                                ref={startPickerRef}
                                dropdownAlign={alignConfig}
                                className="rc-picker-time-range"
                                dropdownClassName="rc-picker-panel-container-time-range"
                                format={"HH:mm"}
                                showNow={false}
                                showSecond={false}
                                generateConfig={generateConfig}
                                value={selectedStartValue}
                                open={startOpen}
                                panelRender={(node) => {
                                    return (
                                        <div onClick={(event) => event.stopPropagation()}>
                                            <StyledPanelHeader><FormattedMessage id="constants.changeEventsFilter.date.from" /></StyledPanelHeader>
                                            {node}
                                        </div>
                                    );
                                }}
                                onOpenChange={(openState) => setStartOpen(openState)}
                                onChange={(value: any) => handleStartChange(value)}
                                onSelect={handleStartChange}
                                picker="time"
                                locale={enUS}
                                minuteStep={props.minuteStep || 10}
                                mode="time"
                                popupStyle={{
                                    width: "193px",
                                    borderRadius: "4px",
                                }}
                                getPopupContainer={(node: HTMLElement) => (node)}
                                inputReadOnly={true}
                            />
                            <StyledFontAwesome
                                icon={faClock}
                                onClick={() => {
                                    if (!startOpen && startPickerRef && startPickerRef.current) {
                                        startPickerRef.current.focus();
                                    }
                                    setStartOpen(true);}}
                            />
                        </StyledDateTimeRow>
                        <StyledDateTimeRow className={"g-0"}>
                            <StyledSmallerTextDiv>
                                <FormattedMessage id="constants.changeEventsFilter.date.to" />
                            </StyledSmallerTextDiv>
                            <Picker<Moment>
                                ref={endPickerRef}
                                dropdownAlign={alignConfig}
                                className="rc-picker-time-range"
                                dropdownClassName="rc-picker-panel-container-time-range"
                                format={"HH:mm"}
                                showNow={false}
                                showSecond={false}
                                open={endOpen}
                                generateConfig={generateConfig}
                                value={selectedEndValue}
                                panelRender={(node) => {
                                    return (
                                        <div onClick={(event) => event.stopPropagation()}>
                                            <StyledPanelHeader><FormattedMessage id="constants.changeEventsFilter.date.to" /></StyledPanelHeader>
                                            {node}
                                        </div>
                                    );
                                }}
                                onOpenChange={(openState) => setEndOpen(openState)}
                                onChange={(value: any) => handleEndChange(value)}
                                onSelect={handleEndChange}
                                picker="time"
                                locale={enUS}
                                minuteStep={props.minuteStep || 10}
                                mode="time"
                                popupStyle={{
                                    width: "193px",
                                    borderRadius: "4px",
                                }}
                                getPopupContainer={(node: HTMLElement) => (node)}
                                inputReadOnly={true}
                            />
                            <StyledFontAwesome
                                icon={faClock}
                                onClick={() => {
                                    if (!endOpen && endPickerRef && endPickerRef.current) {
                                        endPickerRef.current.focus();
                                    }
                                    setEndOpen(true);
                                }}
                            />
                        </StyledDateTimeRow>
                    </StyledDropdownContainer>
                </>

            )}

        </StyledReactBootstrapDropdownButton>

    );
};

export default TimeRangePickerDropdown;
