import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';


const StyledWrapper = styled(StyledLeft)<any>`
    flex-direction: column;
    align-items: ${(props) => props.alignItems ? props.alignItems : 'start'};
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { isProductChange } = props.data.row.original;

    return (
        <StyledWrapper alignItems={props.data.column.cellAlignItems}>
            <FormattedMessage id={'resident.history.tableRow.statusChangeTrigger.' + value} />
            {isProductChange && <small>(<FormattedMessage id={'resident.history.tableRow.statusChangeTrigger.productChange'} />)</small>}
        </StyledWrapper>
    );
};

const StatusChangeTrigger = (data: any) => <CellFormatter data={data} />;
export default StatusChangeTrigger;
