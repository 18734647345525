import ActionsContainer from '../../../common/table/formatters/ActionsContainer';
import UserDeleteIcon from '../common/CommonUserDeleteIcon';
import CaregiversResetPasswordIcon from './CaregiversResetPasswordIcon';
import CaregiversEditIcon from './CaregiversEditIcon';

const CaregiversActions = (props: any) => {
    return (
        <ActionsContainer>
            <CaregiversEditIcon 
                user={props.row.original}
            />
            <CaregiversResetPasswordIcon
                user={props.row.original}
            />
            <UserDeleteIcon
                user={props.row.original}
            />
        </ActionsContainer>
    );
};

export default CaregiversActions;
