import { ButtonGroup, ToggleButton } from "react-bootstrap";
import styled, { css } from "styled-components";
import Colors from "../../../utils/Colors";
import ErrorMessage from "../../../views/ErrorMessages/ErrorMessage";

export const StyledButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const inactiveCommonStyles = `
    border-color: ${Colors.grey.disabledGrey} !important;
    background: #F1F5F8 !important;
    color: ${Colors.black} !important;
    box-shadow: none !important;
`;

const inactivePseudoSelectorsCommonStyles = `
    ${inactiveCommonStyles}
    box-shadow: inherit;
    z-index: 0 !important;
`;

export const StyledToggleButton = styled<any>(ToggleButton)`
    margin: 0 !important;
    ${inactiveCommonStyles}

    ${(props) =>
        props.stroke
            ? css`
                  stroke: ${Colors.black} !important;
                  fill: none;
              `
            : css`
                  fill: ${Colors.black} !important;
              `}

    &:not(:last-child) {
        border-right-width: 0;
    }

    &.active {
        background: ${Colors.blue.brandingDark} !important;
        color: ${Colors.white.clearWhite} !important;
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
        z-index: 0 !important;

        ${(props) =>
            props.stroke
                ? css`
                      stroke: ${Colors.white.clearWhite} !important;
                  `
                : css`
                      fill: ${Colors.white.clearWhite} !important;
                  `}

        &.disabled {
            background-color: ${Colors.grey.disabledGrey} !important;
            color: ${Colors.white.clearWhite} !important;
        }
    }
    &:hover&:not(.disabled) {
        background: #092477 !important;
        color: ${Colors.white.clearWhite} !important;

        ${(props) =>
            props.stroke
                ? css`
                      stroke: ${Colors.white.clearWhite} !important;
                  `
                : css`
                      fill: ${Colors.white.clearWhite} !important;
                  `}
    }
    &:focus {
        ${inactivePseudoSelectorsCommonStyles}
    }
    &:active&:focus {
        ${inactivePseudoSelectorsCommonStyles}
    }
    &.disabled {
        background: #edeff0 !important;
        border-color: ${Colors.grey.mediumLight} !important;
        color: ${Colors.grey.medium} !important;
        ${(props) =>
            props.stroke
                ? css`
                      stroke: ${Colors.grey.mediumLight} !important;
                  `
                : css`
                      fill: ${Colors.grey.mediumLight} !important;
                  `}
        opacity: 1 !important;
    }

    white-space: nowrap;
    font-size: 14px;
    flex: 1 !important;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: ${(props) => (props.fluid ? "auto" : "40px")};
    width: ${(props) => (props.fluid ? "auto" : "40px")};
    height: ${(props) => (props.fluid ? "auto" : "40px")};
`;

const StyledButtonGroup = styled<any>(ButtonGroup)`
    min-width: 120px;

    display: ${(props) => (props.fluid ? `flex` : `inline-flex`)};
`;

export function Switcher(props: any): React.ReactElement {
    return (
        <div>
            <StyledButtonGroup fluid={props.fluid}>
                {props.radios.map((radio: any) => (
                    <StyledToggleButton
                        data-qa={props.id + radio.value + "_button"}
                        stroke={radio.stroke}
                        fluid={props.fluid}
                        disabled={props.disabled || radio.disabled}
                        id={props.id + radio.value}
                        key={radio.value}
                        type="radio"
                        name="radio"
                        value={radio.value}
                        active={props.radioValue === radio.value}
                        checked={props.radioValue === radio.value}
                        onChange={(e: any) =>
                            props.setMonitoringStatus(e.currentTarget.value)
                        }
                    >
                        {radio.name}
                    </StyledToggleButton>
                ))}
            </StyledButtonGroup>

            <ErrorMessage
                errorMessageStyles={"error-message"}
                errorMessage={props.errorMessage}
            />
        </div>
    );
}
