import Toggle from "react-toggle";
import styled from "styled-components";

const CustomToggle = styled(Toggle)`
    &:hover:not(.react-toggle--disabled) {
        .react-toggle-track {
            background-color:${(props) => props.checked ? '#1D388B' : '#CCCCCC'};
        }
    }
    .react-toggle-track {
        background-color:${(props) => props.checked ? '#1D388B' : '#CCCCCC'};
    }
`;

export default CustomToggle;