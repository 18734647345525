import React, { SVGProps } from "react";

interface ICheckIconProps {
    color: string;
}

const CheckIcon: React.FunctionComponent<SVGProps<any> & ICheckIconProps> = (
    props
) => {
    const { color } = props;
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.0002 1.33333C7.89996 1.33333 1.3335 7.8998 1.3335 16C1.3335 24.1002 7.89996 30.6667 16.0002 30.6667C24.1004 30.6667 30.6668 24.1002 30.6668 16C30.6668 7.8998 24.1004 1.33333 16.0002 1.33333Z"
                fill={color}
            />
            <path
                d="M24.3639 11.6564L23.0196 10.3062C22.7411 10.0265 22.2879 10.0247 22.0072 10.3021L13.5749 18.6359L10.0081 15.0533C9.72965 14.7737 9.27639 14.7719 8.99572 15.0492L7.64042 16.3887C7.35975 16.6661 7.3579 17.1177 7.63637 17.3974L13.0519 22.8367C13.3303 23.1163 13.7835 23.1182 14.0642 22.8407L24.3599 12.6651C24.6405 12.3877 24.6423 11.9361 24.3639 11.6564Z"
                fill="white"
            />
        </svg>
    );
};

export default CheckIcon;
