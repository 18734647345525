import { createSlice } from '@reduxjs/toolkit';
import Market from '../models/Market';
import ProductFamily from '../models/ProductFamily';
import Prod from '../models/Prod';
import { RestClient } from '../data/RestClient';

export interface IMarketStateType {
    markets: Market[];
    productFamilies: ProductFamily[];
    products: Prod[];
}

const initialState: IMarketStateType = {
    markets: [],
    productFamilies: [],
    products: [],
};

export const marketSlice = createSlice({
    name: 'market',
    initialState,
    reducers: {
        setMarkets: (state, action) => {
            state.markets = Market.sort(action.payload);
        },
        setProductFamilies: (state, action) => {
            state.productFamilies = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },

        clearMarketSlice: () => initialState,
    },
});

export const {
    setMarkets, setProductFamilies, setProducts, clearMarketSlice
} = marketSlice.actions;

export const fetchMarkets = () => async (dispatch: any) => {
    try {
        const marketsResponse = await RestClient.getMarkets();
        const marketsAggregator: Market[] = marketsResponse.map((marketResponse: any) => new Market(marketResponse));
        dispatch(setMarkets(marketsAggregator));
    } catch (exception) {
        // tslint:disable-next-line:no-console
        console.error(exception);
    }
};

export const fetchProducts = () => async (dispatch: any) => {
    try {
        const productFamiliesAggregator: ProductFamily[] = [];
        let productsAggregator: Prod[] = [];

        const getProductsResponse = await RestClient.getProducts();
        const productFamiliesResponse = getProductsResponse?.productFamilies;

        productFamiliesResponse.forEach((productFamilyProductsResponse: any) => {
            const { products: productsResponse, ...productFamilyResponse } = productFamilyProductsResponse;
            const productFamily = new ProductFamily(productFamilyResponse, getProductsResponse.code);
            productFamiliesAggregator.push(productFamily);
            productsAggregator = [...productsAggregator, ...productsResponse.map((productResponse: any) => new Prod(productResponse, productFamily.name))];
        });

        dispatch(setProductFamilies(productFamiliesAggregator));
        dispatch(setProducts(productsAggregator));
    } catch (exception) {
        // tslint:disable-next-line:no-console
        console.error(exception);
    }
};

export default marketSlice.reducer;

