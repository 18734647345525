import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Routes,
    Navigate,
    Route,
} from "react-router-dom";
import styled from "styled-components";
import { Roles } from "../../models/Role";
import { IContextStateType } from "../../reducers/contextSlice";
import LoginView from "../../routes/LoginView";
import NursingHomeGroupsView from "../views/NursingHomeGroupsView";
import { RootState } from "../../store";
import { routePaths } from "../../utils/PathHelpers";
import Navbar, { LinkData } from "../../views/Navbar";
import CountriesView from "../views/CountriesView";
import UserView from "../views/UserView";
import NursingHomesView from "../views/NursingHomesView";
import { NurseRepository } from "../../data/NurseRepository";
import ResidentsView from "../../routes/Residents/ResidentsView";
import Statistics from "../../routes/Statistics/Statistics";
import SetupView from "../../routes/Setup/SetupView";
import AuditView from "../views/AuditView";
import HomeView from "../views/HomeView";
import NavbarNurse from "../../views/NavbarNurse";
import RoutesWrapper from "./RoutesWrapper";

const StyledSelectUserResourceContextPrompt = styled.div`
    height: calc(100vh - 200px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
`;

const RoutesGenerator = () => {
    const context = useSelector<RootState, IContextStateType>(state => state.contextSlice);
    const [route, setRoute] = useState<React.ReactElement | null>(null);

    const prepareComponent = () => {
        let component = (
            <StyledSelectUserResourceContextPrompt>
                <FormattedMessage id="home.selectUserResourceContext" />
                <br />
                <br />
                <FormattedMessage id="home.selectUserResourceContext2" />
            </StyledSelectUserResourceContextPrompt>
        );

        if (context.isAuthenticated) {
            switch (context.role) {
                case Roles.GlobalAdmin:
                    const linksData: LinkData[] = [
                        {
                            id: "mainNav.users",
                            qaId: "users",
                            to: routePaths.users,
                        },
                        {
                            id: "mainNav.countries",
                            qaId: "countries",
                            to: routePaths.countries,
                        },
                        {
                            id: "mainNav.audit",
                            qaId: "audit",
                            to: routePaths.audit,
                        }
                    ];
                    component = (
                        <RoutesWrapper>
                            <Navbar linksData={linksData} />
                            <Routes>
                                <Route path={routePaths.users} element={<UserView />} />
                                <Route path={routePaths.countries} element={<CountriesView />} />
                                <Route path={routePaths.audit} element={<AuditView />} />
                                <Route path="*" element={<Navigate to={routePaths.users} />} />
                            </Routes>
                        </RoutesWrapper>
                    );
                    break;
                case Roles.CountryAdmin: {
                    const linksData: LinkData[] = [
                        {
                            id: "mainNav.users",
                            qaId: "users",
                            to: routePaths.users,
                        },
                        {
                            id: "mainNav.nursingHomeGroups",
                            qaId: "nursingHomeGroups",
                            to: routePaths.nursingHomeGroups,
                        },
                        {
                            id: "mainNav.nursingHomes",
                            qaId: "nursingHomes",
                            to: routePaths.nursingHomes,
                        }
                    ];
                    component = (
                        <RoutesWrapper>
                            <Navbar linksData={linksData} />
                            <Routes>
                                <Route path={routePaths.users} element={<UserView />} />
                                <Route path={routePaths.nursingHomeGroups} element={<NursingHomeGroupsView />} />
                                <Route path={routePaths.nursingHomes} element={<NursingHomesView />} />
                                <Route path="*" element={<Navigate to={routePaths.users} />} />
                            </Routes>
                        </RoutesWrapper>
                    );
                    break;
                }
                case Roles.NHGManager: {
                    const linksData: LinkData[] = [
                        {
                            id: "mainNav.users",
                            qaId: "users",
                            to: routePaths.users,
                        },
                        {
                            id: "mainNav.nursingHomes",
                            qaId: "nursingHomes",
                            to: routePaths.nursingHomes,
                        },
                        {
                            id: "mainNav.audit",
                            qaId: "audit",
                            to: routePaths.audit,
                        }
                    ];
                    component = (
                        <RoutesWrapper>
                            <Navbar linksData={linksData} />
                            <Routes>
                                <Route path={routePaths.users} element={<UserView />} />
                                <Route path={routePaths.nursingHomes} element={<NursingHomesView />} />
                                <Route path={routePaths.audit} element={<AuditView />} />
                                <Route path="*" element={<Navigate to={routePaths.users} />} />
                            </Routes>
                        </RoutesWrapper>
                    );
                    break;
                }
                case Roles.Support:
                case Roles.NHManager: {
                    const linksData: LinkData[] = [
                        {
                            id: "mainNav.home",
                            qaId: "home",
                            to: routePaths.home,
                        },
                        {
                            id: "mainNav.residents",
                            qaId: "residents",
                            to: routePaths.residents,
                        },
                        {
                            id: "mainNav.statistics",
                            qaId: "statistics",
                            to: routePaths.statistics,
                        },
                        {
                            id: "mainNav.setup",
                            qaId: "setup",
                            to: routePaths.setup,
                        },
                        {
                            id: "mainNav.users",
                            qaId: "users",
                            to: routePaths.users,
                        },
                        {
                            id: "mainNav.audit",
                            qaId: "audit",
                            to: routePaths.audit,
                        }
                    ];
                    component = (
                        <RoutesWrapper>
                            <NavbarNurse linksData={linksData} />
                            <NurseRepository>
                                <Routes>
                                    <Route path={routePaths.home} element={<HomeView />} />
                                    <Route path={routePaths.residents} element={<ResidentsView />} />
                                    <Route path={routePaths.statistics} element={<Statistics />} />
                                    <Route path={routePaths.setup} element={<SetupView />} />
                                    <Route path={routePaths.users} element={<UserView />} />
                                    <Route path={routePaths.audit} element={<AuditView />} />
                                    <Route path="*" element={<Navigate to={routePaths.home} />} />
                                </Routes>
                            </NurseRepository>
                        </RoutesWrapper>
                    );
                    break;
                }
                case Roles.Nurse: {
                    const linksData: LinkData[] = [
                        {
                            id: "mainNav.home",
                            qaId: "home",
                            to: routePaths.home,
                        },
                        {
                            id: "mainNav.residents",
                            qaId: "residents",
                            to: routePaths.residents,
                        },
                        {
                            id: "mainNav.statistics",
                            qaId: "statistics",
                            to: routePaths.statistics,
                        },
                        {
                            id: "mainNav.setup",
                            qaId: "setup",
                            to: routePaths.setup,
                        },
                        {
                            id: "mainNav.users",
                            qaId: "users",
                            to: routePaths.users,
                        }
                    ];
                    component = (
                        <RoutesWrapper>
                            <NavbarNurse linksData={linksData} />
                            <NurseRepository>
                                <Routes>
                                    <Route path={routePaths.home} element={<HomeView />} />
                                    <Route path={routePaths.residents} element={<ResidentsView />} />
                                    <Route path={routePaths.statistics} element={<Statistics />} />
                                    <Route path={routePaths.setup} element={<SetupView readonly={true}/>} />
                                    <Route path={routePaths.users} element={<UserView />} />
                                    <Route path="*" element={<Navigate to={routePaths.home} />} />
                                </Routes>
                            </NurseRepository>
                        </RoutesWrapper>
                    );
                    break;
                }
            }
        } else {
            component = (
                <>
                    <Routes>
                        <Route path={routePaths.loginPath} element={<LoginView />} />
                        <Route path="*" element={<Navigate to={routePaths.loginPath} />} />
                    </Routes>
                </>
            );
        }
        setRoute(component);
    };

    useEffect(() => {
        prepareComponent();
    }, [context.role, context.isAuthenticated]);

    return (
        <Router>
            {route}
        </Router>
    );
};

export default RoutesGenerator;
