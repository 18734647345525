export enum KpiType {
    ResponseTime = 'ResponseTime',
    AllProductChanges = 'AllProductChanges',
    PromptedProductChanges = 'PromptedProductChanges',
    UnpromptedProductChanges = 'UnpromptedProductChanges',
    AvgNightTimeMinutesBetweenChanges = 'AvgNightTimeMinutesBetweenChanges'
}

export function getPromptedFilter(selectedKpi: KpiType): string | undefined {
    switch (selectedKpi) {
        case KpiType.PromptedProductChanges:
            return "yes";
        case KpiType.UnpromptedProductChanges:
            return "no";
        default:
            return undefined;
    }
}