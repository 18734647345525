import moment, { Moment } from "moment";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Form, Col, FormText, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { StyledFormLabel } from "../../setup/styled";
import CheckBox from "../../../common/controls/CheckBox";
import TextField from "../../../common/controls/TextFieldNew";
import TimePickerDropdown from "../../../common/controls/dateTime/TimePickerDropdown";
import ValidatedFormGroupWrapper from "../../../wrappers/ValidatedFormGroupWrapper";
import ErrorCodes from "../../../../models/ErrorCodes";
import User, { UserRegexp } from "../../../../models/User";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import AddEditButtonGroup from "../../../../views/Buttons/AddEditButtonGroup";
import {
    extendAccess,
    grantAccess,
    NursingHomeRole,
} from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import { localizer } from "../../../../utils/Localizer";

const StyledFormContainer = styled.div`
    width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
    height: 42px !important;
    :focus{
        border: 1px solid #00488F !important;
        padding-bottom: 6px !important;
    }
`;

enum Fields {
    Name = "Name",
    IsEssitySupport = "IsEssitySupport",
}

export interface IProps {
    hideModal?: () => void;
    user?: User;
}

const SupportUserAddForm = (props: IProps) => {
    const users = useSelector<RootState, User[]>(
        (state) => state.userSlice.users
    );

    const [timeWidth, setTimeWidth] = useState();

    const onDiscard = (event: any) => {
        event.preventDefault();

        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const timeInputRef = useRef<any>(null);

    useEffect(() => {
        if (timeInputRef?.current?.offsetWidth) {
            setTimeWidth(timeInputRef?.current?.offsetWidth);
        }
    }, [timeInputRef]);

    const dispatch = useDispatch();

    const [name, setName] = useState<string>(props?.user?.name || "");
    const [expirationDate, setExpirationDate] = useState<Moment>(
        props?.user?.expiresAt
            ? moment(
                DateTimeUtils.formatUsingBrowserLocale(props?.user?.expiresAt)
            )
            : moment().add(1, "days")
    );
    const [isEssitySupport, setIsEssitySupport] = useState<boolean>(
        Boolean(props?.user?.activeDirectoryAccount)
    );

    const [validationErrors, setErrors] = useState<string[]>([]);

    const clearFormData = () => {
        setName("");
        setIsEssitySupport(false);
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!name) {
            errors.push(ErrorCodes.FaultyNameErrorEmpty);
        }

        if (
            name &&
            name.length > 0 &&
            !UserRegexp.EmailRegexp.test(name.trim().toLowerCase())
        ) {
            errors.push(ErrorCodes.FaultyNameErrorEmail);
        }

        if (
            users.some(
                (user) =>
                    user.name.toLocaleLowerCase() ===
                    name.toLocaleLowerCase() &&
                    user.activeDirectoryAccount === isEssitySupport
            ) &&
            !props?.user
        ) {
            errors.push(ErrorCodes.UserAlreadyExists);
        }

        if (expirationDate < moment()) {
            errors.push(ErrorCodes.ExpirationDatePastError);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendUser = (addMore: boolean) => {
        if (props.user) {
            dispatch(extendAccess(props.user.id, expirationDate.toISOString()));
        } else {
            dispatch(
                grantAccess(
                    name,
                    NursingHomeRole.Support,
                    undefined,
                    undefined,
                    isEssitySupport,
                    expirationDate.toISOString()
                )
            );
        }

        if (addMore) {
            clearFormData();
        } else if (props?.hideModal) {
            props.hideModal();
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }

        sendUser(false);
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendUser(true);
    };

    const handleChange = (name: string, value: any) => {
        switch (name) {
            case Fields.Name:
                setName(value);
                break;
            case Fields.IsEssitySupport:
                setIsEssitySupport(value);
                break;
        }
    };

    const isToday = (date: Moment) => {
        const today = moment();
        return date.format("MM-DD-YYYY") === today.format("MM-DD-YYYY");
    };

    const calculateDisabledHours = (): number[] => {
        const disabledHours = [];
        if (isToday(expirationDate)) {
            const hours = moment().hours();

            for (let i = 0; i < Number(hours); i++) {
                disabledHours.push(i);
            }
        }

        return disabledHours;
    };

    const calculateDisabledMinutes = (): number[] => {
        const disabledMinutes = [];

        if (isToday(expirationDate)) {
            const currHour = moment().hours();
            const hour = expirationDate.hours();

            if (currHour === hour) {
                const minMinutes = moment().minutes();

                for (let i = 0; i < Number(minMinutes); i++) {
                    disabledMinutes.push(i);
                }
            }
        }

        return disabledMinutes;
    };

    const onTimeChange = (date: Moment | null) => {
        if (date) {
            date.set('year', expirationDate.year());
            date.set('month', expirationDate.month());
            date.set('date', expirationDate.date());
            handleExpirationDateChange(date);
        }
    };

    const handleExpirationDateChange = (date: Moment) => {
        const now = moment();
        if (
            isToday(date) &&
            date.hours() <= now.hours() &&
            date.minutes() <= now.minutes()
        ) {
            date.hours(now.hours());
            date.minutes(now.minutes());

            setExpirationDate(date);
        } else {
            setExpirationDate(date);
        }
    };

    const rootElement = document.getElementById('root');
    const popupContainer = rootElement && rootElement.parentElement ? { defaultPopupContainer: rootElement.parentElement } : {};

    return (
        <StyledFormContainer>
            <Form autoComplete="false">
                <Row className="g-0">
                    <Col>
                        {props?.user ? (
                            <div className="mb-3">
                                <StyledFormLabel>
                                    {localizer("users.editForm.email")}
                                </StyledFormLabel>
                                <FormText>{name}</FormText>
                            </div>
                        ) : (
                            <ValidatedFormGroupWrapper
                                validationErrors={validationErrors}
                                dependentErrorCodes={[
                                    ErrorCodes.FaultyNameErrorEmail,
                                    ErrorCodes.FaultyNameErrorEmpty,
                                    ErrorCodes.UserAlreadyExists,
                                ]}
                                label={"users.editForm.email"}
                            >
                                <TextField
                                    data-qa="support_email_input_text"
                                    fieldName={Fields.Name}
                                    disabled={false}
                                    value={name}
                                    onChange={handleChange}
                                />
                            </ValidatedFormGroupWrapper>
                        )}
                    </Col>
                </Row>

                <Row className="g-0">
                    <Col>
                        <label className="mb-0">
                            {localizer("users.editForm.expirationTitle")}
                        </label>
                    </Col>
                </Row>

                <Row className="g-0">
                    <Col sm={3}>
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.ExpirationDatePastError,
                            ]}
                            label={"users.editForm.date"}
                            hideMessage={true}
                        >
                            <StyledDatePicker
                                maxDate={new Date("2099-01-01")}
                                isClearable={false}
                                // due to needing a Portal here we need to get the modal container which is the parent of root in order to have the arrows functional
                                popperContainer={(props: { children: ReactNode }) => (
                                    createPortal(props.children, document.getElementById('root')?.parentElement as Element)
                                )}
                                selected={new Date(expirationDate.toDate())}
                                onChange={(value: any) =>
                                    value
                                        ? handleExpirationDateChange(
                                            moment(value)
                                        )
                                        : null
                                }
                                minDate={new Date()}
                                dateFormat={"yyyy-MM-dd"}
                                className="form-control"
                                popperPlacement="bottom-start"
                                showPopperArrow={false}
                                disabledKeyboardNavigation={true}
                            />
                        </ValidatedFormGroupWrapper>
                    </Col>
                    <Col sm={3} className="ms-2" ref={timeInputRef}>
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.ExpirationDatePastError,
                            ]}
                            label={"users.editForm.time"}
                            hideMessage={true}
                        >
                            <TimePickerDropdown
                                id={"support-user-time-picker"}
                                value={expirationDate}
                                onChange={onTimeChange}
                                minuteStep={1}
                                popupWidth={timeWidth}
                                calculateDisabledHours={calculateDisabledHours}
                                calculateDisabledMinutes={calculateDisabledMinutes}
                                {...popupContainer}
                            />
                        </ValidatedFormGroupWrapper>
                    </Col>
                </Row>
                <br />
                <Row className="g-0">
                    <Col>
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[]}
                        >
                            <CheckBox
                                label={localizer(
                                    "users.editForm.isEssitySupport"
                                )}
                                value={isEssitySupport}
                                fieldName={Fields.IsEssitySupport}
                                onChange={handleChange}
                                disabled={props.user !== undefined}
                            />
                        </ValidatedFormGroupWrapper>
                    </Col>
                </Row>
                <Row className="g-0 mt-3">
                    <Col>
                        <AddEditButtonGroup
                            isEdit={props.user !== undefined}
                            onSave={onSave}
                            onSaveAndAddAnother={onSaveAndAddAnother}
                            onDiscard={onDiscard}
                            extraActions={[]}
                        />
                    </Col>
                </Row>
            </Form>
        </StyledFormContainer>
    );
};

export default SupportUserAddForm;
