
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../images/dropdownClose.svg";
import { ReactComponent as ArrowIcon } from "../../../../images/dropdownArrow.svg";
import Colors from "../../../../utils/Colors";
import { StyledPickerCss, StyledPickerPanelCss } from "./CommonDatePickerStyle";

export const StyledPickerPanel = styled.div<any>`
    ${StyledPickerPanelCss}
    .rc-picker-panel {
        .rc-picker-date-panel {
            .rc-picker-body {
                .rc-picker-content {
                    tbody {
                        .rc-picker-cell {
                            .rc-picker-cell-inner:hover {
                                background-color: ${Colors.teal.hover};
                            }
                        }
                        .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner{
                            background-color: ${Colors.teal.tail};
                            background: ${Colors.teal.tail};
                        }

                    }
                }
            }
        }
        .rc-picker-year-panel {
            .rc-picker-body {
                padding: 10px;
                .rc-picker-content {
                    .rc-picker-cell {
                        .rc-picker-cell-inner:hover {
                            background-color: ${Colors.teal.hover};
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        background-color: ${Colors.teal.tail};
                        background: ${Colors.teal.tail};
                    }
                }
            }
        }
        .rc-picker-decade-panel {
            .rc-picker-body {
                .rc-picker-content {
                    .rc-picker-cell {
                        .rc-picker-cell-inner:hover {
                            background-color: ${Colors.teal.hover};
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        background-color: ${Colors.teal.tail};
                        background: ${Colors.teal.tail};
                    }
                }
            }
        }
    }
`;

export const StyledPicker = styled.div<any>`
    ${StyledPickerCss}
    .rc-picker {
        width: 242px;
    }
    .rc-picker-focused {
        .rc-picker-input-placeholder > input{
            ${ props => props.selectedValue ? "" : "color: #bfbfbf !important;"}
        }
    }
`;

export const StyledLabel = styled.div`
    text-align: left;
`;

export const StyledCloseIcon = styled(CloseIcon)`
    fill: ${Colors.grey.medium};
    :hover{
        cursor: pointer;
        fill: ${Colors.black};
    }
`;

export const StyledDropdownArrow = styled(ArrowIcon)`
    fill: ${Colors.grey.medium};
    margin-bottom: 2px;
`;

export const StyledDay = styled.div<any>`
    line-height: 27px;
    height: 27px;
    width: 27px;
    &:hover {  
        ${(props) => !props.disabled ? `background-color: ${Colors.teal.hover};` : ""}
        border-radius: 5px;
    }
    ${(props) => {
        return props.baseline ? `
            border-radius: 5px;
            height: 26px;
            line-height: 26px;
            width: 26px;
        ` : "";
    }}
    ${(props) => {
        return props.overlap ? `
            background-color: ${Colors.teal.tail};
            border: 2px solid white;
            border-radius: 5px;
            height: 26px;
            line-height: 23px;
            width: 26px;
            &:hover {  
                background-color: ${Colors.teal.hover};
            }
        ` : "";
    }}
`;

export const StyledMonth = styled.div<any>`
    text-transform: uppercase;
    ${(props) => {
        return `&:hover {
            ${!props.disabled ? `background-color: ${Colors.teal.hover};` : ""}
        }`;
    }}
    ${(props) => {
        return !props.baseline ? `
            &:hover {  
                ${!props.disabled ? `background-color: ${Colors.teal.hover};` : ""}
                border-radius: 5px;
            }
        ` : "";
    }}
    ${(props) => {
        return props.baseline && !props.overlap ? `
            border-radius: 5px;
            border: 1px solid  ${Colors.blue.brandingDark};
        ` : "";
    }}
    ${(props) => {
        return props.overlap ? `
            background-color: ${Colors.teal.tail};
            border: 1px solid ${Colors.blue.brandingDark};
            border-radius: 5px;
            &:hover {  
                background-color: ${Colors.teal.hover};
            }
            box-shadow:
                inset 0 0 0 1px white
        ` : "";
    }}
`;
