import '../../styles/main.scss';
import {NursingHomeAddEditFields} from "./NursingHomeAddEditForm";
import {FormattedMessage} from 'react-intl';
import ErrorCodes from '../../models/ErrorCodes';
import {NursingHome} from "../../models/NursingHome";
import CheckBox from "../../components/common/controls/CheckBox";
import NursingHomeGroup from '../../models/NursingHomeGroup';
import TextFieldNew from '../../components/common/controls/TextFieldNew';
import ValidatedFormGroupWrapper from '../../components/wrappers/ValidatedFormGroupWrapper';
import {useSelector} from 'react-redux';
import DropdownButtonNew from '../../components/common/controls/DropdownButtonNew';
import Market from '../../models/Market';
import {RootState} from '../../store';
import { localizer } from "../../utils/Localizer";

interface IAdminNursingHomePreferencesProps {
    nursingHome: NursingHome;
    handlePreferenceChanges: (fieldName: string, value: string) => void;
    validationErrors: string[];
    nursingHomeGroups: NursingHomeGroup[];
}

const AdminNursingHomePreferences = (props: IAdminNursingHomePreferencesProps) => {
    const markets = useSelector<RootState, Market[]>(state => state.marketSlice.markets);
    const dropdownOptions = markets.map(market => ({
        label: market.name,
        value: market.code
    })) || [];

    return (
        <div>
            <h3 className="fields-box-title"><FormattedMessage id="setup.preferences.preferences" /></h3>
            <form>
                <ValidatedFormGroupWrapper
                    validationErrors={props.validationErrors}
                    dependentErrorCodes={[ErrorCodes.EmptyNameError, ErrorCodes.DuplicateNameError]}
                    label={'setup.preferences.communityName'}
                >
                    <TextFieldNew
                        data-qa="nh_name_input_text"
                        fieldName={NursingHomeAddEditFields.Name}
                        value={props.nursingHome.name}
                        onChange={props.handlePreferenceChanges}
                    />
                </ValidatedFormGroupWrapper>
                <ValidatedFormGroupWrapper
                    validationErrors={props.validationErrors}
                    dependentErrorCodes={[ErrorCodes.EmptyCustomer]}
                    label={"setup.preferences.customer"}
                >
                    <TextFieldNew
                        data-qa="nh_customer_input_text"
                        fieldName={NursingHomeAddEditFields.CustomerId}
                        value={props.nursingHome.customerId}
                        onChange={props.handlePreferenceChanges}
                    />
                </ValidatedFormGroupWrapper>
                <ValidatedFormGroupWrapper
                    validationErrors={props.validationErrors}
                    dependentErrorCodes={[]}
                    label={"setup.preferences.marketCode"}
                >
                    <DropdownButtonNew
                        qaId="nh_assortment_dropdown"
                        id={NursingHomeAddEditFields.MarketCode}
                        name={NursingHomeAddEditFields.MarketCode}
                        selectedValue={props.nursingHome.marketCode}
                        handleChange={props.handlePreferenceChanges}
                        options={dropdownOptions}
                    />
                </ValidatedFormGroupWrapper>
                <CheckBox
                    qaId="nh_isActive_checkbox"
                    label={localizer("setup.preferences.isActive.checkboxLabel")}
                    value={props.nursingHome.isActive}
                    fieldName={NursingHomeAddEditFields.IsActive}
                    onChange={props.handlePreferenceChanges}
                />
                <CheckBox
                    qaId="nh_isWeakAuthenticationAllowed_checkbox"
                    label={localizer("setup.preferences.isWeakAuthenticationAllowed.checkboxLabel")}
                    value={props.nursingHome.isWeakAuthenticationAllowed}
                    fieldName={NursingHomeAddEditFields.IsWeakAuthenticationAllowed}
                    onChange={props.handlePreferenceChanges}
                />
                <CheckBox
                    qaId="nh_is2FAActive_checkbox"
                    label={localizer("setup.preferences.is2FAActive.checkboxLabel")}
                    value={props.nursingHome.is2FactorAuthenticationEnabled}
                    fieldName={NursingHomeAddEditFields.Is2FAActive}
                    onChange={props.handlePreferenceChanges}
                />
            </form>
        </div>
    );
};

export default AdminNursingHomePreferences;
