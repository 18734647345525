enum ErrorCodes {
    EmptyNameError = "validationErrors.EmptyNameError",
    DuplicateNameError = "validationErrors.EmptyNameError.duplicate",
    EmptyWard = "validationErrors.EmptyWard",
    EmptyAssignedWards = "validationErrors.EmptyAssignedWards",
    EmptyWardId = "validationErrors.EmptyWardId",
    BaseTransmitterError = "validationErrors.TransmitterError",
    EmptyTransmitterId = "validationErrors.TransmitterError.EmptyTransmitterId",
    DuplicateTransmitterId = "validationErrors.TransmitterError.DuplicateTransmitterId",
    BaseTransmitterErrorNight = "validationErrors.TransmitterError.Night",
    BaseTransmitterErrorDay = "validationErrors.TransmitterError.Day",
    EmptyTransmitterIdNight = "validationErrors.TransmitterError.Night.EmptyTransmitterId",
    NightTransmitterCodeLength = "validationErrors.TransmitterError.Night.NightTransmitterCodeLength",
    InvalidNightTransmitterCode = "validationErrors.TransmitterError.Night.InvalidNightTransmitterCode",
    DuplicateTransmitterIdNight = "validationErrors.TransmitterError.Night.DuplicateTransmitterId",
    EmptyTransmitterIdDay = "validationErrors.TransmitterError.Day.EmptyTransmitterId",
    DayTransmitterCodeLength = "validationErrors.TransmitterError.Day.DayTransmitterCodeLength",
    InvalidDayTransmitterCode = "validationErrors.TransmitterError.Day.InvalidDayTransmitterCode",
    InvalidDayControlDigit = "validationErrors.TransmitterError.InvalidDayControlDigit",
    InvalidNightControlDigit = "validationErrors.TransmitterError.InvalidNightControlDigit",
    DuplicateTransmitterIdDay = "validationErrors.TransmitterError.Day.DuplicateTransmitterId",
    TransmitterIdAlreadyUsedInNightSetup = "validationErrors.TransmitterError.Day.TransmitterIdAlreadyUsedInNightSetup",
    EmptyProductNameNight = "validationErrors.Night.EmptyProductName",
    EmptyProductTypeNight = "validationErrors.Night.EmptyProductType",
    EmptyProductNameDay = "validationErrors.Day.EmptyProductName",
    EmptyProductTypeDay = "validationErrors.Day.EmptyProductType",
    EmptyProductSizeNight = "validationErrors.Night.EmptyProductSize",
    EmptyProductSizeDay = "validationErrors.Day.EmptyProductSize",
    EmptyChangeThresholdNight = "validationErrors.Night.EmptyChangeThreshold",
    EmptyChangeThresholdDay = "validationErrors.Day.EmptyChangeThreshold",
    EmptyMorningShiftStartTime = "validationErrors.EmptyMorningShiftStartTime",
    EmptyNightShiftStartTime = "validationErrors.EmptyNightShiftStartTime",
    EmptyMorningShiftStartNotificationTime = "validationErrors.EmptyMorningShiftStartNotificationTime",
    EmptyNightShiftStartNotificationTime = "validationErrors.EmptyNightShiftStartNotificationTime",
    EmptyLastEscalationTime = "validationErrors.EmptyLastEscalationTime",
    EmptyCaregiverResponseTargetMinutes = "validationErrors.EmptyCaregiverResponseTargetMinutes",
    EmptyCaregiverResponseTargetMinutesDay = "validationErrors.daySetting.EmptyCaregiverResponseTargetMinutesDay",
    EmptyCaregiverNotificationIntervalMinutes = "validationErrors.EmptyCaregiverNotificationIntervalMinutes",
    EmptyCaregiverNotificationIntervalMinutesDay = "validationErrors.daySetting.EmptyCaregiverNotificationIntervalMinutesDay",
    EmptyReApplyHours = "validationErrors.EmptyReApplyHours",
    EmptyRole = "validationErrors.EmptyRole",
    BaseFaultyPassword = "validationErrors.PasswordWrongFormat",
    FaultyPasswordStrong = "validationErrors.PasswordWrongFormat.strong",
    FaultyPasswordWeak = "validationErrors.PasswordWrongFormat.weak",
    BaseFaultyNameError = "validationErrors.NameEmptyOrWrongFormat",
    FaultyNameErrorEmpty = "validationErrors.NameEmptyOrWrongFormat.empty",
    FaultyNameErrorEmail = "validationErrors.NameEmptyOrWrongFormat.email",
    EmptyNursingHome = "validationErrors.EmptyNursingHome",
    ValidationErrorsExist = "validationErrors.ValidationErrorsExist",
    UserAlreadyExists = "constants.errorMessages.userExists",
    NotMatchingPasswords = "validationErrors.NotMatchingPasswords",
    EmptyCustomer = "validationErrors.EmptyCustomerId",
    LoginFailed = "validationErrors.LoginFailed",
    BaseNotificationError = "validationErrors.Notification",
    DayNotificationError = "validationErrors.Notification.day",
    NightNotificationError = "validationErrors.Notification.night",
    BothDayAndNightNotificationError = "validationErrors.Notification.both",
    EmptyNursingHomeGroupMarket = "validationErrors.nursingHomeGroup.emptyMarket",
    EmptyNursingHomeGroupCustomerId = "validationErrors.nursingHomeGroup.emptyCustomerId",
    NursingHomeGroupDuplicateNameError = "validationErrors.EmptyNameError.duplicate",
    CountryDuplicateCountryError = "validationErrors.Country.DuplicateCountryError",
    CountryEmptyCodeError = "validationErrors.Country.EmptyCodeError",
    CountryEmptyMarketError = "validationErrors.Country.EmptyMarketError",
    EmptyBedNumberError = "validationErrors.EmptyBedNumberError",
    NoPermissions = "constants.errorMessages.noPermissions",
    AccessDenied = "constants.errorMessages.accessDenied",
    PayloadEmpty = "Payload empty",
    WrongValidationCode = "validationErrors.wrongValidationCode",
    ExpiredValidationCode = "validationErrors.expiredValidationCode",
    Locked = "constants.errorMessages.locked",
    ExpirationDatePastError = "validationErrors.expirationDateInPast",
    NoDataAvailable = "validationErrors.noDataAvailable",
    UserNotAvailable = "constants.errorMessages.usernameNotAvailable"
}

export default ErrorCodes;
