import { FormGroup } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../../utils/Colors";

export const StyledViewContainer = styled.div`
    background-color: ${Colors.white.clearWhite};
    padding: 24px 16px 0px 16px;
`;

export const StyledInformationBlocks = styled.div<any>`
    min-height: 160px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 48px;
    margin-left: 8px;
    margin-right: 8px;
    gap: 8px;
`;

export const StyledInformationBlockWrapper = styled.div<any>`
    padding: 23px 23px 23px 23px;
    border: 1px solid ${props => props.isSelected ? '#006DAE' : '#EDEFF0'};
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06), 0 10px 16px rgba(114, 210, 224, 0.22);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${props => props.alignItems || 'stretch'};
    height: 200px;
    min-height: 200px
    max-height: 200px;
    width: 339px;
    min-width: 339px
    max-width: 339px;
`;

export const StyledSpan = styled.span`
    margin-left: 5px;
`;

export const StyledBoldSpan = styled.span`
    font-weight: bold;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-bottom: 1rem;
`;

export const StyledATag = styled.a`
    text-decoration: initial;
    :hover{
        text-decoration: underline;
    }
`;