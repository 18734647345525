import React, { SVGProps } from "react";

interface IDiaperIconProps {
    color: string;
}

const DiaperIcon: React.FunctionComponent<SVGProps<any> & IDiaperIconProps> = (
    props
) => {
    const { color } = props;
    return (
        <svg
            width="21"
            height="15"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.00719183 9.50297C0.198969 15.0948 4.65641 19.6283 10.2377 19.9776C9.89357 14.3378 5.48599 9.81795 0.00719183 9.50297Z"
                fill={color}
            />
            <path
                d="M22.0083 2.48011H27.9995V1.28065C27.9995 0.574387 27.4219 0 26.7118 0H1.28765C0.577523 0 0 0.574387 0 1.28065V2.48011H5.99112C6.75439 2.48011 7.3752 3.12044 7.3752 3.90736V3.88883C7.3752 4.67575 6.75439 5.31608 5.99112 5.31608H0V8.59128C5.97687 8.90463 10.7877 13.8452 11.1236 20H16.8764C17.2123 13.8452 22.0231 8.90463 28 8.59074V5.31553H22.0083C21.2451 5.31553 20.6243 4.6752 20.6243 3.88828V3.90681C20.6248 3.11989 21.2456 2.48011 22.0083 2.48011Z"
                fill={color}
            />
            <path
                d="M27.9924 9.50297C22.5136 9.8185 18.106 14.3378 17.7619 19.9776C23.3432 19.6283 27.8007 15.0948 27.9924 9.50297Z"
                fill={color}
            />
        </svg>
    );
};

export default DiaperIcon;
