import { get } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RestClient } from "../../../../data/RestClient";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { NursingHome } from "../../../../models/NursingHome";
import { RootState } from "../../../../store";
import { allAuditTypes } from "../AuditHelpers";
import * as auditKeys from "../AuditKeys";
import AuditsLoginsTable from "./AuditsLoginsTable";
import AuditsResidentsTable from "./AuditsResidentsTable";
import AuditsUsersTable from "./AuditsUsersTable";
import { IAuditsTableHocProps } from "./IAuditsTableHocProps";

export const AuditsTableHoc = (props: IAuditsTableHocProps) => {
    const activeNursingHome = useSelector<RootState, NursingHome | null>(state => state.nursingHomeSlice.nursingHome);

    useEffect(() => {
        fetchData();
    }, [props.filters.auditType,
    props.filters.auditTimePeriod,
    props.filters.auditTimePeriodStart,
    props.filters.auditTimePeriodEnd,
    activeNursingHome?.id]);

    const fetchData = async () => {
        const { startDate, endDate } = DateTimeUtils.getDateRange(props.filters.auditTimePeriod, props.filters.auditTimePeriodStart, props.filters.auditTimePeriodEnd);
        let data = [];
        switch (props.filters.auditType) {
            case allAuditTypes.resident:
                data = await RestClient.getResidentAudits(startDate, endDate);
                break;
            case allAuditTypes.user:
                data = await RestClient.getNurseAudits(startDate, endDate);
                break;
            case allAuditTypes.admin:
                data = await RestClient.getAdminAudits(startDate, endDate);
                break;
            case allAuditTypes.login:
                data = await RestClient.getNursingHomeAccessAudits(startDate, endDate);
                break;
            case allAuditTypes.adminLogin:
                data = await RestClient.getAdminAccessAudits(startDate, endDate);
                break;
            case allAuditTypes.nursingHomeGroupManager:
                data = await RestClient.getNursingHomeGroupManagerAudits(startDate, endDate);
                break;
            default:
                break;
        }

        props.setData(data);
    };

    const filterPredicate = (filterKey: string, columnKey: string) => (row: any) => {
        const filter = get(props.filters, filterKey);
        return !filter || row[columnKey] === filter;
    };

    const getAuditsTable = () => {
        switch (props.filters[auditKeys.auditTypeKey]) {
            case allAuditTypes.resident:
                return (<AuditsResidentsTable data={
                    props.data
                        .filter(filterPredicate(auditKeys.auditResidentKey, 'resident'))
                        .filter(filterPredicate(auditKeys.auditModifiedByKey, 'performedBy'))
                } />);
            case allAuditTypes.user:
                return (<AuditsUsersTable data={props.data
                    .filter(filterPredicate(auditKeys.auditUserKey, 'user'))
                } />);
            case allAuditTypes.admin:
                return (<AuditsUsersTable data={props.data
                    .filter(filterPredicate(auditKeys.auditUserKey, 'user'))
                } />);
            case allAuditTypes.nursingHomeGroupManager:
                return (<AuditsUsersTable data={props.data
                    .filter(filterPredicate(auditKeys.auditUserKey, 'user'))
                } />);
            case allAuditTypes.login:
                return (<AuditsLoginsTable data={props.data
                    .filter(filterPredicate(auditKeys.auditUserKey, 'performedBy'))
                } />);
            case allAuditTypes.adminLogin:
                return (<AuditsLoginsTable data={props.data
                    .filter(filterPredicate(auditKeys.auditUserKey, 'performedBy'))
                } />);
            default:
                return null;
        }
    };
    return (
        getAuditsTable()
    );
};
