
import { FormattedMessage } from "react-intl";
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';
import styled from 'styled-components';
import strip from "../../../../../images/strip.svg";

const StyledStripesContainer = styled.div`
    display: flex;
    flex: 25%;
    padding: 0 12px;
`;

const StyledImage = styled.img`
    margin: 0 2px;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;

    const strips = typeof value === "boolean"
        ? value
            ? 2 : 1
        : 0;

    return (
        <StyledLeft>
            <StyledStripesContainer>
                {strips ? (
                    new Array(strips).fill(true).map((_: any, i: number) =>
                        <StyledImage src={strip} key={i} />
                    )
                ) : (
                    <FormattedMessage id="monitoring.off"/>
                )}
            </StyledStripesContainer>
        </StyledLeft>
    );
};

const StripsFormatter = (data: any) => <CellFormatter data={data} />;
export default StripsFormatter;




