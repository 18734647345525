import { StyledLeft } from '../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Colors from '../../../../utils/Colors';

const StyledFontAwesome = styled(FontAwesomeIcon)<any>`
    color: ${props=> props.isActive ? Colors.green.dark : Colors.grey.mediumLight};
    font-size: 13px;
    margin-right: 10px;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledLeft data-qa={value.toLowerCase() +"-table_item"}>
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                <>
                    <StyledFontAwesome
                        isActive={props.data.row.original.ward.isActive}
                        icon={faCircle}
                    />
                    {value}
                </>
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const WardFormatter = (data: any) => <CellFormatter data={data} />;
export default WardFormatter;
