import { StyledLeft } from "../../../components/common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../components/wrappers/TextOverflowWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Ward } from "../../../models/Ward";

const WardName = (props: any): React.ReactElement => {
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);
    const { value } = props;
    const { hideTooltipOnProd } = props.column;

    const wardName = wards.find(ward => ward.id === value)?.name || "";

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={wardName} hideTooltipOnProd={hideTooltipOnProd}>
                {wardName}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

export default WardName;
