import React from "react";
import { ITableProps } from "../../ITableProps";
import { FormattedMessage } from "react-intl";
import Table, { headerJustifyOptions } from "../../../common/table/Table";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import RequestFormatter from "./formatters/RequestFormatter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Roles } from "../../../../models/Role";

const AuditsUsersTable = (props: ITableProps) => {
  const role = useSelector<RootState, Roles>(
    (state) => state.contextSlice.role
  );

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="audits.tableHeader.user" />,
        accessor: "user",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 3,
        hideTooltipOnProd: false,
      },
      ...(role !== Roles.GlobalAdmin
        ? [
            {
              Header: <FormattedMessage id="audits.tableHeader.resident" />,
              accessor: "resident",
              Cell: StringFormatter,
              headerAlignment: headerJustifyOptions.left,
              flexGrow: 3,
              hideTooltipOnProd: false,
            },
          ]
        : []),
      {
        Header: <FormattedMessage id="audits.tableHeader.action" />,
        accessor: "action",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 1,
        hideTooltipOnProd: false,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.area" />,
        accessor: "area",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexShrink: 1,
        hideTooltipOnProd: true,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.time" />,
        accessor: "time",
        Cell: DateFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 1,
        hideTooltipOnProd: true,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.status" />,
        accessor: "status",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexShrink: 1,
        hideTooltipOnProd: true,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.request" />,
        accessor: "requestBody",
        Cell: RequestFormatter,
        headerAlignment: headerJustifyOptions.left,
        disableSortBy: true,
        flexShrink: 1,
        hideTooltipOnProd: true,
      },
    ],
    [role]
  );
  const data = React.useMemo(() => props.data, [props.data]);

  return (
    <Table
      qaId={"audit_users_table"}
      columns={columns}
      data={data}
      initialState={{
        sortBy: [
          {
            id: "time",
            desc: true,
          },
        ],
      }}
    />
  );
};

export default React.memo(AuditsUsersTable);
