import { FormattedMessage } from "react-intl";
import { auditModifiedByKey } from "../AuditKeys";
import { IAuditInformationBlockProps } from "../IAuditInformationBlockProps";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import { StyledTitle } from "../../../common/informationBlock/styled";

const AuditModifiedByInformationBlock = (props: IAuditInformationBlockProps) => (
    <>
        <StyledTitle><FormattedMessage id={auditModifiedByKey} /></StyledTitle>
        <DropdownButtonNew
            id={auditModifiedByKey}
            name={auditModifiedByKey}
            handleChange={(_name, value) => props.handleChange(value, auditModifiedByKey)}
            options={props.data || []}
            selectedValue={props.filters[auditModifiedByKey]}
            placeholder={'dropDown.all'}
            isFilterable
            maxHeight={500}
        />
    </>
);

export default AuditModifiedByInformationBlock;
