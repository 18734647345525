// Adjust X axis points to contents.
// For 1-30 days, show all dates (date and month)
// For 31-60 days, show every other date (date and month)
// For 61-90 days, show every third date (date and month)
// For 91-180 days, show every 6th date (date and month)
// For 181 up to 24 month, show every months (month and year)
// For 24 months up to 5 years, show every other month (month and year)
// More than 5 years, show every year (year only)
export const getInterval = (interval: number) => {
    switch (true) {
        case (interval <= 30):
            return 0;
        case (interval > 30 && interval <= 60):
            return 1;
        case (interval > 60 && interval <= 90):
            return 2;
        case (interval > 90 && interval <= 180):
            return 5;
        case (interval > 180 && interval <= 730):
            return 30;
        case (interval > 730 && interval <= 1825):
            return 60;
        case (interval > 1825):
            return 365;
        default:
            return 0;
    }
};

// based on the tick interval we show different date formats
export const getXAxisFormat = (interval: number) => {
    switch (true) {
        case (interval <= 6):
            return "DD MMM";
        case (interval > 6 && interval <= 60):
            return "MMM YYYY";
        case (interval > 60):
            return "YYYY";
        default:
            return "DD MMM";
    }
};

// time period when we swtich between Bar Charts or Line Chart
export const selectedTimePeriodChartSwitcher = 15;