import { Mutable } from "./Mutable";

export class Ward extends Mutable {
    id: string;
    name: string;
    nursingHomeId: string;
    isActive: boolean;
    avgResponseTimeLast7DaysMinutes: number | null;
    avgProductChangesLast7Days: number | null;
    avgResponseTimeShiftMinutes: number | null;
    createdDate: Date;

    constructor(json: any = {}) {
        super();
        this.id = json.id ? String(json.id) : '';
        this.name = json.name || '';
        this.nursingHomeId = json.nursingHomeId || '';
        this.isActive = json.isActive ?? true;
        this.avgResponseTimeLast7DaysMinutes = json.avgResponseTimeLast7DaysMinutes;
        this.avgProductChangesLast7Days = json.avgProductChangesLast7Days;
        this.avgResponseTimeShiftMinutes = json.avgResponseTimeShiftMinutes;
        this.createdDate = json.created;
    }
}
