import store from "../../store";
import { localTimeSpanToUtc } from "../../utils/TimeZoneConverter";
import { Country } from "../Country";
import { MonitoringTime } from "../MonitoringTime";
import { NursingHome } from "../NursingHome";
import NursingHomeGroup from "../NursingHomeGroup";
import { Resident } from "../Resident";
import User from "../User";

export const countryToReqBody = (country: Country) => {
    const requestBody: any = {
        code: country.code,
        marketCode: country.marketCode,
        // isActive: country.isActive,
    };
    if (country.id) {
        requestBody.id = Number(country.id);
    }

    return requestBody;
};

export const nursingHomeGroupToReqBody = (nursingHomeGroup: NursingHomeGroup) => {
    const requestBody: any = {
        name: nursingHomeGroup.name,
        customerId: nursingHomeGroup.customerId,
        marketCode: nursingHomeGroup.marketCode,
        // isActive: nursingHomeGroup.isActive,
        countryId: store.getState().contextSlice.id
    };
    if (nursingHomeGroup.id) {
        requestBody.id = Number(nursingHomeGroup.id);
    }

    return requestBody;
};

export const nursingHomeToReqBody = (nursingHome: NursingHome) => {
    const requestBody: any = {
        name: nursingHome.name,
        morningShiftStartTime: localTimeSpanToUtc(nursingHome.morningShiftStartTime),
        nightShiftStartTime: localTimeSpanToUtc(nursingHome.nightShiftStartTime),
        localMorningShiftStartTime: nursingHome.morningShiftStartTime,
        localNightShiftStartTime: nursingHome.nightShiftStartTime,
        caregiverResponseTargetMinutesNight: nursingHome.caregiverResponseTargetMinutes,
        caregiverNotificationIntervalMinutesNight: nursingHome.caregiverNotificationIntervalMinutes,
        caregiverResponseTargetMinutesDay: nursingHome.caregiverResponseTargetMinutesDay,
        caregiverNotificationIntervalMinutesDay: nursingHome.caregiverNotificationIntervalMinutesDay,
        isWeakAuthenticationAllowed: nursingHome.isWeakAuthenticationAllowed,
        nursingHomeGroupId: nursingHome.nursingHomeGroupId,
        countryId: nursingHome.countryId,
        customerId: nursingHome.customerId,
        isActive: nursingHome.isActive,
        marketCode: nursingHome.marketCode,
        morningShiftNotificationTime: localTimeSpanToUtc(nursingHome.morningShiftNotificationTime),
        nightShiftNotificationTime: localTimeSpanToUtc(nursingHome.nightShiftNotificationTime),
        localMorningShiftNotificationTime: nursingHome.morningShiftNotificationTime,
        localNightShiftNotificationTime: nursingHome.nightShiftNotificationTime,
        shiftNotificationTimeDisabled: nursingHome.shiftNotificationTimeDisabled,
        is2FactorAuthenticationEnabled: nursingHome.is2FactorAuthenticationEnabled,
        notifyOfSomeUrineStatusOverdue: nursingHome.notifyOfSomeUrineStatusOverdue,
    };
    if (nursingHome.id) {
        requestBody.id = Number(nursingHome.id);
    }

    return requestBody;
};

export const userToReqBody = (user: User) => {
    const requestBody: any = {
        userName: user.name,
        wardId: user.wardId,
        assignedNursingHomes: user.assignedNursingHomes,
        assignedWards: user.assignedWardsIds,
    };
    if (user.id) {
        requestBody.id = Number(user.id);
    }

    return requestBody;
};

export const residentToReqBody = (resident: Resident) => {
    const transmitters = [];

    if (resident.nightTimeSetup && resident.dayTimeSetup) {
        transmitters.push(resident.nightTransmitter.backToJson(MonitoringTime.Night));
        transmitters.push(resident.dayTransmitter.backToJson(MonitoringTime.Day));
    }  else if (resident.nightTimeSetup) {
        transmitters.push(resident.nightTransmitter.backToJson(MonitoringTime.Night));
    }

    const requestBody: any = {
        name: resident.name.trim(),
        wardId: Number(resident.wardId),
        transmitters,
        isActive: resident.isActive,
        isSeparateTransmitter: resident.separateTransmitter,
    };

    if (resident.id) {
        requestBody.id = Number(resident.id);
    }

    return requestBody;
};
