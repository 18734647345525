import styled from 'styled-components';
import { StyledCenter } from '../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';
import { getDeviceErrorsCount } from '../../../../reducers/residentHelper';

const StyledError = styled.div`
    color: red;
`;

const CellFormatter = (props: any) => {
    const deviceErrorsCount = getDeviceErrorsCount(props.data.row.original.residents);
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledCenter>
            <TextOverflowWrapper tooltip={deviceErrorsCount} hideTooltipOnProd={hideTooltipOnProd}>
                {deviceErrorsCount === 0 ? (
                    <div> {deviceErrorsCount} </div>
                ) : (
                        <>
                            <StyledError>
                                {deviceErrorsCount}
                            </StyledError>
                        </>
                    )
                }
            </TextOverflowWrapper>
        </StyledCenter>
    );
};

const ErrorsFormatter = (data: any) => <CellFormatter data={data} />;
export default ErrorsFormatter;
