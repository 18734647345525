import React from 'react';
import {ITableProps} from '../../ITableProps';
import {FormattedMessage} from 'react-intl';
import Table, {headerJustifyOptions} from '../../../common/table/Table';
import NumberFormatter from '../../../common/table/formatters/NumberFormatter';
import CountryNameFormatter from '../../../common/table/formatters/CountryNameFormatter';
import MarketCodeFormatter from '../../../common/table/formatters/MarketCodeFormatter';
import NursingHomeGroupActions from '../NursingHomeGroupActions';

const NursingHomeGroupsTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="nursingHomeGroups.tableHeader.name"/>,
                accessor: 'name',
                Cell: CountryNameFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: false,
                filterDisabled: true,
            },
            // {
            //     Header: <FormattedMessage id="nursingHomeGroups.tableHeader.isActive" />,
            //     accessor: 'isActive',
            //     Cell: BooleanIconFormatter,
            //     sortType: 'basic',
            //     headerAlignment: headerJustifyOptions.center,
            //     flexGrow: 1,
            // },
            {
                Header: <FormattedMessage id="nursingHomeGroups.tableHeader.marketCode"/>,
                accessor: 'marketCode',
                Cell: MarketCodeFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="nursingHomeGroups.tableHeader.nursingHomes"/>,
                accessor: 'amountOfNursingHomes',
                Cell: NumberFormatter,
                headerAlignment: headerJustifyOptions.center,
                flexGrow: 1,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="nursingHomeGroups.tableHeader.admins"/>,
                accessor: 'amountOfAssignedUsers',
                Cell: NumberFormatter,
                headerAlignment: headerJustifyOptions.center,
                flexGrow: 1,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="general.tableHeader.actions"/>,
                headerAlignment: headerJustifyOptions.right,
                accessor: 'id',
                Cell: NursingHomeGroupActions,
                disableSortBy: true,
                flexShrink: 3,
                filterDisabled: true,
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"nhg_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: 'code',
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(NursingHomeGroupsTable);
