import ModalWrapper, { EModalSize } from "../../../common/modal/ModalWrapper";
import { localizer } from "../../../../utils/Localizer";
import UserDeleteForm from "./CommonUserDeleteForm";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IContextStateType } from "../../../../reducers/contextSlice";
import { ReactComponent as Trashcan } from "../../../../images/trashcan.svg";
import Colors from "../../../../utils/Colors";

interface IProps {
    user: any;
    tooltip?: boolean;
}
const StyledIcon = styled.div<any>`
    font-size: 12px;
    color: ${(props) => (props.active ? Colors.blue.brandingDark : Colors.grey.disabledGrey)};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    :hover {
        cursor: ${(props) => (props.active ? "pointer" : "default")};
    }
`;

const CommonUserDeleteIcon = (props: IProps) => {
    const context = useSelector<RootState, IContextStateType>(
        (state) => state.contextSlice
    );
    const triggerComponent: React.ReactNode = (
        <StyledIcon
            data-qa={props.user.name.toLowerCase() + "-delete_button"}
            data-tip={ 
                context.userName === props.user.name && props.tooltip
                    ? localizer("users.delete.tooltip")
                    : undefined
            }
            active={
                !(props.tooltip && context.userName === props.user.name) ||
                !props.tooltip
            }
        >
            <Trashcan
                fill={
                    !(props.tooltip && context.userName === props.user.name) ||
                    !props.tooltip
                        ? Colors.blue.brandingDark
                        : Colors.grey.disabledGrey
                }
            />
            {localizer("users.revokeButton")}
        </StyledIcon>
    );

    return (
        <ModalWrapper
            disabled={context.userName === props.user.name && props.tooltip}
            triggerComponent={triggerComponent}
            modalContent={<UserDeleteForm user={props.user} />}
            modalTitle={localizer("users.deleteForm.title", {
                name: props.user.name,
            })}
            size={EModalSize.S}
        />
    );
};

export default CommonUserDeleteIcon;
