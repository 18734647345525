import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const StyledLowerBarButton = styled.div`
    cursor: pointer;
    font-size: 14px;
    color: #E5E5E5;
`;
const StyledImage = styled.img`
    margin-left: 10px;
`

interface Props {
    qaId: string,
    text: string,
    iconSource: string,
    onClick: () => void,
}

const LowerBarButton = (props: Props) => (
    <StyledLowerBarButton onClick={props.onClick} data-qa = {props.qaId}>
        <span><FormattedMessage id={props.text} /></span>
        <StyledImage src={props.iconSource} alt={props.text} />
    </StyledLowerBarButton>
);

export default LowerBarButton