import store from "../store";
import { clearCareGiverSlice, fetchCaregiversDevices } from "./caregiverDeviceSlice";
import { clearMarketSlice, fetchMarkets, fetchProducts } from "./marketSlice";
import {
    clearNursingHomeGroupSlice,
    fetchNursingHomeGroups,
} from "./nursingHomeGroupSlice";
import {
    clearNursingHomeSlice,
    fetchNursingHome,
    fetchNursingHomes,
} from "./nursingHomeSlice";
import { clearResidentSlice, fetchResidents } from "./residentSlice";
import { clearUserSlice } from "./userSlice";
import { clearWardSlice, fetchWards } from "./wardSlice";
import {
    clearSpinnerSlice,
} from "./spinnerSlice";
import { clearCountrySlice, fetchCountries } from "./countrySlice";
import { clearContextSlice } from "./contextSlice";
import { Role, Roles } from "../models/Role";

export const cleanupRedux = () => {
    store.dispatch(clearContextSlice());
    store.dispatch(clearNursingHomeSlice());
    store.dispatch(clearWardSlice());
    store.dispatch(clearUserSlice());
    store.dispatch(clearResidentSlice());
    store.dispatch(clearCareGiverSlice());
    store.dispatch(clearNursingHomeGroupSlice());
    store.dispatch(clearMarketSlice());
    store.dispatch(clearSpinnerSlice());
    store.dispatch(clearCountrySlice());
};

export const populateRedux = async (role: Role) => {
    switch (role) {
        case Roles.GlobalAdmin:
            store.dispatch(clearUserSlice());
            store.dispatch(clearNursingHomeSlice());
            store.dispatch(fetchCountries());
            store.dispatch(fetchMarkets());
            break;
        case Roles.CountryAdmin:
            store.dispatch(clearUserSlice());
            store.dispatch(clearNursingHomeSlice());
            store.dispatch(clearNursingHomeGroupSlice());
            store.dispatch(fetchCountries());
            store.dispatch(fetchMarkets());
            store.dispatch(fetchNursingHomes());
            store.dispatch(fetchNursingHomeGroups());
            break;
        case Roles.NHGManager:
            store.dispatch(clearNursingHomeSlice());

            store.dispatch(fetchMarkets());
            store.dispatch(fetchNursingHomes());
            store.dispatch(fetchNursingHomeGroups());
            break;
        case Roles.NHManager:
        case Roles.Support:
            store.dispatch(fetchNursingHomes());

            store.dispatch(fetchMarkets());
            store.dispatch(fetchNursingHome());
            store.dispatch(fetchWards());
            store.dispatch(fetchCaregiversDevices());
            store.dispatch(fetchResidents());
            store.dispatch(fetchProducts());
            break;
        case Roles.Nurse:
            store.dispatch(fetchMarkets());
            store.dispatch(fetchNursingHome());
            store.dispatch(fetchWards());
            store.dispatch(fetchCaregiversDevices());
            store.dispatch(fetchResidents());
            store.dispatch(fetchProducts());
            break;
        default:
            break;
    }
};
