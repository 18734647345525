import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import BasicModal from "../../../components/common/modal/BasicModal";
import { EModalSize } from "../../../components/common/modal/ModalWrapper";
import { StyledLeft } from "../../../components/common/table/formatters/PositionContainers";
import { RestClient } from "../../../data/RestClient";
import DayIcon from "../../../images/dayIcon";
import DayNightIcon from "../../../images/dayNightIcon";
import NightIcon from "../../../images/nightIcon";
import OffIcon from "../../../images/offIcon";
import { MonitoringValues } from "../../../models/MonitoringValues";
import Colors from "../../../utils/Colors";
import { localizer } from "../../../utils/Localizer";
import AddEditButtonGroup from "../../../views/Buttons/AddEditButtonGroup";
import { MonitoringSwitch } from "./MonitoringSwitch";

const StyledRow = styled(Row)`
    margin-top 13px;
    font-weight: 400;
    color: ${Colors.black};
    line-height: 25px;
    height: 75px;
`;

const BoldedRow = styled(StyledRow)`
    margin-top 13px;
    font-weight: 700;
    height: unset;
    min-height: 35px;
    max-height: 75px;
`;

function MonitoringEditor(props: any): React.ReactElement {
    const [buttonValue, setButtonValue] = useState<string>(props.selected);
    const [buttons, setButtons] = useState<JSX.Element[]>(props.selected);
    const [strips, setStrips] = useState<number>(0);
    const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false);
    const [targetValue, setTargetValue] = useState<any>(undefined);

    useEffect(() => {
        const row = props.row.original;
        // Only product WITHOUT transmitter
        const daySetupWithProduct =
            row.dayTimeSetup && row.dayTransmitter.productId;
        const nightSetupWithProduct =
            row.nightTimeSetup && row.nightTransmitter.productId;

        let generatedRadios: any[] = [];

        if (daySetupWithProduct && nightSetupWithProduct) {
            if (row.separateTransmitter) {
                generatedRadios = [
                    {
                        name: <OffIcon />,
                        value: MonitoringValues.Off,
                        stroke: true,
                        disabled: !row.nightTransmitter.transmitterProductCode && !row.dayTransmitter.transmitterProductCode,
                    },
                    {
                        name: <NightIcon />,
                        value: MonitoringValues.Night,
                        disabled: !row.nightTransmitter.transmitterProductCode
                    },
                    {
                        name: <DayIcon />,
                        value: MonitoringValues.Day,
                        disabled: !row.dayTransmitter.transmitterProductCode
                    },
                ];
                setStrips(2);
            } else {
                if (
                    row.dayTransmitter.productId ===
                    row.nightTransmitter.productId
                ) {
                    generatedRadios = [
                        {
                            name: <OffIcon />,
                            value: MonitoringValues.Off,
                            stroke: true,
                            disabled: !row.nightTransmitter.transmitterProductCode
                        },
                        {
                            name: <DayNightIcon />,
                            value: MonitoringValues.Both,
                            disabled: !row.nightTransmitter.transmitterProductCode
                        },
                    ];
                    setStrips(1);
                } else {
                    generatedRadios = [
                        {
                            name: <OffIcon />,
                            value: MonitoringValues.Off,
                            stroke: true,
                            disabled: !row.nightTransmitter.transmitterProductCode && !row.dayTransmitter.transmitterProductCode
                        },
                        {
                            name: <NightIcon />,
                            value: MonitoringValues.Night,
                            disabled: !row.nightTransmitter.transmitterProductCode
                        },
                        {
                            name: <DayIcon />,
                            value: MonitoringValues.Day,
                            disabled: !row.dayTransmitter.transmitterProductCode
                        },
                    ];
                    setStrips(1);
                }
            }
        } else if (daySetupWithProduct) {
            if (nightSetupWithProduct) {
                generatedRadios = [
                    {
                        name: <OffIcon />,
                        value: MonitoringValues.Off,
                        stroke: true,
                        disabled: !row.dayTransmitter.transmitterProductCode
                    },
                    {
                        name: <NightIcon />,
                        value: MonitoringValues.Night,
                        disabled: true,
                    },
                    {
                        name: <DayIcon />,
                        value: MonitoringValues.Day,
                        disabled: !row.dayTransmitter.transmitterProductCode
                    },
                ];
                setStrips(2);
            } else {
                generatedRadios = [
                    {
                        name: <OffIcon />,
                        value: MonitoringValues.Off,
                        stroke: true,
                        disabled: !row.dayTransmitter.transmitterProductCode
                    },
                    {
                        name: <DayIcon />,
                        value: MonitoringValues.Day,
                        disabled: !row.dayTransmitter.transmitterProductCode
                    },
                ];
                setStrips(1);
            }
        } else if (nightSetupWithProduct) {
            if (daySetupWithProduct) {
                generatedRadios = [
                    {
                        name: <OffIcon />,
                        value: MonitoringValues.Off,
                        stroke: true,
                        disabled: !row.nightTransmitter.transmitterProductCode
                    },
                    {
                        name: <NightIcon />,
                        value: MonitoringValues.Night,
                        disabled: !row.nightTransmitter.transmitterProductCode
                    },
                    {
                        name: <DayIcon />,
                        value: MonitoringValues.Day,
                        disabled: true,
                    },
                ];
                setStrips(2);
            } else {
                generatedRadios = [
                    {
                        name: <OffIcon />,
                        value: MonitoringValues.Off,
                        stroke: true,
                        disabled: !row.nightTransmitter.transmitterProductCode
                    },
                    {
                        name: <NightIcon />,
                        value: MonitoringValues.Night,
                        disabled: !row.nightTransmitter.transmitterProductCode
                    },
                ];
                setStrips(1);
            }
        } else {
            setStrips(0);
        }

        if (!row.nightTransmitter.transmitterProductCode && !row.dayTransmitter.transmitterProductCode) {
            setButtonValue("");
        } else if (
            daySetupWithProduct &&
            nightSetupWithProduct &&
            !row.separateTransmitter &&
            row.dayTransmitter.productId === row.nightTransmitter.productId
        ) {
            setButtonValue(
                row.monitoringTransmitter
                    ? MonitoringValues.Both
                    : MonitoringValues.Off
            );
        } else {
            setButtonValue(
                row.monitoringTransmitter
                    ? row.monitoringTransmitter
                    : MonitoringValues.Off
            );
        }
        setButtons(generatedRadios);
    }, [
        props.row.original.dayTimeSetup,
        props.row.original.nightTimeSetup,
        props.row.original.dayTransmitter,
        props.row.original.nightTransmitter,
        props.row.original.monitoringTransmitter,
    ]);

    const getRequestBody = (value: any) => {
        let returnValue;
        if (value === MonitoringValues.Off) {
            returnValue = {
                monitoringTransmitter: null,
            };
        } else {
            returnValue = {
                monitoringTransmitter: value,
            };
        }

        return returnValue;
    };

    const setMonitoringStatus = async (value: any) => {
        setButtonValue(value);
        const residentRequestBody = getRequestBody(value);
        try {
            await RestClient.setMonitoringStatus(
                props.row.original.id,
                residentRequestBody
            );
        } catch (error: any) {
            // tslint:disable-next-line:no-console
            console.error(error.message);
        }
    };

    const toggleModal = (value: string) => {
        setTargetValue(value);
        setIsModalDisplayed(true);
    };

    const getModalMessage = (currentValue: string, targetValue: string) => {
        const residentName = props.row.original.name;
        if (targetValue === MonitoringValues.Off) {
            return (
                <StyledRow>
                    <FormattedMessage
                        id={"residents.confirmationDialog.monitorOffMessage"}
                        values={{ residentName }} />
                </StyledRow>);
        } else if (currentValue === MonitoringValues.Off) {
            if (targetValue === MonitoringValues.Night) {
                return (
                    <StyledRow>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorNightProductMessage"}
                            values={{ residentName }}
                        />
                    </StyledRow>);
            } else if (targetValue === MonitoringValues.Day) {
                return (
                    <StyledRow>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorDayProductMessage"}
                            values={{ residentName }}
                        />
                    </StyledRow>);
            }
            return (
                <StyledRow>
                    <FormattedMessage
                        id={"residents.confirmationDialog.monitorBothProductMessage"}
                        values={{ residentName }}
                    />
                </StyledRow>);
        } else if (currentValue === MonitoringValues.Day) {
            return (
                <>
                    <BoldedRow style={{}}>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorNightProductMessage"}
                            values={{ residentName }}
                        />
                    </BoldedRow>
                    <StyledRow>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorNighInfo"}
                        />
                    </StyledRow>
                </>);
        } else if (currentValue === MonitoringValues.Night) {
            return (
                <>
                    <BoldedRow>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorDayProductMessage"}
                            values={{ residentName }}
                        />
                    </BoldedRow>
                    <StyledRow>
                        <FormattedMessage
                            id={"residents.confirmationDialog.monitorDayInfo"}
                        />
                    </StyledRow>
                </>);
        }
        return <></>;
    };

    const getModalHeader = (currentValue: string, targetValue: string) => {
        if (targetValue === MonitoringValues.Off) {
            return localizer("residents.confirmationDialog.offHeader");
        } else if (currentValue === MonitoringValues.Off) {
            if (targetValue === MonitoringValues.Night) {
                return localizer("residents.confirmationDialog.nightHeader");
            } else if (targetValue === MonitoringValues.Day) {
                return localizer("residents.confirmationDialog.dayHeader");
            } else {
                return localizer("residents.confirmationDialog.bothHeader");
            }
        }
        return localizer("residents.confirmationDialog.switchHeader");
    };

    const getConfirmationButtonText = (currentValue: string, targetValue: string) => {
        if (targetValue === MonitoringValues.Off) {
            return localizer("residents.confirmationDialog.turnOffButton");
        } else if (currentValue === MonitoringValues.Off) {
            return localizer("residents.confirmationDialog.startButton");
        } else {
            return localizer("residents.confirmationDialog.switchButton");
        }
    };

    const monitoringSwitch = () => (
        <MonitoringSwitch
            buttons={buttons}
            setMonitoringStatus={setMonitoringStatus}
            buttonValue={buttonValue}
            strips={strips}
            toggle={toggleModal}
            id={props.row.original.id}
        />
    );
    
    return (
        <>
            <StyledLeft>
                {buttons && props.row.original.isActive
                    ? monitoringSwitch()
                    : null}
            </StyledLeft>

            <BasicModal
                width={EModalSize.S}
                handleOnHide={() =>
                    setIsModalDisplayed(false)
                }
                show={isModalDisplayed}
                title={getModalHeader(buttonValue, targetValue)}
                fixedFooter={false}
            >
                <div>
                    <Row className="row g-0">
                        {getModalMessage(buttonValue, targetValue)}
                    </Row>
                    <Row className="g-0 mt-3">
                        <AddEditButtonGroup
                            isEdit={false}
                            customConfirmButtonMessageId={getConfirmationButtonText(buttonValue, targetValue)}
                            onSave={() => {
                                setMonitoringStatus(targetValue);
                                setIsModalDisplayed(false);
                            }}
                            onDiscard={() => setIsModalDisplayed(false)}
                        />
                    </Row>
                </div>
            </BasicModal>
        </>
    );
}

export default MonitoringEditor;
