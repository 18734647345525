import { StyledLeft } from "./PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { localizer } from "../../../../utils/Localizer";

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    const localizeMarketName = localizer(`markets.${value}`) || value;

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={localizeMarketName} hideTooltipOnProd={hideTooltipOnProd}>
                {localizeMarketName}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const MarketCodeFormatter = (data: any) => <CellFormatter data={data} />;
export default MarketCodeFormatter;
