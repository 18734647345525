import React, { SVGProps } from "react";

function OffIcon(props: SVGProps<any>): React.ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="12" cy="12" r="9" stroke-width="2"/>
            <path d="M6.5 12C6.5 11.837 6.54205 11.6567 6.59474 11.5H17.4392C17.4699 11.6041 17.5 11.7657 17.5 12C17.5 12.2343 17.4699 12.3959 17.4392 12.5H6.59474C6.54205 12.3433 6.5 12.163 6.5 12Z"/>
        </svg>

    );
}

export default OffIcon;
