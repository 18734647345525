import ProductAbsLevel from "./ProductAbsLevel";
import ProductFamily from "./ProductFamily";
import { utcTimeSpanToLocal } from "../utils/TimeZoneConverter";
import { getSystemDate } from "../reducers/nursingHomeHelpers";

export class NursingHome {
    static caregiverResponseTargetTimes = [10, 20, 30, 40];
    static supervisorResponseTargetTimes = [0, 10, 20, 30, 40];
    static caregiverNotificationIntervals = [0, 3, 6, 9, 12, 15];
    static supervisorNotificationIntervals = [0 ,5, 10, 15, 20, 25];
    static reapplyReminderTimes = [1, 2, 3, 4, 5, 0];
    id: string;
    name: string;
    systemStartTime: string;
    systemEndTime: string;
    nightShiftStartTime: string;
    morningShiftStartTime: string;
    lastEscalationTime: string;
    caregiverResponseTargetMinutes: number;
    caregiverResponseTargetMinutesDay: number;
    caregiverNotificationIntervalMinutes: number;
    caregiverNotificationIntervalMinutesDay: number;
    reApplyHours: number;
    isWeakAuthenticationAllowed: boolean;
    is2FactorAuthenticationEnabled: boolean;
    averageResponseTimeFor30DaysInMinutes: number | null;
    nursingHomeGroupId: string;
    customerId: string;
    isActive: boolean;
    briefAbsorptionTypes: ProductAbsLevel[] = [];
    productFamilies: ProductFamily[] = [];
    assignedUsersIds: number[];
    amountOfAssignedUsers: number;
    countryId: string;
    marketCode: string;
    morningShiftNotificationTime: string;
    nightShiftNotificationTime: string;
    shiftNotificationTimeDisabled: boolean;
    notifyOfSomeUrineStatusOverdue: boolean;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.name = json.name || '';
        this.systemStartTime = json.morningShiftStartTime ? utcTimeSpanToLocal(getSystemDate(json.morningShiftStartTime)) : "00:00";
        this.systemEndTime = json.morningShiftStartTime ? utcTimeSpanToLocal(getSystemDate(json.morningShiftStartTime, -1)) : "23:59";
        this.nightShiftStartTime = utcTimeSpanToLocal(json.nightShiftStartTime);
        this.morningShiftStartTime = utcTimeSpanToLocal(json.morningShiftStartTime);
        this.lastEscalationTime = json.lastEscalationNotificationTime;
        this.caregiverResponseTargetMinutes = json.caregiverResponseTargetMinutesNight;
        this.caregiverResponseTargetMinutesDay = json.caregiverResponseTargetMinutesDay;
        this.caregiverNotificationIntervalMinutes = json.caregiverNotificationIntervalMinutesNight;
        this.caregiverNotificationIntervalMinutesDay = json.caregiverNotificationIntervalMinutesDay;
        this.reApplyHours = json.lastEscalationNotificationHoursDay;
        this.isWeakAuthenticationAllowed = json.isWeakAuthenticationAllowed;
        this.is2FactorAuthenticationEnabled = json.is2FactorAuthenticationEnabled ?? true;
        this.averageResponseTimeFor30DaysInMinutes = json.avgResponseTimeLast30DaysMinutes;
        this.nursingHomeGroupId = json.nursingHomeGroupId ? String(json.nursingHomeGroupId) : '';
        this.countryId = json.countryId;
        this.customerId = json.customerId ? json.customerId : '';
        this.isActive = json.isActive ?? true;
        this.assignedUsersIds = json.assignedUsers || [];
        this.amountOfAssignedUsers = this.assignedUsersIds.length;
        this.marketCode = json.marketCode;
        this.morningShiftNotificationTime = utcTimeSpanToLocal(json.morningShiftNotificationTime);
        this.nightShiftNotificationTime = utcTimeSpanToLocal(json.nightShiftNotificationTime);
        this.shiftNotificationTimeDisabled = json.shiftNotificationTimeDisabled;
        this.notifyOfSomeUrineStatusOverdue = json.notifyOfSomeUrineStatusOverdue ?? true;

        if (json.product_abs_levels && Array.isArray(json.product_abs_levels)) {
            this.briefAbsorptionTypes = json.product_abs_levels.map((productAbsLevels: any) => new ProductAbsLevel(productAbsLevels));
        }
    }

    setProductAbsLevels(productAbsLevels: ProductAbsLevel[]): void {
        this.briefAbsorptionTypes = ProductAbsLevel.sort(productAbsLevels);
    }

    setProductFamilies(productFamilies: ProductFamily[]): void {
        this.productFamilies = productFamilies;
    }
}
