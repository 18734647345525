import ModalWrapper, { EModalSize } from "../../components/common/modal/ModalWrapper";
import { StyledAddButton } from "../../components/styled";
import { Ward } from "../../models/Ward";
import { localizer } from "../../utils/Localizer";
import ResidentEditForm from "./ResidentEditForm";

export interface IProps {
    selectedWards: Ward[];
}

const ResidentAddButton = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" />
    );
    let selectedWard;
    if (props.selectedWards.length === 1) {
        selectedWard = props.selectedWards[0];
    }
    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<ResidentEditForm selectedWard={selectedWard} entity={undefined} />}
            modalTitle={localizer("residents.addButton.title")}
            fixedFooter={true}
            size={EModalSize.M}
        />
    );
};

export default ResidentAddButton;
