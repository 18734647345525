import { Col, Row } from 'react-bootstrap';
import '../../styles/main.scss';
import PolicyTimeSelectionFormGroup from "../../views/Fields/PolicyTimeSelectionFormGroup";
import ErrorCodes from '../../models/ErrorCodes';

interface IDayNightTimesProps {
    dayValue: string;
    nightValue: string;
    handleChange: (fieldName: string, value: any) => void;
    validationErrors: string[];
    dayTimeFieldName: string;
    nightTimeFiledName: string;
    dayError: ErrorCodes;
    nightError: ErrorCodes;
    minuteStep?: number;
    dayMinTime?: string;
    nightMinTime?: string;
    disabled?: boolean;
    readOnly?: boolean;
}

const DayNightTimes = (props: IDayNightTimesProps) => {
    const getError = (error: ErrorCodes): string | undefined => {
        const validationError = props.validationErrors.find(err => err.startsWith(error));
        if (validationError !== undefined) {
            return validationError;
        }
        return;
    };

    return (
        <div>
            <Row className="mb-3">
                <Col sm={4} md={4}>
                    <PolicyTimeSelectionFormGroup
                        id={props.dayTimeFieldName}
                        label="setup.shiftStartTime.morning"
                        timeFieldName={props.dayTimeFieldName}
                        timeFieldOnChange={props.handleChange}
                        timeFieldValue={props.dayValue}
                        errorMessage={getError(props.dayError)}
                        errorMessageStyles="error-message"
                        minuteStep={props.minuteStep}
                        minTime={props.dayMinTime}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                </Col>
                <Col sm={4} md={4}>
                    <PolicyTimeSelectionFormGroup
                        id={props.nightTimeFiledName}
                        label="setup.shiftStartTime.evening"
                        timeFieldName={props.nightTimeFiledName}
                        timeFieldOnChange={props.handleChange}
                        timeFieldValue={props.nightValue}
                        errorMessage={getError(props.nightError)}
                        errorMessageStyles="error-message"
                        minuteStep={props.minuteStep}
                        minTime={props.nightMinTime}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default DayNightTimes;
