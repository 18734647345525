import React, { Component } from "react";
import "./ResidentHistory.scss";
import "./ResidentHistoryGeneralInfo.scss";
import "../../styles/main.scss";
import {
    convertMinutesToHours,
    convertTimeToHtml,
    convertTimeToString,
} from "../../utils/ConvertMinutesToHours";
import InformationBlock from "../../views/InformationBlock/InformationBlock";
import { NursingHomeContext } from "../../data/NurseRepository";
import { ResidentEventType } from "../../models/ResidentEventType";
import { EMPTY_VALUE, localizer } from "../../utils/Localizer";
import DropdownDateTimeDaysInThePastFromNowRangeButton from "../../components/common/controls/dateTime/DropdownDateTimeDaysInThePastFromNowRangeButton";
import { PickerType } from "../../components/common/controls/dateTime/helpers/PickerType";
import { DateTimeUtils } from "../../models/DateTimeUtils";
import {
    StyledInformationBlockWrapper,
    StyledInformationBlocks,
    StyledSpan,
} from "../../components/common/View";
import styled from "styled-components";
import { isProdEnv } from "../../utils/EnvCheck";
import NumberInformationBlock from "../../components/common/informationBlock/NumberInformationBlock";
import { Col, Row } from "react-bootstrap";
import TimeRangePickerDropdown from "../../components/common/controls/dateTime/TimeRangePickerDropdown";

const StyledResidentHistoryInformationBlocks = styled(StyledInformationBlocks)`
    margin-left: 0px;
`;

interface IResidentHistoryGeneralInfoProps {
    selectedEventType: ResidentEventType;
    selectedDateFilter: number;
    selectedStartDate: Date;
    selectedEndDate: Date;
    selectedStartTime: Date | null;
    selectedEndTime: Date | null;
    averageResponseTime: number | null;
    changesPerResident: number | null;
    selectedTimeFilter: string;
    handleOptionSelection: (name: string, value: any) => void;
    startDateChanged: (value: Date | any) => void;
    endDateChanged: (value: Date | any) => void;
    startTimeChanged: (value: Date | null) => void;
    endTimeChanged: (value: Date | null) => void;
}

export default class ResidentHistoryGeneralInfo extends Component<IResidentHistoryGeneralInfoProps> {
    render(): React.ReactElement {
        const averageResponseTimeFormatted = convertTimeToString(
            convertMinutesToHours(this.props.averageResponseTime),
            true
        );

        const averageChangesPerShift =
            this.props.changesPerResident || this.props.changesPerResident === 0
                ? Math.round(this.props.changesPerResident)
                : EMPTY_VALUE;

        const specificXAxisOffsetValue = 149;

        return (
            <>
                <Row className="g-5 mb-4">
                    <Col md="2">
                        <InformationBlock
                            title="residents.historyGeneralInfo.date"
                            parentClassName="information-block-wrapper"
                        >
                            <DropdownDateTimeDaysInThePastFromNowRangeButton
                                id="dateDropdown"
                                options={DateTimeUtils.getDateFilterOptions()}
                                handleChange={this.props.handleOptionSelection}
                                selectedValue={this.props.selectedDateFilter}
                                name="dateSelected"
                                start={this.props.selectedStartDate}
                                end={this.props.selectedEndDate}
                                handleStartDateTimeChange={
                                    this.props.startDateChanged
                                }
                                handleEndDateTimeChange={
                                    this.props.endDateChanged
                                }
                                pickerType={PickerType.Date}
                                offsetValue={specificXAxisOffsetValue}
                            />
                        </InformationBlock>
                    </Col>
                    <Col md="2">
                        <InformationBlock
                            title="residents.historyGeneralInfo.time"
                            parentClassName="information-block-wrapper"
                        >
                            <TimeRangePickerDropdown
                                name="timeSelected"
                                value={this.props.selectedTimeFilter}
                                onChange={this.props.handleOptionSelection}
                                options={DateTimeUtils.getTimeFilterOptions()}
                                handleStartDateTimeChange={
                                    this.props.startTimeChanged
                                }
                                handleEndDateTimeChange={this.props.endTimeChanged}
                                start={this.props.selectedStartTime}
                                end={this.props.selectedEndTime}
                            />
                        </InformationBlock>
                    </Col>
                </Row>

                <Row className="g-0 mb-4">
                    <StyledResidentHistoryInformationBlocks>
                        <StyledInformationBlockWrapper
                            title={
                                !isProdEnv() && averageResponseTimeFormatted
                                    ? String(averageResponseTimeFormatted)
                                    : ""
                            }
                            minWidth={416}
                        >
                            <NumberInformationBlock
                                header={localizer(
                                    "residents.informationBlocks.title.avgTime"
                                )}
                                description={localizer(
                                    "home.avgResponseTimes30Days.description"
                                )}
                                value={convertTimeToHtml(
                                    convertMinutesToHours(
                                        this.props.averageResponseTime
                                    ),
                                    true
                                )}
                            />
                        </StyledInformationBlockWrapper>

                        <StyledInformationBlockWrapper
                            title={
                                !isProdEnv() && averageChangesPerShift
                                    ? String(averageChangesPerShift)
                                    : ""
                            }
                            minWidth={416}
                        >
                            <NumberInformationBlock
                                header={localizer(
                                    "residents.historyGeneralInfo.changes"
                                )}
                                description={localizer(
                                    "residents.history.productChanges"
                                )}
                                value={
                                    <>
                                        {averageChangesPerShift}
                                        <StyledSpan>
                                            {localizer(
                                                "constants.changeEventsFilter.kpi.changes"
                                            )}
                                        </StyledSpan>
                                    </>
                                }
                            />
                        </StyledInformationBlockWrapper>
                    </StyledResidentHistoryInformationBlocks>
                </Row>
            </>
        );
    }
}

ResidentHistoryGeneralInfo.contextType = NursingHomeContext;
