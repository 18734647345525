
import ActionsContainer from '../../../common/table/formatters/ActionsContainer';
import CommonUserDeleteIcon from '../common/CommonUserDeleteIcon';

const UserActions = (props: any) => {
    return (
        <ActionsContainer>
            <CommonUserDeleteIcon
                user={props.row.original}
            />
        </ActionsContainer>
    );
};

export default UserActions;