export class Country {
    id: string;
    code: string;
    marketCode: string;
    // isActive: boolean;
    assignedUsersIds: number[];
    amountOfAssignedUsers: number;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.code = json.code || '';
        this.marketCode = json.marketCode || '';
        // this.isActive = json.isActive;
        this.assignedUsersIds = json.assignedUsers || [];
        this.amountOfAssignedUsers = this.assignedUsersIds.length;
    }
}