enum ToastMessages {
    UpdateUserSuccessful = "toast.updateUser.success",
    UpdateUserError = "toast.updateUser.error",
    UpdateUserPasswordSuccessful = "toast.updateUserPassword.success",
    UpdateUserPasswordError = "toast.updateUserPassword.error",
    CreateUserSuccessful = "toast.createUser.success",
    CreateUserError = "toast.createUser.error",
    DeleteUserSuccessful = "toast.deleteUser.success",
    DeleteUserError = "toast.deleteUser.error",
    UpdateWardSuccessful = "toast.updateWard.success",
    UpdateWardError = "toast.updateWard.error",
    CreateWardSuccessful = "toast.createWard.success",
    CreateWardError = "toast.createWard.error",
    DeleteWardSuccessful = "toast.deleteWard.success",
    DeleteWardError = "toast.deleteWard.error",
    UpdateResidentSuccessful = "toast.updateResident.success",
    UpdateResidentError = "toast.updateResident.error",
    CreateResidentSuccessful = "toast.createResident.success",
    CreateResidentError = "toast.createResident.error",
    DeleteResidentSuccessful = "toast.deleteResident.success",
    DeleteResidentError = "toast.deleteResident.error",
    UpdateNursingHomeSuccessful = "toast.updateNursingHome.success",
    UpdateNursingHomeError = "toast.updateNursingHome.error",
    NursingHomeTimeShiftError = "toast.nursingHome.timeShift.error",
    CreateNursingHomeSuccessful = "toast.createNursingHome.success",
    CreateNursingHomeError = "toast.createNursingHome.error",
    DeleteNursingHomeSuccessful = "toast.deleteNursingHome.success",
    DeleteNursingHomeExistingWardsError = "toast.deleteNursingHome.wards.error",
    DeleteNursingHomeError = "toast.deleteNursingHome.error",
    PageLoadingError = "toast.pageLoading.error",
    DataLoadingError = "toast.dataLoading.error",
    UpdateNursingHomeGroupSuccessful = "toast.updateNursingHomeGroup.success",
    UpdateNursingHomeGroupError = "toast.updateNursingHomeGroup.error",
    CreateNursingHomeGroupSuccessful = "toast.createNursingHomeGroup.success",
    CreateNursingHomeGroupError = "toast.createNursingHomeGroup.error",
    DeleteNursingHomeGroupSuccessful = "toast.deleteNursingHomeGroup.success",
    DeleteNursingHomeGroupRestrictionError = "toast.deleteNursingHomeGroup.restriction.error",
    DeleteNursingHomeGroupError = "toast.deleteNursingHomeGroup.error",
    NoNursingHomeGroupsError = "toast.noNursingHomeGroups.error",
    UpdateCountrySuccessful = "toast.updateCountry.success",
    UpdateCountryError = "toast.updateCountry.error",
    CreateCountrySuccessful = "toast.createCountry.success",
    CreateCountryError = "toast.createCountry.error",
    DeleteCountrySuccessful = "toast.deleteCountry.success",
    DeleteCountryError = "toast.deleteCountry.error",
    DeleteCountryErrorHasConnectedHomes = "countries.deleteCountryError.hasConnectedHomes",
}

export default ToastMessages;
