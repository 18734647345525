import ModalWrapper, { EModalSize } from "../../../common/modal/ModalWrapper";
import { localizer } from "../../../../utils/Localizer";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../../../common/table/Icon";
import CaregiverEditForm from "../caregivers/CaregiverEditForm";

interface IProps {
    user: any;
}

const CaregiversEditIcon = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <Icon icon={faPencilAlt} text={"caregivers.editButton"} qaId={props.user.name.toLowerCase() + "-edit_button"}/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<CaregiverEditForm entity={props.user} />}
            modalTitle={localizer("caregivers.edit.title", {
                name: props.user.name,
            })}
            size={EModalSize.S}
        />
    );
};

export default CaregiversEditIcon;
