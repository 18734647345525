import styled from "styled-components";

export const StyledCenter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCenterUsing = (justifyContent: string) => styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
`;

export const StyledRight = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledLeft = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    word-break: break-word;
`;
