import React, {Component} from "react";
import {Button, Form} from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface IConfirmDeleteFormProps {
    title: string;
    onConfirmCancel: (event: any) => void;
    onDeleteConfirm: (event: any) => void;
}

export default class ConfirmDeleteForm extends Component<IConfirmDeleteFormProps> {
    render(): React.ReactElement {
        return (
            <Form className="user-delete-confirm">
                <div className="confirm-delete-text">
                    <FormattedMessage id="confirmDeleteForm.areYouSure" values={{target: this.props.title}}/>
                </div>
                {/* double check which button group to use */}
                <div className="add-edit-button-group">
                    <Button
                        data-qa="cancel_delete_button"
                        variant={"outline-secondary"}
                        type="button"
                        className="discard-button"
                        onClick={this.props.onConfirmCancel}
                    >
                        <FormattedMessage id="confirmDeleteForm.cancel"/>
                    </Button>
                    <Button
                        data-qa="confirm_delete_button"
                        variant={"primary"}
                        type="button"
                        className="confirm-button"
                        onClick={this.props.onDeleteConfirm}
                    >
                        <FormattedMessage id="confirmDeleteForm.confirm"/>
                    </Button>
                </div>
            </Form>
        );
    }
}
