import React from "react";
import { StyledLeft } from "../../../components/common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../components/wrappers/TextOverflowWrapper";

class ResidentIcon extends React.Component<any> {
    render(): React.ReactElement {
        const { value } = this.props;
        const { hideTooltipOnProd } = this.props.column;

        return (
            <StyledLeft data-qa={value + "-table_item"}>
                <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                    {value}
                </TextOverflowWrapper>
            </StyledLeft>
        );
    }
}

export default ResidentIcon;
