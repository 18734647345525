import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StyledFormButtons } from "../styled";

interface IProps {
    title: string;
    onConfirmCancel: () => void;
    onDeleteConfirm: () => void;
}

const StyledMessageContainer = styled.div`
    margin-bottom: 5vh;
`;

const ConfirmDeleteForm = (props: IProps) => {
    return (
        <Form>
            <StyledMessageContainer className="confirm-delete-text">
                <FormattedMessage id="confirmDeleteForm.areYouSure" values={{ target: props.title }} />
            </StyledMessageContainer>
            <StyledFormButtons>
                <Button
                    data-qa="confirm_delete_button"
                    variant={"primary"}
                    type="button"
                    className="confirm-button"
                    onClick={props.onDeleteConfirm}
                >
                    <FormattedMessage id="confirmDeleteForm.confirm" />
                </Button>
                <Button
                    data-qa="cancel_delete_button"
                    variant={"outline-secondary"}
                    type="button"
                    className="discard-button"
                    onClick={props.onConfirmCancel}
                >
                    <FormattedMessage id="confirmDeleteForm.cancel" />
                </Button>
            </StyledFormButtons>
        </Form>
    );
}

export default ConfirmDeleteForm;