import React from "react";
import { FormattedMessage } from "react-intl";
import ReactJson from "react-json-view";
import styled from "styled-components";
import Colors from "../../../../../utils/Colors";
import ModalWrapper, {
    EModalSize
} from "../../../../common/modal/ModalWrapper";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers";

const StyledButton = styled.div`
    font-size: 16px;
    color: ${Colors.blue.brandingDark};
    cursor: pointer;
`;

const CellFormatter = (props: any) => {
    const jsonValue = React.useMemo(() => {
        let parsedValue = null;
        if (props.data.value) {
            try {
                parsedValue = JSON.parse(
                    `{"requestValue": ${props.data.value}}`
                );
            } catch (error) {
                try {
                    parsedValue = JSON.parse(props.data.value);
                } catch (error) {
                    console.log(error);
                    console.log(props.data.value);
                }
            }
        }

        return parsedValue;
    }, [props?.data?.value]);

    const triggerComponent: React.ReactNode = (
        <StyledButton>
            <FormattedMessage id="audits.tableFormatter.view" />
        </StyledButton>
    );
    return props.data.value ? (
        <StyledLeft>
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={
                    <ReactJson
                        src={jsonValue}
                        theme="summerfruit:inverted"
                        name={false}
                        displayDataTypes={false}
                    />
                }
                modalTitle={
                    <FormattedMessage id="audits.tableHeader.request"></FormattedMessage>
                }
                size={EModalSize.L}
            />
        </StyledLeft>
    ) : null;
};

const RequestFormatter = (data: any) => <CellFormatter data={data} />;
export default RequestFormatter;
