export default class ProductAbsLevel {
    id: number;
    name: string;
    changeThreshold: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.type;
        this.changeThreshold = json.changeThreshold ;
    }

    static sort(productAbsLevels: ProductAbsLevel[]): ProductAbsLevel[] {
        productAbsLevels.sort(((a, b) => {
            if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }));
        return productAbsLevels;
    }
}