import styled from "styled-components";
import Colors from "../../../utils/Colors";

export const StyledDescription = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: ${Colors.grey.medium};
`;

export const StyledAmount = styled.div`
    width: 100%;
    font-size: 40px;
    line-height: 33px;
    margin-top: auto;
    text-transform: lowercase;
    color: ${Colors.black};

    span {
        font-size: 24px;
    }
`;

export const StyledTitle = styled.span`
    font-size: 24px;
    line-height: 28px;
    color: ${Colors.black};
`;
