import { NursingHome } from "../models/NursingHome";
import React, { ReactNode } from "react";
import { ResidentEventType } from "../models/ResidentEventType";
import ResidentHistory from "../models/ResidentHistory";
import ResidentHistoryPager from "../models/ResidentHistoryPager";
import SocketClient, { DataType, Method } from "./SocketClient";
import { DateFilter } from "../models/DateFilter";
import { DateTimeUtils } from "../models/DateTimeUtils";
import { TimeFilter } from "../models/TimeFilter";
import store from "../store";
import { useDispatch } from "react-redux";
import {
    setSocketStatus,
    updateAverageResponseTimeFor30DaysInMinutesForActiveNursingHome,
} from "../reducers/nursingHomeSlice";
import { addUpdateWardInStore } from "../reducers/wardSlice";
import {
    addUpdateResidentInStore,
    deleteResidentInStore,
    updateResidentState,
} from "../reducers/residentSlice";
import { addUpdateCareiverDeviceInStore } from "../reducers/caregiverDeviceSlice";
import { RestClient } from "./RestClient";

interface INurseRepositoryProps {
    children: ReactNode;
}

export interface NursingHomeState {
    getResidentHistory: (
        residentId: string,
        eventType: ResidentEventType,
        dateFilter: DateFilter,
        startDate: Date,
        endDate: Date,
        timeFilter: TimeFilter,
        startTime: Date | null,
        endTime: Date | null,
        isScrolling: boolean
    ) => Promise<ResidentHistoryPager>;
}

export const NursingHomeContext = React.createContext<NursingHomeState>({
    // @ts-ignore
    getResidentHistory: () => new Promise(() => {}),
});

export const NurseRepository = (props: INurseRepositoryProps) => {
    let residentHistoryPager: ResidentHistoryPager | undefined;

    const dispatch = useDispatch();

    const onSocketPayload = (
        method: Method,
        dataType: DataType,
        payload: any
    ) => {
        const nursingHome =
            store.getState().nursingHomeSlice.nursingHome || new NursingHome();

        if (nursingHome) {
            switch (dataType) {
                case DataType.SocketStatus:
                    dispatch(setSocketStatus(payload));
                    break;
                case DataType.WardState:
                    dispatch(addUpdateWardInStore(payload.wardState));
                    dispatch(
                        updateAverageResponseTimeFor30DaysInMinutesForActiveNursingHome(
                            payload.nursingHomeAvgResponseTimeForLast30Days
                        )
                    );
                    break;
                case DataType.ResidentCurrentState:
                    if (method === Method.Update) {
                        dispatch(updateResidentState(payload));
                    }
                    break;
                case DataType.Resident:
                    if (method === Method.New || method === Method.Update) {
                        dispatch(addUpdateResidentInStore(payload));
                    } else if (method === Method.Delete) {
                        dispatch(deleteResidentInStore(payload.id?.toString()));
                    }
                    break;
                case DataType.CareGiver:
                    if (method === Method.Update) {
                        dispatch(addUpdateCareiverDeviceInStore(payload));
                    }
                    break;
            }
        }
    };

    const getResidentHistory = (
        residentId: string,
        eventType: ResidentEventType,
        dateFilter: DateFilter,
        selectedStartDate: Date,
        selectedEndDate: Date,
        timeFilter: TimeFilter,
        selectedStartTime: Date | null,
        selectedEndTime: Date | null,
        isScrolling: boolean
    ): Promise<ResidentHistoryPager> => {
        return new Promise((resolve, reject) => {
            if (
                !residentHistoryPager ||
                !residentHistoryPager.isSameHistory(
                    residentId,
                    eventType,
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate,
                    selectedStartTime,
                    selectedEndTime
                ) ||
                !isScrolling
            ) {
                residentHistoryPager = new ResidentHistoryPager(
                    eventType,
                    residentId,
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate,
                    selectedStartTime,
                    selectedEndTime
                );
            }
            const pager = residentHistoryPager!;
            if (!pager.hasMorePages() || pager.isLoading) {
                resolve(pager);
                return;
            }
            try {
                const nursingHome =
                    store.getState().nursingHomeSlice.nursingHome ||
                    new NursingHome();
                const { startDate, endDate } = DateTimeUtils.getDateRange(
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate
                );
                const { startTime, endTime } = DateTimeUtils.getTimeRange(
                    nursingHome,
                    timeFilter,
                    selectedStartTime,
                    selectedEndTime
                );
                pager.isLoading = true;
                RestClient.getHistoryLogs(
                    residentId,
                    startDate,
                    endDate,
                    undefined, // we are setting them as undefined because we want to retrieve all entries
                    undefined, // we are setting them as undefined because we want to retrieve all entries
                    eventType,
                    startTime,
                    endTime
                )
                    .then((response: any) => {
                        pager.logSize = response.count;
                        pager.averageResponseTime =
                            response.avgResponseTimeMinutes;
                        pager.changesPerResident = response.productChangesCount;
                        const rawHistoryLogs: any[] =
                            response.residentHistories;
                        const histories = rawHistoryLogs.map(
                            (value) => new ResidentHistory(value)
                        );
                        pager.pages.push(histories);
                        pager.isLoading = false;
                        resolve(pager);
                    })
                    .catch((err: any) => {
                        reject(err);
                    });
            } catch (err) {
                pager.logSize = 0;
                resolve(pager);
            }
        });
    };

    return (
        <NursingHomeContext.Provider
            value={{
                getResidentHistory,
            }}
        >
            {<SocketClient onSocketPayload={onSocketPayload} />}
            {props.children}
        </NursingHomeContext.Provider>
    );
};
