import NursingHomeGroup from '../../../models/NursingHomeGroup';
import ActionsContainer from '../../common/table/formatters/ActionsContainer';
import NursingHomeGroupEditIcon from './NursingHomeGroupEditIcon';

const NursingHomeGroupActions = (props: any) => {
    return (
        <ActionsContainer>
            <NursingHomeGroupEditIcon
                nursingHomeGroup={new NursingHomeGroup(props.row.original)}
            />
        </ActionsContainer>
    );
};

export default NursingHomeGroupActions;