import styled from "styled-components";
import { useCookies } from 'react-cookie';
import { languageCookieName, getLocale, localizations, convertLangCode, convertLocale } from "../utils/Localizer";
import CustomDropdown from './CustomDropdown';
import { IDropDownOption } from "../components/common/IDropDownOption";

const StyledLanguageSelector = styled.div`
    cursor: pointer;
    color: #E5E5E5;
    margin-top: 6px;
`;

function LanguageSelector(): React.ReactElement {
    const languages: IDropDownOption[] = [
        { value: 'en', label: 'English (UK)' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'sv', label: 'Svenska' },
        { value: 'da', label: 'Dansk' },
        { value: 'nl', label: 'Nederlands (NL)' },
        { value: 'de', label: 'Deutsch'},
        { value: 'fr', label: 'Français'},
        { value: 'it', label: 'Italiano'},
        { value: 'nl-BE', label: 'Nederlands (BE)'},
        { value: 'fr-BE', label: 'Français (BE)'},
        { value: 'nb', label: 'Norsk'},
        { value: 'cs', label: 'Čeština'},
        { value: 'sk', label: 'Slovák'},
    ];

    const existingLocalizations: string[] = Object.keys(localizations);

    const options = languages.filter(language => existingLocalizations.some((localization: string) => {
        return localization === convertLangCode(language.value);
    }));

    const [cookies, setCookie] = useCookies([languageCookieName]);

    const languageChange = ({ value }: { value: string }) => {
        document.documentElement.lang = convertLocale(value);
        setCookie(languageCookieName, value, { path: '/' });
        window.location.reload();
    };

    const selectedOption = options.find(option => option.value === getLocale(cookies[languageCookieName]));

    return (
        <StyledLanguageSelector >
            <CustomDropdown
                qaId="language_dropdown"
                selectedOption={selectedOption}
                handleChange={languageChange}
                options={options}
                placeholder={''}
                width={150}
                fontSize={12}
                maxHeight={500}
            />
        </StyledLanguageSelector>
    );
}

export default LanguageSelector;
