import { createSelector } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { StyledViewContainer } from "../../components/common/View";
import { LocalStorageKeys } from '../../data/Persistence';
import { Roles } from '../../models/Role';
import { Ward } from '../../models/Ward';
import { RootState } from '../../store';
import '../../styles/main.scss';
import ResidentsInformationBlocks from "./ResidentsInformationBlocks";
import ResidentsTable from './ResidentsTable';


const selectActiveWards = createSelector(
    (state: RootState) => state.wardSlice.wards,
    (wards) => wards.filter(ward => ward.isActive)
);


export default function ResidentsView(): React.ReactElement {
    const wards = useSelector<RootState, Ward[]>(selectActiveWards);

    const limitedAccess = useSelector<RootState, boolean>(state => state.contextSlice.role !== Roles.NHManager && state.contextSlice.role !== Roles.Support);

    const handleWardSelection = (_: string, selectedWardsId: any) => {
        const selectedWards = selectedWardsId.map((id: string) => wards.find(ward => ward.id === id));
        reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify(selectedWardsId));
        setSelectedWards(selectedWards);
    };

    const getDefaultWard = useCallback((): Ward[] | [] => {
        if (wards.length > 0) {
            const cachedSelectedResidentsWardId = reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId) ? JSON.parse(reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId)) : [];
            if (typeof cachedSelectedResidentsWardId === "object") {
                if (cachedSelectedResidentsWardId.length === 0) {
                    return wards;
                } else {
                    return cachedSelectedResidentsWardId.reduce((selectedResidentWards: Ward[], id: string) => {
                        const ward = wards.find(ward => ward.id === id);
                        if (ward) {
                            selectedResidentWards.push(ward);
                        }
                        return selectedResidentWards;
                    }, []);
                }
            } else {
                reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify([wards?.[0]?.id || ""]));
                return [wards?.[0]] || [];
            }
        }
        return [];
    }, [wards]);

    const [selectedWards, setSelectedWards] = useState<Ward[]>([]);

    useEffect(() => {
        setSelectedWards(getDefaultWard());
    }, [getDefaultWard, wards]);

    return (
        <StyledViewContainer>
            <ResidentsInformationBlocks
                handleWardSelection={handleWardSelection}
                selectedWards={selectedWards}
                wards={wards}
                limitedAccess={limitedAccess}
            />
            <ResidentsTable
                selectedWards={selectedWards}
                limitedAccess={limitedAccess}
            />

        </StyledViewContainer>
    );
}
