import { useSelector } from 'react-redux';
import { NursingHome } from '../../../models/NursingHome';
import { RootState } from '../../../store';
import ActionsContainer from '../../common/table/formatters/ActionsContainer';
import NursingHomeEditIcon from './NursingHomeEditIcon';

const NursingHomeActions = (props: any) => {
    const nursingHomes = useSelector<RootState, NursingHome[]>(state => state.nursingHomeSlice.nursingHomes);
    const nursingHome = nursingHomes.find(nursingHome => nursingHome.id === props.row.original.id);
    return (
        <ActionsContainer>
            {nursingHome
                ? <NursingHomeEditIcon
                    nursingHome={nursingHome}
                  />
                : null
            }
        </ActionsContainer>
    );
};

export default NursingHomeActions;
