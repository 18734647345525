import React, { useState } from "react";
import "./ResidentEditIcon.scss";
import ResidentEditForm from "./ResidentEditForm";
import { FormattedMessage } from "react-intl";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Resident } from "../../models/Resident";
import { Icon } from "../../components/common/table/Icon";
import ModalWrapper, {
    EModalSize,
} from "../../components/common/modal/ModalWrapper";

/**
 * Clicking this button should bring up a modal for
 *  resident editing for selected resident.
 *
 * @export
 * @class ResidentEditIcon
 * @extends {Component}
 */

interface IProps {
    resident: Resident;
}

const ResidentViewIcon = (props: IProps) => {
    const [modalSize, setModalSize] = useState<EModalSize>(EModalSize.M);

    const triggerComponent: React.ReactNode = (
        <Icon icon={faPencilAlt} text={"residents.editButton"} />
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={
                <ResidentEditForm
                    onModalSizeChange={setModalSize}
                    entity={props.resident}
                    blockDeletion={true}
                />
            }
            modalTitle={
                <FormattedMessage
                    id="residents.editForm.title"
                    values={{ resident: props.resident?.name }}
                ></FormattedMessage>
            }
            size={modalSize}
            fixedFooter={true}
        />
    );
};

export default ResidentViewIcon;
