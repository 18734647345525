import { SetupFields } from "./SetupView";
import { FormattedMessage } from 'react-intl';
import ErrorCodes from '../../models/ErrorCodes';
import { NursingHome } from "../../models/NursingHome";
import CheckBox from "../../components/common/controls/CheckBox";
import TextFieldNew from '../../components/common/controls/TextFieldNew';
import ValidatedFormGroupWrapper from '../../components/wrappers/ValidatedFormGroupWrapper';
import { localizer } from "../../utils/Localizer";
import styled from "styled-components";

interface IPreferencesProps {
    nursingHome: NursingHome;
    handlePreferenceChanges: (fieldName: string, value: any) => void;
    validationErrors: string[];
    readonly?: boolean;
}

const StyledGap = styled.div`
    margin-bottom: 16px;
`;

const Preferences = (props: IPreferencesProps) => {
    return (
        <>
            <h3 className="fields-box-title"><FormattedMessage id="setup.preferences.preferences" /></h3>
            <ValidatedFormGroupWrapper
                validationErrors={props.validationErrors}
                dependentErrorCodes={[ErrorCodes.EmptyNameError]}
                label={"setup.preferences.communityName"}
            >
                <TextFieldNew
                    data-qa="nh_name_input_text"
                    disabled={props.readonly}
                    fieldName={SetupFields.Name}
                    value={props.nursingHome.name}
                    onChange={props.handlePreferenceChanges}
                />
            </ValidatedFormGroupWrapper>
            <StyledGap>
                <CheckBox
                disabled={props.readonly}
                label={localizer("setup.preferences.isWeakAuthenticationAllowed.checkboxLabel")}
                value={props.nursingHome.isWeakAuthenticationAllowed}
                fieldName={SetupFields.IsWeakAuthenticationAllowed}
                onChange={props.handlePreferenceChanges}
            />
            </StyledGap>
            <CheckBox
                disabled={props.readonly}
                label={localizer("setup.preferences.is2FAActive.checkboxLabel")}
                value={props.nursingHome.is2FactorAuthenticationEnabled}
                fieldName={SetupFields.Is2FAActive}
                onChange={props.handlePreferenceChanges}
            />
        </>
    );
};

export default Preferences;
