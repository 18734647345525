import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NursingHome } from "../../../../models/NursingHome";
import {
    clearUserSlice,
    fetchUsers,
    IUserStateType,
    NursingHomeRole,
    ResourceTypes,
    setChildResourceId,
    setChildResourceType,
    UserRole
} from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import { localizer } from "../../../../utils/Localizer";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from "../../../common/View";
import UserAddInformationBlock from "../UserAddInformationBlock";
import UserFilterInformationBlock from "../UserCountryFilterInformationBlock";
import UsersTable from "../users/tables/UsersTable";
import UserAddButton from "../users/UserAddButton";

const NursingHomeGroupManagerUserView = () => {
    const dispatch = useDispatch();
    const nursingHomes = useSelector<RootState, NursingHome[]>(
        (state) => state.nursingHomeSlice.nursingHomes
    );
    const { childResourceId, users } = useSelector<RootState, IUserStateType>(
        (state) => state.userSlice
    );

    useEffect(() => {
        dispatch(setChildResourceType(ResourceTypes.NursingHome));
        dispatch(setChildResourceId(childResourceId && nursingHomes.some(nh => nh.id === childResourceId) ? childResourceId : nursingHomes[0]?.id));
    }, [nursingHomes]);

    useEffect(() => {
        if (childResourceId) {
            dispatch(fetchUsers());
        } else {
            dispatch(clearUserSlice());
        }
    }, [childResourceId]);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks alignLeft data-qa="nursing_home_group_manager_cards">
                <StyledInformationBlockWrapper data-qa="select_nursing_home_card">
                    <UserFilterInformationBlock
                        qaId="nursing_home_dropdown"
                        title={localizer(
                            "users.informationBlocks.title.nursingHome"
                        )}
                        name="nursingHomesFilter"
                        selectOption={(childResourceId) =>
                            dispatch(setChildResourceId(childResourceId))
                        }
                        selectedValue={childResourceId}
                        options={nursingHomes.map((nursingHome) => {
                            return {
                                label: nursingHome.name,
                                value: nursingHome.id,
                            };
                        })}
                    />
                </StyledInformationBlockWrapper>
                {childResourceId && (
                    <StyledInformationBlockWrapper data-qa="users_card">
                        <UserAddInformationBlock
                            addButton={
                                <UserAddButton
                                    titleBasedOnRole={UserRole.NHManager}
                                    assignedRole={NursingHomeRole.Manager}
                                />
                            }
                            amountOfUsers={users.length}
                        />
                    </StyledInformationBlockWrapper>
                )}
            </StyledInformationBlocks>

            <UsersTable data={users} />
        </StyledViewContainer>
    );
};

export default NursingHomeGroupManagerUserView;
