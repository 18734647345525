import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
    opacity: 1 !important;
    border-radius: 0 !important;
    min-width: 125px;
    max-width: 270px;
    padding: 15px !important;
    font-size: 14px !important;
    text-align: left;
    letter-spacing: 0.4px;
    word-break: break-word;
    font-weight: 400;
    line-height: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px !important;
`;

const Tooltip = () => (
    <StyledTooltip
        delayShow={500}
        type={'light'}
        effect={'solid'}
    />
);

export const TooltipRebuild = () => StyledTooltip.rebuild();

export default Tooltip;
// should be used with TextOverflowWrapper
// example:
    // <TextOverflowWrapper tooltip={value}>
    //     {value}
    // </TextOverflowWrapper>
