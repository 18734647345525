import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import ModalWrapper, { EModalSize } from "../../components/common/modal/ModalWrapper";
import { Icon } from '../../components/common/table/Icon';
import { Ward } from '../../models/Ward';
import AddEditWardForm from "./AddEditWardForm";

interface IEditWardButtonState {
    isModalDisplayed: boolean;
}

interface Props {
    ward: Ward;
}

export default class EditWardButton extends Component<Props, IEditWardButtonState> {
    render(): React.ReactElement {
        const triggerComponent: React.ReactNode = (
            <Icon
                icon={faPencilAlt}
                text={"setup.addEditButton"}
            />
        );

        return (
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={<AddEditWardForm
                    entity={this.props.ward}
                />}
                modalTitle={<FormattedMessage id="setup.editWardPopup.modalTitle"></FormattedMessage>}
                size={EModalSize.S}
            />
        );
    }
}
