import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledTextOverflowWrapper } from '../../wrappers/TextOverflowWrapper';
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import Colors from '../../../utils/Colors';

const StyledTitle = styled.div`
    margin-left: 37px;
    width: calc(100% - 74px);
    color: ${Colors.black};

    &:first-letter {
        text-transform: capitalize;
    }
`;

const StyledModal = styled(Modal) <any>`
    .modal-header {
        height: 54px;
    }
    .modal-content {
        width: ${props => props.width || '100%'};
        max-width: 100%;
        margin: 0 auto;
        max-height:  ${props => props.height}px;
    }
    .modal-body {
        padding: 26px 40px 35px 40px;
        overflow:auto;
    }
    .modal-footer {
        height: 80px;
        padding: 16px 40px 16px 40px;
    }
`;

const StyledIcon = styled(CloseIcon)`
    :hover{
        cursor: pointer;
        stroke: ${Colors.black};
    }
`;

interface IBasicModalProps {
    handleOnHide: () => void;
    title: JSX.Element | Element | string;
    children: ReactNode;
    show: boolean;
    width?: string;
    fixedFooter?: boolean;
}

const BasicModal = (props: IBasicModalProps) => {
    return (
        <StyledModal
            show={props.show}
            onHide={props.handleOnHide}
            backdrop={'static'}
            width={props.width}
            height={window.innerHeight-100}
            centered
        >
            <Modal.Header
                translate={"yes"}
            >
                <StyledTitle>
                    <StyledTextOverflowWrapper>
                        {props.title}
                    </StyledTextOverflowWrapper>
                </StyledTitle>
                <StyledIcon stroke={Colors.grey.medium} onClick={props.handleOnHide}/>
            </Modal.Header>
            {props.fixedFooter ?
                props.children : 
                (
                    <Modal.Body>
                        {props.children}
                    </Modal.Body>
                )
        }
        </StyledModal>
    );
};


export default BasicModal;
