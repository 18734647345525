import { useState } from "react";
import { Form, Row } from 'react-bootstrap';
import styled from "styled-components";
import ErrorCodes from "../../../../models/ErrorCodes";
import User, { UserRegexp } from "../../../../models/User";
import ValidatedFormGroupWrapper from '../../../wrappers/ValidatedFormGroupWrapper';
import AddEditButtonGroup from '../../../../views/Buttons/AddEditButtonGroup';
import TextField from '../../../common/controls/TextFieldNew';
import MultiSelectionFieldNew, { IOption } from "../../../common/controls/MultiSelectionFieldNew";
import { useDispatch, useSelector } from "react-redux";
import { Ward } from '../../../../models/Ward';
import { RootState } from '../../../../store';
import { fetchUsers, IUserStateType, NursingHomeRole } from "../../../../reducers/userSlice";
import { NursingHome } from "../../../../models/NursingHome";
import { localizer } from "../../../../utils/Localizer";
import { RestClient } from "../../../../data/RestClient";
import ToastMessages from "../../../../models/ToastMessages";
import { toastError, toastSuccess } from "../../../../utils/Toaster";

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    Name = 'Name',
    Password = 'Password',
    AssignedWards = 'AssignedWards',
}

export interface IProps {
    hideModal?: () => void;
}

const CaregiverAddForm = (props: IProps) => {
    const users = useSelector<RootState, User[]>(state => state.userSlice.users);
    const nursingHome = useSelector<RootState, NursingHome>(state => state.nursingHomeSlice.nursingHome || new NursingHome());
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);
    const { childResourceId, childResourceType } = useSelector<RootState, IUserStateType>(state => state.userSlice);

    const onDiscard = (event: any) => {
        event.preventDefault();

        if (props.hideModal) {
            props.hideModal();
        }
    };

    const dispatch = useDispatch();

    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [assignedWardsIds, setAssignedWardsIds] = useState<string[]>(wards.map(ward => (ward.id)));

    const [validationErrors, setErrors] = useState<string[]>([]);


    const getAssignedWards = (): IOption[] | undefined => {
        if (assignedWardsIds && assignedWardsIds.length > 0) {
            const assignedWards: IOption[] = [];
            assignedWardsIds.forEach(wardId => {
                const ward = wards.find((x: any) => x.id === String(wardId));
                if (ward) {
                    const option: IOption = { label: ward.name, value: ward.id };
                    assignedWards.push(option);
                }
            });
            return assignedWards;
        }
        return undefined;
    };

    const clearFormData = () => {
        setName('');
        setPassword('');
        setAssignedWardsIds([]);
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!name) {
            errors.push(ErrorCodes.FaultyNameErrorEmpty);
        }

        if (!nursingHome.isWeakAuthenticationAllowed && !UserRegexp.EmailRegexp.test(name.toLowerCase())) {
            errors.push(ErrorCodes.FaultyNameErrorEmail);
        }

        if (nursingHome.isWeakAuthenticationAllowed && (!password || password.length < 4 || password.length > 64)) {
            errors.push(ErrorCodes.FaultyPasswordWeak);
        }

        if (!nursingHome.isWeakAuthenticationAllowed && (!password || password.length < 1 || !UserRegexp.PasswordRegexp.test(password))) {
            errors.push(ErrorCodes.FaultyPasswordStrong);
        }

        if (users.some(user => user.name?.toLocaleLowerCase() === name?.toLocaleLowerCase())) {
            errors.push(ErrorCodes.UserAlreadyExists);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendUser = async (addMore: boolean) => {
        try {

            const requestBody: any = {
                userName: name.trim(),
                childResourceId,
                childResourceType,
                assignedRole: NursingHomeRole.Caregiver,
                caregiverPassword: password,
                caregiverAssignedWards: assignedWardsIds
            };
            await RestClient.grantAccess(requestBody);
            if (addMore) {
                clearFormData();
            } else if (props?.hideModal) {
                props.hideModal();
            }
            toastSuccess(localizer(ToastMessages.CreateUserSuccessful));
            dispatch(fetchUsers());
        } catch (error: any) {
            if(error.status === 409) {
                const errors = [ErrorCodes.UserNotAvailable];
                setErrors(errors);
            } else {
                toastError(localizer(ToastMessages.CreateUserError));
            }
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendUser(false);
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendUser(true);
    };


    const handleChange = (name: string, value: any) => {
        switch (name) {
            case Fields.Name:
                setName(value);
                break;
            case Fields.Password:
                setPassword(value);
                break;
            case Fields.AssignedWards:
                setAssignedWardsIds(value);
                break;
        }
    };

    return (
        <StyledFormContainer>
            <Form autoComplete="false">
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmail, ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists, ErrorCodes.UserNotAvailable]}
                        label={'users.editForm.nameEmail'}
                        multiErrorStack
                        fullWidthMessage
                    >
                        <TextField
                            data-qa="caregiver_name_input_text"
                            fieldName={Fields.Name}
                            disabled={false}
                            value={name}
                            onChange={handleChange}

                        />
                    </ValidatedFormGroupWrapper>
                </Row>

                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.FaultyPasswordWeak, ErrorCodes.FaultyPasswordStrong]}
                        label={'users.editForm.password'}
                        multiErrorStack
                        fullWidthMessage
                    >
                        <TextField
                            data-qa="caregiver_password_input_text"
                            fieldName={Fields.Password}
                            disabled={false}
                            value={password}
                            onChange={handleChange}
                            type='password'
                        />
                    </ValidatedFormGroupWrapper>
                </Row>

                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyAssignedWards]}
                        label={'users.editForm.assignedWards'}
                    >
                        <MultiSelectionFieldNew
                            name={Fields.AssignedWards}
                            options={wards?.map((ward) => ({ value: ward.id, label: ward.name })) || []}
                            selectedValue={getAssignedWards()}
                            handleChange={handleChange}
                            translations={{
                                allOptionsSelected: localizer("statistics.chart.allWards"),
                                multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                                noOptions: localizer("statistics.chart.noWards"),
                            }}
                            maxHeight={350}
                            portalingRequired={true}
                        />


                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0 mt-3">
                    <AddEditButtonGroup
                        isEdit={false}
                        onSave={onSave}
                        onSaveAndAddAnother={onSaveAndAddAnother}
                        onDiscard={onDiscard}
                        extraActions={[]} />
                </Row>
            </Form>
        </StyledFormContainer>
    );
};

export default CaregiverAddForm;
