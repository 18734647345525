import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import NursingHomeGroup from "../../../models/NursingHomeGroup";
import { RootState } from "../../../store";
import { StyledAmount, StyledDescription, StyledTitle } from "../../common/informationBlock/styled";
import { StyledInlineFlexStart } from "../../styled";
import NursingHomeGroupAddButton from "./NursingHomeGroupAddButton";
const NursingHomeGroupInformationBlock = () => {
    const nursingHomeGroups = useSelector<RootState, NursingHomeGroup[]>(state => state.nursingHomeGroupSlice.nursingHomeGroups);
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={'nursingHomeGroups.informationBlocks.title'} /></StyledTitle>
                <NursingHomeGroupAddButton />
            </StyledInlineFlexStart>
            <StyledDescription>
                <FormattedMessage id={'nursingHomeGroups.informationBlocks.totalNursingHomeGroupsProvisioned'} />
            </StyledDescription>
            <StyledAmount data-qa="nursing_home_groups_amount">
                {nursingHomeGroups.length}
            </StyledAmount>
        </>
    );
};

export default NursingHomeGroupInformationBlock;
