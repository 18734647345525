import { Slide, toast } from 'react-toastify';
import { localizer } from './Localizer';

export function toastSuccess(message: string): void {
    const content = (
        <div qa-id="qa-success-toast">
            {message}
        </div>);
    toast.success(
        content,
        {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            transition: Slide,
            style: {
                backgroundColor: "#005597",
            },
            bodyStyle: {
                backgroundColor: "#005597",
                textAlign: "center",
                color: "white"
            },
            hideProgressBar: true,
            closeButton: false,
            icon: false,
            pauseOnHover: true,
        });
}

export function toastError(message: string): void {
    const content = (
        <div qa-id="qa-error-toast">
            {message}
        </div>);
    toast.error(
        content,
        {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            transition: Slide,
            style: {
                backgroundColor: "#cc2b3e",
            },
            bodyStyle: {
                backgroundColor: "#cc2b3e",
                textAlign: "center",
                color: "white"
            },
            icon: false,
            hideProgressBar: true,
            closeButton: false
        });
}

export function toastLogoutInfo(message: string): void {
    const content = (
        <div qa-id="qa-logout-info-toast">
            {localizer(message)}
        </div>);
    toast.error(
        content,
        {
            toastId: "toastLogoutInfo",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            transition: Slide,
            style: {
                backgroundColor: "#005597",
            },
            bodyStyle: {
                backgroundColor: "#005597",
                textAlign: "center",
                color: "white"
            },
            icon: false,
            hideProgressBar: true,
            closeButton: true,
        });
}