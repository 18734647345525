import styled from "styled-components";
import { getChangeThresholdsShort } from "../../../../../reducers/residentHelper";
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';

const StyledWrapper = styled(StyledLeft)<any>`
    flex-direction: column;
    align-items: ${(props) => props.alignItems ? props.alignItems : 'start'};
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
   
    const getSelectedChangeThresholdForResident = (changeThreshold: Number): any => {
        const thresholds = getChangeThresholdsShort();
        const selectedThreshold = thresholds.find((threshold: any) => threshold.value === changeThreshold);
        return selectedThreshold?.label || '--';
    };

    return (
        <StyledWrapper alignItems={props.data.column.cellAlignItems}>
            {getSelectedChangeThresholdForResident(value)}
        </StyledWrapper>
    );
};

const ChangeThresholdFormatter = (data: any) => <CellFormatter data={data} />;
export default ChangeThresholdFormatter;
