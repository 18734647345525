export const UserRegexp = {
    // Regexp validates that the password that is at least 8 to 64 characters. It requires 3 out of 4 of lowercase, uppercase, numbers, or symbols..
    PasswordRegexp: new RegExp("^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,64}$"),
    // Regexp validates email address format
    EmailRegexp: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
};

export default class User {
    id: string;
    name: string;
    role: string;
    wardId: string;
    assignedNursingHomes: string[];
    lastAccess: Date | undefined;
    assignedWardsIds: string[];
    activeDirectoryAccount: boolean;
    isLocked: boolean;
    expiresAt?: string;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.name = json.userName ? json.userName : '';
        this.role = json.role ? json.role : '';
        this.wardId = json.wardId ? String(json.wardId) : '';
        this.lastAccess = json.lastAccess === "0001-01-01T00:00:00" ? null : json.lastAccess;
        this.expiresAt = json.expirationDateTime === "0001-01-01T00:00:00" ? null : json.expirationDateTime;
        this.activeDirectoryAccount = !!json.activeDirectoryAccount;
        this.isLocked = !!json.isLocked;

        if (json.assignedNursingHomes && Array.isArray(json.assignedNursingHomes)) {
            this.assignedNursingHomes = json.assignedNursingHomes as any[];
        } else {
            this.assignedNursingHomes = [];
        }

        if (json.assignedWards && Array.isArray(json.assignedWards)) {
            const arr = json.assignedWards as any[];
            this.assignedWardsIds = arr.map(t => String(t));
        } else {
            this.assignedWardsIds = [];
        }
    }
}
