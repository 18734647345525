import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { ResponseError } from "superagent";
import CheckBox from "../../components/common/controls/CheckBox";
import CustomToggle from "../../components/common/controls/Toggle";
import DropdownButtonNew from "../../components/common/controls/DropdownButtonNew";
import TextFieldNew from "../../components/common/controls/TextFieldNew";
import { EModalSize } from "../../components/common/modal/ModalWrapper";
import ValidatedFormGroupWrapper from "../../components/wrappers/ValidatedFormGroupWrapper";
import { RestClient } from "../../data/RestClient";
import { ReactComponent as DayIcon } from "../../images/day.svg";
import { ReactComponent as DayOnlyIcon } from "../../images/dayOnly.svg";
import { ReactComponent as NightIcon } from "../../images/night.svg";
import { ReactComponent as NightOnlyIcon } from "../../images/nightOnly.svg";
import { ReactComponent as SameAsNightIcon } from "../../images/sameAsNight.svg";
import { ReactComponent as StripIcon } from "../../images/stripIcon.svg";
import ErrorCodes from "../../models/ErrorCodes";
import { residentToReqBody } from "../../models/helpers/RequestHelpers";
import Market from "../../models/Market";
import { MarketCode } from "../../models/MarketCode";
import Prod from "../../models/Prod";
import ProductFamily from "../../models/ProductFamily";
import ProductSize from "../../models/ProductSize";
import { Resident } from "../../models/Resident";
import ToastMessages from "../../models/ToastMessages";
import { Ward } from "../../models/Ward";
import { getChangeThresholds, removeWhiteSpaces, validateResident } from "../../reducers/residentHelper";
import { RootState } from "../../store";
import Colors from "../../utils/Colors";
import { localizer } from "../../utils/Localizer";
import { toastError, toastSuccess } from "../../utils/Toaster";
import AddEditButtonGroup from "../../views/Buttons/AddEditButtonGroup";
import "../../views/Buttons/SnoozeButton.scss";
import ConfirmDeleteForm from "../../views/ConfirmDeleteForm";
import SensorStripIndicator from "./SensorStripIndicator";

const commonColStyling = css`
    border-radius: 4px;
    padding:0 20px 0 20px;
`;

const StyledEmptyCol = styled(Col)`
    ${commonColStyling}
    margin-right: 8px;
`;

const productChosingSectionCommonStyling = css`
    border-radius: 4px;
    background-color: ${Colors.white.hoverWhite};
    padding:0 20px 0 20px;
`;

const StyledLeftCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-right: 8px;
`;

const StyledRightCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-left: 8px;
`;

const StyledNightIcon = styled.div`
    height: 68px;
`;

const StyledRecommendationRow = styled(Row)`
    height: 104px;
    border-radius: 4px;
    background-color: ${Colors.white.hoverWhite};
    padding: 0 20px 0 20px;
`;

const StyledTitle = styled.h3`
    color: ${Colors.blue.brandingDark};
    font-size: 18px;
    padding: 0px;
`;

const StyledLabel = styled.label`
    margin-bottom: .5rem
`;

const StyledStripRightCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-left: 8px;
    height: 223px;
`;

const StyledStripLeftCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-right: 8px;
    height: 223px;
`;

const StyledToggle = styled(CustomToggle)`
    margin-left: 3vh;
`;

const StyledIconContainer = styled(Row)`
    margin-top: 30px;
`;

enum Fields {
    Name = "Name",
    NightTransmitterId = "NightTransmitterId",
    DayTransmitterId = "DayTransmitterId",
    Ward = "Ward",
    NightProductFamily = "NightProductFamily",
    NightProductName = "NightProductNamesas",
    NightProductSize = "NightProductSize",
    NightChangeThreshold = "NightChangeThreshold",
    DayProductFamily = "DayProductFamily",
    DayProductName = "DayProductName",
    DayProductSize = "DayProductSize",
    DayChangeThreshold = "DayChangeThreshold",
    Active = "Active",
    DayTimeSetup = "DayTimeSetup",
    NightTimeSetup = "NightTimeSetup",
    SeparateTransmitter = "SeparateTransmitter",
    SameAsNightTimeSetup = "SameAsNightTimeSetup",
}

enum EditStates {
    Edit,
    ConfirmDelete
}

interface IResidentEditFormProps {
    entity: Resident | undefined;
    hideModal?: () => void;
    blockDeletion?: boolean;
    onModalSizeChange?: (modalSize: EModalSize) => void;
    selectedWard?: Ward;
}

const ResidentEditForm = (props: IResidentEditFormProps) => {
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);
    const market = useSelector<RootState, Market>(state => state.marketSlice.markets[0] || new Market());
    const productFamilies = useSelector<RootState, ProductFamily[]>(state => state.marketSlice.productFamilies);
    const products = useSelector<RootState, Prod[]>(state => state.marketSlice.products);
    const residents = useSelector<RootState, Resident[]>(state => state.residentSlice.residents);

    const [entity, setEntity] = useState<Resident>(cloneDeep(props.entity) || new Resident({ wardId: props.selectedWard ? props.selectedWard.id : undefined }));
    const [sameAsNight, setSameAsNight] = useState(false);
    const [editState, setEditState] = useState<EditStates>(EditStates.Edit);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isFirstRenderDone, setIsFirstRenderDone] = useState<boolean>(false);

    const selectedWard = wards.find(ward => ward.id === entity.wardId);
    const isEdit = !!props.entity;

    useEffect(() => {
        const modalSize = editState === EditStates.Edit ? EModalSize.M : EModalSize.S;
        if (props?.onModalSizeChange) {
            props.onModalSizeChange(modalSize);
        }
    }, [editState]);

    const clearFormData = (): void => {
        setEntity(new Resident());
        setValidationErrors([]);
    };

    const onDiscard = (event: any) => {
        event.preventDefault();
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        errors.push(...validateResident(entity));

        const duplicateNameFound = residents.some(resident =>
            resident.wardId === entity.wardId
            && resident.name.toLowerCase() === entity.name.toLowerCase()
            && resident.id !== entity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.DuplicateNameError);
        }

        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendResident(false);
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setEditState(EditStates.Edit);

    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendResident(true);
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (entity.id) {
            setEditState(EditStates.ConfirmDelete);
        }
    };

    const onDeleteConfirm = (event: any) => {
        event.preventDefault();
        deleteResident();
    };

    const sendResident = (addMore: boolean) => {
        if (isEdit) {
            RestClient.updateResident(residentToReqBody(entity))
                .then(() => {
                    toastSuccess(localizer(ToastMessages.UpdateResidentSuccessful));
                    if (props?.hideModal) {
                        props.hideModal();
                    }
                })
                .catch((error: ResponseError) => {
                    handleBackendValidation(error);
                    // tslint:disable-next-line:no-console
                    console.error(error);
                });

        } else {
            RestClient.postResident(residentToReqBody(entity))
                .then(() => {
                    toastSuccess(localizer(ToastMessages.CreateResidentSuccessful));
                    if (addMore) {
                        clearFormData();
                    } else if (props?.hideModal) {
                        props.hideModal();
                    }
                })
                .catch((error: any) => {
                    handleBackendValidation(error);
                    // tslint:disable-next-line:no-console
                    console.error(error);
                });
        }
    };

    const handleBackendValidation = (error: any) => {
        if (error?.status === 409) {
            const errorObject = JSON.parse(error.response.text);
            const errors = [];
            if (entity.nightTransmitter.transmitterProductCode.length > 0
                && errorObject.transmitterCodes.includes(removeWhiteSpaces(entity.nightTransmitter.transmitterProductCode).substring(0, 12))) {
                errors.push(ErrorCodes.DuplicateTransmitterIdNight);

            }
            if (entity.dayTransmitter.transmitterProductCode.length > 0
                && errorObject.transmitterCodes.includes(removeWhiteSpaces(entity.dayTransmitter.transmitterProductCode).substring(0, 12))) {
                errors.push(ErrorCodes.DuplicateTransmitterIdDay);
            }
            setValidationErrors(errors);
        } else {
            toastError(localizer(ToastMessages.CreateResidentError));
        }
    };

    const deleteResident = () => {
        RestClient.deleteResident(entity.id)
            .then(() => {
                toastSuccess(localizer(ToastMessages.DeleteResidentSuccessful));
                if (props?.hideModal) {
                    props.hideModal();
                }
            })
            .catch((error: ResponseError) => {
                toastError(localizer(ToastMessages.DeleteResidentError));
                // tslint:disable-next-line:no-console
                console.error(error);
            });
    };

    const setDayTransmitterId = (resident: Resident) => {
        return areStripsLengthTheSame(resident)
            ? resident.nightTransmitter.transmitterProductCode
            : resident.dayTransmitter.transmitterProductCode;
    };

    const handleChange = (name: string, value: any) => {
        const resident = cloneDeep(entity);
        switch (name) {
            case Fields.Name:
                resident.name = value;
                break;
            case Fields.NightTransmitterId:
                resident.nightTransmitter.transmitterProductCode = value;
                if (resident.dayTimeSetup && !resident.separateTransmitter) {
                    resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                }
                break;
            case Fields.NightProductFamily:
                resident.nightTransmitter.productFamily = value;
                resident.nightTransmitter.productName = '';
                resident.nightTransmitter.productSize = '';
                resident.nightTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.NightProductName:
                resident.nightTransmitter.productName = value.name;
                resident.nightTransmitter.productPadType = value.padType;
                resident.nightTransmitter.productSize = '';
                resident.nightTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.NightProductSize:
                resident.nightTransmitter.productSize = value.name;
                resident.nightTransmitter.productId = value.id;
                resident.nightTransmitter.productThresholdCategory = value.thresholdCategory;

                if (resident.dayTransmitter.productId) {
                    resident.separateTransmitter = !areStripsLengthTheSame(resident);
                }

                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayTransmitterId:
                resident.dayTransmitter.transmitterProductCode = value;
                break;
            case Fields.DayProductFamily:
                resident.dayTransmitter.productFamily = value;
                resident.dayTransmitter.productName = '';
                resident.dayTransmitter.productSize = '';
                resident.dayTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayProductName:
                resident.dayTransmitter.productName = value.name;
                resident.dayTransmitter.productPadType = value.padType;
                resident.dayTransmitter.productSize = '';
                resident.dayTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayProductSize:
                resident.dayTransmitter.productSize = value.name;
                resident.dayTransmitter.productId = value.id;
                resident.dayTransmitter.productThresholdCategory = value.thresholdCategory;
                resident.separateTransmitter = !!resident.nightTransmitter.productId && !areStripsLengthTheSame(resident);
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.Ward:
                resident.wardId = value;
                break;
            case Fields.Active:
                resident.isActive = value;
                break;
            case Fields.NightTimeSetup:
                resident.nightTimeSetup = value;
                if (value === false) {
                    resident.nightTransmitter.clear();
                    resident.dayTransmitter.clear();
                    resident.dayTimeSetup = false;
                    resident.separateTransmitter = false;
                }
                break;
            case Fields.DayTimeSetup:
                resident.dayTimeSetup = value;
                if (value === false) {
                    resident.dayTransmitter.clear();
                    resident.separateTransmitter = false;
                }
                break;
            case Fields.SameAsNightTimeSetup:
                if (value) {
                    resident.dayTransmitter.copyFrom(resident.nightTransmitter);
                    resident.separateTransmitter = false;
                } else {
                    resident.dayTransmitter.clear();
                }
                break;
            case Fields.NightChangeThreshold:
                resident.nightTransmitter.changeThreshold = value;
                break;
            case Fields.DayChangeThreshold:
                resident.dayTransmitter.changeThreshold = value;
                break;
            case Fields.SeparateTransmitter:
                resident.separateTransmitter = value;

                resident.dayTransmitter.transmitterProductCode = !value && resident.dayTransmitter.productId
                    ? resident.nightTransmitter.transmitterProductCode
                    : "";
                break;
        }

        setEntity(resident);
    };

    useEffect(() => {
        setSameAsNight(areProductsTheSame(entity));
        const nightProductStripSize = getSelectedProductSizeForResident(entity, true)?.strip.toUpperCase();
        const dayProductStripSize = getSelectedProductSizeForResident(entity, false)?.strip.toUpperCase();
        if (
            entity.nightTimeSetup &&
            entity.dayTimeSetup &&
            residentHasValidSelectedSize(entity, true) &&
            residentHasValidSelectedSize(entity, false) &&
            nightProductStripSize !== dayProductStripSize &&
            !entity.separateTransmitter
        ) {
            handleChange(Fields.SeparateTransmitter, true);
        }
    }, [entity]);

    useEffect(() => {
        if (!isFirstRenderDone) {
            if (entity &&
                productFamilies &&
                products &&
                !!entity.nightTransmitter.productId &&
                !!entity.dayTransmitter.productId &&
                !entity.dayTransmitter.transmitterProductCode &&
                !entity.nightTransmitter.transmitterProductCode
            ) {
                const isSeparateTransmitter = isEdit ? entity.separateTransmitter : false;
                handleChange(Fields.SeparateTransmitter, isSeparateTransmitter);
                setIsFirstRenderDone(true);
            }
        }
    }, [entity, productFamilies, products]);

    const getProduct = (productFamily: string, productName: string) => {
        const productFamilyName = productFamilies.find(x => x.name === productFamily)?.name;
        return products.find((product: Prod) =>
            product.name === productName
            && product.productFamilyName === productFamilyName
        );
    };

    const getProducts = (productFamily: string) => {
        const productFamilyName = productFamilies.find(x => x.name === productFamily)?.name;
        return products.filter((product: Prod) =>
            product.productFamilyName === productFamilyName
        );
    };

    const residentHasValidSelectedBrief = (resident: Resident, nightTransmitter: boolean): boolean => {
        if (!residentHasValidSelectedProduct(resident, nightTransmitter)) {
            return false;
        }

        if (nightTransmitter) {
            return !!getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName);

        } else {
            return !!getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName);
        }
    };

    const getSelectedBriefForResident = (resident: Resident, nightTransmitter: boolean): Prod | undefined => {
        if (nightTransmitter) {
            return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName);
        }
        else {
            return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName);
        }
    };

    const getSelectedProductSizeForResident = (resident: Resident, nightTransmitter: boolean): ProductSize | undefined => {
        if (nightTransmitter) {
            return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName)?.ProductSizes
                .find((productSize: ProductSize) => productSize.name === resident.nightTransmitter.productSize);
        }
        else {
            return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName)?.ProductSizes
                .find((productSize: ProductSize) => productSize.name === resident.dayTransmitter.productSize);
        }
    };

    const residentHasValidSelectedSize = (resident: Resident, nightTransmitter: boolean): boolean => {
        if (!residentHasValidSelectedProduct(resident, nightTransmitter)) {
            return false;
        }
        if (!residentHasValidSelectedBrief(resident, nightTransmitter)) {
            return false;
        }

        if (nightTransmitter) {
            if (resident.nightTransmitter.productName) {
                return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName)?.ProductSizes
                    .some((productSize: ProductSize) => productSize.name === resident.nightTransmitter.productSize) || false;
            }
            return false;
        }
        else {
            if (resident.dayTransmitter.productName) {
                return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName)?.ProductSizes
                    .some((productSize: ProductSize) => productSize.name === resident.dayTransmitter.productSize) || false;
            }
            return false;
        }
    };

    const residentHasValidSelectedProduct = (resident: Resident, nightTransmitter: boolean): boolean => {
        if (nightTransmitter) {
            return productFamilies
                .some((productFamily: ProductFamily) => productFamily.name === resident.nightTransmitter.productFamily);
        }
        else {
            return productFamilies
                .some((productFamily: ProductFamily) => productFamily.name === resident.dayTransmitter.productFamily);
        }
    };

    const getSelectedProductForResident = (nightTransmitter: boolean): ProductFamily => {
        if (nightTransmitter) {
            return productFamilies
                .filter((productFamily: ProductFamily) => productFamily.name === entity.nightTransmitter.productFamily)[0];
        } else {
            return productFamilies
                .filter((productFamily: ProductFamily) => productFamily.name === entity.dayTransmitter.productFamily)[0];
        }
    };

    const getSelectedChangeThresholdForResident = (nightTransmitter: boolean): any => {
        const thresholds = getChangeThresholds();
        const thresholdValue = nightTransmitter ? entity.nightTransmitter.changeThreshold : entity.dayTransmitter.changeThreshold;
        const selectedThreshold = thresholds.find((threshold: any) => threshold.value === thresholdValue);
        return selectedThreshold;
    };

    const getProductTypeLabel = (): string => {
        if (market.code) {
            if (market.code === MarketCode.NorthAmerica) {
                return 'residents.editForm.productType';
            }
        }
        return 'residents.editForm.absLevel';
    };

    const areStripsLengthTheSame = (resident: Resident) => {
        return residentHasValidSelectedSize(resident, true) &&
            residentHasValidSelectedSize(resident, false) &&
            getSelectedProductSizeForResident(resident, true)?.strip.toUpperCase() ===
            getSelectedProductSizeForResident(resident, false)?.strip.toUpperCase();
    };

    const areProductsTheSame = (resident: Resident) => {
        return residentHasValidSelectedSize(resident, true) &&
            residentHasValidSelectedSize(resident, false) &&
            resident.dayTransmitter.productId === resident.nightTransmitter.productId;
    };

    const recommendationLabel = (resident: Resident) => {
        if (
            (resident.nightTimeSetup && residentHasValidSelectedSize(resident, true) && !resident.dayTimeSetup)
        ) {
            return (
                <>
                    <Row >
                        <Col md={9}>
                            <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                            <Col className={'mt-2'}>
                                {localizer("residents.editForm.recommendation.oneStrip", { stripSize: localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true)?.strip.toLowerCase()}`).toUpperCase() })}
                            </Col>
                        </Col >
                        <Col >
                            <StyledIconContainer >
                                <Col md={7} />
                                <Col md={3}>
                                    <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={2}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                            </StyledIconContainer>
                        </Col>
                    </Row>
                </>);
        } else if (
            resident.nightTimeSetup &&
            resident.dayTimeSetup &&
            residentHasValidSelectedSize(resident, true) &&
            residentHasValidSelectedSize(resident, false)
        ) {
            if (
                getSelectedProductSizeForResident(resident, true)?.strip.toUpperCase() ===
                getSelectedProductSizeForResident(resident, false)?.strip.toUpperCase()
            ) {
                return (
                    <>
                        <Row>
                            <Col md={9}>
                                <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                                <Col className="mt-2">
                                    {localizer("residents.editForm.recommendation.sameStrip", { stripSize: localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true)?.strip.toLowerCase()}`).toUpperCase() })}
                                </Col>
                            </Col >
                            <Col >
                                <StyledIconContainer>
                                    {!sameAsNight ?
                                        <>
                                            <Col md={4} />
                                            <Col md={3}>
                                                <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                            </Col>
                                            <Col md={3}>
                                                <DayOnlyIcon fill={Colors.blue.brandingDark} />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md={7} />
                                            <Col md={3}>
                                                <SameAsNightIcon fill={Colors.blue.brandingDark} />
                                            </Col></>
                                    }
                                    <Col md={2}>
                                        <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                    </Col>
                                </StyledIconContainer>
                            </Col>
                        </Row>
                    </>);
            }
            return (
                <>
                    <Row>
                        <Col>
                            <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                            <Col className="mt-2">
                                {localizer("residents.editForm.recommendation.twoStrips", { stripSize1: localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true)?.strip.toLowerCase()}`).toUpperCase(), stripSize2: localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, false)?.strip.toLowerCase()}`).toUpperCase() })}
                            </Col>
                        </Col>
                        <Col md={3}>
                            <StyledIconContainer>
                                <Col md={3} />
                                <Col md={3}>
                                    <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col >
                                <Col md={3}>
                                    <DayOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={1}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={1}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                            </StyledIconContainer>
                        </Col>
                    </Row>
                </>);
        }

        return (
            <Row>
                <Col>
                    <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                    <Col className="mt-2">
                        {localizer("residents.editForm.recommendation.choose")}
                    </Col>
                </Col>
            </Row>);
    };

    const StyledSpaceSmall = styled.div`
      margin: 1rem 0.5rem;
    `;

    const isDayTransmitterIdFieldDisabled = (resident: Resident) => {
        return !resident.dayTimeSetup || !resident.separateTransmitter || (!resident.separateTransmitter && areStripsLengthTheSame(resident));
    };

    const isSeparateTransmitterFieldDisabled = (resident: Resident) => {
        return !resident.dayTimeSetup || (resident.dayTimeSetup && !!resident.nightTransmitter.productId && !!resident.dayTransmitter.productId && !areStripsLengthTheSame(resident));
    };

    const isDayStripImageDisabled = (resident: Resident) => {
        return resident.dayTimeSetup &&
            !!resident.dayTransmitter.productId &&
            (!resident.nightTransmitter.productId || areStripsLengthTheSame(resident)) &&
            !resident.separateTransmitter;
    };

    const isNightStripImageDisabled = (resident: Resident) => {
        return resident.nightTimeSetup &&
            resident.dayTimeSetup &&
            !!resident.nightTransmitter.productId &&
            !resident.dayTransmitter.productId;
    };

    const isSameAsNightDisabled = (resident: Resident) => {
        return !resident.nightTimeSetup || !resident.dayTimeSetup || !resident.nightTransmitter.productId;
    };

    const addEditForm =
        <Form className="resident-edit">
            <Row className="gx-3 gy-0">
                <Col md="4">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyBedNumberError, ErrorCodes.DuplicateNameError]}
                        label={'residents.editForm.name'}
                    >
                        <TextFieldNew
                            data-qa="bed_number_input_text"
                            fieldName={Fields.Name}
                            value={entity.name}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Col>
                <Col md="4">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyWard, ErrorCodes.EmptyWardId]}
                        label={"residents.editForm.ward"}
                    >
                        <DropdownButtonNew
                            id="resident-edit-ward-name"
                            name={Fields.Ward}
                            options={
                                wards.filter(ward => ward.isActive).map((ward: Ward) => {
                                    return { value: ward.id, label: ward.name };
                                })
                            }
                            selectedValue={entity.wardId ? selectedWard?.id : props.selectedWard?.id}
                            handleChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Col>
                <Col md="4">
                    <div className="float-end">
                        <StyledLabel>
                            <FormattedMessage id={"residents.editForm.active"} />
                        </StyledLabel>
                        <StyledToggle
                            checked={entity.isActive}
                            icons={false}
                            onChange={() => handleChange(Fields.Active, !entity.isActive)}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="g-3">
                <StyledLeftCol className="g-3">
                    <Row className="mt-2 mb-3 g-2">
                        <Col md="auto">
                            <Row className="mb-2">
                                <CheckBox
                                    label={localizer("residents.editForm.nightTimeSetup")}
                                    value={entity.nightTimeSetup}
                                    fieldName={Fields.NightTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Col>
                        <Col>
                            <StyledNightIcon>
                                <NightIcon className="float-end align-top" fill={!entity.nightTimeSetup ? Colors.grey.mediumLight : Colors.blue.brandingDark} />

                            </StyledNightIcon>
                        </Col>
                    </Row>
                    <Row className="mb-1 g-2">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductNameNight]}
                            label={"residents.editForm.productName"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-family-night"
                                name={Fields.NightProductFamily}
                                disabled={!entity.nightTimeSetup}
                                options={
                                    productFamilies.map((productFamily: ProductFamily) => {
                                        return { value: productFamily.name, label: productFamily.name };
                                    })
                                }
                                selectedValue={
                                    entity.nightTransmitter.productFamily && residentHasValidSelectedProduct(entity, true)
                                        ? getSelectedProductForResident(true).name
                                        : null
                                }
                                handleChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">

                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductTypeNight]}
                            label={getProductTypeLabel()}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-name-night"
                                name={Fields.NightProductName}
                                disabled={!entity.nightTimeSetup || !entity.nightTransmitter.productFamily}
                                options={
                                    residentHasValidSelectedProduct(entity, true) ? getProducts(entity.nightTransmitter.productFamily)
                                        .map((product: Prod) => {
                                            return { value: JSON.stringify(product), label: product.name };
                                        }) : []
                                }
                                selectedValue={
                                    entity.nightTransmitter.productName && residentHasValidSelectedBrief(entity, true)
                                        ? JSON.stringify(getSelectedBriefForResident(entity, true))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductSizeNight]}
                            label={"residents.editForm.size"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-size-night"
                                name={Fields.NightProductSize}
                                disabled={!entity.nightTimeSetup || !entity.nightTransmitter.productName || !residentHasValidSelectedBrief}
                                options={
                                    entity.nightTransmitter.productName && residentHasValidSelectedBrief(entity, true) ? getProduct(entity.nightTransmitter.productFamily, entity.nightTransmitter.productName)
                                        ?.ProductSizes.map((productSize: ProductSize) => {
                                            return { value: JSON.stringify(productSize), label: productSize.name };
                                        }) || [] : []
                                }
                                selectedValue={
                                    entity.nightTransmitter.productSize && residentHasValidSelectedSize(entity, true)
                                        ? JSON.stringify(getSelectedProductSizeForResident(entity, true))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyChangeThresholdNight]}
                            label={"residents.editForm.changeThreshold.title"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-threshold-night"
                                name={Fields.NightChangeThreshold}
                                disabled={!entity.nightTimeSetup}
                                options={getChangeThresholds()}
                                selectedValue={
                                    entity.nightTransmitter.changeThreshold
                                        ? getSelectedChangeThresholdForResident(true)?.value
                                        : null
                                }
                                handleChange={(name, value) => handleChange(name, Number(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledLeftCol>
                <StyledRightCol className="g-3">
                    <Row className="mt-2 mb-3 g-2">
                        <Col md="auto">
                            <Row className="mb-2">
                                <CheckBox
                                    label={localizer("residents.editForm.dayTimeSetup")}
                                    value={entity.dayTimeSetup}
                                    disabled={!entity.nightTimeSetup}
                                    fieldName={Fields.DayTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                            <Row>
                                <CheckBox
                                    label={localizer("residents.editForm.sameAsNightSetup")}
                                    disabled={isSameAsNightDisabled(entity)}
                                    value={sameAsNight}
                                    fieldName={Fields.SameAsNightTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Col>
                        <Col>
                            <DayIcon className="float-end" fill={!entity.dayTimeSetup ? Colors.grey.mediumLight : Colors.blue.brandingDark} />
                        </Col>
                    </Row>
                    <Row className="mb-1 g-2">

                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductNameDay]}
                            label={"residents.editForm.productName"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-family-day"
                                name={Fields.DayProductFamily}
                                disabled={!entity.dayTimeSetup}
                                options={
                                    productFamilies.map((productFamily: ProductFamily) => {
                                        return { value: productFamily.name, label: productFamily.name };
                                    })
                                }
                                selectedValue={
                                    entity.dayTransmitter.productFamily && residentHasValidSelectedProduct(entity, false)
                                        ? getSelectedProductForResident(false).name
                                        : null
                                }
                                handleChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductTypeDay]}
                            label={getProductTypeLabel()}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-name-day"
                                name={Fields.DayProductName}
                                disabled={!entity.dayTimeSetup || !entity.dayTransmitter.productFamily}
                                options={
                                    entity.dayTransmitter.productFamily && residentHasValidSelectedProduct(entity, false) ? getProducts(entity.dayTransmitter.productFamily)
                                        .map((product: Prod) => {
                                            return { value: JSON.stringify(product), label: product.name };
                                        }) : []
                                }
                                selectedValue={
                                    entity.dayTransmitter.productName && residentHasValidSelectedBrief(entity, false)
                                        ? JSON.stringify(getSelectedBriefForResident(entity, false))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductSizeDay]}
                            label={"residents.editForm.size"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-size-day"
                                name={Fields.DayProductSize}
                                disabled={!entity.dayTimeSetup || !entity.dayTransmitter.productName}
                                options={
                                    entity.dayTransmitter.productName && residentHasValidSelectedBrief(entity, false) ? getProduct(entity.dayTransmitter.productFamily, entity.dayTransmitter.productName)?.ProductSizes.map((productSize: ProductSize) => {
                                        return { value: JSON.stringify(productSize), label: productSize.name };
                                    }) || [] : []
                                }
                                selectedValue={
                                    entity.dayTransmitter.productSize && residentHasValidSelectedSize(entity, false)
                                        ? JSON.stringify(getSelectedProductSizeForResident(entity, false))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyChangeThresholdDay]}
                            label={"residents.editForm.changeThreshold.title"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-threshold-day"
                                name={Fields.DayChangeThreshold}
                                disabled={!entity.dayTimeSetup}
                                options={getChangeThresholds()}
                                selectedValue={
                                    entity.dayTransmitter.changeThreshold
                                        ? getSelectedChangeThresholdForResident(false)?.value
                                        : null
                                }
                                handleChange={(name, value) => handleChange(name, Number(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledRightCol>
            </Row>
            <StyledRecommendationRow className="g-3 mt-3">
                {recommendationLabel(entity)}
            </StyledRecommendationRow>
            <Row className="g-3 mt-0">
                <StyledStripLeftCol className="g-3">
                    <Row className="mt-3 mb-3">
                        <SensorStripIndicator
                            icon={isDayTransmitterIdFieldDisabled(entity) ? undefined : <NightIcon height={24} width={24} fill={Colors.blue.brandingDark} />}
                            stripLength={residentHasValidSelectedSize(entity, true) ? getSelectedProductSizeForResident(entity, true)?.strip : undefined}
                            disabled={isNightStripImageDisabled(entity)}
                        />
                    </Row>
                    <Row className="gy-3">
                        <ValidatedFormGroupWrapper
                            multiErrorStack={true}
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.BaseTransmitterErrorNight,
                                ErrorCodes.DuplicateTransmitterIdNight,
                                ErrorCodes.NightTransmitterCodeLength,
                                ErrorCodes.InvalidNightTransmitterCode,
                                ErrorCodes.InvalidNightControlDigit,
                            ]}
                            label={"residents.editForm.transmitterId"}
                        >
                            <TextFieldNew
                                data-qa="night_transmitter_id_input_text"
                                disabled={!entity.nightTimeSetup}
                                fieldName={Fields.NightTransmitterId}
                                value={entity.nightTransmitter.transmitterProductCode}
                                onChange={handleChange}
                                format={"### ### ### ### ###"}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledStripLeftCol>
                {!isDayTransmitterIdFieldDisabled(entity) ?
                    <StyledStripRightCol className="g-3">
                        <Row className="mt-3 mb-3">
                            <SensorStripIndicator
                                icon={<DayIcon height={24} width={24} fill={Colors.blue.brandingDark} />}
                                stripLength={residentHasValidSelectedSize(entity, false) ? getSelectedProductSizeForResident(entity, false)?.strip : undefined}
                                disabled={isDayStripImageDisabled(entity)}
                            />
                        </Row>
                        <Row className="g-3">
                            <ValidatedFormGroupWrapper
                                multiErrorStack={true}
                                validationErrors={validationErrors}
                                dependentErrorCodes={[
                                    ErrorCodes.BaseTransmitterErrorDay,
                                    ErrorCodes.DuplicateTransmitterIdDay,
                                    ErrorCodes.DayTransmitterCodeLength,
                                    ErrorCodes.InvalidDayTransmitterCode,
                                    ErrorCodes.TransmitterIdAlreadyUsedInNightSetup,
                                    ErrorCodes.InvalidDayControlDigit,
                                ]}
                                label={"residents.editForm.transmitterId"}
                            >
                                <TextFieldNew
                                    data-qa="day_transmitter_id_input_text"
                                    disabled={isDayTransmitterIdFieldDisabled(entity)}
                                    fieldName={Fields.DayTransmitterId}
                                    value={entity.dayTransmitter.transmitterProductCode}
                                    onChange={handleChange}
                                    format={"### ### ### ### ###"}
                                />
                            </ValidatedFormGroupWrapper>
                        </Row>
                    </StyledStripRightCol>
                    :
                    <StyledEmptyCol />}
            </Row>
            <Row className="g-2 mt-3">
                <Col md="12">
                    <StyledSpaceSmall>
                        <CheckBox
                            label={localizer("residents.editForm.separateTransmitter")}
                            value={entity.separateTransmitter}
                            fieldName={Fields.SeparateTransmitter}
                            onChange={handleChange}
                            disabled={isSeparateTransmitterFieldDisabled(entity)}
                        />
                    </StyledSpaceSmall>
                </Col>
            </Row>
        </Form >;

    const confirmDeleteForm = (
        <Modal.Body>
            <ConfirmDeleteForm
                title={entity.name}
                onConfirmCancel={onConfirmCancel}
                onDeleteConfirm={onDeleteConfirm}
            />

        </Modal.Body>
    );

    return (
        <React.Fragment>
            {editState === EditStates.ConfirmDelete ? confirmDeleteForm :
                (
                    <React.Fragment>
                        <Modal.Body>
                            {addEditForm}
                        </Modal.Body>
                        < Modal.Footer >
                            <AddEditButtonGroup
                                isEdit={isEdit}
                                onSave={onSave}
                                onSaveAndAddAnother={onSaveAndAddAnother}
                                onDiscard={onDiscard}
                                extraActions={isEdit && !props.blockDeletion ? [
                                    {
                                        label: "residents.editForm.deleteLabel",
                                        action: onDelete
                                    }
                                ] : []} />
                        </Modal.Footer>
                    </React.Fragment>
                )
            }
        </React.Fragment >
    );
};

export default ResidentEditForm;
