import DropdownButtonNew, { IDropDownOption } from "../../common/controls/DropdownButtonNew";
import { StyledTitle } from "../../common/informationBlock/styled";

interface IUserFilterInformationBlockProps {    
    title: string;
    selectOption: (value: any) => void;
    options: IDropDownOption[];
    name: string;
    selectedValue: string | undefined;
    qaId?: string;
}

const UserFilterInformationBlock = (props: IUserFilterInformationBlockProps) => {
    return (
        <>
            <StyledTitle >{props.title}</StyledTitle>
            <DropdownButtonNew
                qaId={props.qaId}
                id={props.name}
                name={props.name}
                handleChange={(_name, value) => props.selectOption(value)}
                options={props.options}
                selectedValue={props.selectedValue}
                maxHeight={500}
            />
        </>
    );
};

export default UserFilterInformationBlock;
