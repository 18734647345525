import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { NursingHomeContext } from "../../../../data/NurseRepository";
import { DateFilter } from "../../../../models/DateFilter";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { ResidentEventType } from "../../../../models/ResidentEventType";
import ResidentHistory from "../../../../models/ResidentHistory";
import ResidentHistoryPager from "../../../../models/ResidentHistoryPager";
import { TimeFilter } from "../../../../models/TimeFilter";
import ToastMessages from "../../../../models/ToastMessages";
import ResidentHistoryGeneralInfo from "../../../../routes/Residents/ResidentHistoryGeneralInfo";
import { localizer } from "../../../../utils/Localizer";
import { toastError } from "../../../../utils/Toaster";
import ResidentHistoryTable from "./ResidentHistoryTable";

interface IResidentHistoryViewProps {
    residentId: string;
    residentName: string;
}

const ScrollTableWrapper = styled.div`
    height: 460px;
`;
const ResidentHistoryView = (props: IResidentHistoryViewProps) => {
    const context = useContext(NursingHomeContext);

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [residentHistory, setResidentHistory] = useState<ResidentHistory[]>(
        []
    );
    const [averageResponseTime, setAverageResponseTime] = useState<
        number | null
    >(null);
    const [changesPerResident, setChangesPerResident] = useState<number | null>(
        null
    );
    const [selectedEventType, setSelectedEventType] =
        useState<ResidentEventType>(ResidentEventType.All);
    const [selectedDateFilter, setSelectedDateFilter] = useState<DateFilter>(1);
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(
        moment().add(-6, "days").toDate()
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(
        moment().toDate()
    );
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilter>(
        DateTimeUtils.getTimeFilterOptions()[0].value
    );
    const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(
        null
    );
    const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);

    useEffect(() => {
        getHistory();
    }, [
        props.residentId,
        selectedEventType,
        selectedDateFilter,
        selectedTimeFilter,
        selectedStartDate,
        selectedEndDate,
        selectedStartTime,
        selectedEndTime,
    ]);

    const getHistory = (isScrolling: boolean = false) => {
        setIsFetching(true);
        context
            .getResidentHistory(
                props.residentId,
                selectedEventType,
                selectedDateFilter,
                selectedStartDate,
                selectedEndDate,
                selectedTimeFilter,
                selectedStartTime,
                selectedEndTime,
                isScrolling
            )
            .then((residentHistoryPager: ResidentHistoryPager) => {
                if (residentHistoryPager) {
                    const history = residentHistoryPager.getLogs();
                    setResidentHistory(history);
                    setChangesPerResident(
                        residentHistoryPager.changesPerResident
                    );
                    setAverageResponseTime(
                        residentHistoryPager.averageResponseTime
                    );
                }
                setIsFetching(false);
            })
            .catch((err) => {
                toastError(localizer(ToastMessages.DataLoadingError));
                // tslint:disable-next-line:no-console
                console.error(err);
                setIsFetching(false);
            });
    };

    const handleOptionsSelection = (name: string, value: any) => {
        if (name === "dateSelected") {
            setSelectedDateFilter(Number(value));
        }
        if (name === "eventType") {
            setSelectedEventType(value);
        }
        if (name === "timeSelected" &&
            value !== selectedTimeFilter) {
            setSelectedTimeFilter(value);
        }
    };

    const startDateChanged = (value: any) => {
        if (selectedDateFilter !== DateFilter.CustomRange) {
            setSelectedStartDate(value);
            setSelectedDateFilter(DateFilter.CustomRange);
        } else {
            setSelectedStartDate(value);
        }
    };

    const endDateChanged = (value: any) => {
        if (selectedDateFilter !== DateFilter.CustomRange) {
            setSelectedEndDate(value);
            setSelectedDateFilter(DateFilter.CustomRange);
        } else {
            setSelectedEndDate(value);
        }
    };

    const startTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedStartTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
        } else {
            setSelectedStartTime(value);
        }
    };

    const endTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedEndTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
        } else {
            setSelectedEndTime(value);
        }
    };

    return (
        <>
            <ResidentHistoryGeneralInfo
                averageResponseTime={averageResponseTime}
                changesPerResident={changesPerResident}
                handleOptionSelection={handleOptionsSelection}
                selectedEventType={selectedEventType}
                selectedDateFilter={Number(selectedDateFilter)}
                selectedTimeFilter={selectedTimeFilter}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                selectedStartTime={selectedStartTime}
                selectedEndTime={selectedEndTime}
                startDateChanged={startDateChanged}
                endDateChanged={endDateChanged}
                startTimeChanged={startTimeChanged}
                endTimeChanged={endTimeChanged}
            />
            <div className="row g-0">
                <ScrollTableWrapper
                    className="table-wrapper w-100"
                >
                    <ResidentHistoryTable data={residentHistory} />
                    {isFetching && (
                        <div className="table-load">
                            <Spinner
                                animation="border"
                                size="sm"
                                variant="primary"
                            />
                        </div>
                    )}
                </ScrollTableWrapper>
            </div>
        </>
    );
};

export default ResidentHistoryView;
