export enum MonitoringTimes {
    Both = "both",
    Day = "day",
    Night = "night",
}

export enum StatusChangeTriggers {
    System = "system",
    Button = "button",
    Web = "web",
    Mobile = "mobile"
}

export default class ResidentHistory {
    id: number;
    residentId: number;
    created: Date | undefined;
    transmitterError: string;
    bedStatus: string;
    isBatteryFlat: boolean;
    isProductChange: boolean;
    transmitterMonitoringTime: MonitoringTimes | null;
    statusChangeTrigger: StatusChangeTriggers;
    transmitterProductCode: string;
    isSeparateTransmitter: boolean | null;
    changeThreshold: number;

    constructor(json: any) {
        this.id = json.id;
        this.residentId = json.residentId;
        this.created = json.created;
        this.transmitterError = json.transmitterError;
        this.bedStatus = json.bedStatus;
        this.isBatteryFlat = json.isBatteryFlat;
        this.transmitterMonitoringTime = json.transmitterMonitoringTime || 0;
        this.statusChangeTrigger = json.statusChangeTrigger || null;
        this.transmitterProductCode = json.transmitterProductCode;
        this.isProductChange = json.isProductChange;
        this.isSeparateTransmitter = json.isSeparateTransmitter;
        this.changeThreshold = json.changeThreshold || 0;
    }
}
