import { useSelector } from "react-redux";
import { Roles } from "../../models/Role";
import { RootState } from "../../store";
import GlobalAdminAuditView from "../themed/audit/views/GlobalAdminAuditView";
import NursingHomeManagerAuditView from "../themed/audit/views/NursingHomeManagerAuditView";
import NursingHomeGroupManagerAuditView from "../themed/audit/views/NursingHomeGroupManagerAuditView";

const AuditView = () => {
    const role = useSelector<RootState, Roles>(
        (state) => state.contextSlice.role
    );
    switch (role) {
        case Roles.GlobalAdmin:
            return <GlobalAdminAuditView />;
        case Roles.NHGManager:
            return <NursingHomeGroupManagerAuditView />;
        case Roles.NHManager:
        case Roles.Support:
            return <NursingHomeManagerAuditView />;
        default:
            return null;
    }
};

export default AuditView;
