import GlobalAdminUserView from "../themed/users/views/GlobalAdminUserView";
import CountryAdminUserView from "../themed/users/views/CountryAdminUserView";
import NursingHomeManagerUserView from "../themed/users/views/NursingHomeManagerUserView";
import NursingHomeGroupManagerUserView from "../themed/users/views/NursingHomeGroupManagerUserView";
import NurseUserView from "../themed/users/views/NurseUserView";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Roles } from "../../models/Role";

const UsersView = () => {
    const role = useSelector<RootState, Roles>(state => state.contextSlice.role);
    switch (role) {
        case Roles.GlobalAdmin:
            return <GlobalAdminUserView />
        case Roles.CountryAdmin:
            return <CountryAdminUserView />
        case Roles.NHGManager:
            return <NursingHomeGroupManagerUserView />
        case Roles.NHManager:
        case Roles.Support:
            return <NursingHomeManagerUserView />
        case Roles.Nurse:
            return <NurseUserView />

        default:
            return null
    }
}

export default UsersView;
