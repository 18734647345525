import Moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ResidentEventType } from '../../../../../models/ResidentEventType';
import Colors from '../../../../../utils/Colors';
import { EMPTY_VALUE } from "../../../../../utils/Localizer";
import ChangeStatusIcon from '../../../../common/ChangeStatusIcon';
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';
import { countTimeDifference } from './helpers/TimeDifferenceBetweenTwoDates';

const StyledContainer = styled(StyledLeft)`
    display: block;
`;

const StyledImageContainer = styled.div`
    width: 36px;
`;

const StyledStatusContainer = styled.div`
    display: flex;
`;

const StyledSpan = styled.span<any>`
    margin-top: 2px;
    max-width: 140px;
    color: ${props => props.status === ResidentEventType.Error ? Colors.alert.red : 'inherit'};
`;

const StyledTimeSpan = styled.span<any>`
    margin-left: 36px;
    font-size: 14px;
`;

function calculateTimeInState(currentObject: any, nextObject: any) {
    const currentCreatedDate = currentObject.created;
    if (nextObject) {
        const nextCreatedDate = nextObject.created;
        return countTimeDifference(nextCreatedDate, currentCreatedDate);
    }

    const now = Moment().utc().toDate();
    return countTimeDifference(now, currentCreatedDate);
}

const secondsToMiliseconds = (amount: number) => amount * 1000;

const CellFormatter = (props: any) => {
    const [timeDifference, setTimeDifference] = useState(EMPTY_VALUE);
    const { data, row, value } = props.data;
    const currentRowIndex = data.findIndex((r: any) => r.id === row.original.id);
    
    useEffect(() => {
        const nextObject = currentRowIndex === 0 ? undefined : data[currentRowIndex - 1]
        setTimeDifference(calculateTimeInState(data[currentRowIndex], nextObject));
        const myInterval = setInterval(() => setTimeDifference(calculateTimeInState(data[currentRowIndex], nextObject)), secondsToMiliseconds(3));
        
        return () => clearInterval(myInterval);
    }, [currentRowIndex, data, props.value]);

return (
    <StyledContainer>
        <StyledStatusContainer>
            <StyledImageContainer>
                <ChangeStatusIcon
                    status={value}
                />
            </StyledImageContainer>
            <StyledSpan status={value}><FormattedMessage id={'resident.history.tableRow.status.' + value} /></StyledSpan>
        </StyledStatusContainer>
        <StyledTimeSpan>{timeDifference}</StyledTimeSpan>
    </StyledContainer>

);
};

const StatusFormatter = (data: any) => <CellFormatter data={data} />;
export default StatusFormatter;




