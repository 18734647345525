import React from 'react';
import { StyledCenter } from './PositionContainers';
import Colors from '../../../../utils/Colors';
import TimesCircleIcon from '../../../../images/TimesCircleIcon';
import CheckIcon from '../../../../images/CheckIcon';
class BooleanIconFormatter extends React.Component<any> {
  render(): React.ReactElement {
    const { value } = this.props;
    
    return (
      <StyledCenter>
        {value ? (<CheckIcon color={Colors.alert.green}/>) : (<TimesCircleIcon color={Colors.alert.grey}/>)}
      </StyledCenter>
    );
  }
}

export default BooleanIconFormatter;
