export const DefaultChangeThreshold = "residents.editForm.changeThreshold.standard";
export const NotifyEarlier = "residents.editForm.changeThreshold.notifyEarlier";
export const NotifyLater = "residents.editForm.changeThreshold.notifyLater";
export const NotifyEvenLater = "residents.editForm.changeThreshold.notifyEvenLater";
export const DefaultChangeThresholdShort = "residents.historyTable.changeThreshold.standardShort";
export const NotifyEarlierShort = "residents.historyTable.changeThreshold.notifyEarlierShort";
export const NotifyLaterShort = "residents.historyTable.changeThreshold.notifyLaterShort";
export const NotifyEvenLaterShort = "residents.historyTable.changeThreshold.notifyEvenLaterShort";

export const ChangeThreshold = {
    [NotifyEarlier]: 75,
    [DefaultChangeThreshold]: 100,
    [NotifyLater]: 125,
    [NotifyEvenLater]: 133
};

export const ChangeThresholdShort = {
    [NotifyEarlierShort]: 75,
    [DefaultChangeThresholdShort]: 100,
    [NotifyLaterShort]: 125,
    [NotifyEvenLaterShort]: 133
};
