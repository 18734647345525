import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Preferences from "./Preferences";
import DayNightTimes from "./DayNightTimes";
import Notifications from "./Notifications";
import { NursingHome } from "../../models/NursingHome";
import { validateNursingHome } from "../../reducers/nursingHomeHelpers";
import WardList from "./WardList";
import { FormattedMessage } from "react-intl";
import ErrorCodes from "../../models/ErrorCodes";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { updateNursingHome } from "../../reducers/nursingHomeSlice";
import cloneDeep from "lodash/cloneDeep";
import { nursingHomeToReqBody } from "../../models/helpers/RequestHelpers";
import styled from "styled-components";
import Colors from "../../utils/Colors";
import { localizer } from "../../utils/Localizer";
import CheckBox from "../../components/common/controls/CheckBox";

export const StyledNursingHomeSettingsContainer = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-flow: row wrap;

    h3 {
        color: ${Colors.black};
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }
`;

export const StyledNursingHomeSettingsColumn = styled.div`
    flex: 1;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
`;

const StyledButton = styled(Button)`
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    font-size: $tena-font-size-1;
    font-family: 'Asap', sans-serif;
`;

const StyledButtonsContainer = styled.div`
    margin-top: 2rem;
    float: right;
`;

const StyledBlock = styled.div`
    padding-top: 50px;
    width: 100%;
`;

export enum SetupFields {
    Name = "Name",
    MorningShiftStartTime = "MorningShiftStartTime",
    EveningShiftStartTime = "EveningShiftStartTime",
    ApplyByTime = "ApplyByTime",
    CaregiverNotificationIntervalDay = "CaregiverNotificationIntervalDay",
    CaregiverTargetResponseTimeDay = "CaregiverTargetResponseTimeDay",
    CaregiverNotificationInterval = "CaregiverNotificationInterval",
    CaregiverResponseTarget = "CaregiverResponseTarget",
    ReapplyHours = "ReapplyHours",
    IsWeakAuthenticationAllowed = "IsWeakAuthenticationAllowed",
    Is2FAActive = "Is2FAActive",
    MarketCode = "MarketCode",
    MorningShiftNotificationTime = "MorningShiftNotificationTime",
    NightShiftNotificationTime = "NightShiftNotificationTime",
    ShiftNotificationTimeDisabled = "ShiftNotificationTimeDisabled",
    EightHourNotification = "EightHourNotification"
}

const SetupView = (props: { readonly?: boolean }) => {
    const dispatch = useDispatch();
    const nursingHome = useSelector<RootState, NursingHome>(
        (state) => state.nursingHomeSlice.nursingHome || new NursingHome()
    );

    const [entity, setEntity] = useState<NursingHome>(
        cloneDeep(nursingHome) || new NursingHome()
    );
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    useEffect(() => {
        setEntity(cloneDeep(nursingHome));
        setValidationErrors([]);
    }, [nursingHome]);

    const resetFormData = (): void => {
        setEntity(cloneDeep(nursingHome));
    };

    const shouldClearNotify = (timeA: string, timeB: string) => {
        const [shiftHours, shiftMinutes] = timeA.split(":");
        const [notifyHours, notifyMinutes] = timeB.split(":");

        const shiftToMinutes = Number(shiftHours) * 60 + Number(shiftMinutes);
        const notifyToMinutes = Number(notifyHours) * 60 + Number(notifyMinutes);

        return shiftToMinutes > notifyToMinutes;
    };

    const handleChange = (name: string, value: string) => {
        const nursingHome = cloneDeep(entity);

        switch (name) {
            case SetupFields.Name:
                nursingHome.name = value;
                break;
            case SetupFields.MorningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.morningShiftNotificationTime)) {
                    nursingHome.morningShiftNotificationTime = "";
                }
                nursingHome.morningShiftStartTime = value;
                break;
            case SetupFields.EveningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.nightShiftNotificationTime)) {
                    nursingHome.nightShiftNotificationTime = "";
                }
                nursingHome.nightShiftStartTime = value;
                break;
            case SetupFields.CaregiverNotificationInterval:
                nursingHome.caregiverNotificationIntervalMinutes =
                    Number(value);
                break;
            case SetupFields.CaregiverNotificationIntervalDay:
                nursingHome.caregiverNotificationIntervalMinutesDay =
                    Number(value);
                break;
            case SetupFields.CaregiverResponseTarget:
                nursingHome.caregiverResponseTargetMinutes = Number(value);
                break;
            case SetupFields.CaregiverTargetResponseTimeDay:
                nursingHome.caregiverResponseTargetMinutesDay = Number(value);
                break;
            case SetupFields.IsWeakAuthenticationAllowed:
                nursingHome.isWeakAuthenticationAllowed = Boolean(value);
                break;
            case SetupFields.MarketCode:
                nursingHome.marketCode = value;
                break;
            case SetupFields.MorningShiftNotificationTime:
                nursingHome.morningShiftNotificationTime = value;
                break;
            case SetupFields.NightShiftNotificationTime:
                nursingHome.nightShiftNotificationTime = value;
                break;
            case SetupFields.ShiftNotificationTimeDisabled:
                nursingHome.shiftNotificationTimeDisabled = Boolean(value);
                break;
            case SetupFields.Is2FAActive:
                nursingHome.is2FactorAuthenticationEnabled = Boolean(value);
                break;
            case SetupFields.EightHourNotification:
                nursingHome.notifyOfSomeUrineStatusOverdue = !!Number(value);
                break;
        }
        setEntity(nursingHome);
    };
    const validate = async (): Promise<boolean> => {
        const errors: ErrorCodes[] = validateNursingHome(entity);
        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendNursingHome();
    };

    const sendNursingHome = async () => {
        const requestBody = nursingHomeToReqBody(entity);
        dispatch(updateNursingHome(requestBody, false));
    };

    return (
        <StyledNursingHomeSettingsContainer>
            <StyledNursingHomeSettingsColumn>
                <StyledBlock>
                    <Preferences
                        readonly={props.readonly}
                        nursingHome={entity}
                        handlePreferenceChanges={handleChange}
                        validationErrors={validationErrors}
                    />
                </StyledBlock>

                <StyledBlock>
                    <Notifications
                        readonly={props.readonly}
                        nursingHome={entity}
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                    />
                </StyledBlock>

                {validationErrors.length > 0 && (
                    <div>
                        <span className="error-message">
                            <FormattedMessage
                                id={ErrorCodes.ValidationErrorsExist}
                            />
                        </span>
                    </div>
                )}
            </StyledNursingHomeSettingsColumn>

            <StyledNursingHomeSettingsColumn>
                <StyledBlock>
                    <h3 className="fields-box-title">
                        <FormattedMessage id="setup.shiftStartTime" />
                    </h3>
                    <DayNightTimes
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                        readOnly={props.readonly}
                        dayValue={entity.morningShiftStartTime}
                        nightValue={entity.nightShiftStartTime}
                        dayTimeFieldName={SetupFields.MorningShiftStartTime}
                        nightTimeFiledName={SetupFields.EveningShiftStartTime}
                        dayError={ErrorCodes.EmptyMorningShiftStartTime}
                        nightError={ErrorCodes.EmptyNightShiftStartTime}
                        minuteStep={10}
                        disabled={props.readonly}
                    />
                    <br />
                    <p>
                        <FormattedMessage id="setup.notifyCaregiver.title" />
                    </p>

                    <DayNightTimes
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                        dayValue={entity.morningShiftNotificationTime}
                        nightValue={entity.nightShiftNotificationTime}
                        dayTimeFieldName={
                            SetupFields.MorningShiftNotificationTime
                        }
                        nightTimeFiledName={
                            SetupFields.NightShiftNotificationTime
                        }
                        dayError={ErrorCodes.EmptyMorningShiftStartNotificationTime}
                        nightError={ErrorCodes.EmptyNightShiftStartNotificationTime}
                        minuteStep={10}
                        dayMinTime={entity.morningShiftStartTime}
                        nightMinTime={entity.nightShiftStartTime}
                        readOnly={props.readonly}
                        disabled={props.readonly || entity.shiftNotificationTimeDisabled}
                    />
                    <CheckBox
                        label={localizer("setup.notifyCaregiver.disable")}
                        value={entity.shiftNotificationTimeDisabled}
                        fieldName={SetupFields.ShiftNotificationTimeDisabled}
                        onChange={handleChange}
                        disabled={props.readonly}
                    />
                </StyledBlock>

                <StyledBlock>
                    <WardList readonly={props.readonly} />
                </StyledBlock>
            </StyledNursingHomeSettingsColumn>
            {!props.readonly && (
                <StyledBlock>
                    <StyledButtonsContainer>
                        <StyledButton
                            variant={"outline-secondary"}
                            onClick={() => resetFormData()}
                        >
                            <FormattedMessage id="setup.setupView.cancel" />
                        </StyledButton>
                        <StyledButton
                            variant={"primary"}
                            onClick={onSubmit}
                        >
                            <FormattedMessage id="setup.setupView.saveChanges" />
                        </StyledButton>
                    </StyledButtonsContainer>
                </StyledBlock>
            )}
        </StyledNursingHomeSettingsContainer>
    );
};

export default SetupView;
