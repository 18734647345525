import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import NursingHomeGroupEditForm from "./NursingHomeGroupEditForm";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import { localizer } from "../../../utils/Localizer";
import NursingHomeGroup from "../../../models/NursingHomeGroup";
import { Icon } from "../../common/table/Icon";

interface IProps {
    nursingHomeGroup: NursingHomeGroup;
}

const NursingHomeGroupEditIcon = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <Icon 
            icon={faPencilAlt} 
            text={"nursingHomeGroups.editButton"}  
            qaId={props.nursingHomeGroup.name.toLowerCase() + "-edit_button"}/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={
                <NursingHomeGroupEditForm entity={props.nursingHomeGroup} />
            }
            modalTitle={localizer("nursingHomeGroups.editForm.title", {
                title: props.nursingHomeGroup.name,
            })}
            size={EModalSize.S}
        />
    );
};

export default NursingHomeGroupEditIcon;
