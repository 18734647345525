import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import useClickOutside from "../../../hooks/useClickOutside";
import Colors from "../../../utils/Colors";
import { localizer } from "../../../utils/Localizer";
import { StyledTextOverflowWrapper } from "../../wrappers/TextOverflowWrapper";
import CheckBox from "./CheckBox";
import { StyledDropdownItem } from "./DropdownButtonNew";


const StyledSelect = styled(Select) <any>`
    .react-select__menu {
        max-height: ${(props) => props.maxMenuHeight}px;
        overflow: auto;
    }
`;

const StyledMultiDropdownItem = styled(StyledDropdownItem)`
    cursor: pointer !important;

    &.active {
        background: ${Colors.blue.lighter};
        color: ${Colors.black}
    }

    label {
        display: block;
    }
    .form-check {
        margin-bottom: 0px;
    }
`;

const StyledNoOptionsLabel = styled.label`
    color: ${Colors.grey.medium};
    padding: 0.25rem 16px;
    margin-bottom: 0;
`;

const StyledPlaceholder = styled.span`
    color: ${Colors.grey.medium} !important;
`;

const StyledMenuContainer = styled.div<any>`
    height: 100%;
    max-height: ${(props) => props.maxMenuHeight}px;
`;

export interface IOption {
    value: any;
    label: string;
}

export interface IOptionExtended extends IOption {
    isChecked: boolean;
}

interface ITranslations {
    allOptionsSelected: string;
    multipleOptionsSelected: string;
    noOptions?: string;
}

interface IMultiValueContainerProps {
    name: string;
    options: IOption[];
    selectedValue: IOption[] | undefined;
    handleChange: (name: string, value: any) => void;
    translations: ITranslations;
    maxHeight: number;
    portalingRequired?: boolean;
    qaId?: string;
}

const ValueContainer = (props: any) => {
    return (
        <>
            {!props.hasValue
                ? <StyledPlaceholder>{localizer("dropDownFieldFormGroup.placeholder")}</StyledPlaceholder>
                : props.children[0].length === 1
                    ? <StyledTextOverflowWrapper>{props.children[0][0].props.data.label}</StyledTextOverflowWrapper>
                    : props.children[0].length === props.options.length
                        ? props.allOptionsSelected
                        : props.multipleOptionsSelected
            }
        </>
    );
};

const Control = (props: any) => {
    return (
        <div onClick={props.onClick}>
            <components.Control {...props} />
        </div>
    );
};

const MenuList = (props: any) => {
    const [options, setOptions] = useState(props.options);
    const menuListRef = useRef(null);

    useClickOutside(menuListRef, props.onClickOutside);

    const selected = props.getValue();
    const allAreChecked = selected.length === options.length;

    const isChecked = useCallback((option: IOption) => {
        return selected.some((selectedOpt: IOption) => selectedOpt.value === option.value);
    }, [selected]);

    useEffect(() => {
        const newOptions = options.map((option: IOption) =>
            ({ ...option, isChecked: isChecked(option) }));

        setOptions(newOptions);
    }, [props.children]);

    useEffect(() => {
        const extendedOptions = props.options.map((option: IOption) =>
            ({ ...option, isChecked: isChecked(option) }));

        const [unchecked, checked] = extendedOptions.reduce((acc: any, element: any) => {
            acc[Number(element.isChecked)].push(element);
            return acc;
        }, [[], []]);

        const sorting = (a: IOptionExtended, b: IOptionExtended) => {
            if (a.label.toLocaleLowerCase().trim() > b.label.toLocaleLowerCase().trim()) {
                return 1;
            }
            if (a.label.toLocaleLowerCase().trim() < b.label.toLocaleLowerCase().trim()) {
                return -1;
            }
            return 0;
        };

        setOptions([...checked.sort(sorting), ...unchecked.sort(sorting)]);
    }, []);

    return (
        <StyledMenuContainer maxMenuHeight={props.maxHeight} ref={menuListRef} >
            {options.length === 0 && (<StyledNoOptionsLabel>{props?.noOptions}</StyledNoOptionsLabel>)}

            {options.length === 1 && (
                options.map((option: IOptionExtended) =>
                    <Option
                        key={option.value}
                        {...props}
                        onCheck={(opt: IOption) => props.setValue([...selected, opt], "set-value")}
                        onUncheck={(opt: IOption) => props.setValue(selected?.filter((selectd: IOption) => opt.value !== selectd.value), "set-value")}
                        isChecked={option.isChecked}
                        option={option}
                    />
                )
            )}

            {options.length > 1 && (
                <>
                    <Option
                        key={0}
                        {...props}
                        onCheck={() => props.setValue([...props.options], "set-value")}
                        onUncheck={() => props.setValue([], "set-value")}
                        isChecked={allAreChecked}
                        option={{ label: "All wards", value: null }}
                    />
                    {options.map((option: IOptionExtended) =>
                        <Option
                            key={option.value}
                            {...props}
                            onCheck={(opt: IOption) => props.setValue([...selected, opt], "set-value")}
                            onUncheck={(opt: IOption) => props.setValue(selected?.filter((selectd: IOption) => opt.value !== selectd.value), "set-value")}
                            isChecked={option.isChecked}
                            option={option}
                        />
                    )}
                </>
            )}
        </StyledMenuContainer>
    );
};

const Option = (props: any) => {
    return (
        <StyledMultiDropdownItem
            active={props.isChecked}
            onClick={() => props.isChecked ? props.onUncheck(props.option) : props.onCheck(props.option)}
        >
            <StyledTextOverflowWrapper>
                <CheckBox
                    key={props.option.value + "-" + props.isChecked}
                    label={props.option.label}
                    value={props.isChecked}
                    fieldName={""}
                    onChange={() => null}
                    size='M'
                />
            </StyledTextOverflowWrapper>
        </StyledMultiDropdownItem>
    );
};

const MultiSelectionFieldNew = (props: IMultiValueContainerProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const selectRef = useRef(null);

    const handleOnClickOutside = () => {
        setIsMenuOpen(false);
    };

    const handleChange = (currentOptions: any) => {
        Array.isArray(currentOptions)
            ? props.handleChange(props.name, currentOptions.map(x => x.value as string))
            : props.handleChange(props.name, []);
    };

    const portaling = props.portalingRequired ? {
        menuPortalTarget: document.body,
    } : {};

    return (
        <StyledSelect
            ref={selectRef}
            closeMenuOnSelect={false}
            onChange={handleChange}
            components={{
                Control: (props: any) => <Control {...props} onClick={() => setIsMenuOpen(!isMenuOpen)} />,
                ValueContainer: (selectProps: any) => <ValueContainer {...selectProps} {...props.translations} />,
                MenuList: React.useCallback((selectProps) => <MenuList {...selectProps} onClickOutside={handleOnClickOutside} {...props.translations} />, [isMenuOpen]),
                IndicatorSeparator: null
            }}
            isMulti
            value={props.selectedValue}
            name={props.name}
            options={props.options}
            className="basic-multi-select dropdown"
            classNamePrefix="react-select"
            placeholder={localizer("dropDownFieldFormGroup.placeholder")}
            noOptionsMessage={() => localizer("dropDown.noOptions")}
            isClearable={false}
            menuIsOpen={isMenuOpen}
            menuShouldScrollIntoView={false}
            maxMenuHeight={props.maxHeight}
            styles={{
                menuPortal: (styles: any) => ({
                    ...styles,
                    zIndex: 1300,
                    maxHeight: props.maxHeight,
                })
            }}
            {...portaling}
        />
    );
};

export default MultiSelectionFieldNew;
