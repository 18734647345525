import React, { SVGProps } from "react";

function NightIcon(props: SVGProps<any>): React.ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9.37611 4.5276C9.58878 4.0691 9.1346 3.54854 8.71358 3.82816C6.39826 5.36591 4.875 7.97361 4.875 10.9342C4.875 15.6647 8.76087 19.5 13.5568 19.5C15.7788 19.5 17.8067 18.6755 19.3428 17.3209C19.7219 16.9866 19.337 16.4153 18.8396 16.505C18.332 16.5966 17.8091 16.6447 17.2758 16.6447C12.4798 16.6447 8.59395 12.8094 8.59395 8.0789C8.59395 6.81152 8.87438 5.6093 9.37611 4.5276Z"/>
        </svg>
    );
}

export default NightIcon;
