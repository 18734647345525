import { Component, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledTextOverflowWrapper } from '../../components/wrappers/TextOverflowWrapper';
import Colors from '../../utils/Colors';
import { ReactComponent as CloseIcon } from "../../images/close.svg";


import './BasicModal.scss';

const StyledTitle = styled.div`
    margin-left: 37px;
    width: calc(100% - 74px);
    color: ${Colors.black};
`;

interface Props {
    classHeader?: string;
    classBody?: string;
    handleOnHide: () => void;
    title: JSX.Element | Element | string;
    children: ReactNode;
    show: boolean;
    modalClass: string;
}

const StyledIcon = styled(CloseIcon)`
    :hover{
        cursor: pointer;
        stroke: ${Colors.black};
    }
`;

export default class BasicModal extends Component<Props, {}> {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleOnHide}
                className={this.props.modalClass}
                backdrop={'static'}
                centered
            >
                <Modal.Header
                    className={this.props.classHeader}
                    translate={"yes"}
                >
                    <StyledTitle>
                        <StyledTextOverflowWrapper>
                            {this.props.title}
                        </StyledTextOverflowWrapper>
                    </StyledTitle>
                    <StyledIcon stroke={Colors.grey.medium} onClick={this.props.handleOnHide}/>

                </Modal.Header>
                <Modal.Body
                    className={this.props.classBody}
                >
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
};
