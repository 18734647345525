import React, { SVGProps } from "react";

function DayNightIcon(props: SVGProps<any>): React.ReactElement {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.4923 12.144C13.748 13.6604 14.1085 13.7269 15.1136 12.8954C16.1187 12.0639 16.2575 10.5736 15.4238 9.56676C14.59 8.55989 13.0993 8.41771 12.0942 9.24919C11.0892 10.0807 11.2367 10.6276 12.4923 12.144Z" />
            <path fillRule="evenodd" d="M18.5799 17.2363C18.2418 17.5103 17.7461 17.4577 17.4727 17.1189L15.9877 15.2783C15.7143 14.9394 15.7667 14.4426 16.1048 14.1686C16.4429 13.8946 16.9386 13.9472 17.212 14.286L18.6971 16.1266C18.9705 16.4655 18.918 16.9623 18.5799 17.2363Z" />
            <path fillRule="evenodd" d="M21.4588 11.9312C21.4131 12.3645 21.0247 12.6786 20.5914 12.6326L18.2461 12.3839C17.8127 12.338 17.4986 11.9494 17.5443 11.5161C17.59 11.0827 17.9784 10.7687 18.4117 10.8146L20.757 11.0633C21.1904 11.1093 21.5046 11.4978 21.4588 11.9312Z" />
            <path fillRule="evenodd" d="M19.752 6.13939C20.0254 6.47824 19.973 6.97506 19.6349 7.24906L17.7984 8.73746C17.4603 9.01146 16.9646 8.9589 16.6912 8.62005C16.4178 8.2812 16.4703 7.78439 16.8084 7.51038L18.6449 6.02199C18.9829 5.74798 19.4787 5.80055 19.752 6.13939Z" />
            <path fillRule="evenodd" d="M14.0446 7.17774C13.6122 7.13189 13.2987 6.74419 13.3444 6.31179L13.5932 3.9544C13.6389 3.52199 14.0264 3.20863 14.4588 3.25448C14.8912 3.30033 15.2047 3.68803 15.159 4.12044L14.9102 6.47782C14.8645 6.91023 14.477 7.22359 14.0446 7.17774Z" />
            <path fillRule="evenodd" d="M11.1551 8.03317C10.817 8.30717 10.3213 8.25461 10.0479 7.91576L8.56287 6.07515C8.28948 5.73631 8.34192 5.23949 8.68001 4.96549C9.01811 4.69148 9.51381 4.74404 9.78721 5.08289L11.2723 6.9235C11.5457 7.26234 11.4932 7.75916 11.1551 8.03317Z" />
            <rect width="22.0505" height="1.25829" rx="0.629146" transform="matrix(0.626646 0.779304 -0.777934 0.628346 4.96875 4.52539)" />
            <path d="M5.98903 10.4816C6.16252 10.0069 5.69511 9.49797 5.29848 9.81111C3.87321 10.9363 2.95996 12.668 2.95996 14.6116C2.95996 18.0049 5.74109 20.7559 9.17357 20.7559C10.5818 20.7559 11.8812 20.2921 12.9236 19.5111C13.3281 19.2082 12.9567 18.6278 12.4538 18.6774C12.2502 18.6975 12.0438 18.7078 11.8352 18.7078C8.40273 18.7078 5.62162 15.9567 5.62162 12.5635C5.62162 11.8324 5.75143 11.1315 5.98903 10.4816Z" />
        </svg>
    );
}

export default DayNightIcon;
