import moment from "moment";
import DropdownDateTimeRangeButton, { IDropdownDateTimeRangeButtonProps } from './helpers/DropdownDateTimeRangeButton';

const DropdownDateTimePeriodRangeButton = (props: IDropdownDateTimeRangeButtonProps) => {
    const amountOfDays = (props.amountOfDays || 6) - 1;
    return (
        <DropdownDateTimeRangeButton
            {...props}
            endPickerPropertiesInitialMaxDate={moment()}
            amountOfDays={amountOfDays}
        />
    );
};

export default DropdownDateTimePeriodRangeButton;
