export class CaregiverDevice {
    isLoggedIn: boolean;
    id: number;
    selectedWards: string[];
    lastActivity: Date | undefined;

    constructor(json: any) {
        this.selectedWards = [];
        this.id = json.id;
        this.isLoggedIn = json.isLoggedIn;
        if (json.selectedWards && Array.isArray(json.selectedWards)) {
            json.selectedWards.map((id: number) => this.selectedWards.push(String(id)));
        }
        this.lastActivity = json.lastActivity;
    }
}
