import {MonitoringTime} from "./MonitoringTime";
import { CRC8 } from "../utils/CRC8";
import { ChangeThreshold, DefaultChangeThreshold } from "./ChangeThreshold";

export class Transmitter {
    productName: string;
    productFamily: string;
    productId: number;
    productSize: string;
    productThresholdCategory: number;
    productPadType: string;
    transmitterProductCode: string;
    monitoringTime: MonitoringTime;
    changeThreshold: number;

    constructor(json: any = {}) {
        this.productFamily = json.productFamily;
        this.productName = json.productName;
        this.productSize = json.productSize;
        this.productId = json.productId;
        this.productThresholdCategory = json.productThresholdCategory;
        this.productPadType = json.productPadType;
        this.transmitterProductCode = !json.transmitterProductCode  ? ''
            : json.transmitterProductCode + CRC8.computeChecksum(json.transmitterProductCode);
        this.monitoringTime = json.monitoringTime ;
        this.changeThreshold = json.changeThreshold || ChangeThreshold[DefaultChangeThreshold];
    }

    backToJson(monitoringTime?: MonitoringTime): any {
        this.monitoringTime = monitoringTime ? monitoringTime : this.monitoringTime;
        return {
            transmitterProductCode: this.transmitterProductCode.split(' ').join('').substring(0, 12),
            productName: this.productName,
            productFamily: this.productFamily,
            productSize: this.productSize,
            productId: this.productId,
            productThresholdCategory: this.productThresholdCategory,
            productPadType: this.productPadType,
            monitoringTime: this.monitoringTime,
            changeThreshold: this.changeThreshold,
        };
    }

    clear(): void {
        this.productFamily = "";
        this.productName = "";
        this.productSize = "";
        this.productId = 0;
        this.productThresholdCategory = -1;
        this.productPadType = "";
        this.transmitterProductCode = "";
        this.changeThreshold = 100;
    }

    copyFrom(transmitter: Transmitter) {
        this.productFamily = transmitter.productFamily;
        this.transmitterProductCode = transmitter.transmitterProductCode;
        this.productSize = transmitter.productSize;
        this.productName = transmitter.productName;
        this.productId = transmitter.productId;
        this.productThresholdCategory = transmitter.productThresholdCategory;
        this.productPadType = transmitter.productPadType;
        this.changeThreshold = transmitter.changeThreshold;
    }
}
