import { StyledLeft } from "./PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { getLocalizedCountry } from "../../../../utils/Localizer";

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    const localizeCountryName = getLocalizedCountry(value) || value;

    return (
        <StyledLeft data-qa={value.toLowerCase() +"-table_item"}>
            <TextOverflowWrapper tooltip={localizeCountryName} hideTooltipOnProd={hideTooltipOnProd}>
                {localizeCountryName}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const CountryNameFormatter = (data: any) => <CellFormatter data={data} />;
export default CountryNameFormatter;
