import { FormattedMessage } from "react-intl";
import { auditTimePeriodKey, auditTimePeriodStartKey, auditTimePeriodEndKey } from "../AuditKeys";
import { IAuditInformationBlockProps } from "../IAuditInformationBlockProps";
import DropdownDateTimePeriodRangeButton from "../../../common/controls/dateTime/DropdownDateTimePeriodRangeButton";
import { PickerType } from "../../../common/controls/dateTime/helpers/PickerType";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { DateFilter } from "../../../../models/DateFilter";
import { StyledTitle } from "../../../common/informationBlock/styled";

const aditTimeFrameOptions: DateFilter[] = [
    DateFilter.Today,
    DateFilter.Yesterday,
    DateFilter.Week,
];


const specificXAxisOffsetValue = 192;

const AuditTimeInformationBlock = (props: IAuditInformationBlockProps) => (
    <>
    <StyledTitle><FormattedMessage id={auditTimePeriodKey} /></StyledTitle>
        <DropdownDateTimePeriodRangeButton
            offsetValue={specificXAxisOffsetValue}
            qaId="time_period_dropdown"
            id={auditTimePeriodKey}
            name={auditTimePeriodKey}
            handleChange={(_name, value) => props.handleChange(parseInt(value), auditTimePeriodKey)}
            handleStartDateTimeChange={(value) => props.handleChange(value, auditTimePeriodStartKey)}
            handleEndDateTimeChange={(value) => props.handleChange(value, auditTimePeriodEndKey)}
            selectedValue={props.filters[auditTimePeriodKey]}
            options={DateTimeUtils.getDateFilterOptions(aditTimeFrameOptions)}
            start={props.filters[auditTimePeriodStartKey]}
            end={props.filters[auditTimePeriodEndKey]}
            amountOfDays={7}
            pickerType={PickerType.Date}
        />
    </>
);

export default AuditTimeInformationBlock;
