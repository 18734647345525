import {MarketCode} from "./MarketCode";

export default class NursingHomeGroup {
    id: string;
    name: string;
    marketCode?: MarketCode;
    assignedUsersIds: number[];
    amountOfAssignedUsers: number;
    customerId: string;
    nursingHomes: any[];
    amountOfNursingHomes: number;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.name = json.name;
        this.marketCode = json.marketCode;
        this.customerId = json.customerId;
        this.assignedUsersIds = json.assignedUsers || [];
        this.amountOfAssignedUsers = this.assignedUsersIds.length;
        this.nursingHomes = json.nursingHomes || [];
        this.amountOfNursingHomes = this.nursingHomes.length;
    }
}
