import React from 'react';
import { ITableProps } from '../ITableProps';
import { FormattedMessage } from 'react-intl';
import Table, { headerJustifyOptions } from '../../common/table/Table';
import NumberFormatter from '../../common/table/formatters/NumberFormatter';
import BooleanIconFormatter from '../../common/table/formatters/BooleanIconFormatter';
import NursingHomeActions from './NursingHomeActions';
import StringFormatter from '../../common/table/formatters/StringFormatter';

const NursingHomesTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="nursingHomes.tableHeader.name" />,
                accessor: 'name',
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: false,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="nursingHomes.tableHeader.customerId" />,
                accessor: 'customerId',
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="nursingHomes.tableHeader.isActive" />,
                accessor: 'isActive',
                Cell: BooleanIconFormatter,
                sortType: 'basic',
                headerAlignment: headerJustifyOptions.center,
                flexShrink: 1,
                filterDisabled: true,
            }, {
                Header: <FormattedMessage id="nursingHomes.tableHeader.systemStartTime" />,
                accessor: 'systemStartTime',
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexShrink: 1,
                hideTooltipOnProd: true,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="nursingHomes.tableHeader.users" />,
                accessor: 'amountOfAssignedUsers',
                Cell: NumberFormatter,
                headerAlignment: headerJustifyOptions.center,
                flexShrink: 1,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="general.tableHeader.actions" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: 'id',
                Cell: NursingHomeActions,
                disableSortBy: true,
                flexShrink: 1,
                filterDisabled: true,
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"nh_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: 'name',
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(NursingHomesTable);
