import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cx from "classnames";
import { useTable, useSortBy, useFlexLayout, useFilters } from "react-table";
import Colors from "../../../utils/Colors";
import FlipMove from "react-flip-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ITableProps } from "./ITableProps";
import Tooltip, { TooltipRebuild } from "../tooltip/Tooltip";

const StyledSortingIndicator = styled(FontAwesomeIcon)`
    margin-left: 9px;
`;

const Styles = styled.div<any>`
    max-width: calc(100vw - 50px);
    height: 100%;
    overflow-x: auto;

    .table {
        display: block;
        ${props=> props.fixedHeader ? `overflow: hidden;` : `overflow-x: hidden;`}
        height: calc(100% - 20px);
        .thead {
            ${props=> props.fixedHeader && props.scrollActive ? `width: 98%` : ``}
        }
        .tbody {
            ${props=> props.fixedHeader ? 
                `height:calc(100% - 72px);
                 overflow: auto;`:
            ``}
            border-top: 0px;
            .tr {
                word-break: break-all;
                min-height: 72px;

                :nth-child(odd) {
                    background: ${Colors.grey.light};
                }

                :nth-child(even) {
                    background: ${Colors.white.clearWhite};
                }

                :last-child:nth-child(even) {
                    border-bottom: 20px solid ${Colors.grey.light};
                }
            }
        }

        .tr {
            padding: 0px 20px;
        }

        .th {
            background: ${Colors.white.clearWhite};
            height: 60px;
            color: ${Colors.grey.medium};
            text-align: center;
            padding: 0px 10px;
        }

        .th,
        .td {
            margin: 0;
            padding: 0.5rem;

            width: 1%;

            &.collapse {
                width: 0.0000000001%;
            }
        }
        .filter-cell {
            width: 100%;
        }
    }
`;

export const headerJustifyOptions = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
};

const getParameter = (data: any, name: string, defaultValue: any): any => {
    const bodyCellParameterOrDefault = get(
        data,
        ["cell", "column", name],
        defaultValue
    );
    const parameterOfDefault = get(
        data,
        ["column", name],
        bodyCellParameterOrDefault
    );

    return parameterOfDefault;
};

const getStyles = (props: any, data: any) => {
    const justifyContent = getParameter(data, "leftAlign", false)
        ? undefined
        : data.column?.headerAlignment || headerJustifyOptions.center;
    const displayStyle = getParameter(data, "withoutFlex", false)
        ? undefined
        : "flex";
    return [
        {
            ...props,
            title: undefined, // removing 'Toggle SortBy' tooltip
        },
        {
            style: {
                justifyContent,
                alignItems: getParameter(data, "alignItems", "stretch"),
                display: displayStyle,
                flexGrow: getParameter(data, "flexGrow", 0),
                flexShrink: getParameter(data, "flexShrink", 1),
                flexBasis: getParameter(data, "flexBasis", "auto"),
                minWidth: getParameter(data, "minWidth", "auto"),
                maxWidth: getParameter(data, "maxWidth", "auto")
            },
        },
    ];
};

const Table = (props: ITableProps) => {
    const { qaId, columns, data, initialState, rowProps, fixedHeader, overflowCount} = props;
    const activeNursingHomeId = useSelector<RootState, string>(
        (state) => state.nursingHomeSlice.nursingHome?.id || ""
    );
    const [disableAllAnimations, setDisableAllAnimations] = useState(false);

    useEffect(() => {
        if (data.length < 100) {
            setDisableAllAnimations(true);
            setTimeout(() => {
                setDisableAllAnimations(false);
            }, 1000);
        } else {
            setDisableAllAnimations(true);
        }
        TooltipRebuild();
    }, [activeNursingHomeId, data]);

    const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            initialState,
            defaultColumn,
            autoResetSortBy: false,
        },
        useFilters,
        useSortBy,
        useFlexLayout
    );
 
    return (
        <>
            <Styles fixedHeader={fixedHeader} data-qa={qaId} scrollActive={overflowCount && overflowCount < rows.length}>
                <div {...getTableProps()} className="table">
                    <div className="thead">
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className="tr"
                            >
                                {headerGroup.headers.map((column: any) => { 
                                    return (
                                        <div
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(
                                                    getStyles
                                                )
                                            )}
                                            className="th"
                                        >
                                            {column.Header.props && column.render("Header")}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <StyledSortingIndicator
                                                            icon={faChevronDown}
                                                        />
                                                    ) : (
                                                        <StyledSortingIndicator
                                                            icon={faChevronUp}
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                            {!column.filterDisabled && (
                                                <span className="filter-cell">
                                                    {column.render("Filter")}
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="tbody">
                        <FlipMove disableAllAnimations={disableAllAnimations}>
                            {rows.map((row) => {
                                prepareRow(row);
                                const className = row.getRowProps(
                                    rowProps && rowProps(row)
                                )?.className;
                                return (
                                    <div
                                        {...row.getRowProps()}
                                        className={cx("tr", {
                                            [className as string]: className,
                                        })}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <div
                                                    {...cell.getCellProps(
                                                        getStyles
                                                    )}
                                                    className="td"
                                                >
                                                    {cell.render("Cell")}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </FlipMove>
                    </div>
                </div>
            </Styles>
            {data.length > 0 && <Tooltip />}
        </>
    );
};

export default Table;
