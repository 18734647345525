import { createSlice } from "@reduxjs/toolkit";
import { RestClient } from "../data/RestClient";
import { CaregiverDevice } from "../models/CaregiverDevice";

export interface ICareGiverStateType {
    caregiversDevices: CaregiverDevice[];
}

const initialState: ICareGiverStateType = {
    caregiversDevices: [],
};

export const caregiverDeviceSlice = createSlice({
    name: "careGiverDevice",
    initialState,
    reducers: {
        setCaregiversDevices: (state, action) => {
            state.caregiversDevices = action.payload;
        },

        addUpdateCareiverDeviceInStore: (state, action) => {
            const updatedCaregiverDevice = new CaregiverDevice(action.payload);

            const index = state.caregiversDevices.findIndex(
                (caregiverDevice) =>
                    caregiverDevice.id === updatedCaregiverDevice.id
            );

            if (index > -1) {
                if (updatedCaregiverDevice.isLoggedIn) {
                    state.caregiversDevices[index] = updatedCaregiverDevice;
                } else {
                    state.caregiversDevices.splice(index, 1);
                }
            } else {
                state.caregiversDevices.push(updatedCaregiverDevice);
            }
        },

        clearCareGiverSlice: () => initialState,
    },
});

export const {
    setCaregiversDevices,
    addUpdateCareiverDeviceInStore,
    clearCareGiverSlice,
} = caregiverDeviceSlice.actions;

export const deleteCaregiversDevicesInStore = (
    caregiverDeviceIds: number[]
) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            const state = getState().careGiverSlice as ICareGiverStateType;

            const updatedCaregiversDevices = state.caregiversDevices.filter(
                (caregiverDevice) =>
                    !caregiverDeviceIds.some(
                        (caregiverDeviceId) =>
                            caregiverDeviceId === caregiverDevice.id
                    )
            );
            dispatch(setCaregiversDevices(updatedCaregiversDevices));
            resolve(true);
        } catch (exception) {
            reject();
        }
    });
};

export const fetchCaregiversDevices = (
    nursingHomeId?: string | undefined
) => async (dispatch: any) => {
    const response = await RestClient.getCaregivers(nursingHomeId);
    const caregiversDevicesArr: CaregiverDevice[] = [];
    response.forEach((caregiverDevice: any) => {
        caregiversDevicesArr.push(new CaregiverDevice(caregiverDevice));
    });

    dispatch(setCaregiversDevices(caregiversDevicesArr));
};

export default caregiverDeviceSlice.reducer;
