import React from "react";
import { AuthRestClient } from "./RestClient";
import { PublicClientApplication } from "@azure/msal-browser";
import {
    handleLogout,
    msalAuthParams,
    msalConfig,
    browserLoginWithAdToken,
} from "./Auth";
import { useDispatch } from "react-redux";
import {
    fetchUserResources,
    setInactiveTimeInMinutesBeforeLogout,
} from "../reducers/contextSlice";

const AppRepository = (props: any) => {
    const dispatch = useDispatch();

    const loginWithAd = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const publicClientApplication = new PublicClientApplication(
                    msalConfig
                );
                const loginResponse = await publicClientApplication.loginPopup(
                    msalAuthParams
                );
                const sessionTimeoutMinutes = await browserLoginWithAdToken(
                    loginResponse.idToken
                );

                dispatch(
                    setInactiveTimeInMinutesBeforeLogout(sessionTimeoutMinutes)
                );
                dispatch(fetchUserResources());
                sessionStorage.removeItem("blockFetchUserResources");
                resolve();
            } catch (error: any) {
                if (error?.errorCode === "user_cancelled") {
                    resolve();
                    return;
                }
                reject(error);
            }
        });
    };

    const loginWithIdToken = (idToken: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const sessionTimeoutMinutes = await browserLoginWithAdToken(
                    idToken
                );

                dispatch(
                    setInactiveTimeInMinutesBeforeLogout(sessionTimeoutMinutes)
                );
                dispatch(fetchUserResources());
                sessionStorage.removeItem("blockFetchUserResources");
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    const loginB2C = async (
        username: string,
        password: string
    ): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await AuthRestClient.loginB2C(
                    username,
                    password
                );
                if (response.is2FactorAuthenticationEnabled) {
                    resolve(true);
                } else {
                    dispatch(
                        setInactiveTimeInMinutesBeforeLogout(
                            response.sessionTimeoutMinutes
                        )
                    );
                    dispatch(fetchUserResources());
                    sessionStorage.removeItem("blockFetchUserResources");
                    resolve(false);
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    const logout = async (message?: string | null) => {
        await handleLogout(message);
    };

    return (
        <AppContext.Provider
            value={{
                loginB2C,
                loginWithAd,
                loginWithIdToken,
                logout,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export const AppContext = React.createContext<IAppState>({
    loginB2C: () => new Promise(() => null),
    loginWithAd: () => new Promise(() => null),
    loginWithIdToken: (_idToken: string) => new Promise(() => null),
    logout: () => null,
});

export interface IAppState {
    loginB2C: (username: string, password: string) => Promise<boolean>;
    loginWithAd: () => Promise<void>;
    loginWithIdToken: (idToken: string) => Promise<void>;
    logout: (message?: string | null) => void;
}

export default AppRepository;
