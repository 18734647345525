import styled from "styled-components";
import {Button} from 'react-bootstrap';
import Colors from '../utils/Colors';

export const StyledAddButton = styled(Button)`
    position: relative;
    color: ${Colors.white.clearWhite};
    background-color: ${Colors.blue.brandingDark};
    margin-left: 8px;
    margin-bottom: 3px;
    padding: 10px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
`;

export const StyledInlineSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const StyledInlineFlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
