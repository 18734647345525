import React from "react";

// check if the contents is overflown
export const useIsOverflow = (ref: any, callback?: any) => {
    const [isOverflow, setIsOverflow] = React.useState(false);

    React.useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            const hasOverflow = current.scrollWidth > current.clientWidth;

            setIsOverflow(hasOverflow);

            if (callback) {
                callback(hasOverflow);
            }
        };

        if (current) {
            trigger();
        }
    }, [callback, ref]);

    return isOverflow;
};