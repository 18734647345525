import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../../../utils/Colors";

interface ICheckBoxNewProps {
    fieldName: string;
    value: boolean;
    onChange: (name: string, value: any) => void;
    disabled?: boolean;
    errorMessage?: string | undefined;
    label: string;
    size?: 'M' | 'L';
    qaId?: string;
}

const StyledCheckBox = styled(Form.Check)<any>`

    :hover {
        cursor: ${props => !!props.disabled ? "default" : "pointer"}
    }
    .form-check-input {
        height: ${(props: any) => props.size === 'M' ? '1em' : '1.5em'};
        width: ${(props: any) => props.size === 'M' ? '1em' : '1.5em'};
        float: none;
        background-color: ${(props: any) =>
            props.disabled ? Colors.grey.light : Colors.white.clearWhite};
        :disabled {
            opacity: 1;
            :checked {
                background-color: ${Colors.blue.darkDisabled};
                border-color: ${Colors.blue.darkDisabled};
            }
        }
        :checked {
            background-color: ${Colors.blue.brandingDark};
            border-color: ${Colors.blue.brandingDark};
        }
        :focus {
            box-shadow: none;
        }
        :hover {
            cursor: ${props => !!props.disabled ? "default" : "pointer"}
        }
    }
    .form-check-label{
        opacity: 1 !important;
        white-space: pre-line;
        display: inline-flex;
        margin-left: 10px;
        vertical-align: bottom;
        :hover {
            cursor: ${props => !!props.disabled ? "default" : "pointer"}
        }
    }
    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label{ // this identifier look like this due to a bug in react-bootstrap which doesn't disable the label
        color: ${Colors.grey.medium} !important; 
    }
`;
const StyledWrapper = styled.div`
    display: block;
    position: relative;
    label {
        cursor: pointer;
    }
`;

const CheckBox = (props: ICheckBoxNewProps) => {
    const onClick = () => {
        if (!props.disabled) {
            props.onChange(props.fieldName, !props.value);
        }
    };

    return (
        <StyledWrapper>
            <StyledCheckBox
                data-qa={props.qaId}
                name={props.fieldName}
                type="checkbox"
                id={`check-api-${props.fieldName}`}
                disabled={!!props.disabled}
                checked={props.value}
                label={props.label}
                onClick={onClick}
                size={props.size}
            >
                {props.errorMessage && (
                    <span className="error-message">
                        <FormattedMessage id={props.errorMessage} />
                    </span>
                )}
            </StyledCheckBox>
        </StyledWrapper>
    );
};

export default CheckBox;
