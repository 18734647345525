import { getLocalizedCountry, localizer } from "../utils/Localizer";
import CustomDropdown from "./CustomDropdown";
// import NursingHomeIcon from "../images/nursing-home-icon.svg";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    contextChange,
    IContextStateType,
    IUserResourcesHierarchy,
} from "../reducers/contextSlice";
import { Roles } from "../models/Role";
import { RootState } from "../store";
import { reactLocalStorage } from "reactjs-localstorage";
import { LocalStorageKeys } from "../data/Persistence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
    width: auto;
    display: flex;
    align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    margin-right: 6px;
`;

const UserResourceContextSelector = () => {
    const dispatch = useDispatch();
    const context = useSelector<RootState, IContextStateType>(
        (state) => state.contextSlice
    );

    const handleOptionSelect = ({ value: jsonValue }: { value: string }) => {
        const { id: value, role } = JSON.parse(jsonValue);
        reactLocalStorage.clear();
        reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
            value,
            role,
        });
        dispatch(contextChange(value, role));
    };

    const generateLabel = (option: IUserResourcesHierarchy) => {
        if (option.role === Roles.GlobalAdmin) {
            return localizer("users.roles.globalAdmin");
        } else {
            return getLocalizedCountry(option.name);
        }
    };

    const generateOptions = (
        option: IUserResourcesHierarchy,
        depth: number
    ): any => {
        const label = generateLabel(option);

        const item = {
            label,
            value: JSON.stringify({ role: option.role, id: option.id }),
            isDisabled: option.role == null,
            depth,
            role: option.role,
        };
        const children =
            option.children?.map((child) =>
                generateOptions(child, depth + 1)
            ) || [];

        return [item, children];
    };

    const options = context.userResourcesHierarchy
        .map((option: any) => generateOptions(option, 0))
        .flat(100);

    const activeOptionValue = JSON.stringify({
        role: context.role,
        id: context.id,
    });

    const activeItem = options.find(
        (option) => option.value === activeOptionValue
    );

    return context.userResourcesHierarchy.length > 0 ? (
        <StyledContainer>
            <StyledIcon icon={faSitemap} />
            <CustomDropdown
                qaId = "main_dropdown"
                selectedOption={activeItem}
                handleChange={handleOptionSelect}
                options={options}
                maxHeight={500}
                placeholder={localizer("dropDownFieldFormGroup.placeholder")}
            />
        </StyledContainer>
    ) : null;
};

export default UserResourceContextSelector;
