import { createSlice } from "@reduxjs/toolkit";
import User from "../models/User";
import { RestClient } from "../data/RestClient";
import { NursingHome } from "../models/NursingHome";
import ToastMessages from "../models/ToastMessages";
import { localizer } from "../utils/Localizer";
import { toastSuccess, toastError } from "../utils/Toaster";
import { handleLogout } from "../data/Auth";
import { addUserToNursingHome, removeUserFromNursingHome } from "./nursingHomeSlice";
import { addUserToNursingHomeGroup, removeUserFromNursingHomeGroup } from "./nursingHomeGroupSlice";
import { addUserToCountry, removeUserFromCountry } from "./countrySlice";

export enum ResourceTypes {
    Global = "Global",
    Country = "Country",
    NursingHomeGroup = "NursingHomeGroup",
    NursingHome = "NursingHome",
    Invalid = "Invalid",
}

export enum UserRole {
    GlobalAdmin = "GlobalAdmin",
    CountryAdmin = "CountryAdmin",
    NHGManager = "NHGManager",
    NHManager = "NHManager",
    Support = "Support",
    Nurse = "Nurse",
    Caregiver = "Caregiver",
}

export enum NursingHomeRole {
    Manager = "Manager",
    Support = "Support",
    Nurse = "Nurse",
    Caregiver = "Caregiver",
}

export interface IUserStateType {
    childResourceType: ResourceTypes;
    childResourceId: string;
    users: User[];
}

const initialState: IUserStateType = {
    childResourceType: ResourceTypes.Invalid,
    childResourceId: "",
    users: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setChildResourceType: (state, action) => {
            state.childResourceType = action.payload;
        },
        setChildResourceId: (state, action) => {
            state.childResourceId = action.payload;
        },
        clearUserSlice: () => initialState,
    },
});

export const {
    setUsers,
    setChildResourceType,
    setChildResourceId,
    clearUserSlice,
} = userSlice.actions;

export const updateUser = (requestBody: any) => (
    dispatch: any,
    getState: any
): Promise<User> => {
    return new Promise(async (resolve, reject) => {
        try {
            const nursingHome = getState().nursingHomeSlice
                .nursingHome as NursingHome;
            const response = await RestClient.putUser(
                requestBody,
                nursingHome.id
            );
            dispatch(fetchUsers());
            const updatedUser = new User(response);
            resolve(updatedUser);
        } catch (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
            reject(error);
        }
    });
};

export const assignWardsToCaregiver = (
    id: string,
    assignedWardsIds: string[]
) => async (dispatch: any): Promise<void> => {
    try {
        const requestBody = {
            id,
            assignedWards: assignedWardsIds,
        };

        await RestClient.assignWardsToCaregiver(requestBody);
        toastSuccess(localizer(ToastMessages.UpdateUserSuccessful));
        dispatch(fetchUsers());
    } catch (error: any) {
        if (error.showInToast) {
            toastError(error.text);
        } else {
            toastError(localizer(ToastMessages.UpdateUserError));
        }
        // tslint:disable-next-line:no-console
        console.error(error);
    }
};

export const grantAccess = (
    userName: string,
    assignedRole?: NursingHomeRole,
    assignedWardsIds?: string[],
    password?: string,
    isEssitySupport?: boolean,
    expirationDate?: string,
    shouldLogoutAfter?: boolean,
) => async (dispatch: any, getState: any): Promise<void> => {
    try {
        const { childResourceId, childResourceType } = getState().userSlice as IUserStateType;

        const requestBody: any = {
            userName: userName.trim(),
            childResourceId,
            childResourceType,
            isAdAccountForSupport: isEssitySupport,
        };

        if (assignedRole) {
            requestBody.assignedRole = assignedRole;
        }

        if (expirationDate) {
            requestBody.expirationDateTime = expirationDate;
        }

        if (password || assignedWardsIds) {
            requestBody.caregiverPassword = password;
            requestBody.caregiverAssignedWards = assignedWardsIds;
        }
        await RestClient.grantAccess(requestBody);

        if (shouldLogoutAfter) {
            await handleLogout();
        } else {
            toastSuccess(localizer(ToastMessages.CreateUserSuccessful));
            dispatch(fetchUsers());
        }
    } catch (error: any) {
        if (error.showInToast) {                
            toastError(error.text);
        } else {
            toastError(localizer(ToastMessages.CreateUserError));
        }
        // tslint:disable-next-line:no-console
        console.error(error);
    }
};

export const extendAccess = (
    userId: string,
    expirationDateTime: string,
) => async (dispatch: any, getState: any): Promise<void> => {
    try {
        const { childResourceId, childResourceType } = getState().userSlice as IUserStateType;

        const requestBody: any = {
            userId,
            childResourceId,
            childResourceType,
            expirationDateTime
        };

        await RestClient.extendAccess(requestBody);
        toastSuccess(localizer(ToastMessages.UpdateUserSuccessful));
        dispatch(fetchUsers());
    } catch (error: any) {
        if (error.showInToast) {
            toastError(error.text);
        } else {
            toastError(localizer(ToastMessages.UpdateUserError));
        }
        // tslint:disable-next-line:no-console
        console.error(error);
    }
};

export const revokeAccess = (userId: string) => async (
    dispatch: any,
    getState: any
): Promise<void> => {
    try {
        const { childResourceId, childResourceType } = getState()
            .userSlice as IUserStateType;
        const requestBody = {
            userId,
            childResourceId,
            childResourceType,
        };
        await RestClient.revokeAccess(requestBody);
        toastSuccess(localizer(ToastMessages.DeleteUserSuccessful));
        if(ResourceTypes.NursingHome === childResourceType){
            dispatch(removeUserFromNursingHome(childResourceId, userId, getState));
        } else if(ResourceTypes.NursingHomeGroup === childResourceType) {
            dispatch(removeUserFromNursingHomeGroup(childResourceId, userId, getState));
        } else if(ResourceTypes.Country === childResourceType) {
            dispatch(removeUserFromCountry(childResourceId, userId, getState));
        }
        dispatch(fetchUsers());
    } catch (error: any) {
        if (error.showInToast) {
            toastError(error.text);
        } else {
            toastError(localizer(ToastMessages.DeleteUserError));
        }
        // tslint:disable-next-line:no-console
        console.error(error);
    }
};

export const deleteUser = (id: string, nursingHomeId: string) => (
    dispatch: any,
    _getState: any
): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteUser(nursingHomeId, id);
            dispatch(fetchUsers());
            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchUsers = () => async (dispatch: any, getState: any) => {
    try {
        const { childResourceType, childResourceId } = getState().userSlice;
        if (childResourceType !== ResourceTypes.Invalid && childResourceId) {
            const usersResponse = await RestClient.getUsers(
                childResourceType,
                childResourceId
            );
            const users = usersResponse.map((user: any) => new User(user));
            dispatch(setUsers(users));
            if(ResourceTypes.NursingHome === childResourceType) {
                dispatch(addUserToNursingHome(childResourceId, users, getState));
            } else if(ResourceTypes.NursingHomeGroup === childResourceType) {
                dispatch(addUserToNursingHomeGroup(childResourceId, users, getState));
            } else if(ResourceTypes.Country === childResourceType) {
                dispatch(addUserToCountry(childResourceId, users, getState));
            }
        }
    } catch (exception) {
        // tslint:disable-next-line:no-console
        console.error(exception);
    }
};

export default userSlice.reducer;
