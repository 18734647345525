
import ModalWrapper, { EModalSize } from '../../../common/modal/ModalWrapper';
import { localizer } from '../../../../utils/Localizer';
import { StyledAddButton } from "../../../styled";
import UserAddForm from  "./UserAddForm";
import { NursingHomeRole, UserRole } from "../../../../reducers/userSlice";

interface IUserAddButtonProps {
    assignedRole?: NursingHomeRole;
    titleBasedOnRole?: UserRole;
}

const UserAddButton = (props: IUserAddButtonProps) => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" data-qa="add_user_button"/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={(
                <UserAddForm assignedRole={props.assignedRole}/>
            )}
            modalTitle={`${localizer(`users.addButton.${props.titleBasedOnRole}`)}`}
            size={EModalSize.S}
        />
    );
};

export default UserAddButton;
