import styled from "styled-components";

export const StyledFormButtons = styled.div`
    width: 100%;

    button {
        float: right;
        margin-left: 10px;
    }
`;
