import { useEffect }
    from "react";
import CareGiversTable from "../caregivers/tables/CareGiversTable";
import { useDispatch, useSelector } from "react-redux";
import User from "../../../../models/User";
import { IContextStateType } from "../../../../reducers/contextSlice";
import { fetchUsers, ResourceTypes, setChildResourceId, setChildResourceType } from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import { StyledViewContainer, StyledInformationBlocks, StyledInformationBlockWrapper } from "../../../common/View";
import UserAddInformationBlock from "../UserAddInformationBlock";
import CaregiverAddButton from "../caregivers/CaregiverAddButton";

const NurseUserView = () => {
    const dispatch = useDispatch();
    const context = useSelector<RootState, IContextStateType>(state => state.contextSlice);
    const users = useSelector<RootState, User[]>(state => state.userSlice.users).filter(user => user.role === 'Caregiver');

    useEffect(() => {
        dispatch(setChildResourceType(ResourceTypes.NursingHome));
        dispatch(setChildResourceId(context.id));
        dispatch(fetchUsers());
    }, [context]);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks
                alignLeft
            >
                <StyledInformationBlockWrapper data-qa="user_caregiver_card">
                    <UserAddInformationBlock
                        addButton={<CaregiverAddButton />}
                        amountOfUsers={users.length}
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks>
            <CareGiversTable
                data={users}
            />
        </StyledViewContainer>
    );
};

export default NurseUserView;
