import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ce from "../../images/ce.svg";
import factory from "../../images/factory.svg";
import instruction from "../../images/instruction.svg";
import md from "../../images/md.svg";
import warning from "../../images/warning.svg";

const StyledAboutSection = styled.div`
    white-space: pre-line;
`;

const StyledLeftColumnParagpraph = styled.p`
    float: left;
    width: 10%;
    font-size: 12px;
`;

const StyledRightColumnParagpraph = styled.p`
    float: left;
    width: 90%;
    font-size: 12px;
`;

const SpacedImg = styled.img`
    margin-left: 20px;
`;

const AboutBlock = () => (
    <StyledAboutSection>
        <br/>
        <p>
            <FormattedMessage id="help.about.title" />
        </p>
        <p>
            <StyledLeftColumnParagpraph>
                <img src={factory} alt="factory" />
            </StyledLeftColumnParagpraph>
            <StyledRightColumnParagpraph>
                <p>
                    <FormattedMessage id="help.about.address" />
                </p>
                <p>
                    <FormattedMessage id="help.about.visitorAddress" />
                </p>
            </StyledRightColumnParagpraph>
        </p>
        <p>
            <StyledLeftColumnParagpraph>
                <img src={warning} alt="warning" />
            </StyledLeftColumnParagpraph>
            <StyledRightColumnParagpraph>
                <FormattedMessage id="help.about.warning" />
            </StyledRightColumnParagpraph>
        </p>
        <br />
        <p>
            <StyledLeftColumnParagpraph>
                <FormattedMessage id="help.about.version" />
            </StyledLeftColumnParagpraph>
            <StyledRightColumnParagpraph>
                {process.env.REACT_APP_VERSION || "debug"}
            </StyledRightColumnParagpraph>
        </p>
        <p>
            <img src={ce} alt="CE"/>
            <SpacedImg src={md} />
            <SpacedImg src={instruction} />
        </p>
    </StyledAboutSection>
);

export default AboutBlock;
