import ActionsContainer from "../../../common/table/formatters/ActionsContainer";
import CommonUserDeleteIcon from "../common/CommonUserDeleteIcon";
import SupportExtendIcon from "./SupportExtendIcon";

const SupportActions = (props: any) => {
    return (
        <ActionsContainer>
            <SupportExtendIcon user={props.row.original} />
            <CommonUserDeleteIcon user={props.row.original} tooltip={true}/>
        </ActionsContainer>
    );
};

export default SupportActions;
