import { useSelector } from "react-redux";
import { NursingHome } from "../../models/NursingHome";
import { RootState } from "../../store";
import { convertMinutesToHours, convertTimeToHtml } from "../../utils/ConvertMinutesToHours";
import { localizer } from "../../utils/Localizer";
import HomeTable from "../themed/home/HomeTable";
import NumberInformationBlock from '../common/informationBlock/NumberInformationBlock';
import { StyledViewContainer, StyledInformationBlocks, StyledInformationBlockWrapper } from "../common/View";
import { CaregiverDevice } from "../../models/CaregiverDevice";
import { Resident } from "../../models/Resident";
import { Ward } from "../../models/Ward";

const HomeView = () => {
    const nursingHome = useSelector<RootState, NursingHome>(state => state.nursingHomeSlice.nursingHome || new NursingHome());
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);
    const loggedInCareGiversCount = useSelector<RootState, number>(state => state.caregiverDeviceSlice.caregiversDevices.filter(careGiver => careGiver.isLoggedIn).length);
    const careGivers = useSelector<RootState, CaregiverDevice[]>(state => state.caregiverDeviceSlice.caregiversDevices);
    const residents = useSelector<RootState, Resident[]>(state => state.residentSlice.residents);

    const rows = wards.map(ward => {
        return {
            ward,
            residents: residents.filter(resident => resident.wardId === ward.id),
            careGivers: careGivers.filter(careGiver => careGiver.selectedWards.some(selectedWard => selectedWard === ward.id)),
        };
    }) || [];

    return (
        <StyledViewContainer>
            <StyledInformationBlocks>
                <StyledInformationBlockWrapper data-qa ="caregivers_card">
                    <NumberInformationBlock
                        header={localizer("home.caregivers")}
                        description={localizer("home.caregivers.description")}
                        value={loggedInCareGiversCount}
                        qaId="caregivers_count"
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper data-qa ="wards_card">
                    <NumberInformationBlock
                        header={localizer("home.wards")}
                        description={localizer("home.wards.description")}
                        value={wards.length}
                        qaId="wards_count"
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper data-qa ="residents_card">
                    <NumberInformationBlock
                        header={localizer("home.residents")}
                        description={localizer("home.residents.description")}
                        value={residents.length}
                        qaId="residents_count"
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper data-qa ="avg_response_times_card">
                    <NumberInformationBlock
                        header={localizer("home.avgResponseTimes30Days")}
                        description={localizer("home.avgResponseTimes30Days.description")}
                        value={convertTimeToHtml(convertMinutesToHours(nursingHome.averageResponseTimeFor30DaysInMinutes), true)}
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >

            <HomeTable
                data={rows}
            />
        </StyledViewContainer>
    );
};

export default HomeView;
