const routePaths = {
    loginPath: '/login',
    home: '/',
    residents: '/residents',
    statistics: '/statistics',
    setup: '/setup',
    users: '/users',
    audit: '/audit',
    nursingHomes: '/nursingHomes',
    nursingHomeGroups: '/nursingHomeGroups',
    countries: '/countries',
};

export { routePaths };
