export default class ChangeEvent {
    id: number;
    wardId: string;
    wardName: string;
    residentName: string;
    residentId: string;
    responseTimeMinutes: number | null;
    productChanges: number;
    timeCreated: Date | undefined;
    responseTargetOverdue: boolean;
    nightTimeMinutesBetweenChanges: number | null;
    productChangesPercentage: number | null;
    avgProductChanges: number;

    constructor(json: any) {
        this.id = json.id;
        this.wardId = String(json.wardId);
        this.wardName = json.wardName;
        this.residentName = json.residentName;
        this.residentId = json.residentId;
        this.productChanges = json.productChangesCount  ? json.productChangesCount : 0; 
        this.responseTimeMinutes = json.responseTimeMinutes ;
        const timeCreated = json.created || json.day;
        this.timeCreated = timeCreated;
        this.responseTargetOverdue = json.responseTargetOverdue;
        this.nightTimeMinutesBetweenChanges = json.nightTimeMinutesBetweenChanges;
        this.productChangesPercentage =  json.productChangesPercentage;
        this.avgProductChanges =  json.avgProductChanges;
    }
}