import { useState } from 'react';
import { Form, FormText, FormLabel, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RestClient } from '../../../data/RestClient';
import { Country } from '../../../models/Country';
import Market from '../../../models/Market';
import ToastMessages from '../../../models/ToastMessages';
import { RootState } from '../../../store';
import { getLocalizedCountries, getLocalizedCountry, localizer } from "../../../utils/Localizer";
import { toastError, toastSuccess } from '../../../utils/Toaster';
import AddEditButtonGroup from '../../../views/Buttons/AddEditButtonGroup';
import DropdownButtonNew from '../../common/controls/DropdownButtonNew';
import ValidatedFormGroupWrapper from '../../wrappers/ValidatedFormGroupWrapper';
import { countryToReqBody } from "../../../models/helpers/RequestHelpers";
import { fetchCountries } from '../../../reducers/countrySlice';
import ConfirmDeleteForm from '../../../views/ConfirmDeleteForm';
import ErrorCodes from '../../../models/ErrorCodes';
import { StyledFormGroup } from '../../common/View';
import Colors from '../../../utils/Colors';

const StyledFormContainer = styled.div`
    width: 100%;
`;

const StyledFormText = styled(FormText)`
    color: ${Colors.black};
`;

enum Fields {
    Country = "Country",
    Market = "Market",
    // Active = "Active",
}

const CountryEditForm = (props: any) => {
    const onDiscard = (event: any) => {
        event.preventDefault();
        props?.hideModal();
    };

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [countryEntity, setCountryEntity] = useState<Country>(props.entity || new Country());
    const [validationErrors, setErrors] = useState<string[]>([]);

    const isEdit = !!props.entity;
    const countries = useSelector<RootState, Country[]>(state => state.countrySlice.countries);

    const dispatch = useDispatch();

    const clearFormData = () => {
        setCountryEntity(new Country());
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!countryEntity.marketCode) {
            errors.push(ErrorCodes.CountryEmptyMarketError);
        }

        if (!countryEntity.code) {
            errors.push(ErrorCodes.CountryEmptyCodeError);
        }

        const duplicateNameFound = countries.some(country => country.code?.toLowerCase() === countryEntity.code?.toLowerCase() && country.id !== countryEntity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.CountryDuplicateCountryError);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendCountry = async (addMore: boolean) => {
        if (isEdit) {
            try {
                const requestBody = countryToReqBody(countryEntity);
                await RestClient.updateCountry(requestBody);

                dispatch(fetchCountries());
                toastSuccess(localizer(ToastMessages.UpdateCountrySuccessful));
                props?.hideModal();
            }
            catch (error) {
                toastError(localizer(ToastMessages.UpdateCountryError));
                // tslint:disable-next-line:no-console
                console.error(error);
            }

        } else {
            try {
                const requestBody = countryToReqBody(countryEntity);
                await RestClient.postCountry(requestBody);
                toastSuccess(localizer(ToastMessages.CreateCountrySuccessful));
                if (addMore) {
                    clearFormData();
                } else {
                    props?.hideModal();
                }
                dispatch(fetchCountries());
            }
            catch (error) {
                toastError(localizer(ToastMessages.CreateCountryError));
                // tslint:disable-next-line:no-console
                console.error(error);
            }
        }
    };

    const deleteCountry = async () => {
        try {
            await RestClient.deleteCountry(props.entity.id);
            props?.hideModal();
            dispatch(fetchCountries());
            toastSuccess(localizer(ToastMessages.DeleteCountrySuccessful));
        } catch (error) {
            toastError(localizer(ToastMessages.DeleteCountryErrorHasConnectedHomes));
            // tslint:disable-next-line:no-console
            console.error(error);
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendCountry(false);
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setShowDeleteConfirmation(false);
    };

    const onDeleteConfirm = (event: any) => {
        event.preventDefault();
        deleteCountry();
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendCountry(true);
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (props.entity.id) {
            setShowDeleteConfirmation(true);
        }
    };

    const handleChange = (name: string, value: any) => {
        const updatedCountry = { ...countryEntity };

        switch (name) {
            case Fields.Country:
                updatedCountry.code = value;
                break;
            case Fields.Market:
                updatedCountry.marketCode = value;
                break;
        }

        setCountryEntity(updatedCountry);
    };

    const localizedCountries = getLocalizedCountries();
    const markets = useSelector<RootState, Market[]>(state => state.marketSlice.markets);
    const dropdownOptions = markets.map(market => ({
        label: market.name,
        value: market.code
    })) || [];

    const deleteConfirmation = (
        <ConfirmDeleteForm
            title={props.entity?.name}
            onConfirmCancel={onConfirmCancel}
            onDeleteConfirm={onDeleteConfirm}
        />
    );

    const form = (
        <StyledFormContainer>
            <Form>
                {isEdit
                    ? <Row className="row g-0">
                        <StyledFormGroup>
                            <FormLabel>{localizer("country.editForm.country")}</FormLabel>
                            <StyledFormText>{getLocalizedCountry(countryEntity?.code)}</StyledFormText>
                        </StyledFormGroup>
                      </Row>
                    : <Row className="row g-0">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.CountryEmptyCodeError, ErrorCodes.CountryDuplicateCountryError]}
                            label={'country.editForm.country'}
                        >
                            <DropdownButtonNew
                                id={Fields.Country}
                                name={Fields.Country}
                                handleChange={(name, value) => handleChange(name, value)}
                                options={localizedCountries.map(localizedCountry => ({
                                    label: localizedCountry.label,
                                    value: localizedCountry.code.toUpperCase()
                                })) || []}
                                selectedValue={countryEntity.code}
                                isFilterable
                                portaling
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                }
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.CountryEmptyMarketError]}
                        label={'country.editForm.assortment'}
                    >
                        <DropdownButtonNew
                            id={Fields.Market}
                            name={Fields.Market}
                            handleChange={(name, value) => handleChange(name, value)}
                            options={dropdownOptions}
                            selectedValue={countryEntity.marketCode}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="g-0 mt-3">
                    <AddEditButtonGroup
                        isEdit={isEdit}
                        onSave={onSave}
                        onSaveAndAddAnother={onSaveAndAddAnother}
                        onDiscard={onDiscard}
                        extraActions={isEdit ? [{
                            label: "constants.delete",
                            action: onDelete
                        }] : []} />
                </Row>
            </Form>
        </StyledFormContainer >
    );

    return showDeleteConfirmation ? deleteConfirmation : form;
};

export default CountryEditForm;
