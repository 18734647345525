export default class ProductSize {
    public name: string;
    public strip: string;
    public thresholdCategory: number;
    public id: number;

    constructor(json: any) {
        this.name = json.name;
        this.strip = json.strip;
        this.thresholdCategory = json.thresholdCategory;
        this.id = json.id;
    }

    static sort(productSizes: ProductSize[]): ProductSize[] {
        productSizes.sort(((a, b) => {
            if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }));
        return productSizes;
    }
}