

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { IContextStateType } from "../reducers/contextSlice";
import { RootState } from "../store";
import Colors from "../utils/Colors";
import { localizer } from "../utils/Localizer";

const VersionContainer = styled.div`
    text-align: right;
    padding-right: 15px;
    font-size: 12px;
    color: ${Colors.black};
    height: 30px;
`;

const FooterContainer = styled.div`
    margin-top: 20px;
    gap: 12px;
    display: flex;
    flex-flow: row-reverse;
    color: ${Colors.black};
    height: 30px;
    font-size: 12px;
`;

const StyledButtonWrapper = styled.div`
    button {
        background-color: transparent !important;
        border: 0px !important;
        color: ${Colors.blue.brandingDark} !important;
        font-size: 12px !important;
        padding-top: 0px !important;
        &:hover {
            color: #092477;
        }
    }
`;

const StyledButton = styled.button`
    background-color: transparent !important;
    border: 0px !important;
    color: ${Colors.blue.brandingDark} !important;
    font-size: 12px !important;
    padding: 0px !important;   
     display: inline-flex; /* keep the inline nature of buttons */
    align-items: flex-start; /* this is default */
    &:hover {
        color: #092477;
    }
`;

const Footer = () => {

    const { cookieScriptExecution } = useSelector<
        RootState,
        IContextStateType
    >((state) => state.contextSlice);

    const triggerComponent: React.ReactNode = (
        <StyledButton>
            {localizer("cookies.termsAndConditions")}
        </StyledButton>
    );

    return (
        <FooterContainer>
            <VersionContainer >
                Version {process.env.REACT_APP_VERSION || "debug"}
            </VersionContainer>
            {cookieScriptExecution && (
                <>
                    <StyledButtonWrapper>
                        {/* OneTrust Cookies Settings button */}
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                            {localizer("cookies.cookiePreferences")}
                        </button>
                    </StyledButtonWrapper>
                    <ModalWrapper
                        triggerComponent={triggerComponent}
                        modalContent={<>
                            <p><FormattedMessage id={"cookies.subTitle"} /> </p>
                            <h2><FormattedMessage id={"cookies.firstParagraphTitle"} /> </h2>
                            <p><FormattedMessage id={"cookies.firstParagraph"} /> </p>
                            <h2><FormattedMessage id={"cookies.secondParagraphTitle"} /> </h2>
                            <p><FormattedMessage id={"cookies.secondParagraph"} /> </p>
                            <p><FormattedMessage id={"cookies.thirdParagraph"} /> </p>
                            {/* OneTrust Cookies List */}
                            <div id="ot-sdk-cookie-policy"></div>
                        </>}
                        modalTitle={<FormattedMessage id={"cookies.title"} />}
                        size={EModalSize.M}
                    />
                </>

            )}
        </FooterContainer>
    );
}

export default Footer;