import React from 'react';
import { ITableProps } from '../ITableProps';
import { FormattedMessage } from 'react-intl';
import Table, { headerJustifyOptions } from '../../common/table/Table';
import WardFormatter from '../../themed/home/formatters/WardFormatter';
import CareGiversFormatter from '../../themed/home/formatters/CareGiversFormatter';
import ActiveResidentsFormatter from '../../themed/home/formatters/ActiveResidentsFormatter';
import AverageResponseTimeFormatter from '../../themed/home/formatters/AverageResponseTimeFormatter';
import ErrorsFormatter from '../../themed/home/formatters/ErrorsFormatter';

const HomeTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="home.ward" />,
                accessor: 'ward.name',
                Cell: WardFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 7,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="home.caregivers" />,
                accessor: 'careGivers',
                Cell: CareGiversFormatter,
                headerAlignment: headerJustifyOptions.right,
                flexShrink: 2,
                hideTooltipOnProd: true,
                withoutFlex: true,
            },
            {
                Header: <FormattedMessage id="home.activeResidents" />,
                accessor: 'residents',
                Cell: ActiveResidentsFormatter,
                headerAlignment: headerJustifyOptions.right,
                flexGrow: 3,
                hideTooltipOnProd: true,
                withoutFlex: true,
            },
            {
                Header: <FormattedMessage id="home.deviceErrors" />,
                accessor: 'deviceErrors',
                Cell: ErrorsFormatter,
                headerAlignment: headerJustifyOptions.right,
                flexGrow: 3,
                hideTooltipOnProd: true,
                withoutFlex: true,
            },
            {
                Header: <FormattedMessage id="home.avgResponse" />,
                accessor: 'ward.avgResponseTimeShiftMinutes',
                Cell: AverageResponseTimeFormatter,
                headerAlignment: headerJustifyOptions.right,
                flexGrow: 3,
                hideTooltipOnProd: true,
                withoutFlex: true,
            }
        ],
        []
    );

    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"home_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: 'ward.name',
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(HomeTable);
