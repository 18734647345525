import { useEffect } from "react";
import UsersTable from "../users/tables/UsersTable";
import UserAddInformationBlock from "../UserAddInformationBlock";
import UserFilterInformationBlock from "../UserCountryFilterInformationBlock";
import { useDispatch, useSelector } from "react-redux";
import { Country } from "../../../../models/Country";
import {
    fetchUsers,
    ResourceTypes,
    setChildResourceId,
    setChildResourceType,
    IUserStateType,
    UserRole
} from "../../../../reducers/userSlice";
import { RootState } from "../../../../store";
import {
    StyledViewContainer,
    StyledInformationBlocks,
    StyledInformationBlockWrapper,
} from "../../../common/View";
import { getLocalizedCountry, localizer } from "../../../../utils/Localizer";
import UserAddButton from "../users/UserAddButton";

const GlobalAdminUserView = () => {
    const dispatch = useDispatch();
    const { childResourceId, users } = useSelector<RootState, IUserStateType>(
        (state) => state.userSlice
    );
    const countries = useSelector<RootState, Country[]>(
        (state) => state.countrySlice.countries
    );

    useEffect(() => {
        dispatch(setChildResourceType(ResourceTypes.Country));
        dispatch(setChildResourceId(childResourceId ? childResourceId : countries[0]?.id));
    }, [countries]);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [childResourceId]);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks alignLeft>
                <StyledInformationBlockWrapper data-qa="select_country_card">
                    <UserFilterInformationBlock
                        qaId="country_dropdown"
                        title={localizer(
                            "users.informationBlocks.title.selectCountry"
                        )}
                        name="countryFilter"
                        selectOption={(childResourceId) =>
                            dispatch(setChildResourceId(childResourceId))
                        }
                        selectedValue={childResourceId}
                        options={countries.map((country) => {
                            return {
                                label: getLocalizedCountry(country.code),
                                value: country.id,
                            };
                        }).sort((c1, c2) => c1.label > c2.label ? 1 : -1)}
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper data-qa="users_card">
                    <UserAddInformationBlock
                        addButton={
                            <UserAddButton
                                titleBasedOnRole={UserRole.CountryAdmin}
                            />
                        }
                        amountOfUsers={users.length}
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks>

            <UsersTable data={users} />
        </StyledViewContainer>
    );
};

export default GlobalAdminUserView;
