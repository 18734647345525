import ModalWrapper, { EModalSize } from '../../common/modal/ModalWrapper';
import { localizer } from '../../../utils/Localizer';
import { StyledAddButton } from "../../styled";
import NursingHomeAddEditForm from '../../../routes_admin/NursingHomes/NursingHomeAddEditForm';

const NursingHomeAddButton = () => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton
            variant="primary"
            data-qa="nh_add_button"
        />
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={(
                <NursingHomeAddEditForm
                    entity={undefined}
                />
            )}
            fixedFooter={true}
            modalTitle={localizer("nursingHomes.addButton.title")}
            size={EModalSize.M}
        />
    );
};

export default NursingHomeAddButton;
