import { Country } from "../../../models/Country";
import CountryEditForm from "./CountryEditForm";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import {
    getLocalizedCountry as getLocalizedCountryName,
    localizer,
} from "../../../utils/Localizer";
import { Icon } from "../../common/table/Icon";
interface IProps {
    country: Country;
}

const CountryEditIcon = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <Icon 
            icon={faPencilAlt} 
            text={"countries.editButton"} 
            qaId={props.country.code + "-edit_button"}/>
    );

    const localizedCountryName = getLocalizedCountryName(props.country.code);

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<CountryEditForm entity={props.country} />}
            modalTitle={localizer("countries.editForm.title", {
                title: localizedCountryName,
            })}
            size={EModalSize.S}
        />
    );
};

export default CountryEditIcon;
