import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getChangeThresholdsShort } from '../../../../reducers/residentHelper';
import { localizer } from "../../../../utils/Localizer";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import { IDropDownOption } from "../../../common/IDropDownOption";
import DateFormatter from '../../../common/table/formatters/DateFormatter';
import Table, { headerJustifyOptions } from '../../../common/table/Table';
import { ITableProps } from '../../ITableProps';
import ChangeThresholdFormatter from './formatters/ChangeThresholdFormatter';
import EventDescriptionFormatter from './formatters/EventDescriptionFormatter';
import StatusChangeTrigger from './formatters/StatusChangeTrigger';
import StatusFormatter from './formatters/StatusFormatter';
import StripsFormatter from "./formatters/StripsFormatter";
import TransmitterMonitoringTimeFormatter from './formatters/TransmitterMonitoringTimeFormatter';

const booleanToNumbers = (value: boolean) => value ? ({ value, label: "2" }) : ({ value, label: "1" });

const capitalize = (string: string) => (string && string[0].toUpperCase() + string.slice(1)) || "";

function SelectColumnFilter(
    {
        column: { filterValue, setFilter, preFilteredRows, id },
    }: any,
    placeholder: string,
    dropdownOptionLocalizer?: string,
    specialHandler?: any,
): React.ReactElement {
    const options = React.useMemo((): IDropDownOption[] => {
        const uniqOptions = new Set();
        const options: IDropDownOption[] = [];
        preFilteredRows.forEach((row: any) => {
            uniqOptions.add(row.values[id]);
        });
        uniqOptions.forEach((option: any) => {
            if (specialHandler) {
                const filterOption = specialHandler(option);
                if (filterOption) {
                    options.push(filterOption);
                }
            } else {
                if (typeof option === "boolean") {
                    options.push(booleanToNumbers(option));
                } else if (option) {
                    const label = option === "NotBeingMonitored" ? localizer("monitoring.off") : localizer(dropdownOptionLocalizer + option);
                    options.push({ value: option, label: capitalize(label) });
                } else {
                    options.push({ value: 0, label: capitalize(localizer("monitoring.off")) });
                }
            }
        });

        return options.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        });
    }, [id, preFilteredRows]);

    const optionsWithAll = [{ value: undefined, label: `-- ${localizer("dropDown.all")} --` }, ...options];

    const handleChange = (value: any) => {
        try {
            setFilter(JSON.parse(value));
        } catch {
            setFilter(value);
        }
    };

    const filterValueText = () => {
        return filterValue ?? localizer(placeholder);
    };

    return (
        <DropdownButtonNew
            placeholder={placeholder}
            id={`dropdown-select`}
            name={"dropdown-select"}
            options={optionsWithAll}
            selectedValue={filterValueText()}
            handleChange={(_, value) => handleChange(value)}
            disabled={options.length < 1}
        />
    );
}

const getSelectedChangeThresholdForResident = (changeThreshold: number): any => {
    const thresholds = getChangeThresholdsShort();
    const selectedThreshold = thresholds.find((threshold: any) => threshold.value === changeThreshold);
    if (!selectedThreshold) {
        return undefined;
    }

    return { value: selectedThreshold.value, label: selectedThreshold.label };
};

const ResidentHistoryTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="residents.historyTableHeader.date" />,
                accessor: 'created',
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
                alignItems: "center",
                cellAlignItems: "self-start"
            },
            {
                accessor: 'bedStatus',
                Cell: StatusFormatter,
                Filter: (props: any) => SelectColumnFilter(props, "residents.historyTableHeader.event", "brief.statusType."),
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                filter: "includes",
                disableSortBy: true,
                alignItems: "center",
            },
            {
                Header: <FormattedMessage id="residents.historyTableHeader.description" />,
                accessor: 'amountOfAssignedUsers',
                Cell: EventDescriptionFormatter,
                headerAlignment: headerJustifyOptions.left,
                disableSortBy: true,
                flexGrow: 5,
                alignItems: "center",
                cellAlignItems: "self-start"
            },
            {
                accessor: 'changeThreshold',
                Cell: ChangeThresholdFormatter,
                Filter: (props: any) => SelectColumnFilter(props, "residents.historyTableHeader.timing", "", getSelectedChangeThresholdForResident),
                headerAlignment: headerJustifyOptions.left,
                disableSortBy: true,
                flexGrow: 1,
                alignItems: "center",
                cellAlignItems: "self-start"
            },
            {
                accessor: 'statusChangeTrigger',
                Cell: StatusChangeTrigger,
                Filter: (props: any) => SelectColumnFilter(props, "residents.historyTableHeader.trigger", "resident.history.tableRow.statusChangeTrigger."),
                headerAlignment: headerJustifyOptions.left,
                filter: "includes",
                flexGrow: 1,
                disableSortBy: true,
                alignItems: "center",
                cellAlignItems: "self-start"
            },
            {
                accessor: 'transmitterMonitoringTime',
                Cell: TransmitterMonitoringTimeFormatter,
                Filter: (props: any) => SelectColumnFilter(props, "residents.tableHeader.monitoring", "resident.history.tableRow.transmitterMonitoringTime."),
                headerAlignment: headerJustifyOptions.left,
                filter: "equals",
                flexGrow: 1,
                disableSortBy: true,
                alignItems: "center",
                cellAlignItems: "self-start"
            },
            {
                accessor: 'isSeparateTransmitter',
                Cell: StripsFormatter,
                Filter: (props: any) => SelectColumnFilter(props, "residents.historyTableHeader.stripSetup"),
                headerAlignment: headerJustifyOptions.left,
                filter: "equals",
                flexGrow: 1,
                disableSortBy: true,
                alignItems: "center",
            },
        ],
        []
    );

    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"resident_history_table"}
            fixedHeader={true}
            overflowCount={5} // calculate dinamically based on screen heigth in 53989
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: 'created',
                        desc: true
                    }
                ]
            }}
        />
    );
};

export default React.memo(ResidentHistoryTable);
