/* eslint-disable no-throw-literal */
import superagentRequest from 'superagent';
import Sorting, { Direction } from "../models/Sorting";
import { getLocale, localizer } from "../utils/Localizer";
import ErrorCodes from '../models/ErrorCodes';
import { startSpinner, stopSpinner, spinnerKeys } from "../reducers/spinnerSlice";

import {
    handleLogout,
} from './Auth';
import store from '../store';
import { Roles } from '../models/Role';
import { ResourceTypes } from '../reducers/userSlice';

const request = superagentRequest
    .agent()
    .retry()
    .withCredentials()
    .use((request: any) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        request.on('response', async (response: any) => {
            store.dispatch(stopSpinner());
            if (response.status === 403) {
                handleLogout("logout.forbidden");
            }
            if (response.status === 401) {
                handleLogout("logout.unauthorized");
            }
        });
    })
    .on('error', () => store.dispatch(stopSpinner()));

const requestNoPopup = superagentRequest
    .agent()
    .retry()
    .withCredentials()
    .use((request: any) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        request.on('response', async (response: any) => {
            store.dispatch(stopSpinner());
            if (response.status === 403 || response.status === 401) {
                handleLogout();
            }
        });
    })
    .on('error', () => store.dispatch(stopSpinner()));

const API_PATH = `${process.env.REACT_APP_API_URL}/api`;

enum URLS {
    NURSING_HOME_ACTIVE = '/nursingHomes/active',
    NURSING_HOME = '/nursingHomes',
    NURSING_HOME_AVERAGE_RESPONSE_TIME = '/nursingHomes/avgResponseTime',
    WARD = '/wards',
    CAREGIVER_DEVICES = '/caregiverDevice/getForNursingHome',
    COUNTRY = '/country',
    RESIDENT = '/residents',
    NURSING_HOME_RESIDENTS = '/residents/getByNursingHome',
    RESIDENT_HISTORY = '/residents/history',
    CHANGE_LOGS = '/nurseActions',
    AVG_CHANGE_LOGS = '/nursingHomeStatistics/avgPerDay',
    EXPORT_FILE = '/nursingHomeStatistics/exportFile',
    PRODUCTS = '/product/nursingHome',
    MARKETS = '/product/markets',
    USERS = '/users',
    GRANT_ACCESS = '/users/grantAccess',
    EXTEND_ACCESS = '/users/extendAccess',
    REVOKE_ACCESS = '/users/revokeAccess',
    AUDITLOG_RESIDENT = '/auditlog/resident',
    AUDITLOG_NURSE = '/auditlog/nurse',
    AUDITLOG_ADMIN = '/auditlog/admin',
    AUDITLOG_NURSING_HOME_GROUP_MANAGER = '/auditLog/nursingHomeGroup',
    AUDITLOG_NURSING_HOME_ACCESS = '/auditlog/nursingHomeAccess',
    AUDITLOG_ADMIN_ACCESS = '/auditLog/loginlogout',
    ASSIGN_WARDS_TO_CAREGIVER = '/users/assignWardsToCaregiver',
    UPDATE_CAREGIVER_PASSWORD = '/users/UpdateCaregiverPassword',
    USERS_PASSWORD = '/users/updatePassword',
    USER_RESOURCES = '/users/userResources',
    NURSING_HOME_GROUPS = '/nursingHomeGroups',
    LOGIN_WITH_ID_TOKEN = '/authentication/browserLoginWithIdToken',
    SET_MONITORING_STATUS = '/monitoringStatus',
    KEEP_SESSION_ALIVE = '/Authentication/browserKeepAlive',
    LOGOUT = '/authentication/browserLogout',
    VALIDATE_2FA_CODE = '/authentication/verifyCode',
    CONFIGURATION = '/configuration'
}

const getResourceTypeBasedOnRole = (role: Roles): string => {
    switch (role) {
        case Roles.GlobalAdmin:
            return ResourceTypes.Global;
        case Roles.CountryAdmin:
            return ResourceTypes.Country;
        case Roles.NHGManager:
            return ResourceTypes.NursingHomeGroup;
        case Roles.NHManager:
        case Roles.Nurse:
        case Roles.CareGiver:
        case Roles.Support:
            return ResourceTypes.NursingHome;
        default:
            return ResourceTypes.Invalid;
    }
};

const getHeaders = async (_nursingHomeId?: null | undefined | string): Promise<any> => {
    const context = store.getState().contextSlice;

    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Resource-Type': getResourceTypeBasedOnRole(context.role),
        'User-Resource-Id': context.id,
        'Accept-Language': getLocale(),
        'X-API-VERSION': "1.0",
    };
};

export const AuthRestClient: any = {
    loginWithAdToken: async (idToken: string): Promise<string> => {
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const response = await superagentRequest
                .get(API_PATH + URLS.LOGIN_WITH_ID_TOKEN)
                .set({
                    'Authorization': 'Bearer ' + idToken,
                    'X-API-VERSION': "1.0",
                })
                .withCredentials();

            return response.body;
        }
        catch (err: any) {
            if (err.status === 403) {
                throw ErrorCodes.NoPermissions;
            } else {
                throw err;
            }
        }
        finally {
            store.dispatch(stopSpinner());
        }
    },

    loginB2C: async (username: string, password: string) => {
        const url = `${API_PATH}/Authentication/browserLogin`;
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    username,
                    password
                }),
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": "application/json",
                    'X-API-VERSION': "1.0",
                    'Accept-Language': getLocale(),
                },
                credentials: "include"
            });
            if (response.status === 403) {
                throw ErrorCodes.NoPermissions;
            } else if (response.status === 401) {
                const body = await response.json();
                if (body && body.isLocked) {
                    throw ErrorCodes.Locked;
                } else {
                    throw ErrorCodes.LoginFailed;
                }
            } else {
                const body = await response.json();
                if (body && (body.sessionTimeoutMinutes || body.is2FactorAuthenticationEnabled)) {
                    return body;
                } else {
                    throw ErrorCodes.LoginFailed;
                }
            }
        }
        catch (err: any) {
            // Error messages are passed as JSON from AzureADB2C in the response.
            // If error is internal it is a normal string. Hence the try/catch below.
            let error: any = {};
            if (err.response && err.response.text) {
                try {
                    error = JSON.parse(err.response.text);
                } catch (e) {
                    error = err.response.text;
                }
            } else {
                error = err;
            }

            // tslint:disable-next-line:no-console
            console.error(error);

            if (error.error && error.error === 'access_denied') {
                throw ErrorCodes.AccessDenied;
            } else if (error.error_description) {
                throw new Error(error.error_description);
            } else {
                throw error;
            }
        }
        finally {
            store.dispatch(stopSpinner());
        }
    },

    keepSessionAlive: async (): Promise<any> => {
        return superagentRequest
            .post(API_PATH + URLS.KEEP_SESSION_ALIVE)
            .set({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-VERSION': "1.0",
            })
            .withCredentials()
            .use((request: any) => {
                request.on('response', async (response: any) => {
                    if (response.status === 401 || response.status === 403) {
                        handleLogout("logout.inactivity");
                    }
                });
            });
    },


    logout: async (): Promise<any> => {
        return superagentRequest
            .delete(API_PATH + URLS.LOGOUT)
            .set({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-VERSION': "1.0",

            })
            .withCredentials();
    },

    verifyCode: async (username: string, password: string, code: string) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        const response = await fetch(API_PATH + URLS.VALIDATE_2FA_CODE, {
            method: "POST",
            body: JSON.stringify({
                username,
                password,
                code,
            }),
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json",
                'X-API-VERSION': "1.0",
            },
            credentials: "include"
        });
        store.dispatch(stopSpinner());
        if (response.status === 200) {
            const body = await response.json();
            return body;
        }
        return response;
    }
};

export const RestClient: any = {
    // #region GET
    getUserResources: async (avoidLogoutMessage: boolean): Promise<any> => get(`${API_PATH + URLS.USER_RESOURCES}`, undefined, avoidLogoutMessage),

    getResidentAudits: async (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_RESIDENT}`, query);
    },

    getResidentsForNursingHome: async () => {
        return get(API_PATH + URLS.NURSING_HOME_RESIDENTS);
    },

    getNurseAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSE}`, query);
    },

    getNursingHomeGroupManagerAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSING_HOME_GROUP_MANAGER}`, query);
    },


    getAdminAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_ADMIN}`, query);
    },

    getNursingHomeAccessAudits: (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSING_HOME_ACCESS}`, query);
    },

    getAdminAccessAudits: (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_ADMIN_ACCESS}`, query);
    },

    getNursingHome: (): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME_ACTIVE);
    },

    getNursingHomeAverageResponseTime: (): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME_AVERAGE_RESPONSE_TIME);
    },

    getNursingHomes: (): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME);
    },

    getAllNursingHomeGroups: (): Promise<any[]> => {
        return get(API_PATH + URLS.NURSING_HOME_GROUPS);
    },

    getProducts: (): Promise<any> => {
        return get(API_PATH + URLS.PRODUCTS);
    },

    getMarkets: (): Promise<any> => {
        return get(API_PATH + URLS.MARKETS);
    },


    getWards: (): Promise<any[]> => {
        return get(API_PATH + URLS.WARD);
    },

    getCountries: () => {
        return get(API_PATH + URLS.COUNTRY);
    },

    getHistoryLogs: (residentId: string, startDate: string, endDate: string, limit: number, offset: number, eventType: string, startTime: string | null, endTime: string | null): Promise<any> => {
        const query: any = {
            startDate,
            endDate,
            limit,
            offset
        };

        if (startTime) {
            query.startTime = startTime;
        }

        if (endTime) {
            query.endTime = endTime;
        }

        // Event type 'All' is not supported by api. It means the same thing as omitting the param.
        if (eventType && eventType !== 'All') {
            // @ts-ignore
            query.bedStatus = eventType;
        }

        return get(`${API_PATH + URLS.RESIDENT_HISTORY}/${residentId}`, query);
    },

    getAverageChangeLogs: (nursingHomeId: string, startDate: string, endDate: string, isDayShift: boolean | null, prompted?: string, wardIds?: string ): Promise<any> => {
        const query: any = {
            nursingHomeId,
            startDate,
            endDate,
            wardIds: [],
            isDayShift,
        };

        if (prompted) {
            query.prompted = prompted;
        }

        if (wardIds) {
            query.wardIds = [wardIds];
        }

        query.isDayShift = isDayShift;

        return get(API_PATH + URLS.AVG_CHANGE_LOGS, query);
    },

    getChangeLogs: (nursingHomeId: string, startDate: string, endDate: string, startTime: string | null, endTime: string | null, limit: number, offset: number, wardId?: string, sorting?: Sorting, prompted?: string): Promise<any> => {
        const query: any = {
            nursingHomeId,
            startDate,
            endDate,
            limit,
            offset
        };

        if (startTime) {
            query.startTime = startTime;
        }

        if (endTime) {
            query.endTime = endTime;
        }

        if (prompted) {
            query.prompted = prompted;
        }

        if (wardId) {
            query.wardId = wardId;
        }

        if (sorting) {
            const sortOrder = sorting.direction === Direction.Up ? 'asc' : 'dsc';
            query.sortBy = sorting.column;
            query.sortOrder = sortOrder;
        }

        return get(API_PATH + URLS.CHANGE_LOGS, query);
    },

    getUsers: (childResourceType: string, childResourceId: string): Promise<any[]> => {
        return get(API_PATH + URLS.USERS, { childResourceType, childResourceId });
    },

    getCaregivers: () => {
        return get(API_PATH + URLS.CAREGIVER_DEVICES);
    },

    getConfiguration: () => {
        return get(API_PATH + URLS.CONFIGURATION);
    },
    // #endregion

    // #region POST
    getStatisticsFile: async (fileName: string, wardIds: string[], startDate: string, endDate: string, nursingHomeId: string): Promise<any> => {
        const query: any = {
            fileName,
            wardIds,
            startDate,
            endDate,
        };

        query.timezoneOffset = new Date().getTimezoneOffset();

        const headers = await getHeaders(nursingHomeId);

        const response = await request
            .post(API_PATH + URLS.EXPORT_FILE)
            .set(headers)
            .send(query)
            .responseType('blob');

        if (!response.body) {
            throw new Error();
        }
        return response;
    },

    grantAccess: (requestBody: any): Promise<any> => {
        const thenableStatusCodes = [404, 409, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 409) {
                throw {
                    status,
                    showInToast: true,
                    text: localizer('constants.errorMessages.userExists'),
                };
            }
            if (status === 404) {
                throw {
                    showInToast: true,
                    text: localizer('constants.errorMessages.accountDoesNotExistInAD')
                };
            }
        };

        return postWithCustomStatus(API_PATH + URLS.GRANT_ACCESS, thenableStatusCodes, validateResponseMethod, requestBody);
    },

    extendAccess: (requestBody: any): Promise<any> => {
        const thenableStatusCodes = [404, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 404) {
                throw {
                    showInToast: true,
                    text: localizer('constants.errorMessages.expiredAccess')
                };
            }
        };

        return postWithCustomStatus(API_PATH + URLS.EXTEND_ACCESS, thenableStatusCodes, validateResponseMethod, requestBody);
    },

    revokeAccess: async (requestBody: any) => {
        const thenableStatusCodes = [404, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 404) {
                throw {
                    showInToast: true,
                    text: localizer('constants.errorMessages.expiredAccess')
                };
            }
        };

        return postWithCustomStatus(API_PATH + URLS.REVOKE_ACCESS, thenableStatusCodes, validateResponseMethod, requestBody);
    },

    postResident: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.RESIDENT, requestBody);
    },

    postCountry: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.COUNTRY, requestBody);
    },

    createNursingHomeGroup: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.NURSING_HOME_GROUPS, requestBody, null);
    },

    postWard: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.WARD, requestBody);
    },

    createNursingHome: (requestBody: any): Promise<any> => {
        const context = store.getState().contextSlice;
        if (!requestBody.countryId) {
            switch (context.role) {
                case Roles.CountryAdmin:
                    requestBody.countryId = context.id;
                    break;
                default:
                    break;
            }
        }

        return post(`${API_PATH + URLS.NURSING_HOME}`, requestBody);
    },
    // #endregion

    // #region PUT
    updateWard: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.WARD}`, requestBody);
    },

    updateResident: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.RESIDENT}`, requestBody);
    },

    updateCountry: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.COUNTRY}`, requestBody);
    },

    putUser: (requestBody: any, nursingHomeId: string): Promise<any> => {
        return put(`${API_PATH + URLS.USERS}`, requestBody, nursingHomeId);
    },

    setMonitoringStatus: (residentId: string, requestBody: any) => { // IF ERROR make  res && res.body check conditional
        return put(`${API_PATH + URLS.RESIDENT + URLS.SET_MONITORING_STATUS}/${residentId}`, requestBody);
    },

    assignWardsToCaregiver: (requestBody: any) => {
        return put(`${API_PATH + URLS.ASSIGN_WARDS_TO_CAREGIVER}`, requestBody);
    },

    updateNursingHomeGroup: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.NURSING_HOME_GROUPS}`, requestBody, null);
    },

    updateNursingHome: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.NURSING_HOME}`, requestBody);
    },
    // #endregion

    // #region PATCH

    updateCaregiverPassword: (requestBody: any): Promise<any> => {
        return patch(`${API_PATH + URLS.UPDATE_CAREGIVER_PASSWORD}`, requestBody);
    },
    // #endregion

    // #region DELETE
    deleteWard: (wardId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.WARD}/${wardId}`);
    },

    deleteResident: (residentId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.RESIDENT}/${residentId}`);
    },

    deleteCountry: (id: string): Promise<any> => {
        return remove(`${API_PATH + URLS.COUNTRY}/${id}`);
    },

    deleteNursingHomeGroup: (nursingHomeGroupId: string): Promise<any> => {
        const nursingHomeIdQueryParameterValue = null;
        return remove(`${API_PATH + URLS.NURSING_HOME_GROUPS}/${nursingHomeGroupId}`, nursingHomeIdQueryParameterValue);
    },
    deleteNursingHome: (nursingHomeId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.NURSING_HOME}/${nursingHomeId}`);
    },
};

const get = async (url: string, query: string | object = {}, avoidLogoutMessage: boolean = false) => {
    const headers = await getHeaders();

    const specificRequest = avoidLogoutMessage ? requestNoPopup : request;

    const responseBody = await specificRequest
        .get(url)
        .query(query)
        .set(headers)
        .then(response => {
            return response?.body;
        });

    return responseBody;
};

const postWithCustomStatus = async (url: string, thenableStatusCodes: number[], validateResponseMethod: (status: number) => void, requestBody: string | object = {}, nursingHomeId: string | null | undefined = undefined) => {
    const headers = await getHeaders(nursingHomeId);
    
    const response = await request
        .post(url)
        .send(requestBody)
        .ok(response => thenableStatusCodes.includes(response.status))
        .set(headers);

    validateResponseMethod(response.status);

    return;
};

const post = async (url: string, requestBody: string | object = {}, nursingHomeId: string | null | undefined = undefined) => {
    const headers = await getHeaders(nursingHomeId);

    const response = await request
        .post(url)
        .send(requestBody)
        .set(headers);

    if (!response.body) {
        throw new Error();
    }

    return response.body;
};

const put = async (url: string, requestBody: string | object = {}, nursingHomeId: string | null | undefined = undefined) => {
    const headers = await getHeaders(nursingHomeId);

    const response = await request
        .put(url)
        .send(requestBody)
        .set(headers);

    if (response.status !== 200) {
        throw new Error();
    }

    return response?.body;
};

const patch = async (url: string, requestBody: string | object = {}, nursingHomeId: string | null | undefined = undefined) => {
    const headers = await getHeaders(nursingHomeId);

    const response = await request
        .patch(url)
        .send(requestBody)
        .set(headers);

    if (response.status !== 200) {
        throw new Error();
    }
};

const remove = async (url: string, nursingHomeId: string | null | undefined = undefined) => {
    const headers = await getHeaders(nursingHomeId);

    return request
        .delete(url)
        .set(headers);
};
