import { useState } from "react";
import { Button, Form, FormText, Row } from "react-bootstrap";
import styled from "styled-components";
import ErrorCodes from "../../../../models/ErrorCodes";
import User, { UserRegexp } from "../../../../models/User";
import ValidatedFormGroupWrapper from '../../../wrappers/ValidatedFormGroupWrapper';
import MultiSelectionFieldNew, { IOption } from "../../../common/controls/MultiSelectionFieldNew";
import { useDispatch, useSelector } from "react-redux";
import { Ward } from '../../../../models/Ward';
import { RootState } from '../../../../store';
import { StyledFormButtons } from "../../../common/styled";
import { FormattedMessage } from "react-intl";
import { assignWardsToCaregiver } from "../../../../reducers/userSlice";
import { NursingHome } from "../../../../models/NursingHome";
import { localizer } from "../../../../utils/Localizer";
import { StyledFormLabel } from "../../setup/styled";
import { StyledFormGroup } from "../../../common/View";
import Colors from "../../../../utils/Colors";

const StyledFormContainer = styled.div`
    width: 100%;
`;

const StyledFormText = styled(FormText)`
    color: ${Colors.black};
`;

enum Fields {
    Name = 'Name',
    AssignedWards = 'AssignedWards',
}

export interface IProps {
    hideModal?: () => void;
    entity: User;
}

const CaregiverEditForm = (props: IProps) => {
    const dispatch = useDispatch();
    const nursingHome = useSelector<RootState, NursingHome>(state => state.nursingHomeSlice.nursingHome || new NursingHome());
    const wards = useSelector<RootState, Ward[]>(state => state.wardSlice.wards);

    const onDiscard = (event: any) => {
        event.preventDefault();
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const [name, setName] = useState<string>(props.entity.name);
    const [assignedWardsIds, setAssignedWardsIds] = useState<string[]>(props.entity.assignedWardsIds);

    const [validationErrors, setErrors] = useState<string[]>([]);


    const getAssignedWards = (): IOption[] | undefined => {
        if (assignedWardsIds && assignedWardsIds.length > 0) {
            const assignedWards: IOption[] = [];
            assignedWardsIds.forEach(wardId => {
                const ward = wards.find((x: any) => x.id === String(wardId));
                if (ward) {
                    const option: IOption = { label: ward.name, value: ward.id };
                    assignedWards.push(option);
                }
            });
            return assignedWards;
        }
        return undefined;
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!name) {
            errors.push(ErrorCodes.FaultyNameErrorEmpty);
        }

        if (!nursingHome.isWeakAuthenticationAllowed && !UserRegexp.EmailRegexp.test(name.toLowerCase())) {
            errors.push(ErrorCodes.FaultyNameErrorEmail);
        }

        if (assignedWardsIds.length === 0) {
            errors.push(ErrorCodes.EmptyAssignedWards);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const updateUser = () => {
        dispatch(assignWardsToCaregiver(props.entity.id, assignedWardsIds));
        if (props?.hideModal) {
            props.hideModal();
        }
    };


    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        updateUser();
    };

    const handleChange = (name: string, value: any) => {
        switch (name) {
            // TODO: in future edition of name could be added
            case Fields.Name:
                setName(value);
                break;
            case Fields.AssignedWards:
                setAssignedWardsIds(value);
                break;
        }
    };

    return (
        <StyledFormContainer>
            <Form autoComplete="false">
                <StyledFormGroup>
                    <StyledFormLabel>{localizer("users.editForm.nameEmail")}</StyledFormLabel>
                    <StyledFormText>{name}</StyledFormText>
                </StyledFormGroup>

                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyAssignedWards]}
                        label={'users.editForm.assignedWards'}
                    >
                        <MultiSelectionFieldNew
                            name={Fields.AssignedWards}
                            options={wards?.map((ward) => ({ value: ward.id, label: ward.name })) || []}
                            selectedValue={getAssignedWards()}
                            handleChange={handleChange}
                            translations={{
                                allOptionsSelected: localizer("statistics.chart.allWards"),
                                multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                                noOptions: localizer("statistics.chart.noWards"),
                            }}
                            maxHeight={350}
                            portalingRequired={true}
                        />


                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0 mt-3">
                    <StyledFormButtons>
                        <Button
                            variant={"primary"}
                            type="submit"
                            className="confirm-button"
                            onClick={onSave}
                        >
                            <FormattedMessage id="users.changePasswordForm.confirm" />
                        </Button>
                        <Button
                            variant={"outline-secondary"}
                            type="button"
                            className="discard-button"
                            onClick={onDiscard}
                        >
                            <FormattedMessage id="addEditButton.cancel" />
                        </Button>
                    </StyledFormButtons>
                </Row>
            </Form>
        </StyledFormContainer>
    );
};

export default CaregiverEditForm;
