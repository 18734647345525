import { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RestClient } from '../../../../data/RestClient';
import ToastMessages from '../../../../models/ToastMessages';
import { RootState } from '../../../../store';
import { localizer } from '../../../../utils/Localizer';
import { toastError, toastSuccess } from '../../../../utils/Toaster';
import ValidatedFormGroupWrapper from '../../../wrappers/ValidatedFormGroupWrapper';
import { NursingHome } from '../../../../models/NursingHome';
import User, { UserRegexp } from '../../../../models/User';
import ErrorCodes from '../../../../models/ErrorCodes';
import TextFieldNew from '../../../common/controls/TextFieldNew';
import { StyledFormButtons } from '../../../common/styled';

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    Password = 'Password',
    PasswordConfirmation = 'PasswordConfirmation'
}

interface IProps {
    user: User;
    hideModal?: () => void;
}

const CaregiverChangePasswordForm = (props: IProps) => {
    const nursingHome = useSelector<RootState, NursingHome>(state => state.nursingHomeSlice.nursingHome || new NursingHome());

    const clearFormData = () => {
        setPassword('');
        setPasswordConfirmation('');
        setErrors([]);
    };

    const onDiscard = () => {
        clearFormData();
        props.hideModal!();
    };

    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [validationErrors, setErrors] = useState<string[]>([]);

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        setNewPassword();
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (nursingHome.isWeakAuthenticationAllowed && (!password || password.length < 4 || password.length > 64)) {
            errors.push(ErrorCodes.FaultyPasswordWeak);
        }

        if (!nursingHome.isWeakAuthenticationAllowed && (!password || password.length < 1 || !UserRegexp.PasswordRegexp.test(password))) {
            errors.push(ErrorCodes.FaultyPasswordStrong);
        }

        if (password !== passwordConfirmation) {
            errors.push(ErrorCodes.NotMatchingPasswords);
        }
        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const setNewPassword = async () => {
        try {
            await RestClient.updateCaregiverPassword({ id: props.user.id, password });

            toastSuccess(localizer(ToastMessages.UpdateUserPasswordSuccessful));
            props.hideModal!();
        }
        catch (error) {
            toastError(localizer(ToastMessages.UpdateUserPasswordError));
            console.error(error);
        }
    };

    const handleChange = (name: string, value: any) => {
        switch (name) {
            case Fields.Password:
                setPassword(value);
                break;
            case Fields.PasswordConfirmation:
                setPasswordConfirmation(value);
                break;
        }
    };

    const form = (
        <StyledFormContainer>
            <Form>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.FaultyPasswordWeak, ErrorCodes.FaultyPasswordStrong]}
                        label="users.changePasswordForm.password"
                    >
                        <TextFieldNew
                            data-qa="caregiver_new_password_input_text"
                            type='password'
                            fieldName={Fields.Password}
                            value={password}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.NotMatchingPasswords]}
                        label="users.changePasswordForm.passwordConfirmation"
                    >
                        <TextFieldNew
                            data-qa="caregiver_password2_input_text"
                            type='password'
                            fieldName={Fields.PasswordConfirmation}
                            value={passwordConfirmation}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
            </Form>
            <StyledFormButtons>
                <Button
                    data-qa="update_button"
                    variant={"primary"}
                    type="submit"
                    className="confirm-button"
                    onClick={onSave}
                >
                    <FormattedMessage id="users.changePasswordForm.confirm" />
                </Button>
                <Button
                    data-qa="cancel_button"
                    variant={"outline-secondary"}
                    type="button"
                    className="discard-button"
                    onClick={onDiscard}
                >
                    <FormattedMessage id="addEditButton.cancel" />
                </Button>
            </StyledFormButtons>
        </StyledFormContainer >
    );
    return form;
};

export default CaregiverChangePasswordForm;
