import ModalWrapper, { EModalSize } from "../../../common/modal/ModalWrapper";
import { localizer } from "../../../../utils/Localizer";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../../../common/table/Icon";
import CaregiverChangePasswordForm from "./CaregiverChangePasswordForm";

interface IProps {
    user: any;
}

const CaregiversResetPasswordIcon = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <Icon icon={faKey} text={"caregivers.resetPasswordButton"} qaId={props.user.name.toLowerCase() + "-reset_password_button"}/>
        
    );

    return (
        <ModalWrapper 
            triggerComponent={triggerComponent}
            modalContent={<CaregiverChangePasswordForm user={props.user} />}
            modalTitle={localizer("caregivers.resetPassword.title", {
                name: props.user.name,
            })}
            size={EModalSize.S}
        />
    );
};

export default CaregiversResetPasswordIcon;
