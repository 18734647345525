import { getActiveResidentCount } from '../../../../reducers/residentHelper';
import { StyledCenter } from '../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';

const CellFormatter = (props: any) => {
    const activeResidents = props.data?.value?.length > 0
        ? `${getActiveResidentCount(props.data.value)} / ${props.data.value.length}`
        : '0';

    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledCenter>
            <TextOverflowWrapper tooltip={activeResidents} hideTooltipOnProd={hideTooltipOnProd}>
                {activeResidents}
            </TextOverflowWrapper>
        </StyledCenter>
    );
};

const ActiveResidentsFormatter = (data: any) => <CellFormatter data={data} />;
export default ActiveResidentsFormatter;
