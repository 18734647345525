import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ChangeStatusIcon from "../../../components/common/ChangeStatusIcon";
import { StyledLeft } from "../../../components/common/table/formatters/PositionContainers";
import infoIcon from "../../../images/info.svg";
import { ResidentEventType } from "../../../models/ResidentEventType";
import { TransmitterError } from "../../../models/TransmitterError";
import { localizer } from "../../../utils/Localizer";

const CommonStyle = `
    margin-right: 8px;
`;

const StyledSpan = styled.span`
    margin-left: 11px;
    max-width: 140px;
`;

const StyledChangeStatusIcon = styled(ChangeStatusIcon)`
    width: 30px;
    ${CommonStyle}
`;

const StyledLeftWordBreak = styled(StyledLeft)`
    word-break: break-word;
`;

const StyledImg = styled.img`
    margin-left: 4px;
    margin-top: 2px;
`;

class ResidentStatus extends React.Component<any> {

    getLocalizedStatusText = (statusText: string): any => {
        return <FormattedMessage id={"brief.statusType." + statusText} />;
    };

    render(): React.ReactElement {

        const { bedStatus, transmitterError, responseTargetOverdue } = this.props.row.original;

        const useTransmitterError = (
            bedStatus === ResidentEventType.Error || (
                bedStatus === ResidentEventType.Inactive &&
                transmitterError === TransmitterError.NoTransmitter
            )
        );

        const statusText = useTransmitterError ? transmitterError : bedStatus;
        return (
            <StyledLeftWordBreak>
                <StyledChangeStatusIcon status={bedStatus} />
                <StyledSpan>{this.getLocalizedStatusText(statusText)}</StyledSpan>
                {bedStatus === ResidentEventType.SomeUrine &&
                    responseTargetOverdue && (
                        <>
                            <StyledImg
                                alt="info"
                                data-tip={localizer("residents.info.someUrineOverdue")}
                                src={infoIcon}
                            />
                        </>)

                }
            </StyledLeftWordBreak>
        );
    }
}

export default ResidentStatus;
