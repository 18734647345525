import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AppContext } from "../data/AppRepository";
import { getResetPasswordLocalizedUrl, localizer } from "../utils/Localizer";
import { toastError, toastLogoutInfo, toastSuccess } from "../utils/Toaster";
import styled from "styled-components";
import Colors from "../utils/Colors";
import ErrorCodes from "../models/ErrorCodes";
import { useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../utils/PathHelpers";
import ModalWrapper, {
    EModalSize,
} from "../components/common/modal/ModalWrapper";
import TwoFactorAuthForm from "./TwoFactorAuthForm";
import { StyledATag } from "../components/common/View";
import ValidatedFormGroupWrapper from "../components/wrappers/ValidatedFormGroupWrapper";
import TextField from "../components/common/controls/TextFieldNew";

const StyledLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    padding: 110px 0px;
`;

const StyledLoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 360px;
`;

const StyledDivider = styled.div`
    color: ${Colors.grey.medium};
    width: 100%;
    min-width: 360px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    line-height: 0.1em;
    margin: 33px 0;
    span {
        background: #e9f7ff;
        padding: 0 10px;
    }
`;

const StyledLoginButton = styled(Button)`
    margin-top: 34px;
`;

const StyledMsButton = styled(Button)`
    background: white;
    height: 41px;
    color: #5e5e5e;
    border: 1px solid #8c8c8c;
    border-radius: 0;
    min-width: 360px;
    width: 100%;
`;

const StyledSpan = styled.span`
    float: left;
`;

enum Fields {
    Email = 'Email',
    Password = 'Password'
}

const Login = () => {
    const appContext = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [hasError, setHasError] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [twoFactorModalVisible, setTwoFactorModalVisible] =
        useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash.includes("id_token")) {
            appContext
                .loginWithIdToken(location.hash.replace("#id_token=", ""))
                .then(() => navigate(routePaths.loginPath))
                .catch((error: any) =>
                    toastError(getErrorMessage(error.message || error))
                );

            toastSuccess(localizer("loginView.successfulPasswordChange"));
        }

        document.body.classList.add("login-screen");

        return () => document.body.classList.remove("login-screen");
    }, []);

    const signInMsAdUser = (event: any) => {
        blockFetchUserResourcesFlag();
        event.preventDefault();
        appContext.loginWithAd().catch((error) => {
            if (error.errorCode === "interaction_in_progress") {
                toastLogoutInfo(localizer("loginView.supportlogin.microsoftLoginInProgress"));
            } else {
                toastError(localizer("loginView.supportlogin.somethingWentWrong"));
            }
        });
    };

    const blockFetchUserResourcesFlag = () => {
        sessionStorage.setItem("blockFetchUserResources", "true");
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setHasError(false);

        const with2fa = await appContext
            .loginB2C(email, password)
            .catch((error: string) => {
                setHasError(true);
                setError(error);
                // tslint:disable-next-line:no-console
                console.error(error);
            });
        if (with2fa) {
            setTwoFactorModalVisible(with2fa);
        }
    };

    const validateForm = (): boolean => {
        return email.length > 0 && password.length > 0;
    };

    const passwordFieldProhibitedErrorCodes = [ErrorCodes.NoPermissions];
    const emailFieldNoTextErrorCodes = [ErrorCodes.Locked];

    const getErrorMessage = (error: string) => {
        if (
            Object.values(ErrorCodes).find((code) => code.toString() === error)
        ) {
            return localizer(error);
        }
        return error;
    };

    return (
        <>
            <StyledLoginContainer>
                <StyledLoginForm onSubmit={handleSubmit} >
                    <ValidatedFormGroupWrapper
                        validationErrors={hasError ? [error] : []}
                        dependentErrorCodes={[ErrorCodes.AccessDenied, ErrorCodes.LoginFailed, ErrorCodes.NoPermissions, ErrorCodes.Locked]}
                        label={'loginView.email'}
                        hideMessage={emailFieldNoTextErrorCodes.some((code) => code === error)}
                    >
                        <TextField
                            data-qa="login_input_text"
                            type={'email'}
                            fieldName={Fields.Email}
                            value={email}
                            onChange={(_, value: any) => setEmail(value)}
                        />
                    </ValidatedFormGroupWrapper>
                    <ValidatedFormGroupWrapper
                        validationErrors={hasError ? [error] : []}
                        dependentErrorCodes={[ErrorCodes.AccessDenied, ErrorCodes.LoginFailed, ErrorCodes.NoPermissions, ErrorCodes.Locked]}
                        label={'loginView.password'}
                        hideMessage={passwordFieldProhibitedErrorCodes.some((code) => code === error)}
                    >
                        <TextField
                            data-qa="password_input_text"
                            type='password'
                            fieldName={Fields.Password}
                            value={password}
                            onChange={(_, value: any) => setPassword(value)}
                        />
                    </ValidatedFormGroupWrapper>
                    <StyledLoginButton
                        data-qa="sign_in_button"
                        disabled={!validateForm()}
                        type="submit"
                    >
                        <FormattedMessage id="loginView.login" />
                    </StyledLoginButton>
                </StyledLoginForm>
                <StyledDivider>
                    <span>
                        <FormattedMessage id="loginView.loginDivider" />
                    </span>
                </StyledDivider>
                <StyledMsButton variant="outline-dark" onClick={signInMsAdUser}>
                    <div className="ms-logo" />

                    <StyledSpan>
                        {localizer("loginView.loginSupport")}
                    </StyledSpan>
                </StyledMsButton>
                <br />
                <StyledATag
                    onClick={blockFetchUserResourcesFlag}
                    href={getResetPasswordLocalizedUrl()}
                >
                    {localizer("loginView.resetPassword")}
                </StyledATag>
            </StyledLoginContainer>
            {twoFactorModalVisible && (
                <ModalWrapper
                    closeCallback={() => setTwoFactorModalVisible(false)}
                    isOpen={twoFactorModalVisible}
                    modalContent={
                        <TwoFactorAuthForm email={email} password={password} />
                    }
                    modalTitle={localizer("twoFactorAuthForm.title")}
                    size={EModalSize.S}
                />
            )}
        </>
    );
};

export default Login;
