import React from 'react';
import { ITableProps } from '../../../ITableProps';
import { FormattedMessage } from 'react-intl';
import Table, { headerJustifyOptions } from '../../../../common/table/Table';
import DateFormatter from '../../../../common/table/formatters/DateFormatter';
import CaregiversActions from '../../caregivers/CaregiversActions';
import WardsFormatter from './formatters/WardsFormatter';
import UserNameFormatter from  '../../common/tables/formatters/UserNameFormatter';

const CareGiversTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="users.tableHeader.name" />,
                accessor: 'name',
                Cell: UserNameFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: false,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.assignedWards" />,
                accessor: 'assignedWardsIds',
                Cell: WardsFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.lastAccess" />,
                accessor: 'lastAccess',
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
                filterDisabled: true,
            },
            {
                Header: <FormattedMessage id="general.tableHeader.actions" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: 'id',
                Cell: CaregiversActions,
                disableSortBy: true,
                flexShrink: 3,
                filterDisabled: true,
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"caregivers_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: 'name',
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(CareGiversTable);
