export const LocalStorageKeys = {
    ResourceContext: "context",

    SelectedUsersNursingHomeId: "selectedUsersNursingHomeId",
    SelectedUsersAdministratorsOnly: "selectedUsersAdministratorsOnly",

    SelectedResidentsWardId: "selectedResidentsWardId",
}

export const cleanupLocalStorage = () => {
    localStorage.clear();
}