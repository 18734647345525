import { FormattedMessage } from "react-intl";
import styled from 'styled-components';
import { StyledAmount, StyledDescription, StyledTitle } from "../../components/common/informationBlock/styled";
import { StyledInlineSpaceBetween } from '../../components/styled';
import { isProdEnv } from "../../utils/EnvCheck";
import { EMPTY_VALUE } from '../../utils/Localizer';

const StyledAmountUnits = styled.div`
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    display: flex;
    align-items: stretch;
    display: contents;
`;

const StyledDetailedAmount = styled.div`
    width: 145px;
    margin-top: auto;
    justify-content: space-between;
    align-items: stretch;
`;

const StyledCustomDescription = styled(StyledDescription)`
    word-break: break-word;
    width: 145px;
`;

export interface IAverageResponseTimeInformationBlockProps {
    averageResponseTimeLast7DaysMinutes: number | null;
    averageProductChangesLast7Days: number | null;
}

const AverageResponseTimeInformationBlock = (props: IAverageResponseTimeInformationBlockProps) => {
    const averageResponseTimeLast7DaysMinutes = (!isProdEnv() && props.averageResponseTimeLast7DaysMinutes) ? String(props.averageResponseTimeLast7DaysMinutes) : "";
    const averageProductChangesLast7Days = (!isProdEnv() && props.averageProductChangesLast7Days) ? String(props.averageProductChangesLast7Days) : "";
    const round = (value: number, precision: number) => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };

    return (
        <>
            <div>
                <StyledTitle>
                    <FormattedMessage id={"residents.informationBlocks.title.avgPerResident"} />
                </StyledTitle>
                <StyledInlineSpaceBetween>
                    <StyledCustomDescription>
                        <FormattedMessage id={"residents.informationBlocks.subtitle.responseTime"} />
                    </StyledCustomDescription>
                    <StyledCustomDescription>
                        <FormattedMessage id={"residents.informationBlocks.subtitle.productChanges"} />
                    </StyledCustomDescription>
                </StyledInlineSpaceBetween>
            </div>
            <StyledInlineSpaceBetween>
                <StyledDetailedAmount title={averageResponseTimeLast7DaysMinutes}>
                    <StyledAmount>
                        {props.averageResponseTimeLast7DaysMinutes ? Math.round(props.averageResponseTimeLast7DaysMinutes) : EMPTY_VALUE}
                        <StyledAmountUnits>
                            {" "}<FormattedMessage id={'residents.informationBlocks.avgTime.minutes'} />
                        </StyledAmountUnits>
                    </StyledAmount>
                </StyledDetailedAmount>
                <StyledDetailedAmount title={averageProductChangesLast7Days}>
                    <StyledAmount>
                        {props.averageProductChangesLast7Days ? round(props.averageProductChangesLast7Days, 1).toString().replaceAll(".", ",") : EMPTY_VALUE}
                        <StyledAmountUnits>
                            / <FormattedMessage id={"residents.informationBlocks.avgTime.day"} />
                        </StyledAmountUnits>
                    </StyledAmount>
                </StyledDetailedAmount>
            </StyledInlineSpaceBetween>
        </>
    );
};

export default AverageResponseTimeInformationBlock;
