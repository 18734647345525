import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StyledTitle } from "../../components/common/informationBlock/styled";
import CheckIcon from "../../images/CheckIcon";
import DiaperIcon from "../../images/DiaperIcon";
import WarningIcon from "../../images/WarningIcon";
import { localizer } from "../../utils/Localizer";
import Colors from "./../../utils/Colors";

const StyledStatus = styled.div`
    gap: 13px;
    font-size: 16px;
    display: flex;
    align-items: stretch;
    height: 78px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 1px;
    color: ${Colors.black};
`;

const StyledCheckIcon = styled(CheckIcon)`
    width: 24px;
    height: 24px;
`;

const StyledImg = styled(DiaperIcon)`
    vertical-align: center;
    width: 24px;
    height: 15px;
`;

const StyledWarning = styled(WarningIcon)`
    vertical-align: center;
    width: 24px;
    height: 18px;
`;

const StyledSpan = styled.span`
    max-width: 208px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledUnevenIconSpan = styled(StyledSpan)`
    margin: 2px 0px -2px 0px;
`;

const StyledAmount = styled(StyledSpan)`
    width: 26px;
    text-align: left;
`;

const StyledUnevenIconAmount = styled(StyledAmount)`
    margin: 2px 0px -2px 0px;
`;

const StyledStatusSummaryTitle = styled(StyledTitle)`
    padding-bottom: 7px;
`;

export interface IStatusSummaryInformationBlockProps {
    amountOfSaturated: number;
    amountOfErrors: number;
    amountOfWarnings: number;
    amountOfSuccesses: number;
}

const StatusSummaryInformationBlock = (
    props: IStatusSummaryInformationBlockProps
) => (
    <>
        <StyledStatusSummaryTitle>
            <FormattedMessage
                id={"residents.informationBlocks.title.statusSummary"}
            />
        </StyledStatusSummaryTitle>
        <StyledStatus>
            <span>
                <StyledImg color={Colors.alert.red} />
            </span>
            <StyledUnevenIconAmount>{props.amountOfSaturated}</StyledUnevenIconAmount>
            <StyledUnevenIconSpan>- {localizer('brief.statusType.Saturated')}</StyledUnevenIconSpan>
        </StyledStatus>
        <StyledStatus>
            <span>
                <StyledWarning color={Colors.alert.red} />
            </span>
            <StyledUnevenIconAmount>{props.amountOfErrors}</StyledUnevenIconAmount>
            <StyledUnevenIconSpan>- {localizer('brief.statusType.Error')}</StyledUnevenIconSpan>
        </StyledStatus>
        <StyledStatus>
            <StyledCheckIcon color={Colors.alert.yellow} />
            <StyledAmount>{props.amountOfWarnings}</StyledAmount>
            <StyledSpan>- {localizer('brief.statusType.SomeUrine')}</StyledSpan>
        </StyledStatus>
        <StyledStatus>
            <StyledCheckIcon color={Colors.alert.green} />
            <StyledAmount>{props.amountOfSuccesses}</StyledAmount>
            <StyledSpan>- {localizer('brief.statusType.Ok')}</StyledSpan>
        </StyledStatus>
    </>
);

export default StatusSummaryInformationBlock;
