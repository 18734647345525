import ModalWrapper, { EModalSize } from "../../../common/modal/ModalWrapper";
import { localizer } from "../../../../utils/Localizer";
import SupportUserAddForm from "./SupportUserAddForm";
import { ReactComponent as ExtendIcon } from "../../../../images/extend.svg";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IContextStateType } from "../../../../reducers/contextSlice";
import Colors from "../../../../utils/Colors";

interface ISupportExtendIconProps {
    user: any;
}

const StyledIcon = styled.div<any>`
    font-size: 12px;
    color: ${(props) =>
        props.active ? Colors.blue.brandingDark : Colors.grey.disabledGrey};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    :hover {
        cursor: ${(props) => (props.active ? "pointer" : "default")};
    }
`;

const SupportExtendIcon = (props: ISupportExtendIconProps) => {
    const context = useSelector<RootState, IContextStateType>(
        (state) => state.contextSlice
    );

    const triggerComponent: React.ReactNode = (
        <StyledIcon
            data-placement="bottom"
            data-tip={
                context.userName === props.user.name
                    ? localizer("users.extension.tooltip")
                    : undefined
            }
            active={context.userName !== props.user.name}
        >
            <ExtendIcon
                fill={
                    context.userName !== props.user.name
                        ? Colors.blue.brandingDark
                        : Colors.grey.disabledGrey
                }
            />
            {localizer("users.extendButton")}
        </StyledIcon>
    );

    return (
        <ModalWrapper
            disabled={context.userName === props.user.name}
            triggerComponent={triggerComponent}
            modalContent={<SupportUserAddForm user={props.user} />}
            modalTitle={localizer("users.extendForm.title")}
            size={EModalSize.S}
        />
    );
};

export default SupportExtendIcon;
