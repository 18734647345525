import ProductSize from './ProductSize';
export default class Prod {
    public name: string;
    public padType: string;
    public absorptionClass: string;
    public productFamilyName: string;
    public ProductSizes: ProductSize[] = [];

    constructor(json: any, productFamilyName: string) {
        this.name = json.name;
        this.padType = json.padType;
        this.absorptionClass = json.absorptionClass;
        this.productFamilyName = productFamilyName
        if (json.sizes && Array.isArray(json.sizes)) {
            this.ProductSizes = json.sizes.map((obj: any) => new ProductSize(obj));
        }

    }
}