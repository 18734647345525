const Colors = {
    alert: {
        red: '#D8574C',
        redFill: '#FBF2F2',
        yellow: '#F2BA4C',
        green: '#6FCF97',
        grey: '#C4C4C4',
    },
    green: {
        dark: '#72BF44',
        bright: '#ACD58A',
        trendGreen: '#219653'
    },
    black: '#333333',
    blue: {
        lighter: '#E5ECF4',/* Mary/Dropdowns/Hover */
        light: '#D1D8E0',/* Mary/Dropdowns/Mouse down */
        dark: '#00488F',
        darkDisabled: '#AAC3D9',
        brandingLight: '#007DC5',
        brandingDark: '#1D388B',
        component: '#006DAE',
        tenaBlue: '#003B73',
        glowingBlue: '#BFD4E5',
        selectionBlue: '#189AFF',
        tailBlue: '#CCDAE9'

    },
    white:  {
        clearWhite: '#FFFFFF',
        hoverWhite: '#F8F9FA',
    },
    grey: {
        light: '#F2F4F5',
        lightGrey: "#E0E0E0",
        mediumLight: '#BDBDBD',/* Mary middle grey */
        medium: '#828282',/* Mary solid grey */
        dark: '#747474',
        disabledGrey: '#A2AEB8',
        almostWhite: '#EDEFF0',
    },
    red: {
        maryRed: '#BF4B48'
    },
    teal: {
        hover: '#80CBD9',
        selected: '#039AB2',
        tail: '#CCEAF1'
    }
};

export default Colors;
