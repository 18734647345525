import { configureStore, Middleware } from "@reduxjs/toolkit";
import logger from 'redux-logger';
import caregiverDeviceSlice from "./reducers/caregiverDeviceSlice";
import contextSlice from "./reducers/contextSlice";
import countrySlice from "./reducers/countrySlice";
import marketSlice from "./reducers/marketSlice";
import nursingHomeGroupSlice from "./reducers/nursingHomeGroupSlice";
import nursingHomeSlice from "./reducers/nursingHomeSlice";
import residentSlice from "./reducers/residentSlice";
import spinnerSlice from "./reducers/spinnerSlice";
import userSlice from "./reducers/userSlice";
import wardSlice from "./reducers/wardSlice";
import { isDevEnv, isLocalEnv } from "./utils/EnvCheck";

let middlewares: Middleware[] = [];

if (isDevEnv() || isLocalEnv()) {
    middlewares = [logger];
}

const store = configureStore({
    reducer: {
        contextSlice,
        nursingHomeSlice,
        userSlice,
        wardSlice,
        residentSlice,
        caregiverDeviceSlice,
        nursingHomeGroupSlice,
        marketSlice,
        spinnerSlice,
        countrySlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
