import React, { useState } from "react";
import './ResidentEditIcon.scss';
import ResidentEditForm from "./ResidentEditForm";
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Resident } from '../../models/Resident';
import { Icon } from '../../components/common/table/Icon';
import ModalWrapper, { EModalSize }  from "../../components/common/modal/ModalWrapper";
import { localizer } from "../../utils/Localizer";

interface IResidentEditIconProps {
    resident: Resident;
}

const ResidentEditIcon = (props: IResidentEditIconProps) => {
    const [modalSize, setModalSize] = useState<EModalSize>(EModalSize.M);

    const triggerComponent: React.ReactNode = (
        <Icon
            icon={faPencilAlt}
            text={"residents.editButton"}
            qaId={props.resident.name.toLowerCase() + ("-edit_button")}
        />
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={(
                <ResidentEditForm
                    entity={props.resident}
                    onModalSizeChange={setModalSize}
                />
            )}
            modalTitle={localizer("residents.editForm.title", { resident: props.resident?.name })}
            size={modalSize}
            fixedFooter={true}
        />
    );
};

export default ResidentEditIcon;
