import { convertMinutesToHours, convertTimeToString } from "../../../../utils/ConvertMinutesToHours";
import { StyledRight } from '../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledRight>
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                {convertTimeToString(convertMinutesToHours(value))}
            </TextOverflowWrapper>
        </StyledRight>
    );
};

const AverageResponseTimeFormatter = (data: any) => <CellFormatter data={data} />;
export default AverageResponseTimeFormatter;
