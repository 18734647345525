import moment, { Moment } from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
    Area,
    AreaChart, Bar, BarChart, CartesianGrid, LabelProps,
    Legend,
    LegendProps, ReferenceLine, ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import styled from "styled-components";
import { ReactComponent as SquareChartIcon } from "../../images/blockChartIcon.svg";
import { ReactComponent as LineChartIcon } from "../../images/lineChartIcon.svg";
import { ReactComponent as LineRefChartIcon } from "../../images/referenceLineChartIcon.svg";
import { KpiType } from "../../models/KpiType";
import Colors from "../../utils/Colors";
import { convertMinutesToHours, convertTimeToHtml } from "../../utils/ConvertMinutesToHours";
import { getLocale } from "../../utils/Localizer";
import AxisLabel from "./AxisLabel";
import "./Chart.scss";
import { getInterval, getXAxisFormat, selectedTimePeriodChartSwitcher } from "./chartHelper";

// constants for the chart
const chartHeight = 500;

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
`;

const StyledLegendDiv = styled.div`
    margin-top: 40px;
`;

const StyledLegendListItem = styled.li<any>`
    ${(props) => props.baseline ? "font-weight: 700;" : ""}
    list-style-type: none;
    margin-right: 70px;
`;

const StyledLegendText = styled.span`
    margin-left: 5px;
    font-size: 14px;
    color: ${props => props.color}
`;

const StyledLegendContainer = styled.div`
    font-size: 14px;
    text-align: left;
`;

const StyledText = styled.span`
    margin-left: 15px;
`;

const DifferenceText = styled.span`
    color: ${Colors.black};
    margin-left: 60px;

`;

const IconContainer = styled.span`
    width: 35px;
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;


    background-color: ${Colors.white.clearWhite};
    border: 1px solid ${Colors.grey.lightGrey};
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
`;

const TooltipInfo = styled.div<any>`
display: flex;
flex-direction: row;
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
    color: ${props => props.color};

`;

const TooltipDateContainer = styled.div`
    width: 90px;
`;

const TooltipValueContainer = styled.div`
    min-width: 120px;
    align: right;
`;

const LegendPeriodContainer = styled.span`
    width:200px;
    display: inline-block;
    text-transform: capitalize;
 `;

interface IYAxisLabelProps {
    labelTranslationKey: string;
}

const getFormattedValue = (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number | null) => {
    let formattedValue: any;
    if (kpiRawValue === null) {
        formattedValue = "--";
    } else {
        if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            formattedValue = convertTimeToHtml(
                convertMinutesToHours(kpiRawValue),
                true
            );
        } else if (selectedKpi === KpiType.AllProductChanges) {
            formattedValue = <>{(Math.round(kpiRawValue * 10) / 10)}/<FormattedMessage id={"constants.changeEventsFilter.kpi.day"} /></>;
        } else {
            formattedValue = Math.round(kpiRawValue) + (percentage ? ` ( ${Math.round(percentage)}%)` : "");
        }
    }
    return formattedValue;
};

const getFormattedTooltipValue = (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number) => {
    let formattedValue: any;
    if (kpiRawValue === null || kpiRawValue === 0) {
        formattedValue = "--";
    } else {
        if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            formattedValue = convertTimeToHtml(
                convertMinutesToHours(Math.round(kpiRawValue)),
                true
            );
        } else if (selectedKpi === KpiType.AllProductChanges) {
            formattedValue = <>{(Math.round(kpiRawValue * 10) / 10).toString() + " "}/<FormattedMessage id={"constants.changeEventsFilter.kpi.day"} /></>;
        } else {
            formattedValue = (
                <>
                    <span>
                        {Math.round(kpiRawValue)}
                    </span>
                    <span>
                        {" "} ({percentage} %)
                    </span>

                </>);
        }
    }
    return formattedValue;
};

const getFormattedDifference = (selectedKpi: KpiType, difference: number, percentageDifference?: number) => {
    let formattedValue;
    if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
        formattedValue = convertTimeToHtml(
            convertMinutesToHours(difference),
            true
        );
    } else if (selectedKpi === KpiType.PromptedProductChanges || selectedKpi === KpiType.UnpromptedProductChanges) {
        formattedValue = <>{Math.round(difference) + " "}<FormattedMessage id={"statistics.tooltip.changes"} />{(percentageDifference ? ` ( ${Math.round(percentageDifference)}%)` : "")}</>;
    } else {
        formattedValue = <>{Math.round(difference * 10) / 10}</>;
    }
    return formattedValue;
};

const YAxisLabel = (props: IYAxisLabelProps) => {
    return (
        <AxisLabel
            axisType="yAxis"
            x={10}
            y={chartHeight / 2 - 45}
            width={0}
            height={0}
            fill={Colors.black}
        >
            <FormattedMessage id={props.labelTranslationKey} />
        </AxisLabel>
    );
};

const CustomLegend = (legendProps: LegendProps & { legendPayload: any[], compareActive: boolean, selectedKpi: KpiType, targetKpi: number | undefined }) => {
    const { legendPayload, selectedKpi, targetKpi, iconType } = legendProps;
    const baselineLegend = legendPayload ? legendPayload[0] : undefined;
    const compareLegend = legendPayload ? legendPayload[1] : undefined;

    const getLegendText = (currentSelectedKpi: KpiType) => {
        if (currentSelectedKpi === KpiType.PromptedProductChanges) {
            return <FormattedMessage id="statistics.legend.prompted" />;
        } else if (currentSelectedKpi === KpiType.UnpromptedProductChanges) {
            return <FormattedMessage id="statistics.legend.unprompted" />;
        } else {
            return <FormattedMessage id="statistics.legend.average" />;
        }
    };

    return (
        <StyledLegendContainer>
            {selectedKpi === KpiType.ResponseTime && (
                <StyledLegendListItem key={"legend-item-reference-line"}>
                    <IconContainer><LineRefChartIcon /></IconContainer>
                    <StyledLegendText color={Colors.black} ><FormattedMessage id={"statistics.referenceLineLegendGroup.targetResponseTime"} values={{ kpiValue: targetKpi }} /></StyledLegendText>

                </StyledLegendListItem>

            )}
            {baselineLegend &&
                (<StyledLegendListItem baseline={true} key={"legend-item-0"}>
                    <IconContainer>
                        {iconType === "line" ? <LineChartIcon stroke={baselineLegend.color} /> : <SquareChartIcon stroke={baselineLegend.color} fill={baselineLegend.color} />}
                    </IconContainer>
                    <StyledLegendText color={baselineLegend.color}>
                        <LegendPeriodContainer>{baselineLegend.value.start} - {baselineLegend.value.end}</LegendPeriodContainer>
                        <StyledText>{getLegendText(selectedKpi)}: {baselineLegend.value.formattedValue}</StyledText>
                    </StyledLegendText>
                </StyledLegendListItem>)
            }
            {compareLegend && (
                <StyledLegendListItem key={"legend-item-1"}>
                    <IconContainer>
                        {iconType === "line" ? <LineChartIcon stroke={compareLegend.color} /> : <SquareChartIcon stroke={compareLegend.color} fill={compareLegend.color} />}
                    </IconContainer>
                    <StyledLegendText color={compareLegend.color}>
                        <LegendPeriodContainer>{compareLegend.value.start} - {compareLegend.value.end}</LegendPeriodContainer>
                        <StyledText>{getLegendText(selectedKpi)}: {compareLegend.value.formattedValue}</StyledText>
                        {compareLegend.value.formattedDifference && (

                            <DifferenceText><FormattedMessage id="statistics.legend.difference" />: {compareLegend.value.formattedDifference}</DifferenceText>
                        )}
                    </StyledLegendText>
                </StyledLegendListItem>

            )}
        </StyledLegendContainer>);
};

const CustomTooltip = (props: any) => {
    const { active, payload, selectedKpi } = props;
    if (active && payload && payload.length) {
        const baseline = payload[0];
        const compare = payload[1];
        const locale = getLocale();
        return (
            <TooltipContainer>
                <TooltipInfo color={Colors.blue.brandingDark}>
                    <TooltipDateContainer>{moment(baseline.payload.date).locale(locale).format("DD MMM YYYY")}</TooltipDateContainer>
                    <TooltipValueContainer>{getFormattedTooltipValue(baseline.value, selectedKpi, baseline.payload.selectedWardPercentage)}</TooltipValueContainer>
                </TooltipInfo>
                {compare && (
                    <TooltipInfo color={Colors.teal.selected}>
                        <TooltipDateContainer>{moment(compare.payload.compareDate).locale(locale).format("DD MMM YYYY")}</TooltipDateContainer>
                        <TooltipValueContainer>{getFormattedTooltipValue(compare.value, selectedKpi, compare.payload.selectedCompareWardPercentage)}</TooltipValueContainer>
                    </TooltipInfo>
                )}
            </TooltipContainer>
        );
    }

    return null;
};


const XAxisLabel = (props: { x: number, y: number, dateStrings: string[] }) => (
    <g transform={`translate(${props.x},${props.y})`}>
        <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={Colors.grey.medium}
            fontSize={14}
            style={{ textTransform: "capitalize" }}
        >
            {props.dateStrings[0]}
        </text>
        <text
            x={0}
            y={15}
            dy={16}
            textAnchor="middle"
            fill={Colors.grey.medium}
            fontSize={14}
            style={{ textTransform: "capitalize" }}
        >
            {props.dateStrings[1]}
        </text>

    </g>
);

interface IChartProps {
    chartData: any[];
    maxYAxisValue: number;
    targetKpiValue?: number;
    selectedKpi: KpiType;
    selectedKpiValue: number | null;
    compareKpiValue: number | null;
    interval: number;
    startDate: Moment;
    compareStartDate: Moment | null;
    endDate: Moment;
    compareEndDate: Moment | null;
    percentageKpiValue?: number | null;
    comparePercentageKpiValue?: number | null;
}

export const Chart = (props: IChartProps) => {

    // create the custom legend for the chart.
    const legendPayload: any[] = [];
    const currentLocale = getLocale();


    const orderedData = props.chartData.sort((a, b) =>
        moment(a.date).isAfter(moment(b.date)) ? 1 : -1
    );

    const yAxisLabel: LabelProps = {
        content: (
            <YAxisLabel
                labelTranslationKey={
                    props.selectedKpi === KpiType.ResponseTime || props.selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges
                        ? "statistics.chart.minutes"
                        : "statistics.chart.changes"
                }
            />
        )
    };

    const xAxisInterval = getInterval(props.interval);

    const start = props.startDate.locale(currentLocale).format("DD MMM YYYY");
    const end = props.endDate.locale(currentLocale).format("DD MMM YYYY");
    const selectedKpi = props.selectedKpi;
    const targetKpi = props.targetKpiValue;
    const formattedValue = getFormattedValue(props.selectedKpiValue, props.selectedKpi, props.percentageKpiValue);
    const compareFormattedValue = getFormattedValue(props.compareKpiValue, props.selectedKpi, props.comparePercentageKpiValue);
    const percentageDifference = props.percentageKpiValue && props.comparePercentageKpiValue ? Math.abs(props.percentageKpiValue - props.comparePercentageKpiValue) : undefined;
    const formattedDifference = props.selectedKpiValue && props.compareKpiValue ? getFormattedDifference(props.selectedKpi, Math.abs(props.selectedKpiValue - props.compareKpiValue), percentageDifference) : undefined;

    legendPayload.push({
        id: 1,
        value: {
            formattedValue,
            start,
            end,
            percentage: props.percentageKpiValue
        },
        type: "square",
        color: Colors.blue.brandingDark
    });
    if (props.compareStartDate && props.compareEndDate) {
        legendPayload.push({
            id: 2,
            value: {
                formattedValue: compareFormattedValue,
                start: props.compareStartDate.locale(currentLocale).format("DD MMM YYYY"),
                end: props.compareEndDate.locale(currentLocale).format("DD MMM YYYY"),
                formattedDifference,
                percentage: props.comparePercentageKpiValue
            },
            type: "square",
            color: Colors.teal.selected
        });
    }

    const chart: React.ReactNode = (
        <ResponsiveContainer width="90%" height={chartHeight}>
            {props.interval > selectedTimePeriodChartSwitcher ?
                (
                    <AreaChart
                        margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
                        data={orderedData}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="35%" stopColor={Colors.blue.brandingDark} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={Colors.blue.brandingDark} stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke={Colors.grey.lightGrey} vertical={false} />
                        <Tooltip content={<CustomTooltip selectedKpi={props.selectedKpi} />} />
                        <Area
                            dataKey={"selectedWard"}
                            type={"monotone"}
                            strokeWidth={3}
                            stroke={Colors.blue.brandingDark}
                            fill={"url(#colorUv)"}
                            strokeLinejoin="miter"
                            activeDot={(props) => <circle cx={props.cx} cy={props.cy} r={4} stroke={Colors.blue.brandingDark} strokeWidth={3} fill="white" />}
                        />
                        {props.compareStartDate && props.compareEndDate && (
                            <Area
                                dataKey={"selectedCompareWard"}
                                type={"monotone"}
                                strokeWidth={3}
                                stroke={Colors.teal.selected}
                                fill={"transparent"}
                                strokeLinejoin="miter"
                                activeDot={(props) => <circle cx={props.cx} cy={props.cy} r={4} stroke={Colors.teal.selected} strokeWidth={3} fill="white" />}
                            />
                        )}
                        <XAxis
                            height={70}
                            dataKey="date"
                            interval={xAxisInterval}
                            tick={(props) => {
                                const tickMoment = moment(props.payload.value).locale(currentLocale);
                                const formattedValue = tickMoment.format(getXAxisFormat(xAxisInterval)).toString();
                                const dateStrings = formattedValue.split(" ");
                                return <XAxisLabel x={props.x} y={props.y} dateStrings={dateStrings} />;
                            }}
                        />
                        <YAxis
                            domain={[0, props.maxYAxisValue]}
                            type="number"
                            interval={0}
                            orientation="left"
                            tickCount={8}
                            allowDecimals={false}
                            label={yAxisLabel}
                            tick={{ fill: Colors.grey.medium, fontSize: 14 }}
                        />
                        {(props.selectedKpi === KpiType.ResponseTime) && (
                            <ReferenceLine
                                y={props.targetKpiValue}
                                stroke={Colors.grey.medium}
                                strokeDasharray="4 4"
                            />
                        )}
                    </AreaChart>
                )

                : (
                    <BarChart
                        margin={{ top: 30, right: 20, bottom: 0, left: 0 }}
                        data={orderedData}
                        barGap={0}
                    >
                        <defs>
                            <linearGradient id="baselineColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="45%" stopColor={Colors.blue.brandingDark} stopOpacity={1} />
                                <stop offset="95%" stopColor={Colors.blue.brandingDark} stopOpacity={0.9} />
                            </linearGradient>
                            <linearGradient id="compareColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="45%" stopColor={Colors.teal.selected} stopOpacity={1} />
                                <stop offset="95%" stopColor={Colors.teal.selected} stopOpacity={0.8} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke={Colors.grey.lightGrey} vertical={false} />
                        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip selectedKpi={props.selectedKpi} />} />
                        <XAxis
                            height={70}
                            dataKey="date"
                            interval={xAxisInterval}
                            tick={(props) => {
                                const tickMoment = moment(props.payload.value).locale(currentLocale);
                                const formattedValue = tickMoment.format(getXAxisFormat(xAxisInterval)).toString();
                                const dateStrings = formattedValue.split(" ");
                                return <XAxisLabel x={props.x} y={props.y} dateStrings={dateStrings} />;
                            }}
                        />
                        <YAxis
                            domain={[0, props.maxYAxisValue]}
                            type="number"
                            interval={0}
                            orientation="left"
                            tickCount={8}
                            allowDecimals={false}
                            label={yAxisLabel}
                            tick={{ fill: Colors.grey.medium, fontSize: 14 }}
                        />
                        <Bar
                            dataKey={"selectedWard"}
                            barSize={20}
                            fill={"url(#baselineColor)"}
                        />
                        {props.compareStartDate && (
                            <Bar
                                dataKey={"selectedCompareWard"}
                                barSize={20}
                                fill={"url(#compareColor)"}
                            />
                        )}
                        {(props.selectedKpi === KpiType.ResponseTime) && (
                            <ReferenceLine
                                y={props.targetKpiValue}
                                stroke={Colors.grey.medium}
                                strokeDasharray="4 4"
                                strokeWidth={2}
                            />
                        )}
                    </BarChart>
                )}
        </ResponsiveContainer>
    );

    return (
        <div style={{ position: "relative" }}>
            <StyledContainer>
                {chart}
                <StyledLegendDiv>
                    <Legend
                        wrapperStyle={{ marginLeft: "120px" }}
                        align="left"
                        verticalAlign={"bottom"}
                        content={<CustomLegend legendPayload={legendPayload} compareActive={true} targetKpi={targetKpi} selectedKpi={selectedKpi} iconType={props.interval > 15 ? "line" : "square"} />}
                    />
                </StyledLegendDiv>
            </StyledContainer>
        </div>
    );
};
