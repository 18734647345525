import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { StyledATag } from "../components/common/View";
import { AppContext, IAppState } from "../data/AppRepository";
import logoutIcon from "../images/logout-icon.svg";
import questionIcon from "../images/question-icon.svg";
import { RootState } from "../store";
import Colors from "../utils/Colors";
import { localizer } from "../utils/Localizer";
import AboutBlock from "./AboutBlock/AboutBlock";
import LanguageSelector from "./LanguageSelector";
import LogoAndTitle from "./LogoAndTitle";
import LowerBarButton from "./LowerBarButton";
import BasicModal from "./Modals/BasicModal";
import "./TitleBar.scss";
import UserResourceContextSelector from "./UserResourceContextSelector";

const StyledTitleBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

const StyledUpperBarBackground = styled.div`
    width: auto;
    max-width: 100vw;
    height: 26px;
    background: ${Colors.blue.dark};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledUpperBar = styled.div`
    background: ${Colors.blue.dark};
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const StyledLowerBarBackground = styled.div`
    width: auto;
    max-width: 100vw;
    height: auto;
    min-height: 100px;
    background: linear-gradient(
        90deg,
        ${Colors.blue.brandingLight} 0.39%,
        ${Colors.blue.brandingDark} 100%
    );
    display: flex;
    justify-content: center;
    align-items: stretch;
`;
const StyledLowerBar = styled.div`
    max-width: 1440px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: ${Colors.white.clearWhite};
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const StyledNursingHomeSelectorAndLowerBarButtonsWrapper = styled.div`
    width: auto;
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-end;
    }
`;

const LowerBarItemsMargin = 15;
const LowerBarItemsSideMargin = 40;

const StyledLowerBarButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: ${LowerBarItemsMargin}px ${LowerBarItemsSideMargin}px;
    width: calc(100vw - ${2 * LowerBarItemsSideMargin}px);

    @media (min-width: 768px) {
        width: auto;
        margin-left: auto;
        margin-bottom: ${LowerBarItemsMargin}px;
        > *:not(:first-child) {
            margin-left: 25px;
        }
    }
`;

const StyledLowerBarNursingHomeSelector = styled.div`
    display: flex;
    justify-content: center;
    margin: ${LowerBarItemsMargin}px ${LowerBarItemsSideMargin}px;
    width: calc(100vw - ${5 * LowerBarItemsSideMargin}px);

    @media (min-width: 768px) {
        width: auto;
        margin-bottom: ${LowerBarItemsMargin}px;
    }
`;
const BRAND = "SmartCare™";

const TitleBar = () => {
    const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
    const appContext = useContext(AppContext);
    const isAuthenticated = useSelector<RootState, boolean>(
        (state) => state.contextSlice.isAuthenticated
    );
    useEffect(() => {
        setShowHelpModal(false);
    }, [isAuthenticated]);

    const handleHelpClicked = () => {
        setShowHelpModal((showHelpModal) => !showHelpModal);
    };
    const getModal = () => (
        <BasicModal
            modalClass="help-popup"
            classBody="help-modal-body"
            title={<FormattedMessage id="titleBar.help.modalTitle" />}
            show={showHelpModal}
            handleOnHide={handleHelpClicked}
        >
            <p>
                <FormattedMessage
                    id="help.description.one"
                    values={{ brand: BRAND }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="help.description.two"
                    values={{ brand: BRAND }}
                />
            </p>
            <div>
                <StyledATag
                    href={localizer("help.quickGuide.link")}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="help.quickGuide.linkText" />
                </StyledATag>
            </div>
            <div>
                <StyledATag
                    href={localizer("help.ifu.link")}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="help.userInstructions.linkText" />
                </StyledATag>
            </div>
            <div>
                <StyledATag
                    href={`${process.env.REACT_APP_HELP_BASE_URL}/${localizer(
                        "help.instructionVideo.file"
                    )}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FormattedMessage id="help.instructionVideo.linkText" />
                </StyledATag>
            </div>
            <AboutBlock />
        </BasicModal>
    );

    const getLowerBarButtons = (appContext: IAppState) => (
        <StyledLowerBarButtons>
            <LowerBarButton
                qaId="mainNav_help_button"
                text="mainNav.help"
                iconSource={questionIcon}
                onClick={handleHelpClicked}
            />
            <LowerBarButton
                qaId="mainNav_logout_button"
                text="mainNav.logout"
                iconSource={logoutIcon}
                onClick={() => logout(appContext)}
            />
        </StyledLowerBarButtons>
    );

    const logout = (appContext: IAppState) => {
        appContext.logout();
    };

    return (
        <StyledTitleBar>
            <StyledUpperBarBackground>
                <StyledUpperBar>
                    <LanguageSelector />
                    <div id="portal-connected-indicator" />
                </StyledUpperBar>
            </StyledUpperBarBackground>
            <StyledLowerBarBackground>
                <StyledLowerBar>
                    <LogoAndTitle />
                    <StyledNursingHomeSelectorAndLowerBarButtonsWrapper>
                        {isAuthenticated && (
                            <>
                                <StyledLowerBarNursingHomeSelector>
                                    <UserResourceContextSelector />
                                </StyledLowerBarNursingHomeSelector>
                                {getLowerBarButtons(appContext)}
                                {getModal()}
                            </>
                        )}
                    </StyledNursingHomeSelectorAndLowerBarButtonsWrapper>
                </StyledLowerBar>
            </StyledLowerBarBackground>
        </StyledTitleBar>
    );
};

export default TitleBar;
