import moment, { Moment } from "moment";
import DropdownDateTimeRangeButton, { IDropdownDateTimeRangeButtonProps } from './helpers/DropdownDateTimeRangeButton';

const DropdownDateTimeDaysInThePastFromNowRangeButton = (props: IDropdownDateTimeRangeButtonProps & { endPickerPropertiesInitialMaxDate?: Moment}) => {
    const amountOfDays = (props.amountOfDays !== undefined ? props.amountOfDays : 90);
    return (
        <DropdownDateTimeRangeButton
            {...props}
            startPickerPropertiesInitialMinDate={moment().subtract(amountOfDays, "d").toDate()}
            endPickerPropertiesInitialMaxDate={props.endPickerPropertiesInitialMaxDate ? props.endPickerPropertiesInitialMaxDate : moment()}
            amountOfDays={undefined}
        />
    );
};

export default DropdownDateTimeDaysInThePastFromNowRangeButton;
