import { memo } from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const StyledBlockingSpinner = styled.div`
    $spinner-size: 80px;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(237, 239, 240, 0.71);
    position: fixed;
    z-index: 9999;
    div {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 10000;
        margin: -$spinner-size/2;
        width: $spinner-size;
        height: $spinner-size;
        svg {
        }
    }
`;

export const BlockingSpinner = memo(({ isVisible }: { isVisible: boolean }) => {
    return isVisible ? (
        <StyledBlockingSpinner>
            <Spinner animation="border" variant="primary" data-qa="spinner" />
        </StyledBlockingSpinner>
    ) : null;
});
