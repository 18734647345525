import { localizer } from '../../../../utils/Localizer';
import ModalWrapper, { EModalSize } from '../../../common/modal/ModalWrapper';
import { StyledAddButton } from "../../../styled";
import SupportUserAddForm from  "./SupportUserAddForm";

const SupportUserAddButton = () => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" data-qa="add_support_user_button"/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={(
                <SupportUserAddForm/>
            )}
            modalTitle={`${localizer("users.addButton.Support")}`}
            size={EModalSize.S}
        />
    );
};

export default SupportUserAddButton;
