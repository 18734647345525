import Moment from "moment";
import { convertMinutesToHours, convertTimeToString } from '../../../../../../utils/ConvertMinutesToHours';
import { EMPTY_VALUE } from '../../../../../../utils/Localizer';

export const countTimeDifference = (startDate: Date, endDate: Date) => {
    const timeDifference = calculateDiffInMinutes(startDate, endDate);
    return convertTimeToString(convertMinutesToHours(timeDifference, EMPTY_VALUE, false));
};

const calculateDiffInMinutes = (startDate: Date, endDate: Date) => {
    return Math.round(Moment.duration(((Moment(startDate).diff(endDate).valueOf()))).asMinutes());
};