import { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import ErrorCodes from "../../../../models/ErrorCodes";
import User, { UserRegexp } from "../../../../models/User";
import ValidatedFormGroupWrapper from '../../../wrappers/ValidatedFormGroupWrapper';
import AddEditButtonGroup from '../../../../views/Buttons/AddEditButtonGroup';
import TextFieldNew from '../../../common/controls/TextFieldNew';
import { grantAccess, NursingHomeRole } from "../../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { FormattedMessage } from "react-intl";
import { StyledFormButtons } from "../../../common/styled";

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    Name = 'Name',
}

export interface IUserAddFormProps {
    assignedRole?: NursingHomeRole;
    hideModal?: () => void;
}

const UserAddForm = (props: IUserAddFormProps) => {
    const users = useSelector<RootState, User[]>(state => state.userSlice.users);
    const userName = useSelector<RootState, string>(state => state.contextSlice.userName);

    const onDiscard = (event: any) => {
        event.preventDefault();

        if (props.hideModal) {
            props.hideModal();
        }
    };

    const dispatch = useDispatch();

    const [name, setName] = useState<string>('');
    const [validationErrors, setErrors] = useState<string[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const clearFormData = () => {
        setName('');
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!name) {
            errors.push(ErrorCodes.FaultyNameErrorEmpty);
        }

        if ((name && name.length > 0) && !UserRegexp.EmailRegexp.test(name.trim().toLowerCase())) {
            errors.push(ErrorCodes.FaultyNameErrorEmail);
        }

        if(users.some(user => user.name.toLocaleLowerCase() === name.toLocaleLowerCase() )) {
            errors.push(ErrorCodes.UserAlreadyExists);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const confirmation = (addMore: boolean) => {
        if (name.toLocaleLowerCase() === userName.toLocaleLowerCase()) {
            setShowConfirmation(true);
        } else {
            sendUser(addMore);
        }
    };

    const sendUser = (addMore: boolean, shouldLogoutAfter?: boolean) => {
        dispatch(grantAccess(name, props.assignedRole, undefined, undefined, undefined, undefined, shouldLogoutAfter));
        if (addMore) {
            clearFormData();
        } else if (props.hideModal) {
            props.hideModal();
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        confirmation(false);
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        confirmation(true);
    };


    const handleChange = (name: string, value: any) => {
        switch (name) {
            case Fields.Name:
                setName(value);
                break;
        }
    };

    return (
        <StyledFormContainer>
            <Form autoComplete="false">
                {showConfirmation ? (
                    <>
                        <div className="confirm-delete-text">
                            <FormattedMessage id="users.addForm.logoutConfirmation" />
                        </div>
                        <StyledFormButtons>
                            <Button
                                data-qa="confirm_delete_button"
                                variant={"primary"}
                                type="button"
                                className="confirm-button"
                                onClick={() => sendUser(false, true)}
                            >
                                <FormattedMessage id="general.yes" />
                            </Button>
                            <Button
                                data-qa="cancel_delete_button"
                                variant={"outline-secondary"}
                                type="button"
                                className="discard-button"
                                onClick={() => setShowConfirmation(false)}
                            >
                                <FormattedMessage id="general.no" />
                            </Button>
                        </StyledFormButtons>
                    </>
                ) : (
                    <>
                        <Row className="row g-0">
                            <ValidatedFormGroupWrapper
                                validationErrors={validationErrors}
                                dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmail, ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists]}
                                label={'users.editForm.email'}
                            >
                                <TextFieldNew
                                    data-qa="user_email_input_text"
                                    fieldName={Fields.Name}
                                    disabled={false}
                                    value={name}
                                    onChange={handleChange}
                                />
                            </ValidatedFormGroupWrapper>
                        </Row>
                        <Row className="row g-0 mt-3">
                            <AddEditButtonGroup
                                isEdit={false}
                                onSave={onSave}
                                onSaveAndAddAnother={onSaveAndAddAnother}
                                onDiscard={onDiscard}
                                extraActions={[]} />
                        </Row>
                    </>
                )}
            </Form>
        </StyledFormContainer>
    );
};

export default UserAddForm;
