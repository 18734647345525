import { useContext, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import TextFieldNew from "../components/common/controls/TextFieldNew";
import ValidatedFormGroupWrapper, {
    IErrors,
} from "../components/wrappers/ValidatedFormGroupWrapper";
import { AuthRestClient } from "../data/RestClient";
import { AppContext } from "../data/AppRepository";
import ErrorCodes from "../models/ErrorCodes";
import { TwoFAValidationCodeLength } from "../utils/Constants";
import { toastError } from "../utils/Toaster";
import { localizer } from "../utils/Localizer";

import {
    fetchUserResources,
    setInactiveTimeInMinutesBeforeLogout,
} from "../reducers/contextSlice";
import AddEditButtonGroup from "../views/Buttons/AddEditButtonGroup";
import { FormattedMessage } from "react-intl";

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    VerificationCode = "VerificationCode",
}

const TwoFactorAuthForm = (props: any) => {
    const appContext = useContext(AppContext);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [validationErrors, setErrors] = useState<IErrors[]>([]);

    const dispatch = useDispatch();

    const onRequestNewCode = (event: any) => {
        event.preventDefault();
        setErrors([]);
        appContext
            .loginB2C(props.email, props.password)
            .catch((error: string) => {
                // tslint:disable-next-line:no-console
                console.error(error);
            });
    };
    const validate = (response: any): Promise<boolean> => {
        const errors: IErrors[] = [];
        if (response.status !== 401) {
            const { remainingAttempts, isCodeValid } = response;
            if (!isCodeValid) {
                if (remainingAttempts !== 0) {
                    errors.push({
                        dependentErrorCode: ErrorCodes.WrongValidationCode,
                        values: { remainingAttempts },
                    });
                } else {
                    errors.push({
                        dependentErrorCode: ErrorCodes.ExpiredValidationCode,
                    });
                }
            }
        } else {
            errors.push({
                dependentErrorCode: ErrorCodes.ExpiredValidationCode,
            });
        }
        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        try {
            const response = await AuthRestClient.verifyCode(
                props.email,
                props.password,
                verificationCode
            );
            if (response && response.sessionTimeoutMinutes) {
                dispatch(
                    setInactiveTimeInMinutesBeforeLogout(
                        response.sessionTimeoutMinutes
                    )
                );
                dispatch(fetchUserResources());
                sessionStorage.removeItem("blockFetchUserResources");
            } else {
                const isValid = await validate(response);
                if (!isValid) {
                    return;
                }
            }
        } catch (error: any) {
            toastError(localizer(error));
        }
    };

    const handleChange = (verificationCode: string, value: any) => {
        switch (verificationCode) {
            case Fields.VerificationCode:
                setVerificationCode(value);
                break;
        }
    };

    return (
        <StyledFormContainer>
            <Form autoComplete="false">
                <>
                    <Row className="row g-0">
                        <p>
                            <FormattedMessage
                                id={"twoFactorAuthForm.description"}
                                values={{ email: props.email }}
                            />
                            <br/>
                            <FormattedMessage
                                id={"twoFactorAuthForm.instruction"}
                            />
                        </p>
                    </Row>
                    <Row className="row g-0">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.WrongValidationCode,
                                ErrorCodes.ExpiredValidationCode,
                            ]}
                            label={"twoFactorAuthForm.verificationCode"}
                        >
                            <TextFieldNew
                                data-qa="2fa_code_input_text"
                                fieldName={Fields.VerificationCode}
                                disabled={false}
                                placeholder={localizer("twoFactorAuthForm.code")}
                                value={verificationCode}
                                onChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="row g-0 mt-3">
                        <AddEditButtonGroup
                            isEdit={false}
                            onSave={onSave}
                            removeCloseButton={true}
                            extraActions={[
                                {
                                    label: "twoFactorAuthForm.requestCode",
                                    action: onRequestNewCode,
                                },
                            ]}
                            customConfirmButtonMessageId="twoFactorAuthForm.continue"
                            disabledConfirm={verificationCode.length !== TwoFAValidationCodeLength}
                        />
                    </Row>
                </>
            </Form>
        </StyledFormContainer>
    );
};

export default TwoFactorAuthForm;
