import { SetupFields } from "./SetupView";
import { FormattedMessage } from 'react-intl';
import { NursingHome } from "../../models/NursingHome";
import { localizer } from "../../utils/Localizer";
import styled from 'styled-components';
import DayIcon from "../../images/dayIcon";
import NightIcon from "../../images/nightIcon";
import { Switcher } from "../Residents/CellFormatters/Switcher";
import { Row, Col } from "react-bootstrap";
import ErrorCodes from "../../models/ErrorCodes";

const StyledNotificationContainer = styled(Row)<any>`
    flex-direction: ${props => props.horizontal ? "row" : "column"};

    .react-select__control {
        height: 42px;
        padding-bottom: 32px;
    }
`;

const StyledTitleWithIcon = styled.div<any>`
    display: flex;
    padding-top: ${props => `${props.paddingTop}px` || 0};

    svg {
        margin-right: 4px;
    }
`;

const StyledSwitcherBlock = styled.div`
    margin-bottom: 24px;
`;

const StyledLabel = styled.label`
    margin-bottom: .5rem;
`;

interface INotificationsProps {
    nursingHome: NursingHome;
    handleChange: (fieldName: string, result: string) => void;
    validationErrors: string[];
    readonly?: boolean;
    horizontal?: boolean;
}

const pluralOrSingular = (value: number, plural: string, singular: string) => value > 1 ? localizer(plural) : localizer(singular);

const getMinutesLabel = (value: number) => pluralOrSingular(value, 'constants.minutes', 'constants.minute');

const valueToLabelMapper = (value: number, formatter: (v: number) => string) => ({
    name: value !== 0 ? `${value} ${formatter(value)}` : localizer('monitoring.off'),
    value
});

export const getCaregiverResponseTargetTimes = () => NursingHome.caregiverResponseTargetTimes.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getSupervisorResponseTargetTimes = () => NursingHome.supervisorResponseTargetTimes.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getSupervisorNotificationIntervals = () => NursingHome.supervisorNotificationIntervals.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getCaregiverNotificationIntervals = () => NursingHome.caregiverNotificationIntervals.map(val => valueToLabelMapper(val, getMinutesLabel));

const Notifications = (props: INotificationsProps) => {
    const getError = (error: ErrorCodes): string | undefined => {
        const validationError = props.validationErrors.find(err => err.startsWith(error));
        if (validationError !== undefined) {
            return validationError;
        }
        return;
    };

    return (
        <StyledNotificationContainer horizontal={props.horizontal}>
            <Col sm={12} md={props.horizontal ? 6 : 12}>
                <StyledTitleWithIcon>
                    <NightIcon />
                    <h3 className="fields-box-title"><FormattedMessage id="setup.notification.nightNotificationsTitle" /></h3>
                </StyledTitleWithIcon>

                {/* Caregiver response target time */}
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverResponseTarget" /></StyledLabel>
                    <Switcher
                        id={"night-caregiverResponseTarget"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverResponseTargetTimes()}
                        radioValue={getCaregiverResponseTargetTimes().find(option => String(option.value) === String(props.nursingHome.caregiverResponseTargetMinutes))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(SetupFields.CaregiverResponseTarget, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverResponseTargetMinutes)}
                    />
                </StyledSwitcherBlock>

                {/* Caregiver notification interval */}
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverNotificationInterval" /></StyledLabel>
                    <Switcher
                        id={"night-caregiverNotificationInterval"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverNotificationIntervals()}
                        radioValue={getCaregiverNotificationIntervals().find(option => String(option.value) === String(props.nursingHome.caregiverNotificationIntervalMinutes))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(SetupFields.CaregiverNotificationInterval, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverNotificationIntervalMinutes)}
                    />
                </StyledSwitcherBlock>
            </Col>

            <Col sm={12} md={props.horizontal ? 6 : 12}>
                <StyledTitleWithIcon paddingTop={props.horizontal ? 0 : 50}>
                    <DayIcon />
                    <h3 className="fields-box-title"><FormattedMessage id="setup.notification.dayNotificationsTitle" /></h3>
                </StyledTitleWithIcon>

                {/* Caregiver response target time (day) */}
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverResponseTarget" /></StyledLabel>
                    <Switcher
                        id={"day-caregiverResponseTarget"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverResponseTargetTimes()}
                        radioValue={getCaregiverResponseTargetTimes().find(option => String(option.value) === String(props.nursingHome.caregiverResponseTargetMinutesDay))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(SetupFields.CaregiverTargetResponseTimeDay, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverResponseTargetMinutesDay)}
                    />
                </StyledSwitcherBlock>

                {/* Caregiver notification interval */}
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverNotificationInterval" /></StyledLabel>
                    <Switcher
                        id={"day-caregiverNotificationInterval"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverNotificationIntervals()}
                        radioValue={getCaregiverNotificationIntervals().find(option => String(option.value) === String(props.nursingHome.caregiverNotificationIntervalMinutesDay))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(SetupFields.CaregiverNotificationIntervalDay, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverNotificationIntervalMinutesDay)}
                    />
                </StyledSwitcherBlock>

                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.eightHourNotification" /></StyledLabel>
                    <Col sm={6} md={6}>
                        <Switcher
                            id={"eightHourNotification"}
                            disabled={props.readonly}
                            fluid
                            radios={[
                                {
                                    name: localizer('monitoring.off'),
                                    value: 0
                                },
                                {
                                    name: localizer('monitoring.on'),
                                    value: 1
                                }]}
                            radioValue={Number(props.nursingHome.notifyOfSomeUrineStatusOverdue)}
                            setMonitoringStatus={(value: any) => props.handleChange(SetupFields.EightHourNotification, value)}
                        />
                    </Col>
                </StyledSwitcherBlock>
            </Col>
        </StyledNotificationContainer>
    );
};

export default Notifications;
